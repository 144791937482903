import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserAuthModel } from 'src/app/datamodels/userModel';
import { TeambuilderService } from 'src/app/services/teambuilder.service';

@Component({
  selector: 'app-teams-page',
  templateUrl: './teams-page.component.html',
  styleUrls: ['./teams-page.component.css']
})
export class TeamsPageComponent implements OnInit {
  @Input() passGstId: any;
  gstId: string = 'AWIDNi214124142'
  allTeams: any = [];
  isCurrentUserAdmin: boolean;
  copyReferral: string;
  constructor(
    private tbs: TeambuilderService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) { }
  // this.teamPositionForm = new FormGroup({
  //   title: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
  //   teamPosition: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
  // })
  ngOnInit(): void {
    const gstId = this.passGstId.GST_Id
    this.isCurrentUserAdmin = this.passGstId.isCurrentUserAdmin
    this.getTeamForCompany(gstId)
    this.copyReferral = `Hey! Our business is on Myapaar. Invite you as my team member. Sign Up now with our GST and get verified.`+'👉🏾 '+'https://www.myapaar.com/signup';
  }
  getTeamForCompany(gstId: string) {
    try {
      this.tbs.getTeamAccounts(gstId).then(teams => {
        this.allTeams = teams.teamUsers
      })
    }
    catch (e) {
      throw e
    }
  }

  editTeams() {
    if (this.isCurrentUserAdmin) {
      this.tbs.updateTeamSettings(this.allTeams)
      this.router.navigate(['/EditTeam', this.gstId])
    }
    else {
      this._snackBar.open('Admin access is requested.', 'solutions@myapaar.com', {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
    }
  }
  successCopyToClipboard(success: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(success, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }
}
