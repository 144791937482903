import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { StateModel } from 'src/app/datamodels/Statemodel';

// import {ALLPRODUCTS} from '../../datamodels/allproducts';
// import { ProductCategory } from 'src/app/datamodels/productcategory';
// import { PRODUCTCATEGORIES } from '../../datamodels/productcategories';
import { ProductService } from '../../services/product.service';
import { Observable, Subject } from 'rxjs';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { ProductsdialogmanagerService } from 'src/app/services/productsdialogmanager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AllstatesService } from 'src/app/services/allstates.service';
import { QuickViewServiceService } from 'src/app/services/quick-view-service.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
interface loadingReason {
  id: number;
  reason: string
}
@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['./allproducts.component.css']
})
export class AllproductsComponent implements OnInit, OnDestroy {

  //general use
  allproducts: Product[] = [];
  allstates: StateModel[]
  modalFilterValue: any[]
  selectedProduct: Product[]
  //Search Inputs
  searchProductHolder: Product[] = []
  products$ = new Observable<any[]>()
  isAlive = true;
  search$ = new Subject<string>();
  SearchName: any;
  quickViewProduct: Product[]
  //filtering Models
  FilterData: any[] = []
  FilterStateIds: StateModel[] = []
  FilterCategoryIds: ProductCategoryModel[] = []
  IdsOfStates: any[] = []
  IdsOfCategories: any[] = []
  ProductsfromCategoriesfilterapi: Product[] = []
  ProductsfromStatesfilterapi: Product[] = []
  CategoryStateApidata: Product[] = []
  isLoading = false;
  allListings: ListingModel[] = []
  FilteredProducts: Product[] = []
  hereFil: Product[] = []
  LoadingText: string = ''
  productcategory: string;
  showLoadingReasons: loadingReason[] = [
    {
      id: 1,
      reason: "Explore Listings based on Products."
    },
    {
      id: 2,
      reason: "Explore Listings based on States."
    },
    {
      id: 3,
      reason: "West Bengal is the largest rice-producing state in India."
    },
    {
      id: 4,
      reason: "The important mint producing regions in India are in the states of Uttar Pradesh and Punjab."
    },
    {
      id: 5,
      reason: "You can search with titles of Listings like - Fresh Chilli , High Quality Peppermint Oil , Seedless Cotton."
    },
    {
      id: 6,
      reason: "Kashmir's lavender is captivating both domestic and international markets."
    }
    ,
    {
      id: 7,
      reason: "Uttar Pradesh is the largest wheat-producing state in India."
    }
    ,
    {
      id: 8,
      reason: "Kerala is the largest producer of cardamom in India."
    }
  ]
  constructor(
    public productService: ProductService,
    public allStatesService: AllstatesService,
    public pdms: ProductsdialogmanagerService,
    public qvds: QuickViewServiceService,
    private route: ActivatedRoute,
  ) { }
  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {

    this.LoadingText = this.showLoadingReasons[Math.floor(Math.random() * this.showLoadingReasons.length)].reason;
    this.route.params.subscribe(params => {
      if (params && Object.keys(params).length > 0 && params.categoryName) {
        this.productcategory = params.categoryName
        this.LoadProductsCategoryWise(this.productcategory);
      }
      if (params && Object.keys(params).length === 0) {
        this.LoadProducts();
      }
    })
    // this.callListings();
    //search box filter logic
  }
  LoadProducts() {
    this.isLoading = true;
    this.productService.getProducts().subscribe((responseproduct) => {
      this.selectedProduct = responseproduct
      this.isLoading = false;
    })
  }

  LoadProductsCategoryWise(category: string) {
    this.isLoading = true;
    this.productService.getProducts().subscribe((responseproduct) => {
      const allproducts = responseproduct
      this.selectedProduct = allproducts.filter(items => {
        return items.categoryName === category
      })
      this.isLoading = false;
    })
  }


}