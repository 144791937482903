<div *ngIf="this.sendFeaturedList" class="singleCards">
    <!-- <img mat-card-image src=" https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
    <div [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]"
        class="image_wrapper">
        <img [src]="this.sendFeaturedList.imageUrls[0]" alt="this.sendFeaturedList.imageUrls[0]" />
        <div class="overlay overlay_1">
            <div class="title">
                <span>
                    {{this.sendFeaturedList.title
                    }}</span>
            </div>
        </div>
    </div>
    <div class="varietyName">
        <span
            [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]">
            {{this.sendFeaturedList.varietyName | translate
            }}</span>
        <button *ngIf="!this.isShowPrice" (click)="showPrice()" mat-stroked-button class="primary">
            Show Price
        </button>
        <button *ngIf="this.isShowPrice" mat-stroked-button class="primary">
            <span>{{(this.sendFeaturedList.bulkPrice).toLocaleString('en-In')}}</span>
            <span> ₹ / {{this.sendFeaturedList.useUnitShort}}</span>
        </button>
    </div>
</div>