import { Component, ViewChild, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { AuthService } from '../auth/auth.service';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { InCartService } from '../services/in-cart.service';
import { AllstatesService } from '../services/allstates.service';
import { HandleListingsService } from '../services/handle-listings.service';
import { UtilsService } from '../services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { HandleUserLinkService } from '../services/handle-user-link.service';
import { MatSnackBar } from '@angular/material/snack-bar';
interface userCurrentLocation {
  userPincode: string,
  userCity: string,
  userState: string
}
interface userGeoLocation {
  userLatitude: number
  userLongitude: number
}
interface langModel {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  languages: langModel[] = [
    {
      value: 'en',
      viewValue: 'English'
    },
    {
      value: 'hi',
      viewValue: 'हिंदी'
    },
    {
      value: 'gj',
      viewValue: 'ગુજરાતી'
    },
    {
      value: 'mh',
      viewValue: 'मराठी'
    },
    {
      value: 'pb',
      viewValue: 'ਪੰਜਾਬੀ'
    },
    // {
    //   value: 'rj',
    //   viewValue: 'राजस्थानी'
    // },
    {
      value: 'tn',
      viewValue: 'தமிழ்'
    },
    {
      value: 'ka',
      viewValue: 'ಕನ್ನಡ'
    },
    {
      value: 'as',
      viewValue: 'অসমীয়া'
    },
    {
      value: 'wb',
      viewValue: 'বাংলা'
    },
    {
      value: 'ud',
      viewValue: 'اردو'
    },
  ]
  selectedLang: string
  currentLang: string
  cartItemsCount: number;
  showLocationPopup: boolean = false;
  selectedPincode: string = '';
  pincodeFullInfo: any = [];
  stateName: string;
  districtName: string;
  iscurrentLocation: boolean = false;
  isgeoLocation: boolean = false
  currentLocation: userCurrentLocation;
  getUserGeoLocation: userGeoLocation
  openPanel: boolean = false
  listingsCreatedBySeller: number;
  businessName: string;
  companyNameSeller: string;
  pendLnksCnt: number;
  @Input() backButtonVisibility: boolean;
  public currentUserId: string
  private authListenerSubs: Subscription
  showBackButton: boolean = true
  userIsAuthenticated = false;
  @ViewChild("sidenavContainer", { static: false })
  sideNavContainer: MatSidenavContainer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  loginUserInfo: any;
  genericUserInfo: any;
  isGeneric: boolean = true
  isLoading = false; //spinner initialized
  productcategories: ProductCategoryModel[] = [];
  skipPageForGST: boolean = false;
  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router,
    private tnslServ: TranslateService,
    private authService: AuthService,
    private productcategoryService: ProductcategoryService,
    private cartservice: InCartService,
    private stateService: AllstatesService,
    private hls: HandleListingsService,
    private util: UtilsService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private usrLnkSrv: HandleUserLinkService,
    private utilS: UtilsService
  ) { }


  ngOnInit() {
    this.doAuth()
    this.util.applySavedTheme();
    this.getLocation()
    this.getLanguage()
    this.getCartItemsCount()
    this.loadCategories()
    //when login First time, Javascript Object is returned , which can be used unparsed, 
    //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
    // this.currentUserId = this.loginUserInfo.userId
  }
  toggleTheme() {
    this.util.toggleTheme();
  }
  refreshNav() {
    this.cdr.markForCheck(); // Or this.cdr.detectChanges();
  }
  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status

      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.refreshNav()
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()
        this.currentUserId = this.loginUserInfo.userId
        this.authOp(this.userIsAuthenticated)
      });
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo()
      this.currentUserId = this.loginUserInfo.userId
      this.authOp(this.userIsAuthenticated)
    }
    catch (e) { }

  }
  authOp(isAuthenticated: boolean) {
    if (isAuthenticated) {
      this.isGeneric = false
      this.companyNameSeller = this.loginUserInfo.companyName
      this.businessName = this.util.getBusinessNameForURL(this.companyNameSeller)
      // feature 2
      // this.loadMyPennetCount()
      if (this.loginUserInfo?.accounttype == 'Seller') {
        this.getAllListingsCountBySeller(this.currentUserId)
      }
      if (this.loginUserInfo && (!this.loginUserInfo.isGSTDocAvailable || this.loginUserInfo.isGSTDocAvailable === '')) {
        const regex = /farm/i;
        const isFarm = this.loginUserInfo.accountSubtype.map(sbAt => {
          return sbAt
        }).some((eachAt: string) => {
          return regex.test(eachAt)
        })
        const isIndian = this.loginUserInfo?.cc?.name === 'India'
        if (isFarm || !isIndian) {
          this.skipPageForGST = true
        }
        if (!isFarm && isIndian) {
          this.skipPageForGST = false
          this.authService.uploadKyc(this.loginUserInfo.userId)
        }
      }
    }
    else if (!isAuthenticated) {
      this.isGeneric = true
      // this.loginUserInfo = await this.authService.getUserLoginInfo()
      // console.log('general user', this.loginUserInfo.userId)
      // this.currentUserId = this.loginUserInfo.userId
    }
  }
  getCartItemsCount() {
    try {
      this.cartservice.getItemCount().subscribe(
        itemCount => {
          if (itemCount && itemCount > 0) {
            this.cartItemsCount = itemCount
          }
        }
      )
    }
    catch (e) {
    }
  }
  getLocation() {
    this.iscurrentLocation = this.authService.isCurrentLocationAvailable()
    this.isgeoLocation = this.authService.isGeoLocationAvailable()
    if (!this.iscurrentLocation || !this.isgeoLocation) {
      this.geoLocation()
      setTimeout(() => {                           // <<<---using ()=> syntax
        this.openPanel = true
      }, 131000);
    }
    else {
      this.openPanel = false
      this.currentLocation = this.authService.getCurrentLocation()
      this.getUserGeoLocation = this.authService.getGeoLocation()
      this.selectedPincode = this.currentLocation.userPincode
      this.districtName = this.currentLocation.userCity
      this.stateName = this.currentLocation.userState
      // this.getAllCommodities(this.districtName)
    }
  }
  geoLocation(): void {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          this.setGeoLoc(longitude, latitude);
        });
      } else {
        console.log("No support for geolocation")
      }
    }
    catch (e) {
      throw e
    }
  }

  setGeoLoc(Longitude: number, Latitude: number) {
    try {
      this.authService.setGeoLocation(Longitude, Latitude)
    }
    catch (e) {
      throw e
    }
  }
  onActivate(event) {
    // window.scroll(0,0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  async getMyPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        this.authService.setCurrentLocation(this.selectedPincode, this.districtName, this.stateName)
        this.openPanel = false
        this.getLocation()
      }
    }
    catch (e) {
      throw e
    }

  }
  getLanguage() {
    const useLang = localStorage.getItem('lang')
    this.selectedLang = JSON.parse(useLang)
    this.tnslServ.use(this.selectedLang)
  }
  loadCategories() {
    try {
      this.isLoading = true; //spinner starts
      this.productcategoryService.getCategories().subscribe((res) => {
        this.productcategories = res
        this.isLoading = false; //spinner stops
      })
    }
    catch (e) {
      throw e
    }
  }
  loadMyPennetCount() {
    try {
      this.usrLnkSrv.mypenntwCnt(this.currentUserId).then(chkpennet => {
        if (chkpennet && chkpennet.status === 200) {
          // this.pendReqMess = chkpennet.message
          this.pendLnksCnt = chkpennet.pntwcount
          if (this.pendLnksCnt > 0) {
            this.linkSuccess(chkpennet.message)
            return
          }
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  linkSuccess(succ: string) {
    try {
      let snackBarAc = this._snackBar.open(succ, "View", {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackBarAc.onAction().subscribe(() => {
        this.router.navigate(['/Merchant_Network/Check_Business_Profile/', this.currentUserId, this.businessName])
      })
      this.utilS.playLinkAudio()
    }
    catch (e) {
      throw e
    }
  }
  letsChangeLang(lang: any) {
    this.authService.setlang(this.selectedLang).then(
      checkLang => {
        location.reload()
      }
    )
  }
  hasRoute(route: string) {
    try {
      return this.router.url === route || this.router.url === '/home';
    }
    catch (e) {
      throw e
    }
  }
  hasRouteSearch(route: string) {
    try {
      return this.router.url === route || this.router.url === '/searchGlobal';
    }
    catch (e) {
      throw e
    }
  }

  // async doAuth() {
  //   try {   //add conditions to if where backbutton should not be visible
  //     //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
  //     // keep checking for user auth status
  //     this.authService.autoAuthUser();
  //     this.userIsAuthenticated = this.authService.getIsAuth();
  //     console.log('chekcAuth', this.userIsAuthenticated)
  //     if (this.userIsAuthenticated) {
  //       this.isGeneric = false

  //     }
  //     this.isGeneric = true
  //     this.loginUserInfo = await this.authService.getUserLoginInfo()
  //     console.log('useRInfo', this.loginUserInfo.userId)
  //     this.currentUserId = this.loginUserInfo.userId
  //     this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
  //       this.userIsAuthenticated = isAuthenticated;
  //       //if auth status changes, get user info again
  //       console.log('authIT', this.userIsAuthenticated)
  //       this.loginUserInfo = this.authService.getUserLoginInfo()
  //       this.currentUserId = this.loginUserInfo.userId
  //     });
  //     console.log('final userId', this.currentUserId)
  //   }
  //   catch (e) { }


  // }
  ngOnDestroy() {
    //subscription for observables or objects managed by us or created by us (like authstatuslistner in auth.ts) also need to be destroyed by us  
    this.authListenerSubs.unsubscribe();
  }
  onLogout() {
    this.authService.logoutUser();
  }
  getAllListingsCountBySeller(userId: string) {
    this.hls.getSellerListingsCount(userId).then(
      res => {
        this.listingsCreatedBySeller = res.count
      }
    )
  }

}
