import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/datamodels/MainProductModel';

@Component({
  selector: 'app-productcard',
  templateUrl: './productcard.component.html',
  styleUrls: ['./productcard.component.css']
})
export class ProductcardComponent implements OnInit {
  @Input() getProd1: Product
  fileUrl: string;
  constructor(private router: Router) { }
  fileType: string = '';
  ngOnInit(): void {
    this.checkFileType();
  }
  checkFileType(): void {
    if (this.getProd1?.productImage?.length) {
      const fileName = this.getProd1.productImage[0];
      this.fileUrl = fileName
      const fileExtension = fileName.split('.').pop()?.toLowerCase();

      if (['mp4', 'mov', 'avi', 'webm'].includes(fileExtension!)) {
        this.fileType = 'video';
      } else if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(fileExtension!)) {
        this.fileType = 'image';
      } else {
        this.fileType = 'unknown'; // fallback for unsupported file types
      }
    }
  }
  onSelectProduct(productName: string) {
    this.router.navigate(['/showListingProduct', productName])
  }
}
