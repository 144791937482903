import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Review } from 'src/app/datamodels/productreview';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.css']
})
export class CommentBoxComponent implements OnInit {
  @Output() reviewSubmitted = new EventEmitter<{
    review: Review;
    status: string;
  }>();
  @Input() postId: string
  isLoading = false;
  private mode = 'create';
  private commentId: string;
  private review: Review;
  reviewform: FormGroup;
  reviewImagePreview: string = '';
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  public currentUserId: any

  loginUserInfo: any
  createPostComments: boolean = false;
  selectedRating = 3; // Default selected rating
  constructor(
    private commentservice: CommentpostService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private imgHandler: ImageHandlingService,
    private _snackBar: MatSnackBar,
    private utils: UtilsService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    try {
      this.reviewform = new FormGroup({
        listingId: new FormControl(null, { validators: [Validators.required] }),
        author: new FormControl(null, { validators: [Validators.required] }),
        comment: new FormControl(null)
      })
      this.doAuth()
      this.mode = "create"
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.reviewform.value.author = this.loginUserInfo.firstname + " " + this.loginUserInfo.lastname
      this.commentId = null;
      this.reviewform.patchValue({
        'listingId': this.postId,
        'author': this.reviewform.value.author,
        'comment': this.reviewform.value.comment,
      })
    }
    catch (e) {
      throw e
    }

  }

  doAuth() {
    try {
      // keep checking for user auth status
      this.userIsAuthenticated = this.authService.getIsAuth();
      if (this.userIsAuthenticated) {
        this.loginUserInfo = this.authService.getUserLoginInfo()
      }
      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()

      });
      if (this.loginUserInfo) {
        this.currentUserId = this.loginUserInfo?.userId
        if (this.currentUserId == undefined || this.currentUserId == null) {
          const parsedUserDetails = JSON.parse(this.loginUserInfo)
          this.currentUserId = parsedUserDetails.userId
        }
      }
      else if (this.loginUserInfo == undefined) {
        this.createPostComments = true
      }
    }
    catch (e) {
      throw e
    }
    //when login First time, Javascript Object is returned , which can be used unparsed, 
    //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed

  }
  onSavePost() {
    try {

      if (this.reviewform.invalid && this.loginUserInfo === undefined) {
        this.errorForm('Login to leave comments')
        return;
      }
      this.isLoading = true; //spinner starts
      if (this.mode === 'create') {
        const commentForm: Review = {
          _id: null,
          creatorID: this.currentUserId,
          listingId: this.postId,
          author: this.reviewform.value.author,
          authorLogo: null,
          comment: this.reviewform.value.comment,
          rating: this.selectedRating,
          date: null,
          reviewImage: null,
        }
        this.commentservice.addReview(commentForm).then(response => {
          if (response && response.status === 201) {
            const commSubmit = {
              status: response.status.toString(),
              review: response.review
            }
            this.reviewSubmitted.emit(commSubmit)
          }
        })
      } else {
        this.commentservice.updateReview(
          this.commentId,
          this.postId,
          this.reviewform.value.author,
          this.reviewform.value.comment,
          this.reviewform.value.rating,
          null,
          null,
          // this.reviewform.value.creatorID
        ).then((response) => {
          if (response && response.status === 201) {
            this.location.back()
            // this.router.navigate(['/Listing', response.reviewOnlistingId])
          }
        });
      }
      //emit post created to array , then array gets fetched to list
      this.reviewform.reset();
      this.isLoading = false;
    }
    catch (e) {

    }

  }

  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      })
      this.isLoading = false
    }
    catch (e) {
      throw e
    }
  }
}
