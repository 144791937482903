<div *ngIf="!this.bidRequestResponse">
    <div class="bidDiscContainer" *ngIf="this.bidListing.data.isDiscount && !this.bidListing.data.isBid">
        <div> <span class="titleBid">
                {{this.bidListing.data.listingTitle}}</span>&nbsp;
            <span class="prodName">{{this.bidListing.data.listingProductName}}</span>
            <div class="bidHead"> Ask for
                discount in 3
                easy steps </div>
            <div class="bidHead">
                <span class="priceHead">
                    Base Price
                </span>
                <span class="bulkPrice">
                    {{(this.bidListing.data.bulkPrice).toLocaleString('en-IN')}}  ₹ / ql
                </span>
            </div>
            <div *ngIf="this.discountPrice" class="bidHead">
                <span class="priceHead">
                    Discounted Price
                </span>
                <span class="bulkPrice">
                    {{(this.discountPrice).toLocaleString('en-IN')}}  ₹ / ql
                </span>
            </div>
            <div *ngIf="this.finalDiscPrice" class="bidHead">
                <span class="priceHead">
                    Final Price
                </span>
                <span class="bulkPrice">
                    {{(this.finalDiscPrice).toLocaleString('en-IN')}}  ₹ / ql
                </span>
            </div>
        </div>
        <img id="discountImage" mat-card-image src="../../../assets/svgiconsandimages/discountbidding.svg">

        <form [formGroup]="bidRequestFormDetails">
            <mat-list id="formSteps">
                <div id="listItems">
                    <section>
                        <mat-list-item id="listitem">
                            <mat-icon *ngIf="!this.isRateSelected">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.isRateSelected"
                                style="color: rgb(4, 193, 4);">check_circle_outline</mat-icon>
                            <span id="bidDiscStep">Select Rate</span>
                        </mat-list-item>
                        <mat-form-field class="bidDiscField"><mat-label>Discount</mat-label>
                            <mat-select formControlName="bidRate" [(ngModel)]="selectedRateValue"
                                (ngModelChange)="rateInput()" name="rate" required>
                                <mat-option *ngFor="let negRate of bidRates" [value]="negRate.value">
                                    {{negRate.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="formErrors.selectedRateValueDiscount">{{formErrors.selectedRateValueDiscount}}</mat-error>
                        </mat-form-field>
                    </section>
                    <section>
                        <mat-list-item id="listitem"> <mat-icon
                                *ngIf="!this.isQuantityEntered">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.isQuantityEntered"
                                style="color: rgb(4, 193, 4);">check_circle_outline</mat-icon>
                            <span id="bidDiscStep"> Select Quantity</span>
                        </mat-list-item>
                        <mat-form-field class="quanitityField">
                            <mat-label>Quintal</mat-label>
                            <input matInput type="number" formControlName="quantityValue" [(ngModel)]="quantityValue"
                                (change)="quantityInput()" required>
                            <mat-error *ngIf="formErrors.quantityValue">{{formErrors.quantityValue}}</mat-error>
                            <button *ngIf="quantityValue" matSuffix mat-icon-button aria-label="Clear"
                                (click)="quantityValue=null">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </section>
                    <section>
                        <mat-list-item id="listitem"><mat-icon
                                *ngIf="!this.bidRequestFormDetails.valid">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.bidRequestFormDetails.valid"
                                style="color: rgb(4, 193, 4);">check_circle_outline</mat-icon>
                            <!-- Loader 3 -->
                            <button id="bidDiscStep" class="discountSendButton" mat-raised-button type="submit"
                                (click)="onSendBidRequest()">
                                <span *ngIf="this.isdiscountRequestPlaced">Get Discount</span>
                                <span *ngIf="!this.isdiscountRequestPlaced"> Sending </span>
                            </button></mat-list-item>

                    </section>
                </div>
            </mat-list>
        </form>
    </div>

    <div class="bidDiscContainer" *ngIf="!this.bidListing.data.isDiscount && this.bidListing.data.isBid">
        <div> <span style="font-weight: 400;font-size: large;color:#3f51b5">
                {{this.bidListing.data.listingTitle}}</span>&nbsp;
            <span
                style="font-weight: 400;font-size: small;color:#3f51b5">{{this.bidListing.data.listingProductName}}</span>
            <div style="font-weight: 420;font-size: small;color:rgba(16, 17, 79, 0.763); padding-top: 10px;"> Place bid
                in 3 easy steps </div>
            <div class="bidHead">
                <span class="priceHead">
                    Base Price
                </span>
                <span class="bulkPrice">
                    {{(this.bidListing.data.bulkPrice).toLocaleString('en-IN')}}  ₹ / ql
                </span>
            </div>
            <div *ngIf="this.finalBidupPrice" class="bidHead">
                <span class="priceHead">
                    Final Price
                </span>
                <span class="bulkPrice">
                    {{(this.finalBidupPrice).toLocaleString('en-IN')}}  ₹ / ql
                </span>
            </div>
        </div>
        <img id="bidImage" mat-card-image src="../../../assets/svgiconsandimages/bidbidding.svg">
        <form [formGroup]="bidRequestFormDetails">
            <mat-list id="formSteps">
                <div id="listItems">
                    <section>
                        <mat-list-item id="listitem">
                            <mat-icon *ngIf="!this.isRateSelected">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.isRateSelected" style="color:#1298ff">check_circle_outline</mat-icon>
                            <span id="bidDiscStep">Select Bid Rate</span>
                        </mat-list-item>
                        <mat-form-field class="bidDiscField"><mat-label>Bid Rate</mat-label>
                            <mat-select formControlName="bidRate" [(ngModel)]="selectedRateValue"
                                (ngModelChange)="rateInput()" name="rate" required>
                                <mat-option *ngFor="let negRate of bidRates" [value]="negRate.value">
                                    {{negRate.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="formErrors.selectedRateValueDiscount">{{formErrors.selectedRateValueDiscount}}</mat-error>
                        </mat-form-field>
                    </section>
                    <section>
                        <mat-list-item id="listitem"> <mat-icon
                                *ngIf="!this.isQuantityEntered">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.isQuantityEntered"
                                style="color:#1298ff">check_circle_outline</mat-icon>
                            <span id="bidDiscStep"> Select Quantity</span>
                        </mat-list-item>
                        <mat-form-field class="quanitityField">
                            <mat-label>Quintal</mat-label>
                            <input matInput type="number" formControlName="quantityValue" [(ngModel)]="quantityValue"
                                (change)="quantityInput()" required>
                            <mat-error *ngIf="formErrors.quantityValue">{{formErrors.quantityValue}}</mat-error>
                            <button *ngIf="quantityValue" matSuffix mat-icon-button aria-label="Clear"
                                (click)="quantityValue=null">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </section>
                    <section>
                        <mat-list-item id="listitem"><mat-icon
                                *ngIf="!this.bidRequestFormDetails.valid">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="this.bidRequestFormDetails.valid"
                                style="color: #1298ff">check_circle_outline</mat-icon>
                            <!-- Loader 3 -->
                            <button id="bidDiscStep" class="bidSendButton" mat-raised-button type="submit"
                                (click)="onSendBidRequest()">
                                <span *ngIf="this.isdiscountRequestPlaced">Place Bid</span>
                                <span *ngIf="!this.isdiscountRequestPlaced"> Sending </span>
                            </button></mat-list-item>

                    </section>
                </div>
            </mat-list>
        </form>
    </div>
</div>

<div *ngIf="this.bidRequestResponse">

    <p>{{ this.bidRequestResponse.message}} </p>
</div>
<!-- <p id="footer">
    Your cart will be updated with this Item when the Seller approves. </p> -->