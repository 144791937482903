import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-quick-view-dialog',
  templateUrl: './quick-view-dialog.component.html',
  styleUrls: ['./quick-view-dialog.component.css']
})
export class QuickViewDialogComponent implements OnInit {
  receivedProduct = []
  logoImagePreview: string;
  constructor(
    private dialogRef: MatDialogRef<QuickViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public getImg: string) { }

  ngOnInit(): void {
    this.logoImagePreview = this.getImg
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
