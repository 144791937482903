<div class="teamHead">
    <!-- <button (click)="editTeams()" mat-raised-button>Manage Team</button> -->
</div>
<div class="banner italics">Myapaar Members with same GST, do you know them ?<br> Add or Remove from team.</div>
<div class="teamBox">
    <div class="teamsHere" *ngFor="let people of this.allTeams">
        <div [ngClass]="{'ownCard':this.passGstId.currentUserID===people._id}" class="teamCard">
            <div class="actionbar">

            </div>
            <div class="name">
                {{people?.firstname}} {{people?.lastname}}
                <span *ngIf="this.passGstId.currentUserID===people._id">( You )</span>
            </div>
            <div class=" contact">
                <mat-icon class="contactIcon"> call</mat-icon>
                <span *ngIf="people?.phone != null"> {{people?.phone}}</span>
            </div>
            <div class="contact">
                <mat-icon class="contactIcon"> email</mat-icon>
                <span *ngIf="people?.email != ''">{{people?.email}}</span>
            </div>

        </div>
    </div>
    <div (click)="this.successCopyToClipboard('Team Code Copied ! Share on whatsapp')"
        [cdkCopyToClipboard]="this.copyReferral" class="addUser">
    </div>
</div>