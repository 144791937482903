import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BankDetails } from 'src/app/datamodels/groupedmodel/bankDetails';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { VersionModel } from 'src/app/datamodels/groupedmodel/versionModel';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';
import { VersionInvoicing } from 'src/app/datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { UtilsService } from 'src/app/services/utils.service';
interface deadLineDateInterface {
  value: number;
  viewValue: string;
  checked: boolean;
}
interface itemsForInvoiceInterface {
  paidAmount: number;
  remainingAmount: number;
  itemCategory: string;
  itemCategoryId: string;
  itemProduct: string;
  itemProductId: string;
  itemVariety: string;
  itemVarietyId: string;
  itemlistingId: string;
  itemPricePerUnit: number;
  itemQuantity: number;
  itemTotalPrice: number;
  itemTotalPriceWithTaxes: number;
  itemTaxes: number;
  optedPaymentTerms: string;
  interestAndCT: string;
  itemCreditInterestAmount: number;
  itemTitle: string;
  daysLeftInPaymentSincePOCreated: number;
  checked: boolean;
  unitShort: string;
}
@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})

export class CreateInvoiceComponent implements OnInit {
  purchaseOrderId: string;
  private currentUserId: string;
  private loginUserInfo: any;
  poData: PurchaseOrderModel;
  allComplete: boolean = false;
  currentPurchaseOrderwithId: PurchaseOrderModel;
  invoiceVersions: VersionModel[] = []
  itemsForInvoice = []
  invoiceForm: FormGroup;
  deadLineDate: deadLineDateInterface[] = [
    { value: 5, viewValue: '5 working Days', checked: false },
    { value: 10, viewValue: '10 working Days', checked: false },
    { value: 20, viewValue: '20 working Days', checked: false },
    { value: 40, viewValue: '40 working Days', checked: false },
    { value: 80, viewValue: '80 working Days', checked: false }
  ];
  deadLineDateValue: string;
  itemsInPoForInvoice: itemsForInvoiceInterface[] = []
  isLoading: boolean = false;
  private checkedItemDetails: ItemDetails[] = []
  itemsNotSelectedShowError: boolean = false
  itemsSelected: itemsForInvoiceInterface[] = [];
  atleastOneItemSelected: boolean = false;
  displayTotalPriceInclTaxForSelectedItems: number;
  createdInvoiceOrder: InvoiceModel;
  sumOfAllInvoiceAmountsRaisedForPO: number;
  latestVersionInvoice: VersionInvoicing;
  allItemsCoveredForInvoicing: boolean = false
  invoiceBalanceRemainingToPay: number
  itemsInvoicePaidAtCheckout: number;
  selectedItemAmountRemaining: any;
  selectedItemAmountPaid: any;
  itemsNotSelectedAndRemainingToInvoice: itemsForInvoiceInterface[];
  nextInvoiceAmountRaiseValue: number;
  vaayuDeliveryCharges: number;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private hpos: HandlePurchaseOrderService,
    private hins: HandleInvoiceService,
    private usc: UtilsService,
  ) { }
  // ?

  formErrors = {
    'selectItems': 'Select Items for Invoice',
    'accountName': 'Account Name is required',
    'accountNumber': 'Account Number is required',
    'accountIFSC': 'IFSC Code is required',
    'accountBankBranch': 'Bank Branch Name is required',
    'paymentDeadline': 'Set Payment Deadline for Invoice',
    'paymentRemarks': 'Add Remarks'
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.invoiceForm = new FormGroup({
      // selectItems: new FormControl(null),
      accountName: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(100)]
      }),
      accountNumber: new FormControl(null, { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(25)] }),
      accountIFSC: new FormControl(null, { validators: [Validators.required, Validators.minLength(11), Validators.maxLength(11)] }),
      accountBankBranch: new FormControl(null, { validators: [Validators.required] }),
      paymentDeadline: new FormControl(null, { validators: [Validators.required] }),
      paymentRemarks: new FormControl(null, { validators: [Validators.required, Validators.maxLength(50)] }),
    })
    this.loginUserInfo = this.authService.getUserLoginInfo();
    const accountType = this.loginUserInfo.accounttype
    if (accountType === 'Buyer') {
      this.router.navigate([`/`])
      return
    }
    this.currentUserId = this.loginUserInfo.userId
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('purchaseOrderId')) {
        this.purchaseOrderId = paramMap.get('purchaseOrderId')
        this.getcurrentPurchaseOrder(this.purchaseOrderId)
      }
    })
  }

  async getcurrentPurchaseOrder(purchaseOrderId: string) {
    try {
      this.hpos.getSinglePurchaseOrder(purchaseOrderId).subscribe(purchaseOrder => {
        this.currentPurchaseOrderwithId = purchaseOrder
        console.info('po', this.currentPurchaseOrderwithId)
        this.vaayuDeliveryCharges = this.currentPurchaseOrderwithId.additionalCharges.find(each => { return each.chargeType == 'Handling Fees' }).amount
        //total days remaining for payment for each item
        const dateToday = new Date()
        const dpsp = this.usc.getDatesDifference(this.currentPurchaseOrderwithId.dateOfPOGenerated, dateToday)
        // no other invoice version in PO - First Version of INVO - so list all items of PO
        if (this.currentPurchaseOrderwithId.invoiceVersions &&
          this.currentPurchaseOrderwithId.invoiceVersions.length === 0) {
          // create new invoice - invoiceVersion is not available so all items present that were in purchase order

          this.currentPurchaseOrderwithId.itemDetails.forEach((item) => {
            // let usePaidAmount: number = 0
            // let useRemainingAmountItem: number = 0
            // if (item.optedPaymentTerms.creditTermStatement == 'Cash and Carry') {
            //   console.info('Cash and Carry')
            //   usePaidAmount = item.itemPriceWithTaxes
            //   useRemainingAmountItem = 0
            // }
            // if (item.optedPaymentTerms.creditTermStatement == 'Check and Pay on delivery') {
            //   console.info('Check and Pay on delivery')
            //   usePaidAmount = item.itemPriceWithTaxes * (30 / 100)
            //   useRemainingAmountItem = item.itemPriceWithTaxes - usePaidAmount
            // }
            // else if (item.optedPaymentTerms.creditTermStatement != 'Cash and Carry'
            //   && item.optedPaymentTerms.creditTermStatement != 'Check and Pay on delivery') {
            //   console.info('paymentDatesFuture')
            //   usePaidAmount = 0
            //   useRemainingAmountItem = item.itemPriceWithTaxes
            // }
            // console.info('usePaidAmount', usePaidAmount)
            // console.info('useRemainingAmountItem', useRemainingAmountItem)
            let totalDaysInCT: number = 0
            const itemsCreditTermsDays = item.optedPaymentTerms.creditTermDays
            // item.optedPaymentTerms.creditTerms === "Instant Payment" ? totalDaysInCT = 0 - dpsp :
            totalDaysInCT = totalDaysInCT + itemsCreditTermsDays - dpsp
            //create item objects to select to create Invoice
            const newitemInPoForInvoice = {
              paidAmount: item.itemPaidAmount,
              remainingAmount: item.itemRemainingAmount,
              itemCategory: item.productCategory,
              itemCategoryId: item.categoryId,
              itemProduct: item.productName,
              itemProductId: item.productId,
              itemVariety: item.productVariety,
              itemVarietyId: item.varietyId,
              itemlistingId: item.listingId,
              itemPricePerUnit: item.itemPricePerUnit,
              itemQuantity: item.itemQuantity,
              itemTotalPrice: item.itemTotalValue,
              itemTotalPriceWithTaxes: item.itemPriceWithTaxes,
              itemTaxes: item.itemTaxes,
              optedPaymentTerms: JSON.stringify(item.optedPaymentTerms.creditTermDays),
              interestAndCT: item.optedPaymentTerms.creditTermStatement,
              itemCreditInterestAmount: item.itemCreditInterestAmount,
              daysLeftInPaymentSincePOCreated: totalDaysInCT,
              itemTitle: item.itemTitle,
              checked: false,
              unitShort: item.useUnitShort
            }
            this.itemsInPoForInvoice.push(newitemInPoForInvoice)
            this.isLoading = false;
          })
          return this.currentPurchaseOrderwithId

        }


        // invoiceVersion in PO - last INVOversion has list of all itemIds remaining for INVO - so filter same from PO and show

        if (this.currentPurchaseOrderwithId.invoiceVersions && this.currentPurchaseOrderwithId.invoiceVersions.length &&
          this.currentPurchaseOrderwithId.invoiceVersions.length > 0) {
          // reviseInvoice - select items which were not selected in first invoice
          // filter out items with listing ids which do not match with listing ids in invoiceversion 
          this.latestVersionInvoice = this.currentPurchaseOrderwithId.invoiceVersions[this.currentPurchaseOrderwithId.invoiceVersions.length - 1]
          const latestNumberInvoiceVersion = this.currentPurchaseOrderwithId.invoiceVersions.length
          const nextNumberInvoiceVersion = this.currentPurchaseOrderwithId.invoiceVersions.length + 1
          const listingIDsOfItemsRemainingForInvoice = this.latestVersionInvoice.itemsRemainingForInvoice
          //show items for which invoice is not raised
          // allListingItemIdsForWhichInvoCreated -> last invoice version contains this info, since we filter and append a fresh list 
          // of all remaining items in last version while creating invoice order and add this to purchsaeOrder

          const itemsFromPOwithoutInvoiceRaised = this.currentPurchaseOrderwithId.itemDetails.filter(eachItem => {
            return listingIDsOfItemsRemainingForInvoice.includes(eachItem.listingId)
          })
          console.info('itemsFromPOwithoutInvoiceRaised', itemsFromPOwithoutInvoiceRaised)
          // after all items are raised
          if (itemsFromPOwithoutInvoiceRaised && itemsFromPOwithoutInvoiceRaised.length === 0) {
            this.allItemsCoveredForInvoicing = true
            this.isLoading = false
            return this.allItemsCoveredForInvoicing
          }
          itemsFromPOwithoutInvoiceRaised.forEach((item) => {
            let totalDaysInCT: number = 0

            // item.optedPaymentTerms.creditTerms === "Instant Payment" ? totalDaysInCT = 0 - dpsp :
            totalDaysInCT = totalDaysInCT + item.optedPaymentTerms.creditTermDays - dpsp
            //create item objects to select to create Invoice
            const newitemInPoForInvoice = {
              paidAmount: item.itemPaidAmount,
              remainingAmount: item.itemRemainingAmount,
              itemCategory: item.productCategory,
              itemCategoryId: item.categoryId,
              itemProduct: item.productName,
              itemProductId: item.productId,
              itemVariety: item.productVariety,
              itemVarietyId: item.varietyId,
              itemlistingId: item.listingId,
              itemPricePerUnit: item.itemPricePerUnit,
              itemQuantity: item.itemQuantity,
              itemTotalPrice: item.itemTotalValue,
              itemTotalPriceWithTaxes: item.itemPriceWithTaxes,
              itemTaxes: item.itemTaxes,
              optedPaymentTerms: JSON.stringify(item.optedPaymentTerms.creditTermDays),
              interestAndCT: item.optedPaymentTerms.creditTermStatement,
              itemCreditInterestAmount: item.itemCreditInterestAmount,
              daysLeftInPaymentSincePOCreated: totalDaysInCT,
              itemTitle: item.itemTitle,
              checked: false,
              unitShort: item.useUnitShort
            }
            this.itemsInPoForInvoice.push(newitemInPoForInvoice)
            this.isLoading = false;
          })
          return this.currentPurchaseOrderwithId
        }

      })
      // if (this.currentPurchaseOrderwithId.invoiceIds.length === 0 && this.currentPurchaseOrderwithId.invoiceNumbers.length === 0
      //   && !this.currentPurchaseOrderwithId.isInvoiceGenerated) {
      //   // no invoiceIds , or invoicenumbers, also invoiceGeneratedisFalse , so not even 1st version of invoice is made rightnow
      //   this.generateNewInvoiceForPO(this.currentPurchaseOrderwithId)
      // }
      // else {
      //   this.updateReviseInvoice(this.currentPurchaseOrderwithId)

      // }
    }
    catch (e) { throw e }
  }

  updateAllComplete() {
    try {
      this.allComplete = this.itemsInPoForInvoice != null && this.itemsInPoForInvoice.every(t => t.checked);
    }
    catch (e) { throw e }
  }

  someComplete(): boolean {

    try {
      if (this.itemsInPoForInvoice == null) {
        return false;
      }
      const check = this.atleastOneItemSelected && !this.allComplete;
      console.info('this.itemsInPoForInvoice', this.itemsInPoForInvoice)
      this.atleastOneItemSelected = this.itemsInPoForInvoice.filter(t => t.checked).length > 0
      // this.itemsNotSelectedAndRemainingForInvoice = this.itemsInPoForInvoice.filter(t => !t.checked).length > 0
      let amountPaidOfItemsFromNextInvoice: number
      let amountRemainingOfItemsFromNextInvoice: number
      if (this.atleastOneItemSelected) {
        this.itemsSelected = this.itemsInPoForInvoice.filter(t => t.checked)
        this.itemsNotSelectedAndRemainingToInvoice = this.itemsInPoForInvoice.filter(t => !t.checked)
        amountPaidOfItemsFromNextInvoice = parseInt(Number(this.itemsNotSelectedAndRemainingToInvoice.reduce((sum, item) => sum + item.paidAmount, 0)).toFixed(2))
        amountRemainingOfItemsFromNextInvoice = parseInt(Number(this.itemsNotSelectedAndRemainingToInvoice.reduce((sum, item) => sum + item.remainingAmount, 0)).toFixed(2))
        this.nextInvoiceAmountRaiseValue = amountPaidOfItemsFromNextInvoice + amountRemainingOfItemsFromNextInvoice
        console.info('this.itemsSelected', this.itemsSelected)
        console.info('this.itemsNotSelectedAndRemainingToInvoice', this.itemsNotSelectedAndRemainingToInvoice)
        console.info('amountPaidOfItemsFromNextInvoice', amountPaidOfItemsFromNextInvoice)
        console.info('amountRemainingOfItemsFromNextInvoice', amountRemainingOfItemsFromNextInvoice)
        this.selectedItemAmountRemaining = parseInt(Number(this.itemsSelected.reduce((sum, item) => sum + item.remainingAmount, 0)).toFixed(2))
        this.selectedItemAmountPaid = parseInt(Number(this.itemsSelected.reduce((sum, item) => sum + item.paidAmount, 0)).toFixed(2))
        this.displayTotalPriceInclTaxForSelectedItems = this.selectedItemAmountPaid + this.selectedItemAmountRemaining
      }
      console.info('this.selectedItemAmountRemaining', this.selectedItemAmountRemaining)
      console.info('displayTotalPriceInclTaxForSelectedItems', this.displayTotalPriceInclTaxForSelectedItems)
      //sum of all items whose invoice is raised - to be deducted from frontend where we are showing balance of remaining added items
      this.sumOfAllInvoiceAmountsRaisedForPO = this.currentPurchaseOrderwithId.invoiceVersions.reduce((sum, item) => sum + item.invoiceAmount, 0)
      console.info(' this.sumOfAllInvoiceAmountsRaisedForPO ', this.sumOfAllInvoiceAmountsRaisedForPO)
      console.info('this.currentPurchaseOrderwithId.amountRemainingForInvoices', this.currentPurchaseOrderwithId.amountRemainingForInvoices)
      console.info('this.allComplete ', this.allComplete)

      if (this.currentPurchaseOrderwithId.invoiceVersions && this.currentPurchaseOrderwithId.invoiceVersions.length === 0) {
        if (this.currentPurchaseOrderwithId.amountRemainingForInvoices === 0) {
          this.invoiceBalanceRemainingToPay = 0
        }
        if (this.currentPurchaseOrderwithId.amountRemainingForInvoices !== 0) {
          this.invoiceBalanceRemainingToPay = parseInt(Number(
            this.currentPurchaseOrderwithId.orderValueIncludingTax - this.displayTotalPriceInclTaxForSelectedItems).toFixed(2))
        }
      }
      if (this.currentPurchaseOrderwithId.invoiceVersions && this.currentPurchaseOrderwithId.invoiceVersions.length > 0) {
        this.invoiceBalanceRemainingToPay = parseInt(Number(
          this.currentPurchaseOrderwithId.orderValueIncludingTax - this.currentPurchaseOrderwithId.amountPaidAtCheckout - this.sumOfAllInvoiceAmountsRaisedForPO - this.displayTotalPriceInclTaxForSelectedItems).toFixed(2))
      }
      if (this.allComplete) {
        this.invoiceBalanceRemainingToPay = 0
      }
      this.atleastOneItemSelected ? this.itemsNotSelectedShowError = false : this.itemsNotSelectedShowError = true
      // this.checkedItemDetails.length && this.checkedItemDetails.length > 0 ?  : this.itemsNotSelectedShowError = true
      return check
    }
    catch (e) {
      throw e
    }


  }

  setAll(completed: boolean) {
    try {
      this.allComplete = completed;
      if (this.itemsInPoForInvoice == null) {
        return;
      }
      this.itemsInPoForInvoice.forEach(t => (t.checked = completed));
    }
    catch (e) { throw e }
  }


  createInvoice(currentPurchaseOrderwithId: PurchaseOrderModel) {

    try {
      this.isLoading = true
      //update new invoice version id doc in backend
      for (let items in this.itemsInPoForInvoice) {
        let matchItemChecked = []
        //matchItemChecked filters from items in PO and items of invoice selection - condition - listingId same & mat-select is true
        if (this.itemsInPoForInvoice[items].checked) {
          matchItemChecked = currentPurchaseOrderwithId.itemDetails.filter((eachItem) => {
            return eachItem.listingId === this.itemsInPoForInvoice[items].itemlistingId
          })
        }
        //gives all items selected
        this.checkedItemDetails = [...this.checkedItemDetails, ...matchItemChecked];
      }
      // const totalSum = allItemsInPO.reduce((sum, item) => sum + parseInt(item.price_with_taxes, 10), 0);
      this.itemsInvoicePaidAtCheckout = parseInt(Number(this.checkedItemDetails.reduce((sum, item) => sum + item.itemPaidAmount, 10)).toFixed(2))
      const invoiceRaiseValueWithTaxOfSelectedItems = this.checkedItemDetails.reduce((sum, item) => sum + item.itemPriceWithTaxes, 0)
      const nextInvoiceBalanceDueValueofPO = Number(currentPurchaseOrderwithId.orderValue - this.sumOfAllInvoiceAmountsRaisedForPO - invoiceRaiseValueWithTaxOfSelectedItems)
      if (this.invoiceForm.invalid) {
        return
      }

      else if (!this.itemsNotSelectedShowError && this.invoiceForm.valid) {
        //bankDetails from invoice form
        const useBankDetails: BankDetails = {
          accountName: this.invoiceForm.value.accountName,
          accountNumber: this.invoiceForm.value.accountNumber,
          ifscNumber: this.invoiceForm.value.accountIFSC,
          branchName: this.invoiceForm.value.accountBankBranch,
          paymentRemarks: this.invoiceForm.value.paymentRemarks,
          paymentDeadline: this.invoiceForm.value.paymentDeadline
        }
        let version: number
        if (!currentPurchaseOrderwithId.invoiceVersions || currentPurchaseOrderwithId.invoiceVersions && currentPurchaseOrderwithId.invoiceVersions.length === 0) {
          version = 1
        }

        if (currentPurchaseOrderwithId.invoiceVersions && currentPurchaseOrderwithId.invoiceVersions.length > 0) {
          this.latestVersionInvoice = this.currentPurchaseOrderwithId.invoiceVersions[this.currentPurchaseOrderwithId.invoiceVersions.length - 1]
          version = this.latestVersionInvoice.version + 1
        }
        const newInvoice: InvoiceModel = {
          //
          _id: null,
          //
          invoiceNumber: null,
          invoiceVersionNumber: version,
          //
          purchaseOrderNumber: currentPurchaseOrderwithId.purchaseOrderNumber,
          //
          purchaseOrderID: currentPurchaseOrderwithId._id,

          //
          buyerDetails: currentPurchaseOrderwithId.buyerDetails,
          //
          sellerDetails: currentPurchaseOrderwithId.sellerDetails,
          // filtered selected items of invoice from all items in PO 
          itemDetails: this.checkedItemDetails,
          invoicedItemsAmtPaidAtCheckout: this.itemsInvoicePaidAtCheckout,
          amountRemainingToPayOnCurrentInvoice: this.selectedItemAmountRemaining,
          //
          shipToBuyerLocation: currentPurchaseOrderwithId.shipToBuyerLocation,
          modePickup: currentPurchaseOrderwithId.modePickup,
          //
          bankDetails: useBankDetails,
          //update in backend

          dateOfINVGenerated: null,
          //update in backend
          dateOfINVExpiry: null,
          //update in backend - 120 days
          validityOfINVExpiry: null,
          //
          // dateOfPOGenerated: currentPurchaseOrderwithId.dateOfPOGenerated,
          //
          // dateOfPurchaseOrderExpiry: currentPurchaseOrderwithId.dateOfPurchaseOrderExpiry,
          //
          validityOfPurchaseOrderExpiry: currentPurchaseOrderwithId.validityOfPurchaseOrderExpiry,
          //
          s3POpdfLink: currentPurchaseOrderwithId.s3POpdfLink,

          s3InvoicepdfLink: null,
          //
          orderValue: currentPurchaseOrderwithId.orderValue,
          //
          orderValueIncludingTax: currentPurchaseOrderwithId.orderValueIncludingTax,

          orderQuantity: currentPurchaseOrderwithId.orderQuantity,
          //
          orderTax: currentPurchaseOrderwithId.orderTax,
          //
          invoiceRaiseValue: this.displayTotalPriceInclTaxForSelectedItems,
          //
          nextInvoiceBalanceDueValue: this.nextInvoiceAmountRaiseValue,
          deadlineDays: this.invoiceForm.value.paymentDeadline,
          //
          paymentLink: null,
          paymentStatus: false,
          //
          paymentTrnsctnId: null,
          //
          isPOStatusActive: currentPurchaseOrderwithId.isPOStatusActive,
          // a po in process cannot be cancelled ,
          //
          poStatus: currentPurchaseOrderwithId.poStatus,
          //
          isInvoiceGenerated: true,
          //
          invoiceStatus: '',
          //
          invoiceType: 'Invoice',
          invoiceDeliveryOTP: null,
          dateOfINVDelivered: null,
          dateOfINVPaid: null
        }
        this.checkedItemDetails = []
        console.info('newInvoice', newInvoice)
        this.hins.createNewInvoice(newInvoice).then(response => {
          this.createdInvoiceOrder = response.createdINVO
          if (response && response.status == 201) {
            //fetch update po with invoice versions doc 
            // we will create 1st invoice version here
            // it will also have details of itemListingIds which were invoiced 
            // as well as itemlisingIds which were left for next invoice , 
            // for 1stVerOfInvoice -> we will put a list of all remaining itemlisingIds comparing to purchaseOrder itself
            // but for invoiceOrders that have multiple versions, we will concat the list of all raised itemlisingIds of all versions ,
            // and then compare against purchaseOrder

            //scenario 1 - firstpurchase order which has 5 items buy order by seller with id 1 , 2 , 3 , 4 ,5
            // in this if we created 1st invoice order and selected 2 items - 1 and 2 , then 1stVersionOfInvoice will have -
            // 1 and 2 item's itemlisingIds as selected, and 3 , 4 , 5 as remaining for next invoice order  
            // which should be added to purchaseOrder , and then shown in 2nd version of createInvoice

            // now if we created 2nd version of InvoOrder , and selected 3 and 4 , 
            //then version 2 of InvoOrder will have 3 and 4 as selected , and to calculate remaining itemlisingIds, if we compare against 
            //purchase order directly, it will save 1 , 2 and 5 , but we dont want that 

            // if the seller creates 3rd InvoVersion, it should display only 5th item remaing , so when we load PurchaseOrdder on this component,
            // we will gather the invoVersions of all invoices raised, and concat the list of all itemlisingIds in all invoices, and then compare against
            // purchase order and show remaining itemlisingIds for last version
            if (currentPurchaseOrderwithId.invoiceVersions && currentPurchaseOrderwithId.invoiceVersions.length === 0) {
              this.createFirstVersionforINVO(currentPurchaseOrderwithId, this.displayTotalPriceInclTaxForSelectedItems)
            }

            if (currentPurchaseOrderwithId.invoiceVersions && currentPurchaseOrderwithId.invoiceVersions.length > 0) {
              this.createMoreVersionsForINVO(currentPurchaseOrderwithId, this.displayTotalPriceInclTaxForSelectedItems)
            }

          }
        })
      }
    }
    catch (e) { throw e }
  }

  createFirstVersionforINVO(currentPurchaseOrderwithId: PurchaseOrderModel, invoiceRaiseValueWithTaxOfSelectedItems: number) {
    try {
      // listingIds of Items which were included in Invoice Order
      const listingIdsInCurrentINVO: string[] = this.createdInvoiceOrder.itemDetails.map(eachItem => {
        return eachItem.listingId
      })
      // listingIds of Items  remaining for version 2 - compare against currentPurchaseOrderwithId directly
      const listingIDSremainV2 = currentPurchaseOrderwithId.itemDetails.filter(itemsInPO => {
        return !listingIdsInCurrentINVO.includes(itemsInPO.listingId)
      }).map(eachItem => {
        return eachItem.listingId
      })
      const invoiceVersion: VersionInvoicing = {
        version: 1,
        invoiceItemsAmountPaidAtCheckout: this.itemsInvoicePaidAtCheckout,
        invoiceAmount: this.displayTotalPriceInclTaxForSelectedItems,
        isActive: true,
        invoiceNumber: this.createdInvoiceOrder.invoiceNumber,
        balanceRemainingForNextInvoice: this.invoiceBalanceRemainingToPay,
        invoiceID: this.createdInvoiceOrder._id,
        invoiceStatus: this.createdInvoiceOrder.invoiceStatus,
        paymentLink: this.createdInvoiceOrder.paymentLink,
        paymentStatus: this.createdInvoiceOrder.paymentStatus,
        s3INVOPDFLink: this.createdInvoiceOrder.s3InvoicepdfLink,
        itemsAddedForInvoice: listingIdsInCurrentINVO,
        itemsRemainingForInvoice: listingIDSremainV2
      }
      const firstVersionOfInvoice = []
      firstVersionOfInvoice.push(invoiceVersion)
      // create invoiceVersionUpdateModelFor PO - invoiceVersions
      this.updateInvoiceVersionInsidePO(firstVersionOfInvoice, currentPurchaseOrderwithId._id, currentPurchaseOrderwithId.purchaseOrderNumber)
    }
    catch (e) {
      throw e
    }
  }

  createMoreVersionsForINVO(currentPurchaseOrderwithId: PurchaseOrderModel, invoiceRaiseValueWithTaxOfSelectedItems: number) {
    try {
      this.latestVersionInvoice = currentPurchaseOrderwithId.invoiceVersions[currentPurchaseOrderwithId.invoiceVersions.length - 1]
      const latestNumberInvoiceVersion = currentPurchaseOrderwithId.invoiceVersions.length
      const nextNumberInvoiceVersion = currentPurchaseOrderwithId.invoiceVersions.length + 1
      // listingIds of Items which were included in current Invoice Order
      const listingIDsOfAllItemsOfInvoiceCreated: string[] = this.createdInvoiceOrder.itemDetails.map(eachItem => {
        return eachItem.listingId
      })
      // concat list of all itemsListingIds raised so far in all invoice versions of this PO 
      const allItemIdsForallINVOvers: string[][] = currentPurchaseOrderwithId.invoiceVersions.map(eachVersion => { return eachVersion.itemsAddedForInvoice })
      // need to flatten allItemIdsForallINVOvers array since it has nested arrays of IDs, and we need to add all of them together
      let flatArrayofallItemIdsForallINVOvers = [].concat(...allItemIdsForallINVOvers)
      const listingIdsAllINVOVersionsCurrentAndPrev = listingIDsOfAllItemsOfInvoiceCreated.concat(flatArrayofallItemIdsForallINVOvers)
      //listingIDs of all items that were selected in current invoice order and also from all versions of previous INVO which were added to POs
      // Items  remaining for next version
      const itemsRemainingItemsForNextInvoiceOfPO = currentPurchaseOrderwithId.itemDetails.filter(itemsInPO => {
        return !listingIdsAllINVOVersionsCurrentAndPrev.includes(itemsInPO.listingId)
      })
      // listingIds of Items  remaining for next version
      const listingIDsOfRemainingItemsForNextInvoiceOfPO = itemsRemainingItemsForNextInvoiceOfPO.map(eachItemRemaining => {
        return eachItemRemaining.listingId
      })
      // .map(eachItem => {
      //   return eachItem.listingId
      // })
      const newInvoiceVersiontoPush: VersionInvoicing = {
        version: nextNumberInvoiceVersion,
        invoiceItemsAmountPaidAtCheckout: this.itemsInvoicePaidAtCheckout,
        invoiceAmount: this.displayTotalPriceInclTaxForSelectedItems,
        isActive: true,
        invoiceNumber: this.createdInvoiceOrder.invoiceNumber,
        balanceRemainingForNextInvoice: this.createdInvoiceOrder.nextInvoiceBalanceDueValue,
        invoiceID: this.createdInvoiceOrder._id,
        invoiceStatus: this.createdInvoiceOrder.invoiceStatus,
        paymentLink: this.createdInvoiceOrder.paymentLink,
        paymentStatus: this.createdInvoiceOrder.paymentStatus,
        s3INVOPDFLink: this.createdInvoiceOrder.s3InvoicepdfLink,
        itemsAddedForInvoice: listingIDsOfAllItemsOfInvoiceCreated,
        itemsRemainingForInvoice: listingIDsOfRemainingItemsForNextInvoiceOfPO
      }
      // all versions of invo in PO 
      const allINVOversionsOfPO = currentPurchaseOrderwithId.invoiceVersions
      // append new version of INvo created on this order to POInvoVersions
      const updatedVersionOfInvoice: VersionInvoicing[] = allINVOversionsOfPO.concat(newInvoiceVersiontoPush)
      // // create invoiceVersionUpdateModelFor PO - invoiceVersions
      this.updateInvoiceVersionInsidePO(updatedVersionOfInvoice, currentPurchaseOrderwithId._id, currentPurchaseOrderwithId.purchaseOrderNumber)
    }
    catch (e) {
      throw e
    }
  }


  updateInvoiceVersionInsidePO(versionOfInvoice: VersionInvoicing[], purchaseOrderId: string, purchaseOrderNumber: string) {
    try {
      this.hpos.updatePurchaseOrderWithInvoiceVersion(versionOfInvoice, purchaseOrderId, purchaseOrderNumber).then(
        createdInvoVersion => {
          // if (createdInvoVersion && createdInvoVersion.status === 201)
          this.isLoading = false
          this.router.navigate([`viewInvoiceOrder/${this.createdInvoiceOrder._id}`])
        }
      )
    }
    catch (e) {
      throw e
    }
  }
}
