import { Component, Input, SimpleChanges, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TradeItemDetails } from 'src/app/datamodels/contractOrders.ts/tradeItemsDetails';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-view-contractor-item-details',
  templateUrl: './view-contractor-item-details.component.html',
  styleUrls: ['./view-contractor-item-details.component.css']
})
export class ViewContractorItemDetailsComponent implements OnInit, OnChanges {
  @Input() contractItemDetails: TradeItemDetails;
  currentListingwithId: TradeItemDetails;
  quantityValue: number;
  sellerViewingOwnListing: boolean = false
  allImagesListing: string[]
  companyNameSeller: string
  businessName: string
  taxSlab: number
  priceUpShowForSortex: number;
  min: number;
  step: number = 0;
  max: number;
  isDisplayProductDetails: boolean = true;

  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['contractItemDetails'] && changes['contractItemDetails'].currentValue) {
    }
    this.loadNewItem(this.contractItemDetails)
  }
  ngOnInit(): void {
  }
  loadNewItem(contrItem: TradeItemDetails) {
    this.currentListingwithId = contrItem
    if (!this.currentListingwithId.productDetailInfo
      || !this.currentListingwithId.productDetailInfo[0].type
      || !this.currentListingwithId.productDetailInfo[0].metric
      || !this.currentListingwithId.productDetailInfo[1].type
      || !this.currentListingwithId.productDetailInfo[1].metric
    ) {
      this.isDisplayProductDetails = false
    }
    this.allImagesListing = contrItem.imageUrls
    const priceUpByPerc = 9
    const principalPrice = contrItem.bulkPrice
    const priceUpAmt = priceUpByPerc * (principalPrice / 100)
    this.priceUpShowForSortex = principalPrice + priceUpAmt
    this.quantityValue = 0
    this.min = contrItem.lotSize;
    this.step = contrItem.lotSize;
    this.max = contrItem.bulkQuantity
    this.cdr.markForCheck(); // Mark component for changes
  }
  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }
  onQuantityChange($event: any) {
    try { }
    catch (e) {
      throw e
    }
    this.quantityValue = $event.value
  }
}
