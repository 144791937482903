import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductService } from 'src/app/services/product.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';

@Component({
  selector: 'app-featuredlistingcategory',
  templateUrl: './featuredlistingcategory.component.html',
  styleUrls: ['./featuredlistingcategory.component.css']
})
export class FeaturedlistingcategoryComponent implements OnInit {
  @Input() appBannersfeaturedListings: string[];
  allfeaturedproducts: Product[] = [];
  AllProducts: Product[] = []
  HoldFeature: Product[] = []
  featuredProd: Product;
  featureTrue: boolean;
  toggleProperty = false;
  holdapiproducts: Product[] = []
  isLoading: boolean = true;
  recentListings: ListingModel[] = []
  featuredListings: ListingModel[] = [];
  userIsAuthenticated = false;
  constructor(public productService: ProductService,
    private handlingListing: HandleListingsService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadRecentListings()
  }

  loadRecentListings() {
    this.handlingListing.getAllListings().subscribe(res => {
      if (res) {
        const key1 = '_id';
        const last10UniqueListings = [...new Map(res.map(listing =>
          [listing[key1], listing])).values()]

        const key2 = 'productName';
        // this.featuredListings = [...new Map(last10UniqueListings.map(listing =>
        //   [listing[key2], listing])).values()].slice(-12).filter(allLists => {
        //     return allLists.imageUrls && allLists.imageUrls.length > 0
        //   })
        this.featuredListings = [...new Map(last10UniqueListings.map(listing =>
          [listing[key2], listing])).values()].slice(-12).filter(allLists => {
            return allLists.imageUrls && allLists.imageUrls.length > 0 && !allLists.isListingSortexType
          })
        this.isLoading = false
      }
    })
  }


  // //ClickMainProduct
  // onSelectProduct(productUnique: string) {
  //   this.router.navigate(['/viewVarietyItems', productUnique], { queryParamsHandling: "preserve" })
  //     .then
  // }
}
