import { ChangeDetectorRef, Component, OnInit, } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatModel } from 'src/app/datamodels/ChatModel/chatModel';
import { InteractionChatModel } from 'src/app/datamodels/ChatModel/interactionChat';
import { ChatServiceService } from 'src/app/services/chat-service.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-myvyapaari-chat',
  templateUrl: './myvyapaari-chat.component.html',
  styleUrls: ['./myvyapaari-chat.component.css']
})
export class MyvyapaariChatComponent implements OnInit {
  showCustomerLogo: boolean = false;
  customerChatBox: boolean = false;
  userIsAuthenticated = false;
  userInfo: any
  widgetInt: ChatModel
  optionOne: string = "Bulk Order Supply"
  optionTwo: string = "Latest Mandi Rates"
  optionThree: string = "View All Items on Myapaar"
  optionFour: string = "Something else..."
  optionFive: string = "Customer Support"
  userMessage: string
  allOldInteractions: InteractionChatModel[];
  loadingOldChat: boolean;
  useClientLogo: boolean = false;
  isTyping: boolean
  collection = Array.from(document.getElementsByClassName('mat-bottom-sheet-container') as HTMLCollectionOf<HTMLElement>)[0]
  otherResponseRecorded: boolean
  customHelpMessage0: string = "Okay, our support executive will respond soon."
  customHelpMessage1: string = "Okay, our support executive will respond soon. For better experience kindly Sign Up and get instant response and call back."
  customHelpMessage2: string = "Easy Vyapaar, Only on Myapaar !"
  latestInteraction: InteractionChatModel;
  currentuserId: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private utils: UtilsService,
    private authService: AuthService,
    private chats: ChatServiceService,
    private _bottomSheetRef: MatBottomSheetRef<MyvyapaariChatComponent>) { }

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    if (this.userIsAuthenticated) {
      this.userInfo = this.authService.getUserLoginInfo()
    }
    if (!this.userIsAuthenticated) {
      this.userInfo = this.authService.getGenericUserInfo()
    }
    if (this.userInfo) {
      this.setUserInfo(this.userInfo)
    }

  }

  setUserInfo(userInfo) {
    try {
      this.currentuserId = userInfo.userId
      this.widgetInt = {
        userId: userInfo.userId,
        userLocation: {
          city: userInfo.city || userInfo.userCity,
          state: userInfo.state || userInfo.userState,
          pincode: userInfo.pinCode || userInfo.userPincode,
          phone: userInfo.phone || null,
        },
        interaction: []
      }
      if (userInfo.logoImage) {
        this.useClientLogo = true
      }
      else if (!userInfo.logoImage) {
        this.useClientLogo = false
      }
      this.getAllOldInteractions(this.currentuserId)
      this.collection.style.paddingTop = '0px'
      this.collection.style.paddingLeft = '0px'
      this.collection.style.paddingRight = '0px'
    }
    catch (e) { throw e }

  }

  getAllOldInteractions(userid: string) {
    try {
      this.loadingOldChat = true
      this.chats.getOldInteractions(userid).then(res => {
        this.allOldInteractions = res.allInteractions
        this.instantScrollDown()
        this.loadingOldChat = false
        // this.cdr.detectChanges();
      })
    }
    catch (e) {
      throw e
    }
  }
  firstResponse(optionType: string) {
    try {
      const firstInteraction = {
        clickedOn: optionType,
        customerResponse: ""
      }
      this.widgetInt.interaction = [firstInteraction]
      this.chats.firstOptionChosen(this.widgetInt).then(resp => {
        if (resp && resp.status == 200) {

        }
      })
    }
    catch (e) {
      throw e
    }
  }
  otherResponse(optionType: string) {
    this.loadingOldChat = true
    // this.cdr.detectChanges();
    try {
      const firstInteraction = {
        clickedOn: optionType,
        customerResponse: this.userMessage
      }
      this.widgetInt.interaction = [firstInteraction]
      this.chats.firstOptionChosen(this.widgetInt).then(resp => {
        if (resp && resp.status == 200) {
          this.latestInteraction = resp.latestInteraction
          this.otherResponseRecorded = true
          this.loadingOldChat = false ///
          // this.cdr.detectChanges();
          this.instantScrollDown()
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  instantScrollDown() {
    try {
      this.collection.scrollTop = this.collection.scrollHeight
    }
    catch (e) {
      throw e
    }
  }
  openLink(event: MouseEvent): void {
    try {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
    catch (e) {
      throw e
    }
  }

  openCustomerChat() {
    try {
      this.utils.playMessageAudio();
      this.showCustomerLogo = true
      this.customerChatBox = true
      this.instantScrollDown()
    }
    catch (e) {
      throw e
    }
  }

  closeChat(event: MouseEvent) {
    try {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
    catch (e) {
      throw e
    }
  }

}
