import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';
import { HandleTradesService } from 'src/app/services/handle-trades.service';
import { TradeItemDetails } from 'src/app/datamodels/contractOrders.ts/tradeItemsDetails';
import { OpenTrade } from 'src/app/datamodels/contractOrders.ts/openpurchaseorder';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UtilsService } from 'src/app/services/utils.service';
import { TrDetailsSheetComponent } from '../tr-details-sheet/tr-details-sheet.component';
import { SocketServiceService } from 'src/app/services/socket-service.service';

@Component({
  selector: 'app-live-trades',
  templateUrl: './live-trades.component.html',
  styleUrls: ['./live-trades.component.css']
})
export class LiveTradesComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  liveTRs: OpenTrade[] = []
  sellerTypeDataSource: any
  buyerTypeDataSource: any
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccTRsByAccTyp: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  showCompletedOrdersOnly: boolean = false
  currentUrl: string;
  isTradAvl: boolean = false;
  valTRs: OpenTrade[];
  presTrs: OpenTrade[];
  constructor(
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet,
    private hts: HandleTradesService,
    private hls: HandleListingsService,
    private utils: UtilsService,
    private skts: SocketServiceService
  ) { }

  ngOnInit(): void {
    // this.skts.initializeSocket()
    // Subscribe to 'new' event and receive new trade data
    this.skts.onEvent<OpenTrade>('new').subscribe((trade) => {
      this.liveTRs.unshift(trade); // Add the new trade to the top of the list
    });
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccTRsByAccTyp = this.loginUserInfo.accounttype
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
    // if (this.router.url.includes('Completed') && this.currentUserId && this.currentUserId !== '') {
    //   this.showCompletedOrdersOnly = true
    //   this.getCompletedUserPurchaseOrders(this.currentUserId, this.fetchAccTRsByAccTyp)
    //   return
    // }
    this.gAlLivTr(this.currentUserId, this.fetchAccTRsByAccTyp)
  }

  gAlLivTr(currentUserId: string, loginAccounttype: string) {
    try {
      this.hts.gtAlLivTr(currentUserId, loginAccounttype).subscribe(fetchedTRs => {
        if (fetchedTRs && fetchedTRs.length > 0) {
          this.isLoading = false;
          this.isTradAvl = true
          this.liveTRs = fetchedTRs.filter(x => {
            return x.contractDetails.contrId && x.contractDetails.contractType && x.itemDetails.productName
          }).filter(y => {
            return y.buyerDetails.buyerId !== this.currentUserId && y.sellerDetails.sellerId !== this.currentUserId
          })
          this.presTrs = this.liveTRs
        }
        if (fetchedTRs && fetchedTRs.length === 0) {
          this.isLoading = false;
          this.isTradAvl = false
        }
      })
    }
    catch (e) { }
  }

  searchItem(event: Event) {
    try {
      const filtVal = (event.target as HTMLInputElement).value;
      if (filtVal == '' || !filtVal) {
        this.liveTRs = this.presTrs
        return
      }
      const searchResults = this.liveTRs.filter(trs => {
        return trs.itemDetails.productName.toLocaleLowerCase().includes(filtVal.toLocaleLowerCase())
      })
      if (filtVal.length >= 3 && searchResults.length > 0) {
        this.liveTRs = searchResults
        this.hls.saveUserSearch(filtVal, this.currentUserId)
      }
      if (filtVal.length >= 3 && searchResults.length == 0 || !searchResults) {
        this.liveTRs = []
      }
    }
    catch (e) {
      throw e
    }
  }
  openBottomSheet(datasource: string, detailType: string, data: any): void {
    this.utils.playWidgetAudio();
    this._bottomSheet.open(TrDetailsSheetComponent, {
      data: { ds: datasource, dt: detailType, d: data },
      panelClass: 'custom-width'
    });
  }
}
