import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';
interface selectComms {
  id: string,
  commImg: string,
  commCategoryName: string,
  checked: boolean
}
@Component({
  selector: 'app-four-comm-details',
  templateUrl: './four-comm-details.component.html',
  styleUrls: ['./four-comm-details.component.css']
})
export class FourCommDetailsComponent implements OnInit {
  allComplete: boolean = false;
  atleastOneItemSelected: boolean = false;
  selectedAccountsCards: selectComms[] = []
  sendAccTypeData: any;
  useCats: ProductCategoryModel[] = []
  categoryComms: selectComms[] = [];
  selectedComms: {
    catId: string,
    catName: string
  }[] = [];
  threeAccountData: any
  loadSubtext: string;
  loadHeader: string;
  constructor(private pageShift: SignUpPageShiftService,
    private _location: Location,
    private categoryService: ProductcategoryService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    this.pageShift.currentData.subscribe(data => {
      if (!data) {
        this.router.navigate(['/signup/DailyBulkSupply'])
      }
      this.threeAccountData = data
      this.threeAccountData.accountTypeName == 'Buyer' ? this.loadHeader = "Items you deal in" : this.loadHeader = "Pick items for trade"
      this.loadProductCategories()
    });
  }

  //ProductCategory Api
  loadProductCategories() {
    try {
      this.categoryService.getCategories().subscribe(allCat => {
        this.useCats = allCat
        this.categoryComms = allCat.map(cats => {
          const giveComms: selectComms = {
            id: cats._id,
            commImg: cats.categoryImages[0],
            commCategoryName: cats.categoryName,
            checked: false
          }
          return giveComms
        })
      })
    }
    catch (e) {
      e
    }
  }

  updateAllComplete() {
    try {
      this.allComplete = this.categoryComms != null && this.categoryComms.every(t => t.checked);
    }
    catch (e) { throw e }
  }

  someComplete(): boolean {
    try {
      if (this.categoryComms == null) {
        return false;
      }
      const check = this.atleastOneItemSelected && !this.allComplete;
      this.atleastOneItemSelected = this.categoryComms.filter(t => t.checked).length > 0
      if (this.atleastOneItemSelected) {
        this.selectedAccountsCards = this.categoryComms.filter(t => t.checked)
      }
      return check
    }
    catch (e) {
      throw e
    }
  }

  setAll(completed: boolean) {
    try {
      this.allComplete = completed;
      if (this.categoryComms == null) {
        return;
      }
      this.categoryComms.forEach(t => (t.checked = completed));
    }
    catch (e) { throw e }
  }

  goBack() {
    try {
      this._location.back()
    }
    catch (e) {
      throw e
    }
  }

  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }

  nextOne() {
    try {

      this.selectedComms = this.categoryComms.filter(comms => {
        return comms.checked
      }).map(catIds => {
        const catsAre = {
          catId: catIds.id,
          catName: catIds.commCategoryName
        }
        return catsAre
      })
      if (!this.selectedComms || this.selectedComms.length === 0) {
        this.signupError('Pick atleast one item that you deal in', "Ok")
        return
      }
      const accountInfo = {
        accountTypeName: this.threeAccountData.accountTypeName,
        subAccountTypesChosen: this.threeAccountData.subAccountTypesChosen,
        personName: this.threeAccountData.personName,
        accountEmail: this.threeAccountData.accountEmail,
        phone: this.threeAccountData.phone,
        cc: this.threeAccountData.cc,
        password: this.threeAccountData.password,
        interCategors: this.selectedComms
      }
      this.pageShift.setAccTypeData(accountInfo)
      this.router.navigate(['/signup/BusinessInfo'])
    }
    catch (e) {
      throw e
    }
  }
}
