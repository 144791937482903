<div class="content">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">{{'create business profile' | translate}}</h1>
        </div>
    </div>
    <div class="signUpBox">
        <mat-card>
            <app-myapaar-spinner *ngIf="isLoading" class="mypspin"></app-myapaar-spinner>
            <form id="formSignUp" [formGroup]="signupform" (submit)="nextOne()" *ngIf="!isLoading">
                <mat-form-field appearance="outline">
                    <input matInput type="text" name="fullname" formControlName="fullname"
                        [placeholder]="'Full Name' | translate" required>
                    <mat-error *ngIf="formErrors.fullname">{{formErrors.fullname}}</mat-error>
                    <mat-hint>Mahendra</mat-hint>
                </mat-form-field>
                <div class="filleither">
                    <div *ngIf="!this.signupform.value.email" class="sec1">
                        <mat-form-field class="bidDiscField" appearance="outline">
                            <mat-label>Country Code</mat-label>
                            <input type="text" matInput formControlName="countryCodeControl" [matAutocomplete]="auto"
                                placeholder="Search for a country" />
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let country of filteredCC | async" [value]="country">
                                    {{ country.name }} ({{ country.dial_code }})
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="tel" name="phone" formControlName="phone"
                                [placeholder]="'phone number' | translate" pattern="[0-9]*" maxlength="10">
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>98xxxxxxx9</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!this.signupform.value.phone && !this.signupform.value.email" class="sec2">
                        <mat-hint>
                            Or
                        </mat-hint>
                    </div>
                    <div *ngIf="!this.signupform.value.phone" class="sec3">
                        <mat-form-field>
                            <input matInput type="email" name="email" formControlName="email"
                                [placeholder]="'email' | translate">
                            <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                            <mat-hint>Paw***ar@emailprovider.com</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <mat-form-field appearance="outline">
                    <input matInput type="password" name="password" formControlName="password"
                        [placeholder]="'password' | translate" [type]="hide? 'password':'text'" required>
                    <button mat-icon-button matSuffix (click)="hide=!hide" type="button"
                        [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                    <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                </mat-form-field>
                <!-- <div class="signRow">
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]="this.viralityReferral" formControlName="referral" matInput type="text"
                                name="referral" placeholder="Merchant Referral Code ( optional )">
                            <mat-hint>Become 4 Star Account Instantly</mat-hint>
                        </mat-form-field>
                    </div> -->
                <div class="actions">
                    <div class=" back">
                        <button (click)="goBack()" mat-stroked-button>{{'back'|translate}}</button>
                    </div>
                    <div class="actionButton next">
                        <button type="submit" (keyup.enter)="nextOne()" (click)="nextOne()"
                            mat-raised-button>{{'next'|translate}}</button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>