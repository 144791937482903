import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AllstatesService } from 'src/app/services/allstates.service';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';

@Component({
  selector: 'app-five-gstkyc',
  templateUrl: './five-gstkyc.component.html',
  styleUrls: ['./five-gstkyc.component.css']
})
export class FiveGstkycComponent implements OnInit {
  isLoading: boolean = false;
  fiveAccData: any
  pdfPicked: boolean = false
  pdfPickLoading: boolean = false
  constructor(
    private pageShift: SignUpPageShiftService,
    private _snackBar: MatSnackBar,
    private stateService: AllstatesService,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.pageShift.currentData.subscribe(data => {
      if (!data) {
        this.router.navigate(['/signup/DailyBulkSupply'])
      }
      this.fiveAccData = data
    });

  }
  gstDocPicked() {
  }
}
