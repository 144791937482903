import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
interface openPOsOps {
  optionNumber: number;
  optionName: string;
  optionHint: string;
  optionlink: string;
  optionAccount: string;
}
@Component({
  selector: 'app-trade-procurement',
  templateUrl: './trade-procurement.component.html',
  styleUrls: ['./trade-procurement.component.css']
})
export class TradeProcurementComponent implements OnInit {
  allOptions: openPOsOps[] = [
    {
      optionNumber: 0,
      optionName: 'New Supply Request',
      optionHint: 'Create new supply trade and book orders for purchase from verified buyers',
      optionlink: '/newSupplyTrade',
      optionAccount: 'Seller'
    },
    {
      optionNumber: 1,
      optionName: 'New Purchase Order',
      optionHint: 'Create new purchase request from verified suppliers',
      optionlink: '/newPurchaseTrade',
      optionAccount: 'Both'
    },
    {
      optionNumber: 2,
      optionName: 'Trades Today',
      optionHint: 'Open trades for bulk purchase',
      optionlink: '/openTradesToday',
      optionAccount: 'Both'
    },
    {
      optionNumber: 3,
      optionName: 'Applied Trades',
      optionHint: 'Track applied trades',
      optionlink: '/aplliedOpenTrades',
      optionAccount: 'Both'
    },
    {
      optionNumber: 4,
      optionName: 'Accepted Trades',
      optionHint: 'Track accepted trades',
      optionlink: '/acceptedTrades',
      optionAccount: 'Both'
    },
    {
      optionNumber: 5,
      optionName: 'Leaving Soon',
      optionHint: 'Supply orders almost booked and about to be shipped',
      optionlink: '/tradesEnding',
      optionAccount: 'Both'
    },
    {
      optionNumber: 6,
      optionName: 'Customer Support',
      optionHint: 'Connect with Vaayu team for related issues',
      optionlink: '/customerSupportOnTrades',
      optionAccount: 'Both'
    },

  ]
  private loginUserInfo: any
  isAccountBuyer: boolean = false;
  isAccountSeller: boolean = false;
  validOptions: openPOsOps[] = []
  constructor(private authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    const accountType = this.loginUserInfo.accounttype
    this.setTradeOptionsWindow(accountType)
  }
  setTradeOptionsWindow(accType: string) {
    console.info('accType', accType)
    let useOptions
    switch (accType) {
      case "Buyer":
        useOptions = this.allOptions.filter(allOps => {
          return allOps.optionAccount == 'Both'
        })
        break;
      case "Seller":
        useOptions = this.allOptions.filter(allOps => {
          return allOps.optionAccount == 'Seller' || 'Both'
        })
        break;
      default:
        useOptions = this.allOptions.filter(allOps => {
          return allOps.optionAccount == 'Seller' || 'Both'
        })
        break;
    }
    this.validOptions = useOptions
    console.info('this.validOptions', this.validOptions)
  }

  switchRoute(routeLink: string) {
    this.router.navigate([`MTR/MyTradeRoom/${routeLink}`])
  }
}
