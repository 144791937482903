import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileLink } from 'src/app/datamodels/userLinking/feedProfileLink';
import { UserLinkPendingRequest } from 'src/app/datamodels/userLinking/userLinkRequestModel';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-penduslnk',
  templateUrl: './penduslnk.component.html',
  styleUrls: ['./penduslnk.component.css']
})
export class PenduslnkComponent implements OnChanges, OnInit {
  @Input() forPendProfs: UserLinkPendingRequest[]
  @Input() currUserId: string
  sndCurrUserIdOwnr: string
  allPendProfs: ProfileLink[] = [];
  constructor(
    private usrLnkSrv: HandleUserLinkService,
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['forPendProfs'] && changes['forPendProfs'].currentValue) {
    }
  }
  ngOnInit(): void {
    this.loadPendProfs(this.forPendProfs)
  }
  loadPendProfs(pendProf: UserLinkPendingRequest[]) {
    const allUsrs = pendProf.map(allProfs => {
      return allProfs.rqstByUserID
    })
    this.usrLnkSrv.loadAllPendLinks(allUsrs).then(
      allPendProfs => {
        this.allPendProfs = allPendProfs.pendProfs
      }
    )
  }

  remCnCorAccPendReq(remId: string) {
    try {
      const requestIndex = this.allPendProfs.findIndex(allReqIds => allReqIds.usid === remId)
      if (requestIndex !== -1) {
        this.allPendProfs.splice(requestIndex, 1)
        this.utils.playLinkAudio()
      }
      return
    }
    catch (e) {
      throw e
    }
  }
  seeMyLinks() {
    try {
      let links: ProfileLink[]
      links = this.allPendProfs
      const linkTypes = {
        linkCards: links, typ: 'pnd', usId: this.currUserId
      }
      this.usrLnkSrv.sendLinks(linkTypes)
      this.router.navigate(['pendingLinks'], { relativeTo: this.route })
    }
    catch (e) {
      throw e
    }
  }
}
