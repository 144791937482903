import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { MatTableDataSource } from '@angular/material/table';
import { HandleTradesService } from 'src/app/services/handle-trades.service';
import { OpenTrade } from 'src/app/datamodels/contractOrders.ts/openpurchaseorder';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UtilsService } from 'src/app/services/utils.service';
import { TrDetailsSheetComponent } from '../tr-details-sheet/tr-details-sheet.component';

@Component({
  selector: 'app-my-trades',
  templateUrl: './my-trades.component.html',
  styleUrls: ['./my-trades.component.css']
})
export class MyTradesComponent implements OnInit {
  presTrs: OpenTrade[];
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  userIdTRs: OpenTrade[] = []
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccTRsByAccTyp: string;
  isTradAvl: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private hts: HandleTradesService,
    private _bottomSheet: MatBottomSheet,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccTRsByAccTyp = this.loginUserInfo.accounttype
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
    // if (this.router.url.includes('Completed') && this.currentUserId && this.currentUserId !== '') {
    //   this.showCompletedOrdersOnly = true
    //   this.getCompletedUserPurchaseOrders(this.currentUserId, this.fetchAccTRsByAccTyp)
    //   return
    // }
    this.geAlMyTr(this.currentUserId, this.fetchAccTRsByAccTyp)
  }


  geAlMyTr(currentUserId: string, loginAccounttype: string) {
    try {
      this.hts.geUsPbTr(currentUserId, loginAccounttype).subscribe(fetchedTRs => {
        if (fetchedTRs && fetchedTRs.length > 0) {
          this.isLoading = false;
          this.isTradAvl = true
          this.userIdTRs = fetchedTRs.filter(x => {
            return x.contractDetails.contrId && x.contractDetails.contractType && x.itemDetails.productName
          }).filter(y => {
            return y.buyerDetails.buyerId == this.currentUserId || y.sellerDetails.sellerId == this.currentUserId
          })
          this.presTrs = this.userIdTRs
        }
        if (fetchedTRs && fetchedTRs.length === 0) {
          this.isLoading = false;
          this.isTradAvl = false
        }
      })
    }
    catch (e) { }
  }
  searchItem(event: Event) {
    try {
      const filtVal = (event.target as HTMLInputElement).value;
      if (filtVal == '' || !filtVal) {
        this.userIdTRs = this.presTrs
        return
      }
      const searchResults = this.userIdTRs.filter(trs => {
        return trs.itemDetails.productName.toLocaleLowerCase().includes(filtVal.toLocaleLowerCase())
      })
      if (filtVal.length >= 3 && searchResults.length > 0) {
        this.userIdTRs = searchResults
      }
      if (filtVal.length >= 3 && searchResults.length == 0 || !searchResults) {
        this.userIdTRs = []
      }
    }
    catch (e) {
      throw e
    }
  }

  openBottomSheet(datasource: string, detailType: string, data: any): void {
    this.utils.playWidgetAudio();
    this._bottomSheet.open(TrDetailsSheetComponent, {
      data: { ds: datasource, dt: detailType, d: data },
      panelClass: 'custom-width'
    });
  }
}
