<div class="bannerData">
  <div class="image">
    <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
    <div class="img-slider">
      <div class="slide">
        <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000" color="accent"
          [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true" [useKeyboard]="true"
          [maintainAspectRatio]="false" orientation="ltr">
          <mat-carousel-slide *ngFor="let slide of this.appBannerslandingvarieties; let i = index" [image]="slide"
            overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
        </mat-carousel>
      </div>
    </div>
    <div *ngIf="isLoading" class="content-loading">
      <div id="loadingScreen">
        <div class="loadingText" *ngIf="this.LoadingText">
          <h1 class="loadingTitle">{{this.LoadingText}} </h1>
        </div>
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div *ngIf="this.featuredVarietyListings && !isLoading" class="overlap-varieties">
      <div class="card-wrapper">
        <div class="itemRow">
          <div *ngFor="let nonfeaturedVariety of this.featuredVarietyListings | slice:0:3; let i=index" class="cards">
            <div (click)="onSelectVariety(nonfeaturedVariety.varietyName)" class="variety-img">
              <img alt="nonfeaturedVariety.imageUrls[0]" src="{{nonfeaturedVariety.imageUrls[0]}}" width="240">
            </div>
            <div>
              <div (click)="onSelectVariety(nonfeaturedVariety.varietyName)" class="variety-info">
                <div class="variety-text">
                  <span class="vname">{{nonfeaturedVariety.varietyName}}</span>
                  <span class="pname">{{nonfeaturedVariety.productName}}</span>
                  <span class="description"> {{nonfeaturedVariety.description}}</span>
                </div>
              </div>
              <div class="variety-view">
                <button mat-stroked-button class="primary">
                  {{(nonfeaturedVariety.bulkPrice).toLocaleString('en-In')}} ₹/{{nonfeaturedVariety.useUnitShort}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="itemRow">
          <div *ngFor="let nonfeaturedVariety of this.featuredVarietyListings | slice:4:7; let i=index" class="cards">
            <div (click)="onSelectVariety(nonfeaturedVariety.varietyName)" class="variety-img">
              <img alt="nonfeaturedVariety.imageUrls[0]" src="{{nonfeaturedVariety.imageUrls[0]}}" width="240">
            </div>
            <div>
              <div (click)="onSelectVariety(nonfeaturedVariety.varietyName)" class="variety-info">
                <div class="variety-text">
                  <span class="vname">{{nonfeaturedVariety.varietyName}}</span>
                  <span class="pname">{{nonfeaturedVariety.productName}}</span>
                  <span class="description"> {{nonfeaturedVariety.description}}</span>
                </div>
              </div>
              <div class="variety-view">
                <button mat-stroked-button class="primary">
                  {{(nonfeaturedVariety.bulkPrice).toLocaleString('en-In')}} ₹/{{nonfeaturedVariety.useUnitShort}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>