<div class="bannerSCentral">
    <div class="shopbanner">
        <div class="backgroundBanner">
            <img *ngIf="!this.isUserShopImagePresent" src="../../../assets/svgiconsandimages/profilebannerImg.svg" />
            <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
        </div>
        <div class="shopimg sec">
            <div class="profileActions settingsButton">
                <div class="editProfile" *ngIf="this.isCurrOwnerProfile">
                    <button class="circle-link" matTooltip="Myapaar Profile Settings"
                        [routerLink]="['/accountInformation', this.userId]">
                        <img alt="playstore myapaar" src="../../../assets/svgiconsandimages/settings-teams.svg"
                            alt="Myapaar team settings" style="width:100%;">
                    </button>
                    <div class="editButton">Settings</div>
                </div>
            </div>
        </div>
        <div class="logoImg">
            <img *ngIf="!this.isUserLogoPresent" src="../../../../../assets/accountTypes/myp-user.svg" />
            <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
        </div>
    </div>
</div>
<div class="contentSC">
    <div *ngIf="this.isLoading" class="content-loading">
        <div id="loadingScreen">
            <div class="loadingText">
                <h1> Verified Merchants </h1>
                <h3> Loading Profile </h3>
            </div>
            <app-myapaar-spinner class="mypspin"></app-myapaar-spinner>
        </div>
    </div>
    <!-- <div *ngIf="!this.isLoading && this.isCurrOwnerProfile && this.currentUserId === this.userId"
        class="listingPageActions lpstart">
        <div *ngIf="this.isCurrOwnerProfile && this.currentUserId === this.userId" class="ucol cardDetails">
            <p class="ucol cardHeader">
                {{this.myPendLinksHeader}}
            </p>
            <div *ngIf="this.showPendProfs">
                <app-penduslnk [forPendProfs]="this.pendLnks" [currUserId]="this.currentUserId"></app-penduslnk>
            </div>
        </div>
    </div> -->
    <div *ngIf="!this.isLoading" class="contentProfile">
        <div class="profileDetails">
            <div class="ucol cardDetails">
                <div class="cardHeader one">
                    {{'Business Details' | translate}}
                </div>
                <h1 class="business-name">{{this.userCompanyName}}</h1>
                <div class="rowCol account">
                    <div class="row gst" *ngIf="this.isUserGSTavailable">
                        <div class="keyIs">GSTIN</div>
                        <div class="valueIs gstBlock">{{this.userGST}}
                            <div matTooltip="GST Verified User" *ngIf="this.isUserGSTVerified" class="verifiedLogo">
                            </div>
                        </div>
                        <div>
                            <mat-icon *ngFor="let ratings of ratingsAsStars(this.userRating)"
                                style="color: rgb(226, 226, 7);">star</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="profileDetails">
            <div class="ucol cardDetails">
                <div class="cardHeader one">
                    {{'Account Details' | translate}}
                </div>
                <div class="two">
                    <div class="linkCol">
                        <div class="userName">
                            <span
                                matTooltip="User registered with GST. To report fraud kindly mail at solutions@myapaar.com"
                                class="userIs"> {{this.userName}}</span>
                            <!-- <button (click)="this.myLink()" *ngIf="this.showLnkCTA && !this.isCurrOwnerProfile"
                                class="linkUser" mat-raised-button>
                                {{this.linkButton}} <mat-icon>supervisor_account</mat-icon>
                            </button> -->
                        </div>

                    </div>
                </div>
                <!-- <mat-hint *ngIf="!this.isCurrOwnerProfile" class="linkHint">
                    <span *ngIf="this.isNew">Link — Contact</span>
                    <span *ngIf="this.isPending">Request sent</span>
                    <span *ngIf="this.isLinked">Message</span> <mat-icon>check</mat-icon>
                </mat-hint> -->
            </div>
        </div>
        <!-- <div class="profileDetails">
            <div class="ucol cardDetails" *ngIf="this.showMyLnkProfs">
                <p class="ucol cardHeader">
                    {{this.myLinksHeader}}
                </p>
                <div *ngIf="this.myLnks.length > 0">
                    <app-accuslnk [allLnksOwn]="this.myLnks" [currUserId]="this.currentUserId"></app-accuslnk>
                </div>
            </div>
        </div> -->
        <div class="profileDetails">
            <div *ngIf="this.isCurrOwnerProfile && this.currentUserId === this.userId" class="ucol cardDetails">
                <div class="cardHeader one">
                    {{'Improve Credit—Build Network' | translate }}
                </div>
                <p class="referral mainText">
                    {{'Invite merchants & business to grow your merchant network!' | translate}}
                </p>
                <div>
                    <button class="shareRef" title="Whatsapp Share" mat-stroked-button (click)="this.shareWhatsapp()">
                        <img class="refim whatsappref" alt="myapaar referral"
                            src="./../../../assets/svgiconsandimages/whatsapp-ref.svg" />
                    </button>
                    <a class="shareRef" [href]="this.useEmailReferral" title="EmailRef" mat-stroked-button>
                        <img class="refim emailref" alt="myapaar referral"
                            src="./../../../assets/svgiconsandimages/email-ref.svg" />
                    </a>
                </div>
            </div>
        </div>
        <div class="profileDetails">
            <div class="ucol cardDetails">
                <div class="cardHeader one">
                    {{'profile' | translate }}
                </div>
                <div class="account">
                    <div class="allAccountTypes" *ngFor="let accountTypes of this.userSubAccountTypes">
                        <div class="valueIs atype">
                            <span>{{accountTypes | translate}}</span> —
                        </div>
                    </div>
                </div>
                <div *ngIf="this.filteredCats">
                    <p class="referral subText">
                        <b> Deals in...</b>
                    </p>
                </div>
                <div class="container">
                    <div class="card" *ngFor="let cats of this.filteredCats">
                        <img [src]="cats.categoryImages[0]" alt="Image Name">
                        <div class="overlay2">
                            <span>{{cats.categoryName}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="ucol cardDetails">
                <div class="cardHeader one">
                    {{'Activity' | translate}}
                </div>
                <div class="rowCol three">
                    <div class="row gst" *ngIf="this.isSellerProfile">
                        <div class="keyIs"> {{'Sold' | translate}}</div>
                        <div class="valueIs">
                            <span>{{this.userSoldTotalPos}} <mat-icon class="ico">check</mat-icon></span>
                        </div>
                    </div>
                    <div class="row gst" *ngIf="this.isSellerProfile">
                        <div class="keyIs"> {{'Listing Popularity Views' | translate}}</div>
                        <div class="valueIs">
                            <span>{{this.allListingViews}}
                                <mat-icon class="ico">check</mat-icon></span>
                        </div>
                    </div>
                    <div class="row gst" *ngIf="this.isSellerProfile || this.isBuyerProfile">
                        <div class="keyIs"> {{'Paid' | translate}}</div>
                        <div class="valueIs">
                            <span>{{this.userPaidTotalPos}}<mat-icon class="ico">check</mat-icon> </span>
                        </div>
                    </div>
                 
                    <div class="row gst" *ngIf="this.isBuyerProfile">
                        <div class="keyIs"> Placed</div>
                        <div class="valueIs">
                            <span>{{this.userPlacedTotalPos}}<mat-icon class="ico">loyalty</mat-icon></span>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="this.isBuyerProfile" class="ucol cardDetails">
                <div class="cardHeader one">
                    <span class="head">
                        Payments
                    </span>
                </div>
                <div class="rowCol three">
                    <div class="row gst">
                        <div class="keyIs">Invoices Paid</div>
                        <div class="valueIs"><span>{{this.paidInvoices}} <mat-icon class="ico">check</mat-icon></span></div>
                    </div>
                    <div class="row gst">
                        <div class="keyIs">Paid Intime</div>
                        <div class="valueIs"><span>{{this.paidInvoIntime}}<mat-icon
                                    class="ico">playlist_add_check</mat-icon></span>
                        </div>
                    </div>
                    <div class="row gst">
                        <div class="keyIs">Late Payment</div>
                        <div class="valueIs"><span>{{this.paidInvoLate}}<mat-icon
                                    class="ico">error_outline</mat-icon></span></div>
                    </div>
                    <div class="row gst">
                        <div class="keyIs">Items Pending Payment</div>
                        <div class="valueIs"><span>{{this.paidInvoPending}} <mat-icon class="ico">history</mat-icon> </span>
                        </div>
                    </div>

                </div>
            </div> -->
            <div *ngIf="this.isSellerProfile" class="ucol listingcardDetails">
                <div class="cardHeader one">
                    {{'Listings' | translate}}
                </div>
                <div class="contentMyListings">
                    <div *ngIf="this.myListings.length>0 && !isLoading" fxLayout="row wrap"
                        fxLayoutAlign="center center" fxLayoutGap="16px grid">
                        <app-showlistingcard class="featureListcard" *ngFor="let featuredList of this.myListings "
                            [sendFeaturedList]="featuredList"></app-showlistingcard>
                    </div>
                    <div
                        *ngIf="this.noListingFound && this.userAccountType=='Seller' && this.isCurrOwnerProfile && this.currentUserId === this.userId">
                        <p class="referral mainText">{{noListings}}</p>
                        <div [routerLink]="['/createListing']" class="card">
                            <div class="overlay2">+ Inventory Listings</div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="this.isCurrOwnerProfile" class="ucol cardDetails">
                <div class="deleteAccount">
                    <a routerLink="/ByeMyapaar" mat-raised-button>Delete My Account</a>
                </div>
            </div>

        </div>
    </div>
</div>