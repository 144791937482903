<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen"><app-myapaar-spinner class="mypsin"></app-myapaar-spinner></div>
</div>
<div class="contentListings">
    <div *ngIf="!isLoading" class="listingPageActions lpstart">
        <app-quick-enquiry-form [currentListingInfo]="this.quickEnquiryDetails">
        </app-quick-enquiry-form>
    </div>
    <div *ngIf="!isLoading" id="listingCard">
        <main>
            <form [formGroup]="addListingToCart">
                <div *ngIf="this.currentListingwithId" class="card">
                    <div class="cardTitle">
                        <h3>{{this.currentListingwithId.productName}}
                            <span>{{this.currentListingwithId.categoryName}}</span>
                        </h3>
                        <div *ngIf="!this.sellerViewingOwnListing" class="stock"><span>AVAILABLE</span></div>
                        <div *ngIf="this.sellerViewingOwnListing" class="preview"><span>PREVIEW LISTING</span></div>
                    </div>
                    <div class="cardBody">
                        <div class="firsthalf">
                            <div class="img-slider">
                                <div class="slide">
                                    <mat-carousel class="mat-carousel" timings="200ms ease-in" [autoplay]="true"
                                        interval="4000" color="accent" [loop]="true" maxWidth="auto"
                                        [hideIndicators]="true" [hideArrows]="false" [useKeyboard]="true"
                                        [maintainAspectRatio]="false" orientation="ltr">
                                        <mat-carousel-slide *ngFor="let slide of this.allImagesListing; let i = index"
                                            [image]="slide" overlayColor="#rgba(255, 255, 255, 0.3)"
                                            [hideOverlay]="false"></mat-carousel-slide>
                                    </mat-carousel>
                                    <div matTooltip="Views on this listing"
                                        *ngIf="this.currentListingwithId.listingViewCount>135" class="viewCount">
                                        <mat-icon>visibility</mat-icon>
                                        <span>
                                            {{this.currentListingwithId.listingViewCount}}</span>
                                    </div>
                                    <div matTooltip="Supplier Photo"
                                        *ngIf="this.currentListingwithId.listingViewCount>135" class="whiteList">
                                        <span>
                                            {{this.companyNameSeller}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="secondhalf">
                            <div class="spacerDiv">
                                <div class="featured_text">
                                    <h1>
                                        <div class="rowButtonSet">
                                            <span class="listTitle">
                                                {{this.currentListingwithId.title}}
                                            </span>
                                            <button class="shareRef" [cdkCopyToClipboard]="this.copyReferral"
                                                title="Whatsapp Share" mat-stroked-button
                                                (click)="this.successCopyToClipboard('Share Link Copied')">
                                                <img class="refim whatsappref" alt="myapaar referral"
                                                    src="./../../../assets/svgiconsandimages/share-ref.svg" />
                                            </button>
                                        </div>
                                        <span *ngIf="this.currentListingwithId.isVarietyAvailable" class="sub">
                                            {{this.currentListingwithId.varietyName}}
                                        </span>
                                    </h1>
                                    <div class="priceEnq">
                                        <div *ngIf="this.currentListingwithId.isListingSortexType"
                                            class="priceTrendSec">
                                            <span class="upPrice">
                                                ₹&nbsp;{{(this.priceUpShowForSortex).toLocaleString('en-IN')}} /
                                                {{this.currentListingwithId.useUnitShort}}
                                            </span>
                                            <span><mat-icon class="saleTrend">trending_down</mat-icon></span>
                                        </div>
                                        <div class="tpandNote">
                                            <p class="price">
                                                ₹&nbsp;{{(this.currentListingwithId.bulkPrice).toLocaleString('en-IN')}}&nbsp;/
                                                <span>
                                                    {{this.currentListingwithId.useUnitShort}}
                                                </span>
                                                <span id="packetSize" *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">

                                                    <span *ngIf="this.currentListingwithId.packetSize < 1">
                                                        [ {{this.currentListingwithId.packetSize * 1000}} Gm</span>
                                                    <span *ngIf="this.currentListingwithId.packetSize >= 1">
                                                        [ {{this.currentListingwithId.packetSize}} KG</span>
                                                    Packet ]
                                                </span>
                                            </p>
                                            <button *ngIf="!this.currentListingwithId.isListingSortexType"
                                                (click)="getLatestPrice()" class="enq" mat-stroked-button>
                                                Latest Price
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="color-changing-border">
                                    <span class="sub shake">
                                        Bulk Orders on Myapaar
                                    </span>
                                    <!-- only enable for producers -->
                                    <!-- <mat-hint *ngIf="!this.currentListingwithId.isListingSortexType" class="logoImage">
                                        Paid Samples Available </mat-hint> -->
                                </div>
                            </div>
                            <div class="card infoLot">
                                <div class="cardFooter">
                                    <!-- <div class="action-slider">
                                        <div class="shake">
                                            <mat-hint>Slide to Select Quantity</mat-hint>
                                        </div>

                                        <mat-slider [min]="min" [max]="max" [step]="step"
                                            formControlName="quantityValue" thumbLabel [(ngModel)]="quantityValue"
                                            (change)="onQuantityChange($event)"></mat-slider>
                                    </div> -->
                                    <div class="rowButtonSet">
                                        <div class="buttonSet limitwidth col1">
                                            <div class="buttonSetItem quantitySelected">
                                                <mat-form-field class="limitwidth" appearance="outline">
                                                    <mat-label>{{quantityValue}}
                                                        {{this.currentListingwithId.useUnitShort}}s,
                                                        {{quantityValue / step | number:
                                                        '1.0-0'}}
                                                        Lot</mat-label>
                                                    <mat-error
                                                        *ngIf="formErrors.quantityValue">{{formErrors.quantityValue}}</mat-error>
                                                    <!-- <input matInput type="number" min="1" max="this.max"
                                                        style="font-size: large;" placeholder="this.max"
                                                        formControlName="quantityValue" readonly
                                                        [(ngModel)]="quantityValue" required> -->
                                                    <button mat-button mat-icon-button matPrefix aria-label="Remove"
                                                        (click)="incrementValue(-step)"
                                                        [disabled]="shouldDisableDecrement(_value)">
                                                        <mat-icon>remove</mat-icon>
                                                    </button>
                                                    <input readonly matInput type="number" [(ngModel)]="_value"
                                                        [(ngModel)]="quantityValue" (change)="onQuantityChange($event)"
                                                        formControlName="quantityValue" />
                                                    <button mat-button mat-icon-button matSuffix aria-label="Add"
                                                        (click)="incrementValue(step)"
                                                        [disabled]="shouldDisableIncrement(_value)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <mat-hint *ngIf="this.currentListingwithId.isListingSortexType">
                                                    {{(this.quantityValue *
                                                    this.currentListingwithId.packetSize).toLocaleString('en-In')}}&nbsp;KGs</mat-hint>&nbsp;
                                                <mat-hint *ngIf="!this.currentListingwithId.isListingSortexType">
                                                    {{(this.quantityValue *
                                                    100).toLocaleString('en-In')}}&nbsp;KGs</mat-hint>
                                            </div>
                                            <div class="buttonSetItem bulkCart">
                                                <button class="bulkorder cartButton" mat-raised-button
                                                    [disabled]="this.sellerViewingOwnListing || quantityValue===0 || quantityValue===undefined || this.selectedCreditDays=== undefined"
                                                    (click)="addToCart(this.currentListingwithId,'bulk',0)">Add to
                                                    Cart</button>
                                            </div>
                                        </div>
                                        <div class="buttonSet limitwidth col2">
                                            <!-- // -->
                                            <div [ngClass]="{'hidden':this.currentListingwithId.isListingSortexType==true}"
                                                class="buttonSetItem sampleCart">
                                                <button [matMenuTriggerFor]="menu" class="shortorder cartButton"
                                                    mat-stroked-button [disabled]="this.sellerViewingOwnListing">
                                                    Sample
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item
                                                        (click)="addToCart(this.currentListingwithId,'sample',0.1)"
                                                        style="display: flex; align-items: center; gap: 13px;">
                                                        <span>100 Gm</span>
                                                    </button>
                                                    <button mat-menu-item
                                                        (click)="addToCart(this.currentListingwithId,'sample',5)"
                                                        style="display: flex; align-items: center; gap: 13px;">
                                                        <span>5 Kg</span>
                                                    </button>
                                                    <button mat-menu-item
                                                        (click)="addToCart(this.currentListingwithId,'sample',30)"
                                                        style="display: flex; align-items: center; gap: 13px;">
                                                        <span>30 Kg</span>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                            <div class="buttonSetItem policyButton">
                                                <button class="refundPolicy" mat-stroked-button
                                                    routerLink="/TermsAndConditionPolicy"> Return
                                                    Policy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="action-bid">
                                    <button
                                        (click)="openDiscountDialog(this.currentListingwithId._id,this.currentListingwithId.listingCreatorID,this.currentListingwithId.productName,this.currentListingwithId.title,this.currentListingwithId.bulkPrice,this.currentListingwithId.bulkQuantity,this.currentListingwithId.imageUrls,this.currentListingwithId.listingCreator)"
                                        mat-raised-button id="discountButton" style="background-color:#68ac68 ;">Ask
                                        Discount</button>
                                    <button
                                        (click)="openBidDialog(this.currentListingwithId._id,this.currentListingwithId.listingCreatorID,this.currentListingwithId.productName,this.currentListingwithId.title,this.currentListingwithId.bulkPrice,this.currentListingwithId.bulkQuantity,this.currentListingwithId.imageUrls,this.currentListingwithId.listingCreator)"
                                        mat-raised-button id="bidButton" style="background-color:#1298ff ;">Place
                                        Bid</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="threehalf">
                        <div class="aboutSec listingDesc">
                            <div class="prodAboutDetailSec">
                                <div class="aboutSec"
                                    *ngIf="this.currentListingwithId.productDetailInfo && this.currentListingwithId.productDetailInfo.length > 0 && this.isDisplayProductDetails">
                                    <h1 class="detailHeader">Product Details</h1>
                                    <div class="productInfoDetailsTable">
                                        <div class="itemInfoTable"
                                            *ngFor="let prodInfodetail of this.currentListingwithId.productDetailInfo">
                                            <span class="detkeyProduct"
                                                *ngIf="prodInfodetail && prodInfodetail.type && prodInfodetail.type !== ''">
                                                {{prodInfodetail.type}}</span>
                                            <span class="detvalueProduct"
                                                *ngIf="prodInfodetail && prodInfodetail.value && prodInfodetail.value !== ''">
                                                {{prodInfodetail.metric}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="this.currentListingwithId.description && this.currentListingwithId.description != ''">
                                    <h1 class="detailHeader">About Product</h1>
                                    <div id="listingDescription">
                                        <p>{{this.currentListingwithId.description}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="listDetailSec">
                                <h1 class="detailHeader">Listing Details</h1>
                                <div class="listingDetailsTable">
                                    <div class="keyProduct">
                                        <p
                                            *ngIf="this.currentListingwithId.listingCreator &&  this.currentListingwithId.listingCreator !== ''  &&  this.currentListingwithId.listingCreator !== null ">
                                            Supplier </p>
                                        <p
                                            *ngIf="this.currentListingwithId.PublishDate &&  this.currentListingwithId.PublishDate !== ''  &&  this.currentListingwithId.PublishDate !== null ">
                                            Listing Date</p>
                                        <p
                                            *ngIf="this.currentListingwithId.listingCreatorRatings && this.currentListingwithId.listingCreatorRatings !== ''  && this.currentListingwithId.listingCreatorRatings !== null">
                                            Seller Ratings</p>
                                        <p
                                            *ngIf="this.currentListingwithId.categoryName && this.currentListingwithId.categoryName !== ''">
                                            Category</p>
                                        <p
                                            *ngIf="this.currentListingwithId.productName && this.currentListingwithId.productName !== ''">
                                            Product</p>
                                        <p
                                            *ngIf="this.currentListingwithId.varietyName && this.currentListingwithId.varietyName !== '' && this.currentListingwithId.varietyName !== null  && this.currentListingwithId.varietyName !== 'null'">
                                            Variety</p>
                                        <p
                                            *ngIf="this.currentListingwithId.state && this.currentListingwithId.state !== ''">
                                            Sourced State</p>
                                        <p
                                            *ngIf="this.currentListingwithId.city && this.currentListingwithId.city !== ''">
                                            Sourced City</p>
                                        <p *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">Packet Size
                                        </p>
                                        <p *ngIf="this.currentListingwithId.lotSize && this.currentListingwithId.lotSize  !== undefined 
                                                && this.currentListingwithId.lotSize !== null">Min. Order- MOQ
                                        </p>
                                        <p *ngIf="this.currentListingwithId.bulkPrice && this.currentListingwithId.bulkPrice !== undefined
                                                && this.currentListingwithId.bulkPrice !== null">Price per
                                            {{this.currentListingwithId.useUnit}}
                                        </p>
                                        <p *ngIf="this.currentListingwithId.hsnCodeItem && this.currentListingwithId.hsnCodeItem !== ''  
                                            && this.currentListingwithId.bulkPrice !== undefined
                                                && this.currentListingwithId.hsnCodeItem !== null">HSN Code</p>

                                    </div>
                                    <div class="valueProduct">
                                        <button class="checkSupplier" mat-stroked-button
                                            (click)="this.viewUserProfile(this.currentListingwithId.listingCreatorID,this.businessName)"
                                            *ngIf="this.currentListingwithId.listingCreator &&  this.currentListingwithId.listingCreator !== ''  &&  this.currentListingwithId.listingCreator !== null ">
                                            <mat-icon>supervisor_account</mat-icon>{{this.currentListingwithId.companyName}}</button>
                                        <p
                                            *ngIf="this.currentListingwithId.PublishDate &&  this.currentListingwithId.PublishDate !== ''  &&  this.currentListingwithId.PublishDate !== null ">
                                            {{this.currentListingwithId.PublishDate | date}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.listingCreatorRatings && this.currentListingwithId.listingCreatorRatings !== ''  && this.currentListingwithId.listingCreatorRatings !== null">
                                            {{this.currentListingwithId.listingCreatorRatings }}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.categoryName && this.currentListingwithId.categoryName !== ''">
                                            {{this.currentListingwithId.categoryName}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.productName && this.currentListingwithId.productName !== ''">
                                            {{this.currentListingwithId.productName}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.varietyName && this.currentListingwithId.varietyName !== null && this.currentListingwithId.varietyName !== 'null' && this.currentListingwithId.varietyName !== ''">
                                            {{this.currentListingwithId.varietyName}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.state && this.currentListingwithId.state !== ''">
                                            {{this.currentListingwithId.state}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.city && this.currentListingwithId.city !== ''">
                                            {{this.currentListingwithId.city}}</p>
                                        <p *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">
                                            {{this.currentListingwithId.packetSize}} KG</p>
                                        <p *ngIf="this.currentListingwithId.lotSize && this.currentListingwithId.lotSize  !== undefined 
                                                    && this.currentListingwithId.lotSize !== null">
                                            {{this.currentListingwithId.lotSize}}
                                            {{this.currentListingwithId.useUnit}}</p>
                                        <p *ngIf="this.currentListingwithId.bulkPrice && this.currentListingwithId.bulkPrice !== undefined
                                                    && this.currentListingwithId.bulkPrice !== null">
                                            ₹&nbsp;{{this.currentListingwithId.bulkPrice}}&nbsp;/{{this.currentListingwithId.useUnit}}
                                        </p>
                                        <p *ngIf="this.currentListingwithId.hsnCodeItem && this.currentListingwithId.hsnCodeItem !== ''  && this.currentListingwithId.bulkPrice !== undefined
                                                    && this.currentListingwithId.hsnCodeItem !== null">
                                            {{this.currentListingwithId.hsnCodeItem }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="aboutSec">
                            <h1 class="detailHeader">Reviews <span id="reviewsNumber"
                                    *ngIf="this.totalReviewsonCurrentListing>0">(
                                    {{this.totalReviewsonCurrentListing}}
                                    reviews )</span> </h1>
                            <div>
                                <div class="banner">
                                    <div class="overlayP">
                                        <div class="colText">
                                            <h2 class="fade-in-text bannerHead">Vaayu Delivery</h2>
                                            <h2 class="fade-in-text bannerMid"> At your doorstep. Fast & Reliable
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="reviews">
                                    <app-post-create id="postCreate" (reviewSubmitted)="reloadPostList($event)"
                                        *ngIf="this.currentUserId"></app-post-create>
                                    <app-post-list id="postList" (totalReviewsEvent)="getTotalReviewsonListing($event)"
                                        (reviewDeletedEvent)="reloadPostListOnDelete($event)"
                                        [listingId]="this.listingId" [reviewDelete]="reviewDeleted"
                                        [reviewSubmit]="reviewSubmitted"></app-post-list>
                                    <!-- <app-show-listing></app-show-listing> -->
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
    <div *ngIf="!isLoading" class="listingPageActions lpEnd">

    </div>
</div>