import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { TnCModel } from 'src/app/datamodels/groupedmodel/tncsModel';
import { HandleUserComplService } from 'src/app/services/handle-user-compl.service';

@Component({
  selector: 'app-complete-kyc',
  templateUrl: './complete-kyc.component.html',
  styleUrls: ['./complete-kyc.component.css']
})
export class CompleteKYCComponent implements OnInit {
  collection = Array.from(document.getElementsByClassName('mat-bottom-sheet-container') as HTMLCollectionOf<HTMLElement>)[0]
  loadHeader: string;
  isUploading: boolean = false
  tnc1: TnCModel = {
    header: "Confidentiality of GST Document",
    pointers: ["Myapaar will not share your GST document with third parties.", "The document is solely used to verify you as the business owner and ensure the legitimacy of your business on the platform."]
  };
  tnc2: TnCModel = {
    header: "GST Document Requirements & Compliance",
    pointers: ["The GST document must be issued in your first name and last name as the GST ID owner or one of the owners or partners.",
      "Your business or trade name should be listed as the company name when creating your profile."]
  };
  tnc3: TnCModel = {
    header: "Authenticity & Integrity",
    pointers: ["The GST document provided is original and certified.",
      "It has been issued by the State or Central Authority of India in compliance with all regulatory checks and guidelines.",
      "The document has not been edited, tampered with, or modified in any manner.",
      "The document adheres to all applicable laws and regulations governing GST in India."
    ]
  };
  tnc4: TnCModel = {
    header: "Business Scope and Compliance",
    pointers: ["The GST document provided is original and certified.",
      "You confirm that the business activities carried out on the Myapaar platform are strictly in accordance with the business agreement registered with the Ministry of Corporate Affairs (MCA) and the Registrar of Companies (ROC).",
      "These activities must align with the nature of business as stated and linked to your GST ID.",
      "You agree not to use the platform for any business or activities outside the scope of your registered business and GST-linked operations."
    ]
  };
  tnc5: TnCModel = {
    header: "GST Updates and Filings",
    pointers: ["Myapaar, All Indus Roots, or any of their associates are not responsible for maintaining or updating your GST records.",
      "It is solely your responsibility to ensure that your GST details are accurate, up-to-date, and compliant with all applicable laws and regulations.",
      "You agree not to use the platform for any business or activities outside the scope of your registered business and GST-linked operations."
    ]
  };
  tnc6: TnCModel = {
    header: "Verification of Business Ownership",
    pointers: ["The sole and exclusive responsibility of Myapaar is to verify that you, the creator of the account on Myapaar, are the owner or one of the owners of the business linked to the provided GST ID.",
      "Exclusive responsibility of Myapaar is to verify that you are authorized to use the platform for your business interests as per the verified details.",
      "Myapaar reserves the right to terminate or suspend your account in the event of any discrepancy identified in relation to the details provided under your GST ID. Prior notice of such action will be communicated to you via the email address associated with your account. In cases where no valid email address is provided, Myapaar may proceed with the termination or suspension of your account and associated activities without prior notice. You will be informed of such actions upon contacting us at solutions@myapaar.com",
    ]
  };
  tnc7: TnCModel = {
    header: "Limitation of Liability",
    pointers: ["Myapaar, All Indus Roots, or their associates shall not be held liable for any discrepancies, inaccuracies, or non-compliance related to your GST details, filings, or business activities.",
      "Any legal or financial consequences arising from the misuse of the platform or non-compliance with applicable laws.",
    ]
  };
  tnc8: TnCModel = {
    header: "Legal Compliance",
    pointers: ["You agree to abide by all applicable laws, regulations, and guidelines governing your business and GST operations in India.",
      "Any violation of these terms may result in the suspension or termination of your account on Myapaar, along with potential legal action as per Indian laws, and reporting to local, state and national authorities.",
      "Engaging in any form of hateful behavior, including but not limited to acts or expressions of hatred, discrimination, or violence based on color, caste, race, region, religion, gender, political affiliation, or personal bias, is strictly prohibited and actively discouraged on this platform. Violations of this policy may result in immediate suspension or termination of access to the platform."
    ]
  };
  allTNCs: TnCModel[] = [
    this.tnc1, this.tnc2,
    this.tnc3, this.tnc4,
    this.tnc5, this.tnc6,
    this.tnc7, this.tnc8,
  ]
  acceptTerms: boolean = false
  images: any;
  selectedFile: File | null = null;
  errorMessage: string = '';
  constructor(
    private hucompl: HandleUserComplService,
    private _snackBar: MatSnackBar,
    private _bottomSheetRef: MatBottomSheetRef<CompleteKYCComponent>
  ) { }

  ngOnInit(): void {
    this.loadHeader = "Terms & Conditions"
  }

  toggle(valueTnc: boolean) {
    this.acceptTerms = valueTnc
  }
  onFileSelected(event: any) {
    this.isUploading = true
    const file: File = event.target.files[0];
    if (file && file.type === 'application/pdf' && file.size <= 3 * 1024 * 1024) {
      this.selectedFile = file;
      this.errorMessage = '';
      this.uploadFile()
    } else {
      this.selectedFile = null;
      this.errorMessage = 'Please upload a valid PDF file (max 3 MB).';
      this.signupError(this.errorMessage, "Ok")
    }
  }
  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
  uploadFile() {
    try {
      if (this.selectedFile) {
        this.hucompl.gstUploadCompl(this.selectedFile).then(x => {
          if (x && x.status == 'ok') {
            this.isUploading = false
            this._bottomSheetRef.dismiss();
            const success = "Document Uploaded"
            this.signupError(success, "Ok")
            const extractedTerms = this.allTNCs.map(term => ({
              header: term.header,
              pointers: term.pointers
            }));
            const shareDataback = {
              isTnCAgreed: true,
              extractedTerms: extractedTerms,
              s3GstLink: x.fileLocationS3 || ''
            }
            this._bottomSheetRef.dismiss(shareDataback);
          }
        }).catch(
          err => {
            this.errorMessage = 'Error occured. Try again';
            this.signupError(this.errorMessage, "Ok")
            this._bottomSheetRef.dismiss(null);
          }
        )
      } else {
        this.errorMessage = 'No valid file selected.';
        this.signupError(this.errorMessage, "Ok")
      }
    }
    catch (e) {
      throw e
    }

  }
  closeSheet() {
    this._bottomSheetRef.dismiss(null)
  }

}
