<div class="contentSC">
    <div class="bannerSCentral">
        <div class="shopbanner">
            <div class="backgroundBanner">
                <img *ngIf="!this.isUserShopImagePresent"
                    src="../../../assets/svgiconsandimages/profilebannerImg.svg" />
                <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
            </div>
            <div class="shopimg sec">
                <div class="profileActions settingsButton">
                    <div *ngIf="this.isOwnerProfile" class="editProfile">
                        <button [routerLink]="['/accountInformation', this.userId]" mat-stroked-button>
                            Edit Profile
                        </button>
                    </div>

                </div>
            </div>
            <div class="logoImg">
                <img *ngIf="!this.isUserLogoPresent" src="../../../assets/svgiconsandimages/4-brand-svgrepo-com.svg" />
                <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
            </div>
        </div>
    </div>
    <div *ngIf="isLoading" class="content-loading">
        <div id="loadingScreen">
            <div class="loadingText">
                <h1> Verified Merchants </h1>
                <h3> Loading Profile </h3>
            </div>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="!this.isLoading" class="profileDetails">
        <div class="ucol cardDetails">
            <div class="one">
                <h1 class="compName">{{this.userCompanyName}}</h1>
            </div>
            <div class="two">
                <span class="valueIs"> {{this.userName}}</span>
                <div *ngIf="this.isUserGSTVerified " class="verifiedLogo"></div>
            </div>
            <div class="rowCol account">
                <div class="row gst" *ngIf="this.isUserGSTavailable">
                    <div class="keyIs">GSTIN</div>
                    <div class="valueIs">{{this.userGST}}</div>
                </div>
                <div class="userRatings settingsButton">
                    <div>
                        <mat-icon *ngFor="let ratings of ratingsAsStars(this.userRating)"
                            style="color: rgb(226, 226, 7);">star</mat-icon>
                    </div>
                </div>
            </div>
            <div class="account">
                <div class="allAccountTypes" *ngFor="let accountTypes of this.userSubAccountTypes">
                    <div class="row valueIs">
                        {{accountTypes}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="this.isOwnerProfile && this.currentUserId === this.userId" class="ucol cardDetails">
            <p class="referral mainText">
                Grow your Ratings & Trade Network ?
            </p>
            <p class="referral subText">
                <b> Invite others with personal referral code</b>
            </p>
            <div>
                <button class="shareRef" [cdkCopyToClipboard]="this.copyReferral" title="Whatsapp Share"
                    mat-stroked-button
                    (click)="this.successCopyToClipboard('Referral Message Copied ! Share on whatsapp')">
                    <img class="refim whatsappref" alt="myapaar referral"
                        src="./../../../assets/svgiconsandimages/whatsapp-ref.svg" />
                </button>
                <a class="shareRef" [href]="this.useEmailReferral" title="EmailRef" mat-stroked-button>
                    <img class="refim emailref" alt="myapaar referral"
                        src="./../../../assets/svgiconsandimages/email-ref.svg" />
                </a>
            </div>
        </div>
        <div class="ucol cardDetails">
            <div class="cardHeader one">
                Orders Fulfilled
            </div>
            <div class="rowCol three">
                <div class="row gst" *ngIf="this.isSellerProfile">
                    <div class="keyIs">Orders Sold</div>
                    <div class="valueIs">
                        <span>{{this.userSoldTotalPos}} <mat-icon class="ico">check_box</mat-icon></span>
                    </div>
                </div>
                <div class="somespace"></div>

                <div class="row gst" *ngIf="this.isSellerProfile || this.isBuyerProfile">
                    <div class="keyIs">Orders Paid & Settled</div>
                    <div class="valueIs">
                        <span>{{this.userPaidTotalPos}}<mat-icon class="ico">check</mat-icon> </span>
                    </div>
                </div>
                <div class="row gst" *ngIf="this.isBuyerProfile">
                    <div class="keyIs">Orders Placed</div>
                    <div class="valueIs">
                        <span>{{this.userPlacedTotalPos}}<mat-icon class="ico">loyalty</mat-icon></span>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="this.isSellerProfile" class="ucol listingcardDetails">
            <div class="cardHeader one">
                Listings
            </div>
            <div class="contentMyListings">
                <div *ngIf="this.myListings.length>0 && !isLoading" fxLayout="row wrap" fxLayoutGap="16px grid">
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let listing of this.myListings">
                        <mat-card
                            [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                            class="mat-elevation-z4">
                            <div class="listingcontainer">
                                <div class="titleVariety">
                                    <div [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                                        class="listingtitle">
                                        {{listing.title}}
                                    </div>
                                    <div>
                                        <mat-card-subtitle *ngIf="listing.isVarietyAvailable">
                                            {{listing.varietyName}}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                                <div class="spacerDiv"></div>
                                <div class="listingDetails">
                                    <div class="listingPrice">
                                        <span>{{(listing.bulkPrice).toLocaleString('en-IN')}}
                                            <span style="font-size: 12px;">₹
                                                / {{listing.useUnitShort}}</span>
                                        </span>
                                    </div>
                                    <div class="listingState">{{listing.packetSize}} Kg Packaging</div>
                                </div>
                            </div>
                            <img [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                                mat-card-image [src]="listing.imageUrls[0]" />
                            <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                            <div id="subtitle">
                                <div class="categoryProduct">
                                    <div id="categoryName"> {{listing.categoryName}} </div>
                                    <div id="productName"> {{listing.productName}} </div>
                                </div>
                                <div class="soldBy">
                                    <div> <span class="subtitle-spacer">Supplied from</span> <span
                                            class="listState">{{listing.state}}</span>
                                    </div>
                                    <div class="sellerName">{{listing.companyName}}</div>
                                </div>
                            </div>
                            <mat-card-content>
                                <p class="listingDesc">
                                    {{listing.description}}
                                </p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div *ngIf=" this.noListingFound">
                    <h2 [routerLink]="['/createListing']">{{noListings}}</h2>
                </div>
            </div>
        </div>
        <div *ngIf="this.isBuyerProfile" class="ucol cardDetails">
            <div class="cardHeader one">
                <span class="head">
                    Payments
                </span>
            </div>
            <div class="rowCol three">
                <div class="row gst">
                    <div class="keyIs">Invoices Paid</div>
                    <div class="valueIs"><span>{{this.paidInvoices}} <mat-icon class="ico">check</mat-icon></span></div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Paid Intime</div>
                    <div class="valueIs"><span>{{this.paidInvoIntime}}<mat-icon
                                class="ico">playlist_add_check</mat-icon></span>
                    </div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Late Payment</div>
                    <div class="valueIs"><span>{{this.paidInvoLate}}<mat-icon
                                class="ico">error_outline</mat-icon></span></div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Pending Payment</div>
                    <div class="valueIs"><span>{{this.paidInvoPending}} <mat-icon class="ico">history</mat-icon> </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>