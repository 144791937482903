import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickFeedPost } from 'src/app/datamodels/QuickFeed/QuickFeedPost';
import { ProfileLink } from 'src/app/datamodels/userLinking/feedProfileLink';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { QuickViewServiceService } from 'src/app/services/quick-view-service.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-quick-feed',
  templateUrl: './quick-feed.component.html',
  styleUrls: ['./quick-feed.component.css']
})
export class QuickFeedComponent implements OnInit {
  @Input() profDet: ProfileLink
  @Input() auth: boolean

  isLoading: boolean = false;
  imageUrls: string[];
  vidUrl: string;
  docUrl: string
  images: any;
  imagesUriArray: string[];
  isImageLoading: boolean = false;
  userInput: string = ''; // Stores the user's input
  Uploadsuccessful: boolean;
  noUploadMsg: string;
  allListingImages: string[] = []
  logoImagePreview: string;

  formattedOutput: string = ''; // Stores the formatted output
  disableDoc: boolean = false;
  disableImg: boolean = false;
  disableVideo: boolean = false
  isUploading: boolean = false
  selectedFile: File | null = null;
  selectedVidFile: File | null = null;
  videoUrl: SafeUrl | null = null; // For video preview

  errorMessage: string;
  qfd: string;
  isUserLogoPresent: boolean = false;
  isUserNamePresent: boolean = false;
  isUserBizNamePresent: boolean = false;
  qfForm: FormGroup
  maxLength = 1300;
  textareaRows = 3;
  remainingChars = this.maxLength;
  qfsCreator: string;
  isDocOverlayOpen: boolean = false;
  formPostDisabled: boolean = true
  textLength: number = 0;
  anyOneCheck: boolean = false;
  isPostDisabled: boolean = false;
  notifyPostError: string;
  private authListenerSubs: Subscription
  loginInfo: any;

  constructor(
    private _snackBar: MatSnackBar,
    private imgHandler: ImageHandlingService,
    private commserv: CommentpostService,
    public qvds: QuickViewServiceService,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.auth = isAuthenticated
        this.loginInfo = this.authService.getUserLoginInfo()
        if (this.loginInfo) {
          this.profDet = {
            usid: this.loginInfo.userId,
            name: this.loginInfo.firstname + ' ' + this.loginInfo.lastname,
            logoImage: this.loginInfo.logoImage || null,
            bannerImage: this.loginInfo.shopImage || null,
            businessName: this.loginInfo.companyName || null,
            isGstVerified: this.loginInfo.isGSTVerified || false,
            profileAppCode: this.loginInfo.profileAppCode || null,
            accSubType: this.loginInfo.accountSubtype,
          }
        }
        this.isDocOverlayOpen = false
      }
    })
    if (this.profDet?.logoImage && this.profDet?.logoImage !== '') {
      this.isUserLogoPresent = true
    }
    if (this.profDet?.name && this.profDet?.name !== '') {
      this.isUserNamePresent = true
    }
    if (this.profDet?.businessName && this.profDet?.businessName !== '') {
      this.isUserBizNamePresent = true
    }
    if (!this.isUserBizNamePresent) {
      this.qfsCreator = this.profDet?.usid.substring(0, 8)
    }
    if (this.auth) {
      this.isDocOverlayOpen = false;
    }

    this.qfForm = new FormGroup({
      description: new FormControl('', [Validators.maxLength(this.maxLength)]),
    })
    // Listen for changes in the description field
    this.qfForm.get('description').valueChanges.subscribe(value => {
      this.remainingChars = this.maxLength - value.length;
    });
    //formT
  }

  // doneForm() {
  //   try {
  //     if (!this.phoneContact || this.phoneContact === undefined) {
  //       this.notify('Add Details')
  //       return
  //     }
  //     this.isLoading = true
  //     const reqForm = {
  //       accountType: this.selectedAccountType,
  //       imageUrls: this.imageUrls,
  //       itemName: this.itemName,
  //       itemQuantity: this.itemQuantity,
  //       phone: this.phoneContact,
  //       targetPr: this.targetPrice
  //     }
  //     this.usels.newRequirement(reqForm).then(posted => {
  //       this.isLoading = false
  //       let notifyMess: string
  //       if (posted && posted.status === 201) {
  //         this.displayPostSuccess = true
  //         notifyMess = posted.message
  //       }
  //       else {
  //         notifyMess = posted.message
  //       }
  //       this.notify(notifyMess)
  //     })
  //   }
  //   catch (e) { throw e }
  // }
  onInputChange(event: Event) {
    const descriptionControl = this.qfForm.get('description');
    this.textLength = descriptionControl?.value.length || 0;
    // Update remaining characters
    this.remainingChars = this.maxLength - this.textLength;
    // Dynamically adjust rows based on content length
    if (this.textLength > 100 && this.textLength <= 300) {
      this.textareaRows = 5;
    } else if (this.textLength > 300 && this.textLength <= 600) {
      this.textareaRows = 7;
    } else if (this.textLength > 600 && this.textLength <= 900) {
      this.textareaRows = 9;
    } else if (this.textLength > 900) {
      this.textareaRows = 11;
    } else {
      this.textareaRows = 3; // Default rows for short content
    }
    const textarea = event.target as HTMLTextAreaElement;
    this.userInput = textarea.value;
  }

  notify(mess: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(mess, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
  async onImagesPicked(event: Event) {
    try {
      this.isImageLoading = true;
      const pickedFiles = (event.target as HTMLInputElement).files;
      if (!pickedFiles || pickedFiles.length === 0) {
        this.noUploadMsg = 'Add 3 Images of Item';
        return;
      }
      this.images = pickedFiles;
      const compressedFiles = await this.imgHandler.compressMultiple(this.images);
      if (compressedFiles) {
        this.imagesUriArray = await this.imgHandler.generateImagePreviews(compressedFiles);
        this.onMultiImageUpload(compressedFiles)
      }
    } catch (e) {
      console.error('Error during image processing:', e);
    } finally {
      this.isImageLoading = false;
    }
  }
  onMultiImageUpload(images: any) {
    this.commserv.addImagesforFeedPost(images)
      .subscribe((ImagesPostResponse) => {
        // mat spinner stops when result comes
        this.isLoading = false;
        this.imagesUriArray = ImagesPostResponse.locationArray
        if (ImagesPostResponse.status == 'ok') {
          //uploadimages successful,
          this.Uploadsuccessful = true;
          this.isImageLoading = false;
          //patch
          this.imageUrls = this.imagesUriArray
          this.disableDoc = true
          this.disableVideo = true
        }
      },
        error => {
          //uploadimages unsuccessful,
          this.Uploadsuccessful = false;
          this.isImageLoading = false;
          this.notify("Image upload fail - Invalid Format & Size")
        })
  }
  onFileSelected(event: any) {
    this.isUploading = true
    const file: File = event.target.files[0];
    if (file && file.type === 'application/pdf' && file.size <= 3 * 1024 * 1024) {
      this.selectedFile = file;
      this.uploadFile()
    } else {
      this.selectedFile = null;
      this.errorMessage = 'Please upload a valid PDF file (max 3 MB).';
      this.notify(this.errorMessage)
    }
  }
  // async onVidPick(event: Event) {
  //   console.info('onVidPick')
  //   this.isUploading = true
  //   const input = event.target as HTMLInputElement;
  //   const file: File = input.files[0];
  //   // Validate MIME type
  //   if (!file.type.startsWith('video/')) {
  //     this.errorMessage = 'Only video files are allowed.';
  //     this.selectedVidFile = null;
  //     this.notify(this.errorMessage)
  //     return;
  //   }
  //   this.selectedVidFile = file;
  //   this.onVidUp()
  // }

  uploadFile() {
    try {
      if (this.selectedFile) {
        this.commserv.upQfDoc(this.selectedFile).then(x => {
          if (x && x.status === 200) {
            this.qfd = x.qfd
            this.disableImg = true
            this.disableVideo = true
            this.isUploading = false
            const success = "Document Uploaded"
            this.notify(success)
          }
        }).catch(
          err => {
            this.errorMessage = 'Error occured. Try again';
            this.notify(this.errorMessage)
          }
        )
      } else {
        this.errorMessage = 'No valid file selected.';
        this.notify(this.errorMessage)
      }
    }
    catch (e) {
      throw e
    }

  }

  // async onVidUp() {
  //   if (!this.selectedVidFile) return;
  //   try {
  //     if (this.selectedVidFile) {
  //       this.commserv.upMySh(this.selectedVidFile).then(x => {
  //         if (x && x.status === 200) {
  //           console.info('MySHotX', x)
  //           this.qfd = x.qfd
  //           this.disableImg = true
  //           this.disableVideo = true
  //           this.isUploading = false
  //           const success = "Document Uploaded"
  //           this.notify(success)
  //         }
  //       }).catch(
  //         err => {
  //           this.errorMessage = 'Error occured. Try again';
  //           this.notify(this.errorMessage)
  //         }
  //       )
  //     } else {
  //       this.errorMessage = 'No valid file selected.';
  //       this.notify(this.errorMessage)
  //     }
  //   }
  //   catch (e) {
  //     throw e
  //   }
  //   // try {
  //   //   // Load FFmpeg
  //   //   if (!this.ffmpeg.isLoaded()) {
  //   //     await this.ffmpeg.load();
  //   //   }

  //   //   // Read the file
  //   //   const fileData = await fetchFile(this.selectedVidFile);

  //   //   // Write the file to FFmpeg's file system
  //   //   this.ffmpeg.FS('writeFile', 'input.mp4', fileData);

  //   //   // Trim and compress the video
  //   //   await this.ffmpeg.run(
  //   //     '-i', 'input.mp4',
  //   //     '-t', '13', // Trim to first 13 seconds
  //   //     '-vf', 'scale=1280:720', // Resize to 1280x720
  //   //     '-c:v', 'libx264', // Use H.264 codec
  //   //     '-crf', '18', // Constant Rate Factor (0-51, lower is better quality)
  //   //     '-preset', 'slow', // Compression preset
  //   //     'output.mp4'
  //   //   );

  //   //   // Read the processed video
  //   //   const outputData = this.ffmpeg.FS('readFile', 'output.mp4');
  //   //   const processedFile = new File([outputData.buffer], 'output.mp4', {
  //   //     type: 'video/mp4',
  //   //   });
  //   //   // Generate video preview URL
  //   //   this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(
  //   //     URL.createObjectURL(processedFile)
  //   //   );
  //   //   console.info(' this.videoUrl', this.videoUrl)
  //   // }
  //   // catch (e) {
  //   //   throw e
  //   // }
  // }
  rmQfFiles() {
    this.imageUrls = []
    this.images = null
    this.imagesUriArray = undefined
    this.docUrl = ''
    this.disableDoc = false
    this.disableImg = false
    this.selectedFile = null
    this.selectedVidFile = null
    this.disableVideo = false
    this.videoUrl = null;
    this.textLength = null
    this.notifyPostError = null
  }
  postQf() {
    if ((!this.textLength) &&
      (!this.imageUrls?.length) &&
      (!this.docUrl)) {
      return this.notifyPostError = "Share something - Your thoughts, Some Photos or Your fresh catalogue"
    }
    const checkData: QuickFeedPost = {
      _id: null,
      type: 'qf',
      description: this.userInput || null,
      imageUrls: this.imageUrls || null,
      docUrl: this.qfd || null,
      qfsCreatorId: this.profDet.usid,
      publishDate: null
    }
    this.commserv.sendQFS(checkData).then(x => {
      if (x.status === 200) {
        this.rmQfFiles()
        this.userInput = null
        this.notifyPostError = null
      }
    })
  }

  openOverlayDoc() {
    try {
      if (!this.auth) {
        this.isDocOverlayOpen = true;
        return
      }
    }
    catch (e) {
      throw e
    }
  }
  closeDocOverlay() {
    this.isDocOverlayOpen = false;
  }

}
