<svg width="100" height="100" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <!-- Outer Rotating Circle with Enhanced Glow Effect -->
    <filter id="glow">
        <feGaussianBlur stdDeviation="8" result="coloredBlur" />
        <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
        </feMerge>
    </filter>

    <circle cx="100" cy="100" r="80" stroke="#ff4141" stroke-width="4" fill="none" stroke-dasharray="251.2"
        stroke-dashoffset="0" filter="url(#glow)">
        <animateTransform attributeName="transform" type="rotate" from="-90 100 100" to="270 100 100" dur="1s"
            repeatCount="indefinite" keyTimes="0;0.3;1" values="-90 100 100; -54 100 100; 810 100 100" />
        <animate attributeName="stroke-width" from="4" to="0" dur="1s" repeatCount="indefinite" keyTimes="0;1"
            values="4;0" />
    </circle>

    <!-- Myapaar Logo -->
    <image href="../../../assets/svgiconsandimages/myapaar-nobg.png" x="50" y="50" width="100" height="100">
        <animate attributeName="opacity" from="1" to="0.3" dur="1.5s" repeatCount="indefinite" />
    </image>
</svg>