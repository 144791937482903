<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <h2 *ngIf="this.LoadingText !== ''">
            {{this.LoadingText}}
        </h2>
        <app-myapaar-spinner class="mypspin"></app-myapaar-spinner>
    </div>
</div>

<div class="contentSearch" fxLayout="column" fxLayoutAlign="start center">
    <mat-form-field class="search-field" appearance="outline">
        <input matInput placeholder=" Search " [(ngModel)]="searchValue" (keyup)="onSearch()"
            [formControl]="this.searchField" autofocus />
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="myvalue()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <div class="results" *ngIf="this.searchTerm !== '' && this.loadedListings && this.loadedListings.length > 0">
        <div *ngIf="!this.isLoading" class="allListingNames">
            <div class="noresutls">
                <mat-card
                    *ngIf="this.filteredListingsForSearch.length === 0 && this.searchValue && this.searchValue !== 'null' && this.searchValue !== null && this.searchValue !== ''">
                    <mat-card-header>
                        <div class="searchHeader">
                            <div class="title-searched">
                                <p class="listingsInCat">{{this.noResults }} </p>
                            </div>
                        </div>
                    </mat-card-header>
                </mat-card>
            </div>

            <!-- priority to user search on category + product + variety  -->
            <div class="searchExactListing">
                <mat-card
                    *ngIf="this.searchValue && this.searchValue !== 'null' && this.searchValue !== null && this.searchValue !== ''"
                    [routerLink]="['/showListingSearch',this.searchValue]">
                    <mat-card-header>
                        <div class="searchHeader">
                            <div class="title-searched">
                                <p class="listingsInCat"> All Listings of <span
                                        class="boldCategory">{{this.searchValue}}
                                    </span></p>
                            </div>
                            <div class="subTitle-searched">
                                <p>
                                    {{this.filteredListingsForSearch.length }} Results
                                </p>
                            </div>

                        </div>
                    </mat-card-header>
                </mat-card>
            </div>

            <!-- categories -->
            <div *ngIf="this.allCategories && this.allCategories.length > 0 " class="searchCategoriesOfListing">
                <div *ngFor="let category of this.allCategories">
                    <mat-card *ngIf="category && category !== 'null' && category !== null && category !== ''"
                        [routerLink]="['/showListingCategory',category]">
                        <mat-card-header>
                            <div class="searchHeader">
                                <div class="title-searched">
                                    <p class="listingsInCat"> Category in<span class="boldCategory">{{category
                                            }}
                                        </span></p>
                                </div>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>

            </div>

            <!-- products -->
            <div *ngIf="this.allProducts && this.allProducts.length > 0 " class="searchProductsOfListing">
                <div *ngFor="let product of this.allProducts">
                    <mat-card *ngIf="product && product !== 'null' && product !== null && product !== ''"
                        [routerLink]="['/showListingProduct',product]">
                        <mat-card-header>
                            <div class="searchHeader">
                                <div class="title-searched">
                                    <p class="listingsInCat"> Products of<span class="boldCategory">{{product
                                            }}
                                        </span></p>
                                </div>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>

            </div>

            <!-- varieties -->
            <div *ngIf="this.allVarieties && this.allVarieties.length > 0 " class="searchVarietiesOfListing">
                <div *ngFor="let variety of this.allVarieties">
                    <mat-card *ngIf="variety && variety !== 'null' && variety !== null && variety !== ''"
                        [routerLink]="['/showListingVariety',variety]">
                        <mat-card-header>
                            <div class="searchHeader">
                                <div class="title-searched">
                                    <p class="listingsInCat"> Variety of<span class="boldCategory">{{variety
                                            }}
                                        </span></p>
                                </div>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>

            </div>

        </div>
    </div>

</div>