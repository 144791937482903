import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AccountType } from 'src/app/datamodels/accountTypeModel';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AllstatesService } from 'src/app/services/allstates.service';
import { UserAuthModel } from 'src/app/datamodels/userModel';
import { mimeType } from 'src/app/directives/mime-type.validator';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  mode: string;
  editAccountInformation: boolean;
  userId: string;
  isLoading: boolean = false;
  userInfoForm: FormGroup
  teamPositionForm: FormGroup
  private authListenerSubs: Subscription;
  hide = true;

  selectedFilteredAccountSubtype: AccountType[] = [];
  // isGSTAvailable: boolean = false;
  selectedPincode: string = '';
  selectedAccountType: string;
  selectedAccountSubtype: string[];
  stateName: string;
  districtName: string;
  editAccountdata: UserAuthModel;

  accountTypes: AccountType[] = [
    { id: 1, accountTypeName: "Seller", accountTypeIMG: '', accountSubType: "Producer" },
    { id: 2, accountTypeName: "Seller", accountTypeIMG: '', accountSubType: "Manufacturer" },
    { id: 3, accountTypeName: "Seller", accountTypeIMG: '', accountSubType: "Agro-Processor" },
    { id: 4, accountTypeName: "Seller", accountTypeIMG: '', accountSubType: "Distributor" },
    { id: 5, accountTypeName: "Seller", accountTypeIMG: '', accountSubType: "Wholesaler" },
    { id: 6, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Hotel" },
    { id: 7, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Restaurant" },
    { id: 8, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Cafe" },
    { id: 9, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Cloud Kitchen" },
    { id: 10, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Retail Shopping Mart" },
    { id: 11, accountTypeName: "Buyer", accountTypeIMG: '', accountSubType: "Kirana Store" }]
  validationMessages = {
    'accounttype': {
      'required': 'Account type is required'
    },
    'firstname': {
      'required': 'First name is required',
      'maxlength': 'First name cannot have more than 50 characters!'
    },
    'lastname': {
      'required': 'Last name is required',
      'maxlength': 'Last name cannot have more than 50 characters!'
    },
    //
    'password': {
      'required': 'Password is required'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    // 'email': {
    //   'required': 'Email is required',
    //   'email': 'Email not in valid format'
    // }
  }
  //js object to display form errors
  formErrors = {
    'accounttype': 'Select Account Type',
    'firstname': 'Enter First name',
    'lastname': 'Enter Last name',
    'companyName': 'Enter Company name',
    'pinCode': 'Add Company Pincode',
    'state': '',
    'city': '',
    'addressLocality': 'Enter Company locality/area',
    'addressBuildingNameAndFloor': 'Enter Building name and Floor number',
    // 'addressLocality':'',
    'landmark': 'Enter nearby landmark',
    'phone': 'Add Phone number',
    'GST_Id': 'Add GSTin',
    // 'Aadhaar_Id':'',
  }
  accountSubtypeList: string;
  pincodeFullInfo: any = [];
  pinLocalities: any;
  localityName: string;
  gstIdChanged: boolean = false;
  gstVerificationPending: boolean = false;
  gstDetailsPending: boolean = false
  logoImagePreview: string;
  isImageUploading: boolean = false;
  shopImagePreview: string;
  autoSaveInterval: ReturnType<typeof setInterval>
  isLogoImageAdded: boolean = false;
  isShopImageAdded: boolean = false;
  currentMail: string;
  isCachedDataSame: boolean = true;
  private loginUserInfo: any;
  currentUserId: any;
  isTeamAvailable: boolean = false;
  passGstId: string;
  passIds: { GST_Id: string; currentUserID: string; isCurrentUserAdmin: boolean };
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private stateService: AllstatesService,
    private _snackBar: MatSnackBar,
    private imgHandler: ImageHandlingService,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.userInfoForm = new FormGroup({
      //generic
      firstname: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      // password: new FormControl('', { validators: [Validators.required] }),
      //if registeredtrue - company details
      companyName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(70)]
      }),
      pinCode: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(6)]
      }),
      state: new FormControl('', {
        validators: [Validators.required]
      }),
      city: new FormControl('', {
        validators: [Validators.required]
      }),
      addressLocality: new FormControl('', {
        validators: [Validators.required]
      }),
      addressBuildingNameAndFloor: new FormControl('', {
        validators: [Validators.required]
      }),
      landmark: new FormControl('', {
        validators: [Validators.required]
      }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      }),
      logoImage: new FormControl(null,
        { asyncValidators: [mimeType] }),

      shopImage: new FormControl(null,
        { asyncValidators: [mimeType] }),
      // https://www.mastersindia.co/gst-number-verification-api-bulk-utility/
      GST_Id: new FormControl('', {
        validators: [Validators.minLength(15), Validators.maxLength(15)]
      }),
      isGSTVerified: new FormControl(false),
      isGSTAvailable: new FormControl(false),
      // isGSTVerified: new FormControl(null, { validators: [Validators.required] }),
      //
    });
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    const isAdmin = this.loginUserInfo?.teamDetails?.teamRights?.isAdminRights
    this.loginUserInfo.isGSTAvailable && this.loginUserInfo.isGSTVerified ?
      this.passGstId = this.loginUserInfo.GST_Id : this.passGstId = ''
    this.passIds = {
      GST_Id: this.passGstId,
      currentUserID: this.currentUserId,
      isCurrentUserAdmin: isAdmin
    }
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('userId')) {
        //ListingId is in routes - editListing
        this.userId = paramMap.get('userId');
        if (this.currentUserId && this.currentUserId !== this.userId) {
          this.router.navigate(['/'])
        }
        this.mode = "editAccountInformation";
        this.editAccountInformation = true;
        this.loadUserDetails(this.userId)
      }
    });

  }
  loadUserDetails(userID: string) {
    this.isLoading = true; //spinner starts
    this.authService.getAccountDetails(userID).then(info => {
      this.editAccountdata = info.result
      if (this.editAccountdata && this.editAccountdata.GST_Id == '') {
        this.gstDetailsPending = true
      }
      if (!this.editAccountdata.isGSTVerified) {
        this.gstVerificationPending = true
        this.isTeamAvailable = false
      }
      this.isLoading = false; //spinner starts
      this.setFormValuesOnEdit(this.editAccountdata)
      const userCachedInfo = this.authService.getUserLoginInfo()
      const stringEditAccountdata = JSON.stringify(this.editAccountdata)
      const stringUserCachedInfo = JSON.stringify(userCachedInfo)
      this.isCachedDataSame = stringEditAccountdata === stringUserCachedInfo
      if (!this.isCachedDataSame) {
        localStorage.setItem('loginUserInfo', stringEditAccountdata);
      }
    })
  }
  accountTypeFilter() {
    if (this.selectedAccountType === undefined || this.selectedAccountType === null) {
      return
    }
    this.selectedFilteredAccountSubtype = this.accountTypes.filter(accounttypeList => {
      return accounttypeList.accountTypeName == this.selectedAccountType
    })
    // this.accountSubtypeList = this.selectedFilteredAccountSubtype[0].accountSubType
  }

  setFormValuesOnEdit(accountData: UserAuthModel) {
    this.currentMail = accountData.email
    this.selectedAccountType = accountData.accounttype
    this.selectedAccountSubtype = accountData.accountSubtype
    if (accountData.logoImage != null && accountData.logoImage != undefined) {
      this.logoImagePreview = accountData.logoImage
      this.isLogoImageAdded = true
    }
    if (accountData.shopImage != null && accountData.shopImage != undefined) {
      this.shopImagePreview = accountData.shopImage
      this.isShopImageAdded = true
    }
    this.selectedPincode = JSON.stringify(accountData.pinCode)
    this.stateName = accountData.state
    this.districtName = accountData.city
    let isGSTAvailable: boolean

    accountData.GST_Id ? isGSTAvailable = true : isGSTAvailable = false;
    // this.isfilterVarietiesLoading = false; //var load stops
    this.userInfoForm.patchValue({
      'accounttype': this.selectedAccountType,
      'accountSubtype': this.selectedAccountSubtype,
      'firstname': accountData.firstname,
      'lastname': accountData.lastname,
      // 'password': this.editAccountdata.password,
      'pinCode': this.selectedPincode,
      'companyName': accountData.companyName,
      'state': accountData.state,
      'city': accountData.city,
      'addressLocality': accountData.addressLocality,
      'addressBuildingNameAndFloor': accountData.addressBuildingNameAndFloor,
      'landmark': accountData.landmark,
      'phone': accountData.phone,
      'logoImage': accountData.logoImage || '',
      'shopImage': accountData.shopImage || '',
      // 'email': accountData.email,
      'isGSTAvailable': isGSTAvailable,
      'GST_Id': accountData.GST_Id,
      'isGSTVerified': accountData.isGSTVerified,
    });
    this.userInfoForm.markAllAsTouched();

  }

  getAccountDetails(userId: string) {
    this.authService.getAccountDetails(userId).then(updatedInfo => {
      this.setFormValuesOnEdit(updatedInfo)
      // this.selectedPincode = updatedInfo.pincode
    })
  }

  getPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        this.stateService.getPincodeDetails(this.selectedPincode).then(
          pincodeInfo => {
            this.pincodeFullInfo = pincodeInfo.pinCodeInfo
            this.stateName = this.pincodeFullInfo[0].State
            this.districtName = this.pincodeFullInfo[0].District
            //find all different localities from pincode
            const localities = [...new Set(this.pincodeFullInfo.map((obj: any) => obj.Name))];
            this.pinLocalities = localities
          }
        )
      }
      return
    }
    catch (e) { }
  }

  onBrandingImagePicked(event: Event, type: string) {
    try {
      this.userInfoForm.markAsDirty();
      if (event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (!file || file === undefined || file === null) {
          return
        }
        if (file && file !== undefined && file !== null) {
          const reader = new FileReader();
          reader.readAsDataURL(file)
          // this.imgHandler.compressSingle(file).subscribe(thisImg => {
          // })
          if (type === 'logoImage') {
            reader.onload = async () => {
              const useWidth = 620
              const useHeight = 620
              const checkResizedImg = await this.imgHandler.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
              this.logoImagePreview = checkResizedImg;
              this.onBrandingImageUploadS3(this.logoImagePreview, file.name, file.type, type)
              this.setBrandingImageBG(this.logoImagePreview, 'logoIMG')
            };
          }
          if (type === 'shopImage') {
            reader.onload = async () => {
              const useWidth = 1080
              const useHeight = 490
              const checkResizedImg = await this.imgHandler.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
              if (checkResizedImg) {
                this.shopImagePreview = checkResizedImg;
                this.onBrandingImageUploadS3(this.shopImagePreview, file.name, file.type, type)
                this.setBrandingImageBG(this.shopImagePreview, 'shopIMG')
              }
            };
          }
        }
      }
    }
    catch (e) {
      throw e
    }

  }

  setBrandingImageBG(imageURL: string, imageType: string) {
    const imgInBG = "url(" + imageURL + ")";
    if (imageType == 'logoIMG') {
      const logoBusinessDiv = document.getElementById('logoBusiness')
      logoBusinessDiv.style.backgroundImage = imgInBG;
      logoBusinessDiv.style.backgroundSize = '70%';
    }
    if (imageType == 'shopIMG') {
      const shopPhotoDiv = document.getElementById('shopPhoto')
      if (shopPhotoDiv) {
        shopPhotoDiv.style.backgroundImage = "url(" + imageURL + ")";
      }
    }
  }

  onBrandingImageUploadS3(dataURI: string, fileName: string, fileFormat: any, type: string) {
    try {
      this.isImageUploading = true;
      const imageName = fileName.replace(/\s/g, '')
      const file = new File([this.utils.convertDataUrlToBlob(dataURI)], imageName, { type: fileFormat });
      this.authService.addLogoImageforUserProfile(file)
        .subscribe((ImagesPostResponse) => {
          // mat spinner stops when result comes
          this.isImageUploading = false;
          if (ImagesPostResponse && ImagesPostResponse.status === "ok") {
            if (type === 'logoImage') {
              this.userInfoForm.patchValue({
                'logoImage': ImagesPostResponse.fileLocationS3
              })
            }
            if (type === 'shopImage') {
              this.userInfoForm.patchValue({
                'shopImage': ImagesPostResponse.fileLocationS3
              })
            }
          }
          else {
            //uploadimages unsuccessful,
            window.alert("Image Upload Failed, try again !")
          }
        })
      //set values for Variety and Description
    }
    catch (e) {
      throw e
    }

  }



  // dataURItoBlob(dataURI: string, blobType: any) {
  //   // const byteString = window.atob(dataURI);
  //   // const blob = URL.createObjectURL(new Blob([dataURI], { type: blobType }));
  //   // return blob;
  //   const arrayBuffer = new ArrayBuffer(dataURI.length);
  //   const int8Array = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < dataURI.length; i++) {
  //     int8Array[i] = dataURI.charCodeAt(i);
  //   }
  //   const blob = new Blob([int8Array], { type: blobType });
  //   return blob;
  // }

  changeProfileInfo() {
    try {
      this.isLoading = true; //spinner starts
      if ((this.userInfoForm.get('GST_Id').value)) {
        this.userInfoForm.patchValue({
          'isGSTAvailable': true,
        })
        if (this.userInfoForm.get('GST_Id').value !== this.editAccountdata.GST_Id) {
          this.gstIdChanged = true
          this.userInfoForm.patchValue({
            'isGSTVerified': false,
          })
        }
      }
      if (this.userInfoForm.invalid) {
        this.isLoading = false
        const message = " Add Required Details "
        const Action = "Ok"
        this.addValidDetails(message, Action)
        return
      }
      if (this.userInfoForm.valid) {
        this.authService.updateUserDetails(
          this.userId,
          this.userInfoForm.value.firstname,
          // this.userInfoForm.value.email,
          this.userInfoForm.value.phone,
          // this.userInfoForm.value.password,

          //registered means GST and PAN detail provided. we have to manually update isGSTVerified and isPANVerified fields upon successful verification of business or entity
          this.userInfoForm.value.companyName,
          this.userInfoForm.value.pinCode,
          this.userInfoForm.value.state,
          this.userInfoForm.value.city,
          this.userInfoForm.value.addressLocality,
          this.userInfoForm.value.addressBuildingNameAndFloor,
          this.userInfoForm.value.landmark,

          this.userInfoForm.value.isGSTAvailable,
          this.userInfoForm.value.GST_Id,
          this.userInfoForm.value.isGSTVerified,
          this.userInfoForm.value.logoImage,
          this.userInfoForm.value.shopImage,
        ).then(data => {
          if (data && data.status == 201) {
            this.isLoading = false
            const message = " Success ! Details Updated"
            const Action = "Ok"
            this.userUpdateSuccessfull(message, Action)
          }
        })
        return
      }
      this.isLoading = true; //spinner starts
    }
    catch (e) {
    }

  }
  addValidDetails(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })

    }
    catch (e) {
      throw e
    }

  }
  userUpdateSuccessfull(message: string, action: string) {
    try {
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
      snackbarRef.afterDismissed().subscribe(() => {
        this.loadUserDetails(this.userId)
      })
    }
    catch (e) {
      throw e
    }

  }
}
