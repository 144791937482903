import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-star-ratings',
  templateUrl: './star-ratings.component.html',
  styleUrls: ['./star-ratings.component.css']
})
export class StarRatingsComponent implements OnInit {
  @Input() rating: number = 0; // Default rating
  @Output() ratingChange = new EventEmitter<number>();
  constructor() { }
  stars: boolean[] = [false, false, false, false, false];
  hoverIndex: number | null = null; // To track hovered star

  ngOnInit() {
    this.updateStars();
  }

  updateStars() {
    this.stars = this.stars.map((_, index) => index < this.rating);
  }

  setRating(index: number) {
    this.rating = index + 1;
    this.updateStars();
    this.ratingChange.emit(this.rating);
  }

  setHover(index: number) {
    this.hoverIndex = index;
  }

  clearHover() {
    this.hoverIndex = null;
  }
}
