<div *ngIf="this.sendFeaturedList" class="product-card small-card">
    <img [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]"
        [src]="this.sendFeaturedList.imageUrls[0]" alt="{{this.sendFeaturedList.title}}">
    <div class="info">
        <div class="categoryProduct">
            <mat-hint id="categoryName"> {{this.sendFeaturedList.categoryName | translate }} </mat-hint> |
            <mat-hint id="productName"> {{this.sendFeaturedList.productName | translate }} </mat-hint>
        </div>
        <h3
            [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]">
            {{this.sendFeaturedList.title}}</h3>
     
        <div class="actRow">
            <div class="soldBy">
                <div>
                    <span class="listState"> <mat-icon class="subtitle-spacer">
                            location_on</mat-icon> &nbsp;{{this.sendFeaturedList.state |
                        translate}}</span>
                    <div class="sellerName">
                        {{this.sendFeaturedList.companyName}}</div>
                </div>
            </div>
            <div class="shP">
                <button *ngIf="!this.isShowPrice" (click)="showPrice()" mat-stroked-button class="primary">
                    Show Price
                </button>
                <button *ngIf="this.isShowPrice" mat-stroked-button class="primary">
                    <span>{{(this.sendFeaturedList.bulkPrice).toLocaleString('en-In')}}</span>
                    <span> ₹ / {{this.sendFeaturedList.useUnitShort}}</span>
                </button>
            </div>
        </div>


    </div>
</div>