import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import * as io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { OpenTrade } from '../datamodels/contractOrders.ts/openpurchaseorder';
import { QuickFeedPost } from '../datamodels/QuickFeed/QuickFeedPost';
import { ProfileLink } from '../datamodels/userLinking/feedProfileLink';
@Injectable({
  providedIn: 'root'
})
export class SocketServiceService {
  private skt: Socket;
  private domain: string | undefined
  constructor(
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  // initializeSocket(): void {
  //   console.info('socket domain', this.domain)
  //   console.info('this.skt', this.skt)
  //   if (this.domain && !this.skt) {
  //     // const socketUrl = `${this.domain}socket.io-dev`
  //     const socketUrl = this.domain
  //     console.info('socketUrl', socketUrl)
  //     // Replace with your server's URL
  //     this.skt = io(this.domain, {
  //       // path: '/socket.io-dev',
  //       transports: ['websocket'], // Force WebSocket to bypass polling
  //       secure: true, // Ensure secure connection (HTTPS)
  //       // withCredentials: true // Include cookies if needed
  //     });
  //   }
  //   console.info('sock here')
  //   this.skt.on('error', function (socket) {
  //     socket.emit('error');
  //   });
  //   this.skt.on('connection', function (socket) {
  //     socket.emit('create_socket');
  //   });
  //   this.skt.on('newQFPostCH', (data) => {
  //     console.log('newQFPostCH', data)
  //   });
  //   // this.skt.on('newQFPost', (data) => {
  //   //   console.log('newQFPost', data)
  //   // });
  //   // this.skt.on('new', (data: OpenTrade) => {
  //   //   console.log('sockDaa', data)
  //   // });
  // }
  initializeSocket(): void {
    if (this.domain && !this.skt) {
      const socketUrl = this.domain.replace(/\/$/, ''); // Remove trailing slash if present
      this.skt = io(socketUrl, {
        transports: ['websocket'],
        secure: true,
      });

      this.skt.on('connect', () => {
      });

      this.skt.on('connect_error', (error) => {
        console.error('connect_error:', error);
      });

      this.skt.on('disconnect', () => {
      });

      this.skt.on('newQFPostCH', (data) => {
      });
    }
  }
 
  onNewQF(): Observable<{ qfP: QuickFeedPost; usrProf: ProfileLink }> {
    return new Observable((observer) => {
      this.skt.on('newQFPost', (data) => {
        observer.next(data);
      });
    });
  }

  getSocket(): Socket {
    return this.skt;
  }
  // Emit an event to the server (e.g., 'createTrade')
  emitEvent(event: string, data: any): void {
    if (this.skt) {
      this.skt.emit(event, data);
    }
  }

  onEvent<T>(event: string): Observable<T> {
    return new Observable((observer) => {
      if (this.skt) {
        this.skt.on(event, (data: T) => {
          observer.next(data);
        });

        // Cleanup when the observer unsubscribes
        return () => {
          this.skt.off(event);
        };
      }
    });
  }
}
