<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1>Easy Withdrawals</h1>
        </div><app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="contentNotLoading">
    <div *ngIf="!isLoading" class="contentWithdrawals">
        <div class="newWithdraw">
            <mat-card>
                <div class="createWithdraw">
                    <div class="cardstate">
                        <span>New Withdrawal Request</span>
                        <span class="invoiceNumb">
                            {{this.withdrawalFromInvo.useInvoiceNumber}}
                        </span>
                    </div>
                    <div *ngIf="this.withdrawalFromInvo" class="withdrawalBox">
                        <form [formGroup]="bankDetailsForm">
                            <div class="bankDetails">
                                <div class="bankDetailsResetAdd">
                                    <mat-hint class="subheading">Payment to this account</mat-hint>
                                    <button class="reset" *ngIf="this.bankDetailsForm.valid" (click)="resetForm()"
                                        mat-stroked-button>
                                        Reset Bank Details
                                    </button>
                                    <button class="add" *ngIf="this.bankDetailsForm.invalid"
                                        (click)="setBankFormValues()" mat-stroked-button>
                                        Add bank details
                                    </button>
                                </div>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" name="accountName" formControlName="accountName"
                                        placeholder="Account Name" minlength="1" maxlength="100" required>
                                    <mat-error *ngIf="formErrors.accountName">{{formErrors.accountName}}</mat-error>
                                    <mat-hint>Jack Ma</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" name="accountNumber" formControlName="accountNumber"
                                        placeholder="Account Number" minlength="8" maxlength="25" required>
                                    <mat-error *ngIf="formErrors.accountNumber">{{formErrors.accountNumber}}</mat-error>
                                    <mat-hint>05110xxxxxxxx</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" name="accountIFSC" minlength="11" maxlength="11"
                                        formControlName="accountIFSC" placeholder="IFSC" required>
                                    <mat-error *ngIf="formErrors.accountIFSC">{{formErrors.accountIFSC}}</mat-error>
                                    <mat-hint>SBIN0511xxxx</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" name="accountBankBranch"
                                        formControlName="accountBankBranch" placeholder="Branch Name" required>
                                    <mat-error
                                        *ngIf="formErrors.accountBankBranch">{{formErrors.accountBankBranch}}</mat-error>
                                    <mat-hint>Kalyan Nagar</mat-hint>
                                </mat-form-field>
                            </div>
                        </form>
                        <div class="col boxBorder">
                            <div class="invoiceAmounts">
                                <div class="invInf credit">
                                    <span>
                                        {{(this.withdrawalFromInvo.useInvoiceAmount).toLocaleString('en-IN')}} ₹
                                    </span>
                                    <mat-hint>Total Invoice Amount</mat-hint>
                                </div>
                                <div class="invInf debit">
                                    <span>
                                        -{{(this.withdrawalFromInvo.usetotalDeducDone).toLocaleString('en-IN')}} ₹
                                    </span>
                                    <mat-hint>Deductions</mat-hint>
                                </div>
                            </div>
                            <div class="withdrawalInfo">
                                <p class="finalPayout major">
                                    {{(this.amountWithdrawVal).toLocaleString('en-IN')}} ₹
                                </p>
                                <mat-hint>Withdrawal Amount</mat-hint>
                            </div>
                        </div>
                        <div class="doWithdrawal">
                            <button [disabled]="this.bankDetailsForm.invalid" class="withdrawButton"
                                (click)="this.createWithdrawal()" mat-raised-button>
                                Make Withdrawal</button>
                        </div>
                    </div>
                    <div *ngIf="!this.withdrawalFromInvo || this.withdrawalFromInvo == undefined" class="withdrawalBox">
                        <div class="doWithdrawal">
                            <button class="withdrawButton" (click)="this.createWithdrawal()" mat-raised-button>Make
                                Withdrawal</button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="oldWithdraw">
            <mat-card>
                <div class="none" *ngIf="!this.myWithdrawals ||  this.myWithdrawals.length == 0">
                    <p>No Past Withdrawals</p>
                </div>
                <div *ngIf="this.myWithdrawals &&  this.myWithdrawals.length > 0">
                    <div class="cardstate">
                        <p>Past Withdrawals</p>
                    </div>
                    <div class="withdrawRequests">
                        <div *ngFor="let withdrawal of this.myWithdrawals" class="request">
                            <div class="eachWithdrawal">
                                <div class="withDrawalInfo">
                                    <div class="withdrawalAmt col">
                                        <span class="amt add mediumText">
                                            {{(withdrawal.withdrawalAmount).toLocaleString('en-IN')}} ₹
                                        </span>
                                        <mat-hint class="witdN">
                                            {{withdrawal.widhdrawalNumber }}
                                        </mat-hint>
                                    </div>
                                    <div class="withdrawalStatus col">
                                        <span class="status cardstate">
                                            {{withdrawal.widhdrawalStatus}}
                                        </span>
                                        <mat-hint class="witdD" *ngIf="!withdrawal.widhdrawalDate">
                                            {{withdrawal.widhdrawalApplicationDate | date }}
                                        </mat-hint>
                                        <mat-hint class="witdD" *ngIf="withdrawal.widhdrawalDate">
                                            {{withdrawal.widhdrawalDate | date }}
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
    </div>
</div>