import { Component, Input, OnInit } from '@angular/core';
import { VarietyItems } from 'src/app/datamodels/varietyModel';
import { Router } from '@angular/router';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { VarietyServiceService } from 'src/app/services/variety-service.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
interface titSub {
  id: number
  title: string;
}
@Component({
  selector: 'app-landingvarieties',
  templateUrl: './landingvarieties.component.html',
  styleUrls: ['./landingvarieties.component.css']
})
export class LandingvarietiesComponent implements OnInit {
  private authListenerSubs: Subscription
  @Input() appBannerslandingvarieties: string[];
  featuredVarietyListings: ListingModel[] = [];
  selectednonfeaturedVariety: VarietyItems[]
  HoldnonFeatureVar: VarietyItems[] = []
  nonfeaturedVariety: VarietyItems;
  featureTrue: boolean;
  toggleProperty = false;
  isLoading: boolean = false; //spinner initialized
  titleSubtitle: titSub[] = [
    {
      id: 1,
      title: "Long Grain Basmati Rice From Punjab and Haryana",
    },
    {
      id: 2,
      title: "Large Green Cardamom From Kerela",
    },
    {
      id: 3,
      title: "Hottest Chilli From Kashmir and Andhra",
    },
    {
      id: 4,
      title: "Peppermint Oil From Uttar Pradesh",
    },
    {
      id: 5,
      title: "Mustard Oil From Rajasthan",
    },
    {
      id: 6,
      title: "Black Cardamom from Sikkim",
    },

  ]
  LoadingText: string = ''
  userIsAuthenticated = false;

  constructor(public productvarietyService: VarietyServiceService,
    private handlingListing: HandleListingsService,
    private router: Router,
    private auS: AuthService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    this.LoadingText = this.titleSubtitle[Math.floor(Math.random() * this.titleSubtitle.length)].title;
    this.loadRecentVarietyListings();
  }

  loadRecentVarietyListings() {
    this.handlingListing.getAllListings().subscribe(res => {
      if (res) {
        // const useFeaturedProds = res.filter(listings => {
        //   return listings.varietyName != "" && listings.varietyName != "null" && listings.varietyName != null
        // })
        // const key1 = '_id';
        // const last10UniqueListings = [...new Map(useFeaturedProds.map(listing =>
        //   [listing[key1], listing])).values()]
        // const key2 = 'varietyName';
        // this.featuredVarietyListings = [...new Map(last10UniqueListings.map(listing =>
        //   [listing[key2], listing])).values()].slice(-15)
        // this.isLoading = false


        // const useFeaturedProds = res.filter(listings => {
        //   return listings.varietyName != "" && listings.varietyName != "null" && listings.varietyName != null
        // })
        const useFeaturedProds = res.filter(listings => {
          return listings.isListingSortexType
        })
        const key1 = '_id';
        const last10UniqueListings = [...new Map(useFeaturedProds.map(listing =>
          [listing[key1], listing])).values()]
        const key2 = 'varietyName';
        this.featuredVarietyListings = [...new Map(last10UniqueListings.map(listing =>
          [listing[key2], listing])).values()].slice(-15)
        this.isLoading = false

      }
    })
  }
}
