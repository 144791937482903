<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Returns & Refunds </h1>
            <h3> Easy Returns </h3>
            <h3>Fast Refunds </h3>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="contentReturns">
    <div *ngIf="!isLoading && this.currentPurchaseOrderwithId">
        <div *ngIf="!this.isPOreturnable">
            <h1 class="titNoPO"> No returnable Items in Purchase Order </h1>
            <h3 class="subNoPO"> {{this.currentPurchaseOrderwithId.returnVersions.length}} Return Orders are Created
            </h3>
            <div *ngFor="let returnVs of this.currentPurchaseOrderwithId.returnVersions" class="returnItems">
                <div [routerLink]="['/viewReturnOrder',returnVs.returnID]" class=" versionReturn">
                    <a mat-stroked-button>
                        V {{returnVs.version}} &nbsp;&nbsp; {{returnVs.returnNumber}}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="this.isPOreturnable">
            <div class="itemsCreditTerms" *ngIf="this.itemsInPoForReturn">
                <h1 class="heading">
                    Select Items for Return and Refund
                </h1>
                <div class="item">
                    <ul>
                        <div class="selectAll list-section">
                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                <span>Select All</span>
                            </mat-checkbox>
                            <mat-error *ngIf="this.itemsNotSelectedShowError">{{formErrors.selectItems}}</mat-error>
                        </div>
                        <br>
                        <li *ngFor="let items of this.itemsInPoForReturn">
                            <mat-checkbox [(ngModel)]="items.checked" (ngModelChange)="updateAllComplete()">
                                <div class="daysRemaining">
                                    <div class="itemRD days">
                                        <span class="warning pay" *ngIf="items.daysLeftInPaymentSincePOCreated <= 0">
                                            {{-1 *
                                            items.daysLeftInPaymentSincePOCreated}} Days Late for Payment
                                        </span>
                                        <span class="reminder pay " *ngIf="items.daysLeftInPaymentSincePOCreated > 0">
                                            {{items.daysLeftInPaymentSincePOCreated}} Days Remaining To Pay
                                        </span>
                                    </div>
                                </div> <br>
                                <div class="mainItem eachItem">
                                    <div class="itemGenInfo">
                                        <div class="itemTitle">
                                            <span class="title">{{items.itemTitle}}
                                            </span>
                                        </div>
                                        <div class="itemDesc ProdCatVar">
                                            <span class="itmhead Cat">{{items.itemCategory}}
                                            </span>
                                            <span class="itmhead Prod">| {{items.itemProduct}}
                                            </span>
                                            <span *ngIf="items.itemVariety !== ''" class="itmhead Var">|
                                                {{items.itemVariety}}
                                            </span>
                                        </div>

                                        <div class="TotalQuantity">
                                            <span class="itemQuant">Total Quantity {{items.itemQuantity}} ql.
                                            </span>
                                        </div>
                                        <div class="StatedCreditTerms">
                                            <span class="itemCT"> {{items.optedPaymentTerms}}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="itemPrices">
                                        <div class="itemTP TotalPrice">
                                            <span> Total Price {{(items.itemTotalPrice).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                        <div class="itemTX Taxes">
                                            <span> Tax on total {{items.itemTaxes |
                                                number : '1.2-2'}} ₹
                                            </span>
                                        </div> <br>
                                        <div class="itemTXTP totalTaxedPrice">
                                            <span> Total taxed price
                                                {{(items.itemTotalPriceWithTaxes).toLocaleString('en-IN')}} ₹
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <br>
                            </mat-checkbox>

                            <hr>
                            <br>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="atleastOneItemSelected" class="selectedItemsSumValue">
                <div>
                    <h3 class="invoiceStatuscheck">{{this.numberOfInvoicesDelivered}} Invoices Delivered</h3>
                    <h3 class="invoiceStatuscheck">{{this.numberOfInvoicesPaid}} Invoices Paid</h3>
                </div>
                <div class="refundAmt">
                    <h3>
                        <span class="subheading paiap">Paid at checkout</span>
                        <span class="itemofinvoice raised">
                            {{(this.currentPurchaseOrderwithId.amountPaidAtCheckout).toLocaleString('en-IN')}}
                            ₹</span>
                    </h3>
                    <h3>
                        <span class="subheading refap">Refund Applicable</span>
                        <span class="itemofinvoice raised"> {{(this.totalRefundApplicable).toLocaleString('en-IN')}}
                            ₹</span>
                    </h3>
                    <h3>
                        <span class="subheading dedap">Handling charges</span>
                        <span class="itemofinvoice raised"> {{(this.totalRefundApplicable).toLocaleString('en-IN')}}
                            ₹</span>
                    </h3>

                </div>

            </div>
            <br>
            <hr>
            <form [formGroup]="returnForm">

                <div class="bankAccountSection">
                    <div class="bankDetails">
                        <h1 class="heading">
                            Bank Details
                        </h1>
                        <mat-hint class="subheading">Payment will be credited to this account</mat-hint>

                        <mat-form-field>
                            <input matInput type="text" name="accountName" formControlName="accountName"
                                placeholder="Account Name" minlength="1" maxlength="100" required>
                            <mat-error *ngIf="formErrors.accountName">{{formErrors.accountName}}</mat-error>
                            <mat-hint>Jack Ma</mat-hint>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput type="text" name="accountNumber" formControlName="accountNumber"
                                placeholder="Account Number" minlength="8" maxlength="25" required>
                            <mat-error *ngIf="formErrors.accountNumber">{{formErrors.accountNumber}}</mat-error>
                            <mat-hint>05110xxxxxxxx</mat-hint>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="accountIFSC" minlength="11" maxlength="11"
                                formControlName="accountIFSC" placeholder="IFSC" required>
                            <mat-error *ngIf="formErrors.accountIFSC">{{formErrors.accountIFSC}}</mat-error>
                            <mat-hint>SBIN0511xxxx</mat-hint>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput type="text" name="accountBankBranch" formControlName="accountBankBranch"
                                placeholder="Branch Name" required>
                            <mat-error *ngIf="formErrors.accountBankBranch">{{formErrors.accountBankBranch}}</mat-error>
                            <mat-hint>Kalyan Nagar</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="dueBy">
                        <mat-form-field appearance="outline">
                            <mat-label>Return Reason</mat-label>
                            <mat-select name="returnRemarks" formControlName="returnRemarks">
                                <mat-option *ngFor="let return of returnReason" [value]="return.viewValue">
                                    {{return.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Number of days for payment</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

            </form>

            <div class="actionButton">
                <div class="returnCondition" *ngIf="this.givenReturnCondition !== ''">
                    <mat-slide-toggle [(ngModel)]="isGivenReturnConditionChecked">
                    </mat-slide-toggle> <span class="returnStatement">{{this.givenReturnCondition}}</span>
                </div>
                <button
                    [disabled]="!this.returnForm.valid || this.itemsNotSelectedShowError || !this.isGivenReturnConditionChecked"
                    class="createRET" mat-raised-button type="button"
                    (click)="createReturn(this.currentPurchaseOrderwithId)">
                    <span>Return</span>
                </button>
                <!-- <button class="createRET" mat-raised-button (click)="createREToice(this.currentPurchaseOrderwithId)">
                    <span>Create Invoice</span>
                </button> -->
            </div>
        </div>

    </div>
</div>