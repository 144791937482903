import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
interface LoadingObj {
  quote: string; quotedBy: string
}

@Component({
  selector: 'app-landingproducts',
  templateUrl: './landingproducts.component.html',
  styleUrls: ['./landingproducts.component.css']
})

export class LandingproductsComponent implements OnInit {
  @Input() appBannerslandingproducts: string[];

  quotes = [
    { quote: "Improve Credit—Build Network", quotedBy: "ratan tata" },
    { quote: "sourcing & procurement made easy", quotedBy: "narendra modi" },
    { quote: "global merchant network", quotedBy: "dhirubhai ambani" },
    { quote: "karo vyapaar only on myapaar", quotedBy: "narayana murthy" },
    { quote: "manufacturers - wholesalers - retailers", quotedBy: "azim premji" },
    { quote: "bulk orders", quotedBy: "mukesh ambani" },
    { quote: "quality products", quotedBy: "sundar pichai" },
    { quote: "bulk orders made easy", quotedBy: "kiran mazumdar-shaw" },
    { quote: "View All", quotedBy: "cyrus mistry" },
    // { quote: "integrity is the foundation of leadership.", quotedBy: "uday kotak" },
    // { quote: "focus on solutions, not problems.", quotedBy: "rajeev chandrasekhar" },
    // { quote: "passion drives excellence in everything.", quotedBy: "vineet nayar" },
    // { quote: "embrace change, drive innovation forward.", quotedBy: "kumar mangalam birla" }
  ];

  selectedOBJ: LoadingObj;
  allnonfeaturedproducts: Product[] = [];
  nonfeaturedProd: Product;
  featureTrue: boolean;
  toggleProperty = false;
  isLoading = false; //spinner stops
  constructor(public productService: ProductService,
  ) { }

  ngOnInit(): void {
    this.LoadNonFeaturedProducts();
    this.selectedOBJ = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  }
  toggle() {
    this.toggleProperty = !this.toggleProperty;
  }
  LoadNonFeaturedProducts() {
    this.isLoading = true; //spinner starts
    this.productService.getProducts().subscribe((res) => {
      this.isLoading = false; //spinner stops
      // normal display when all products have listings
      // this.allnonfeaturedproducts = res

      // bhari dukan to show products with listings available only
      const populatedProducts = res.filter(allProds => {
        return allProds.productState && allProds.productState.length >= 1
      })
      this.allnonfeaturedproducts = populatedProducts.sort((a, b) => b.productState.length - a.productState.length).slice(0, 8)
    })

  }


}
