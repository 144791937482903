<mat-card [ngClass]="{'shared':this.shrfd}" class="post-card">
  <mat-hint class="sharedHint" *ngIf="this.shrfd">Shared to you</mat-hint>
  <!-- Header Section -->
  <div class="headRow">
    <mat-card-header [ngClass]="{'verified':this.post.isGSTVerified === true}">
      <!-- User Logo -->
      <img *ngIf="this.post.isGSTVerified" (click)="this.openProfile(this.post.qfsCreatorId,this.post.companyName)"
        class="avatar " mat-card-avatar [src]="post.logoUser || '../../../../../assets/accountTypes/myp-user.svg'"
        alt="User Logo">
      <img *ngIf="!this.post.isGSTVerified" class="avatar" mat-card-avatar
        [src]="post.logoUser || '../../../../../assets/accountTypes/myp-user.svg'" alt="User Logo">
      <!-- User Name and Company Profile -->
      <mat-card-title *ngIf="!this.isUserNamePresent ">Unknown</mat-card-title>
      <mat-card-subtitle *ngIf="!this.isUserBizNamePresent ">{{ this.qfsCreator}}</mat-card-subtitle>
      <mat-card-title>{{ post.firstname }}</mat-card-title>
      <mat-card-subtitle>{{ post.companyName }}</mat-card-subtitle>
    </mat-card-header>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="this.shareWhatsapp(post.qfId)"
        style="display: flex; align-items: center; gap: 13px;">
        <img class="refim whatsappref" alt="myapaar referral"
          src="./../../../assets/svgiconsandimages/whatsapp-ref.svg" />
        <span>Share on Whatsapp</span>
      </button>
      <button (click)="this.openProfile(this.post.qfsCreatorId,this.post.companyName)" mat-menu-item>
        <mat-icon>supervisor_account</mat-icon>
        <span>View Profile</span>
      </button>
    </mat-menu>
  </div>

  <!-- Description with "More" Button -->
  <mat-card-content>
    <p class="description" *ngIf="post.description && post.description !== 'null'">
      {{ showFullDescription ? post.description : (post.description | truncate:100) }}
      <button class="moreShow" mat-button *ngIf="hasMoreThan100Chars(post.description) && !showFullDescription"
        (click)="toggleDescription()">
        <span *ngIf="!showFullDescription">...more</span>
      </button>
      <button class="moreShow" mat-button *ngIf="hasMoreThan100Chars(post.description) && showFullDescription"
        (click)="toggleDescription()">
        <span *ngIf="showFullDescription">...less</span>
      </button>
    </p>
  </mat-card-content>

  <!-- Photo Grid -->
  <mat-card-content *ngIf="post.imageUrls && post.imageUrls.length > 0">
    <mat-grid-list gutterSize="5px" [cols]="getGridColumns(post.imageUrls.length)" rowHeight="1:1">
      <mat-grid-tile *ngFor="let photo of post.imageUrls; let i = index"
        [colspan]="getColspan(post.imageUrls.length, i)">
        <!-- <div (click)="this.checkImg(photo)"> -->
        <div (click)="openOverlay(photo)">
          <img [src]="photo" alt="Post Photo" class="post-photo">
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  <mat-card-content *ngIf="this.post.type === 'qf' && post.docUrl && post.docUrl !== 'null'">
    <mat-grid-list gutterSize="5px" cols="1" rowHeight="1:1">
      <mat-grid-tile colspan="1">
        <!-- <div (click)="this.checkImg(photo)"> -->
        <div (click)="openOverlayDoc(post.docUrl)">
          <img src="../../../../assets/svgiconsandimages/document.svg" alt="Post Photo" class="post-photo">
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  <mat-card-content>
    <div class="postInteract">
      <!-- <button class="linkUser" mat-stroked-button>
        Link
      </button> -->
      <button (click)="toggleVisibility()" class="linkUser" mat-stroked-button>
        Comments <span *ngIf="post.comments.length > 0">({{post.comments.length}})</span>
      </button>

      <!-- <h4 id="reviewDate">{{ this.currentDate | date }}</h4> -->
      <span class="date">{{ post.publishDate | date }}</span>
    </div>
  </mat-card-content>
  <!-- <div class="contentPostComment">
    <app-post-create id="postCreate" (reviewSubmitted)="reloadPostList($event)"
      *ngIf="this.currentUserId"></app-post-create>
  </div> -->
  <div class="container">

    <div *ngIf="isCommVisible" [class.visible]="isCommVisible" class="pop-effect">
      <div *ngIf="this.postComments && this.postComments.length > 0">
        <div class="contentPostComment">
          <mat-accordion multi="true">
            <div class="reviews" *ngFor="let review of this.postComments">
              <div class="singlereview">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <div class="logoImg">
                      <img *ngIf=" !review?.authorLogo || review?.authorLogo==='null'"
                        src=" ../../../../../assets/accountTypes/myp-user.svg" />
                      <img *ngIf="review?.authorLogo && review?.authorLogo !== 'null'" [src]="review.authorLogo" />
                    </div>
                    <div>
                      <span id="reviewAuthor">{{ review.author }}</span>
                    </div>
                  </mat-expansion-panel-header>
                  <div class=" reviewContent">
                    <!-- <div *ngIf="review.reviewImage && review.reviewImage !== ''" class="post-image">
                        <img [src]="review.reviewImage" [alt]="review.author">
                      </div> -->
                    <div *ngIf="review.reviewImage && review.reviewImage !== ''" class="spacer"></div>
                    <div class="infoAndActions">
                      <div class="reviewerInfo">
                        <div id="reviewComment">
                          — {{ review.comment }}
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="matActions">
                    <div *ngIf="userIsAuthenticated && this.loginUserInfo.userId === review.creatorID"
                      class="actionsComment">
                      <!-- <a class="editBut" mat-stroked-button [routerLink]="['/QuickFeed/editComment', review._id]">EDIT</a> -->
                      <button class="cancelBut" mat-stroked-button (click)="onDeleteComment(review._id)">DELETE</button>
                    </div>
                    <mat-panel-description>
                      <mat-icon *ngFor="let ratings of ratingsAsStars(review.rating)" class="starRating">star</mat-icon>
                    </mat-panel-description>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-accordion>
        </div>
      </div>
      <div class="contentPostComment">
        <!-- <app-comment-box id="postCreate" [postId]="this.postId" (reviewSubmitted)="reloadPostList($event)"
          *ngIf="this.currentUserId"></app-comment-box> -->
        <app-comment-box id="postCreate" [postId]="this.postId"
          (reviewSubmitted)="reloadPostList($event)"></app-comment-box>
      </div>

    </div>
  </div>

</mat-card>

<div *ngIf="isOverlayOpen" (click)="closeOv.click()" class="overlay">
  <!-- Overlay content -->
  <div class="overlay-content" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
    <!-- Close button -->
    <span class="close-btn" #closeOv (click)="closeOverlay()">&times;</span>
    <!-- Announcement content -->
    <div class="announcement-text">
      <p class="whitelabel">
        <span *ngIf="!this.isUserBizNamePresent">{{ this.qfsCreator}}</span><br>
        <span *ngIf="this.isUserNamePresent">{{ post.firstname }}</span><br>
        <span *ngIf="this.isUserBizNamePresent">{{ post.companyName }}</span>
      </p>
    </div>
  </div>
</div>

<div *ngIf="isDocOverlayOpen" class="overlay">
  <div class="overlay-content-doc">
    <pdf-viewer [src]="this.docOpen" [render-text]="true" style="width: 100%; height: 90vh;"></pdf-viewer>
    <span class="close-btn" #closeOvDoc (click)="closeDocOverlay()">&times;</span>
    <!-- Announcement content -->
    <div class="announcement-text">
      <p class="whitelabel">
        <span *ngIf="!this.isUserBizNamePresent">{{ this.qfsCreator}}</span><br>
        <span *ngIf="this.isUserNamePresent">{{ post.firstname }}</span><br>
        <span *ngIf="this.isUserBizNamePresent">{{ post.companyName }}</span>
      </p>
    </div>
  </div>
</div>