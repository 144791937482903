import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/datamodels/MainProductModel';

@Component({
  selector: 'app-prod-card',
  templateUrl: './prod-card.component.html',
  styleUrls: ['./prod-card.component.css']
})
export class ProdCardComponent implements OnChanges, OnInit {
  @Input() getProd: Product
  fileUrl: string;
  fileType: string = '';
  constructor(
    private router: Router,
  ) { }
  ngOnChanges() {
  }
  ngOnInit(): void {
    this.checkFileType();
  }
  checkFileType(): void {
    const fileName = this.getProd.productImage[0];
    this.fileUrl = fileName
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (['mp4', 'mov', 'avi', 'webm'].includes(fileExtension!)) {
      this.fileType = 'video';
    } else if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(fileExtension!)) {
      this.fileType = 'image';
    } else {
      this.fileType = 'unknown'; // fallback for unsupported file types
    }
  }
  onSelectProduct(productName: string) {
    this.router.navigate(['/showListingProduct', productName])
  }

  onSelectProductwithState(productName: string, productState: string) {
    this.router.navigate(['/showListingProductwithState', productName, productState])
  }

}
