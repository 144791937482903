<mat-card class="mat-elevation-z4">
    <div class="listingcontainer">
        <div class="listingtitle">
            <div class="productname">
                <p (click)="onSelectProduct(getProd.productName)" style="font-size:large;">
                    {{getProd.productName|translate
                    }}</p>
            </div>
            <div class="categoryname">
                <span style="color: #808080 ; font-size: 13px;">
                    {{'more' | translate}} {{getProd.categoryName|translate}}
                </span>
            </div>
        </div>
        <div class="listingInfo">
        </div>
    </div>
    <img (click)="onSelectProduct(getProd.productName)" mat-card-image *ngIf="fileType  !== 'video' "
        [src]="this.fileUrl" alt="this.fileUrl" />
    <video (click)="onSelectProduct(getProd.productName)" mat-card-image *ngIf="fileType === 'video'" #video autoplay
        muted loop playsinline preload="auto">
        <source [src]="this.fileUrl" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    <mat-card-content>
        <div id="productstate">
            <button mat-raised-button color="primary" style="margin-right:5px"
                (click)="onSelectProduct(getProd.productName)">{{'View All'|translate}}</button>
            <span id="singlestates" *ngFor="let state of getProd.productState">
                <button mat-stroked-button color="primary"
                    (click)="onSelectProductwithState(getProd.productName,state)">{{state|translate}}</button></span>
        </div>
    </mat-card-content>
</mat-card>