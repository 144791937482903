<!-- <h1>{{listingId}}</h1> -->
<div *ngIf="this.createPostComments">
    <a class="linkUser" routerLink="/login">Login to Comment and Rate</a>
</div>
<mat-card *ngIf="!this.createPostComments">
    <app-myapaar-spinner *ngIf="isLoading" class="mypspin"></app-myapaar-spinner>
    <form [formGroup]="reviewform" (submit)="onSavePost()" *ngIf="!isLoading">
        <div class="comment-image">
            <div class="comment-row">
                <div class="section commentBox">
                    <mat-form-field appearance="outline">
                        <textarea matInput rows="5" name="comment" formControlName="comment"
                            placeholder="Leave a comment"></textarea>
                        <mat-error *ngIf="reviewform.get('comment').invalid">Your feedback</mat-error>
                    </mat-form-field>
                </div>
                <div class="section action-slider">
                    <app-star-ratings [(rating)]="selectedRating"></app-star-ratings>
                    <div id="commentAction">
                        <!-- change triggers the file picking - from no file to somefile , or somefile to some other file -->
                        <button id="feedbackButton" [disabled]="!reviewform.valid" mat-raised-button color="warn"
                            type="submit">Comment
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </form>
</mat-card>