import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userIsAuthenticated: boolean = false;
  private loginUserInfo: any
  gstDetailsPending: boolean = false
  gstVerificationPending: boolean = false

  //insert image banners here
  appBannerslandingproducts: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/1myapaar_track_Payments_invoices.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/2myapaar_Suppliers_Bulk_Orders_Origin.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/3myapaar_doorstep_bulk_Order_delivery.png",
  ]
  appBannerslandingvarieties: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/34.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/8myapaar-purchase-ordering.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/4multiple-varieties-choose.png",
  ]
  appBannersallproducthomegrid: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/7-myapaar-suppliers.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/9-vaayusupport.png",
  ]

  allLeads: any;
  private currentUserId: string
  constructor(
    private hls: HandleListingsService,
    private authService: AuthService,
    public router: Router,
  ) {
  }
  ngOnInit(): void {
    this.doAuth()
    this.appBannerslandingproducts
    this.appBannersallproducthomegrid
    this.appBannerslandingvarieties
    this.getAllLeads()
  }
  getAllLeads() {
    this.hls.getAllRequirements().then(allreqsfound => {
      const allReqs = allreqsfound.reqs
      let usableLeads = allReqs.filter(eachReq => {
        return eachReq.accountType && eachReq.accountType != 'undefined'
          && eachReq.itemName && eachReq.itemName != 'undefined'
          && eachReq.itemQuantity && eachReq.itemQuantity != 'undefined'
          && eachReq.phone && eachReq.phone != 'undefined'
      })
      if (!this.userIsAuthenticated) {
        usableLeads.forEach(allLeads => {
          const maskMyPhone = allLeads.phone
          allLeads.phone = maskMyPhone.slice(0, -2) + '**';
        });
      }
      this.allLeads = [...usableLeads, ...usableLeads]
    })
  }
  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
      if (this.loginUserInfo.GST_Id === '') {
        this.gstDetailsPending = true
      }
      if (this.loginUserInfo.isGSTVerified === false) {
        this.gstVerificationPending = true
      }
    }
    catch (e) { }

  }
  changeUserInfo() {
    this.router.navigate([`accountInformation/${this.currentUserId}`])
  }
}
