import { Injectable } from '@angular/core';
import { Review } from '../datamodels/productreview';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import { BlogPostModel } from '../datamodels/blogPostModel';
import { Subject, Observable, BehaviorSubject, throwError, } from 'rxjs';
import { UtilsService } from './utils.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { QuickFeedPost } from '../datamodels/QuickFeed/QuickFeedPost';
import { SocketServiceService } from './socket-service.service';
import { ProfileLink } from '../datamodels/userLinking/feedProfileLink';
import { QuickFeedNetwork } from '../datamodels/QuickFeed/QuickFeedModel';

@Injectable({
  providedIn: 'root'
})
export class CommentpostService {
  private domain: string | undefined
  private reviews: Review[]
  private postSubject = new BehaviorSubject<Review[]>(null);
  private posts$: Observable<Review[]> | null = null;

  private QFPsubject = new BehaviorSubject<QuickFeedNetwork[]>(null);
  private QFPs$: Observable<QuickFeedNetwork[]> | null = null;

  constructor(private http: HttpClient,
    private utils: UtilsService,
    private skts: SocketServiceService,
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async getReviews(listingId: string): Promise<Review[]> {
    const queryParams = `?listingId=${listingId}`;
    const resReviews = await this.http.get<{
      allReviews: Review[];
      status: number;
    }>(`${this.domain}api/posts/getAllReviews/forListing/` + queryParams).toPromise()
    return resReviews.allReviews
  }

  getAllComms(): Observable<Review[]> {
    if (this.postSubject.value) {
      return this.postSubject.asObservable();
    }
    // Check if posts are already available
    if (!this.posts$) {
      this.posts$ = this.http.get<{ status: number; data: string }>(
        `${this.domain}api/posts/allComms/`
      ).pipe(
        map(response => {
          const decryptedDataComms = this.utils.decryptData(response.data);
          this.postSubject.next(decryptedDataComms);
          return decryptedDataComms;
        }),
        shareReplay(1), // Cache the response for future subscribers
        tap(() => this.posts$ = null) // Reset observable after first completion
      );
    }
    return this.posts$;
  }
  async gets3Url(docUrl: string): Promise<Blob> {
    const params = { docUrl }
    const findDoc = await this.http.get(`${this.domain}api/posts/qfp-prox-pdf/`, { params, responseType: 'blob' }).toPromise() as Blob;
    return findDoc
  }

  getAllQFPs(): Observable<QuickFeedNetwork[]> {
    if (this.QFPsubject.value) {
      return this.QFPsubject.asObservable();
    }
    // Check if posts are already available
    if (!this.QFPs$) {
      this.QFPs$ = this.http.get<{ status: number; dataQFPsProfs: string }>(
        `${this.domain}api/posts/allQFPs/`
      ).pipe(
        map(response => {
          const decryptedDataQFPsProfs = this.utils.decryptData(response.dataQFPsProfs);
          this.QFPsubject.next(decryptedDataQFPsProfs);
          return decryptedDataQFPsProfs;
        }),
        shareReplay(1), // Cache the response for future subscribers
        tap(() => this.QFPs$ = null) // Reset observable after first completion
      );
    }
    return this.QFPs$;
  }

  addImagesforFeedPost(ImagesPicked: any): Observable<any> {
    const imagesData = new FormData();
    for (let i = 0; i < ImagesPicked.length; i++) {
      imagesData.append('Photos', ImagesPicked[i])
    }
    return this.http.post<any>(`${this.domain}api/posts/uploadListingImages/`, imagesData)
  }

  getSingleReview(id: string) {
    return this.http.get<{
      _id: string, listingId: string, author: string, authorLogo: string, comment: string, rating: number, date: Date,
      reviewImage: string, creatorID: string
    }>(`${this.domain}api/posts/` + id);
  }

  addImageforReview(ImagePicked: File): Observable<any> {
    const imageData = new FormData()
    imageData.append('Photos', ImagePicked)
    return this.http.post<any>(`${this.domain}api/posts/uploadReviewImage/`, imageData)
  }

  async updateReview(id: string, listingId: string, author: string, comment: string, rating: number, date: Date,
    reviewImage: string): Promise<{ message: string; reviewOnlistingId: string; status: number; }> {
    let reviewData: FormData;
    reviewData = new FormData();
    reviewData.append("id", id);
    reviewData.append("listingId", listingId);
    reviewData.append("author", author);
    reviewData.append("comment", comment);
    reviewData.append("rating", rating.toString());
    reviewData.append("date", date.toString());
    reviewData.append("reviewImage", reviewImage);
    const updatereview = await this.http.put<{ message: string; reviewOnlistingId: string, status: number }>(`${this.domain}api/posts/updateReview/` + id, reviewData).toPromise()
    return updatereview
  }

  async addReview(commentForm: Review): Promise<{ message: string; review: Review; status: number }> {
    const reviewData = new FormData();
    reviewData.append("listingId", commentForm.listingId);
    reviewData.append("author", commentForm.author);
    reviewData.append("comment", commentForm.comment);
    reviewData.append("rating", commentForm.rating.toString());
    reviewData.append("date", commentForm.toString());
    reviewData.append("reviewImage", commentForm.reviewImage);
    const createdReview = await this.http.post<{
      message: string,
      status: number,
      review: Review
    }>
      (`${this.domain}api/posts/createReview/`,
        reviewData).toPromise()
    return createdReview
  }
  async upQfDoc(selectedFile: File): Promise<{ status: number, qfd: string }> {
    const formData = new FormData();
    formData.append('gsf', selectedFile);
    const uploadGST = await this.http.post<{ status: number, qfd: string }>(`${this.domain}api/posts/upDocQuickFeed/`, formData).toPromise()
    return uploadGST
  }
  async upMySh(selectedFile: File): Promise<{ status: number, qfd: string }> {
    const formData = new FormData();
    formData.append('mysh', selectedFile);
    const uploadMySh = await this.http.post<{ status: number, qfd: string }>(`${this.domain}api/posts/upMyShotQuickFeed/`, formData).toPromise()
    return uploadMySh
  }
  async deleteReview(reviewId: string): Promise<{ status: number }> {
    const deletedPost = await this.http.get<{ status: number }>(`${this.domain}api/posts/deletePost/` + reviewId).toPromise()
    return deletedPost
  }
  async sendQFS(qfsData: QuickFeedPost): Promise<{ status: number }> {
    const qfs = new FormData();
    qfs.append("type", qfsData.type);
    qfs.append("description", qfsData.description);
    qfs.append("imageUrls", JSON.stringify(qfsData.imageUrls));
    qfs.append("docUrl", qfsData.docUrl);
    qfs.append("qfsCreatorId", qfsData.qfsCreatorId);
    const postQFS = await this.http.post<{ status: number }>(`${this.domain}api/posts/doQFS/` + qfsData.qfsCreatorId, qfs).toPromise()
    return postQFS
  }
  // onNewQF(): Observable<{ qfP: QuickFeedPost, usrProf: ProfileLink }> {
  //   return new Observable((observer) => {
  //     this.skts.getSocket().on('newQFPost', (newQfP: { qfP: QuickFeedPost, usrProf: ProfileLink }) => {
  //       console.info('qfP on New QF', newQfP)
  //       observer.next(newQfP);
  //     });
  //   });
  // }
  async getAllBlogs(): Promise<{
    status: number;
    blogsfetched: BlogPostModel[]
  }> {
    const allBlogs = await this.http.get<{ status: number; blogsfetched: BlogPostModel[] }>(`${this.domain}api/posts/findAllBlogsStyle1`).toPromise()
    return allBlogs
  }

  async getSingleBlog(blogTitle: string): Promise<{
    status: number;
    singleBlogfetched: BlogPostModel
  }> {
    const matchBlogTitle = { blogTitle }
    const singleBlog = await this.http.post<{ status: number; singleBlogfetched: BlogPostModel }>(`${this.domain}api/posts/getSingleBlogStyle1`, matchBlogTitle).toPromise()
    return singleBlog
  }
}
