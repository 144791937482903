<div class="content">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">{{'get verified'|translate }}</h1>
        </div>
    </div>
    <div class="signUpBox">
        <mat-card>
            <app-myapaar-spinner *ngIf="isLoading" class="mypspin"></app-myapaar-spinner>
            <form id="formSignUp" [formGroup]="this.signupform" (submit)="onSignup()" *ngIf="!isLoading">
                <div class="conditionalRequired">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'business name'|translate}}</mat-label>
                        <input matInput type="text" name="companyName" formControlName="companyName"
                            placeholder="Name of your Business">
                        <mat-error *ngIf="formErrors.companyName">{{formErrors.companyName}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'pin code'|translate}}</mat-label>
                        <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                            formControlName="pinCode" autocomplete="off" maxlength="10" pattern="[0-9]*" />
                        <mat-hint style="margin-right: 13px;">
                            {{this.stateName
                            }}
                            &nbsp;&nbsp; {{this.districtName}}
                        </mat-hint>

                    </mat-form-field>

                    <mat-form-field *ngIf="!this.skipPageForGST" appearance="outline">
                        <mat-label>
                            {{'gst id'|translate}}
                        </mat-label>
                        <input matInput type="text" [(ngModel)]="this.checkGSTID" (ngModelChange)="changeGST()"
                            name="GST" formControlName="GST_Id" placeholder="GST Number" maxlength="15">
                        <mat-error *ngIf="formErrors.GST_Id">{{formErrors.GST_Id}}</mat-error>
                        <mat-hint *ngIf="isGSTOptional">
                            {{"Gst is optional"| translate}}</mat-hint>
                        <mat-hint *ngIf="!isGSTOptional">
                            {{"Required to use ecommerce supply"| translate}}</mat-hint>
                    </mat-form-field>
                </div>
            </form>
            <div class="actions">
                <div *ngIf="!this.skipPageForGST" class="actionButton">
                    <button
                        [ngClass]="{'inactive':this.completeKYC===false,'active':this.completeKYC===true,'done':this.isGSTDocLinkCompleted===true}"
                        [disabled]="!this.completeKYC" (click)="uploadKyc()" mat-stroked-button>
                        <span *ngIf="!this.isGSTDocLinkCompleted">Complete KYC</span>
                        <span [ngClass]="{'done':this.isGSTDocLinkCompleted===true}"
                            *ngIf="this.isGSTDocLinkCompleted">Completed</span>
                    </button>
                </div>
                <div class="back">
                    <button (click)="onSignup()" raised-button mat-raised-button>
                        {{'sign up'|translate }}
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>