import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { CreditTermsModel } from 'src/app/datamodels/groupedmodel/creditTermsDetails';
import { PackagingTypeModel } from 'src/app/datamodels/groupedmodel/packagingTypeModel';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { UtilsService } from 'src/app/services/utils.service';
import { InCartService } from 'src/app/services/in-cart.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-confirm-paymentmode',
  templateUrl: './confirm-paymentmode.component.html',
  styleUrls: ['./confirm-paymentmode.component.css']
})

export class ConfirmPaymentmodeComponent implements OnInit {
  selectedCreditDays: CreditTermsModel;
  selectedPackagingType: PackagingTypeModel;
  addListingToCart: FormGroup;
  isAddBrandingSection: boolean = false
  currentListingwithId: any;
  isLoading = false;
  reviewImagePreview: string;
  userCart: any;
  validationMessages = {
    'creditTerms': {
      'required': 'Select Credit terms'
    },
    'packagingType': {
      'required': 'Select Packaging Type'
    }
  }
  formErrors = {
    'creditTerms': 'Select Credit terms',
    'packagingType': 'Select Packaging'
  }
  constructor(
    private utils: UtilsService,
    private imgHandler: ImageHandlingService,
    private _snackBar: MatSnackBar,
    private commentservice: CommentpostService,
    private inCartService: InCartService,
    private authService: AuthService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bulkOrderdata: any,
    private _bottomSheetRef: MatBottomSheetRef<ConfirmPaymentmodeComponent>,
  ) { }

  ngOnInit(): void {
    this.addListingToCart = new FormGroup({
      creditTerms: new FormControl('', { validators: [Validators.required] }),
      packagingType: new FormControl('', { validators: [Validators.required] }),
    })
    this.currentListingwithId = this.bulkOrderdata.listing
    if (this.currentListingwithId.creditTerms && this.currentListingwithId.creditTerms[0].viewValue != "Pay Later") {
      let idInst = (Math.random() + 1).toString(36).substring(7);
      const instantCTArr = [{
        checked: "true",
        percentage: 0,
        value: 0,
        viewValue: "Pay Later",
        _id: idInst
      }]
      const newArrayWithInstantPO = instantCTArr.concat(this.currentListingwithId.creditTerms)
      this.currentListingwithId.creditTerms = newArrayWithInstantPO
    }
    this.selectedCreditDays = this.currentListingwithId.creditTerms[0]
    this.selectedPackagingType = this.currentListingwithId.packagingType[0]
  }
  // onImagePicked(event: Event) {
  //   try {
  //     //type conversion to tell typescript that event.target registers an HtmlInp ,
  //     //  which typescript by default doesn't know
  //     const file = (event.target as HTMLInputElement).files[0];
  //     const fileName = file.name
  //     const fileFormat = file.type
  //     //create file url for image preview
  //     const reader = new FileReader();
  //     reader.onload = async () => {
  //       const useWidth = 620
  //       const useHeight = 620
  //       const checkResizedImg = await this.imgHandler.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
  //       this.reviewImagePreview = checkResizedImg
  //       const imageName = fileName.replace(/\s/g, '')
  //       const compressedfile = new File([this.utils.convertDataUrlToBlob(this.reviewImagePreview)], imageName, { type: fileFormat });
  //       this.onImageUpload(compressedfile)
  //     };
  //     reader.readAsDataURL(file)
  //   }
  //   catch (e) {
  //     throw e
  //   }
  // }
  // onImageUpload(file: File) {
  //   try {
  //     this.isLoading = true;
  //     this.commentservice.addImageforReview(file)
  //       .subscribe((ImagesPostResponse) => {
  //         // mat spinner stops when result comes
  //         this.isLoading = false;
  //         if (ImagesPostResponse && ImagesPostResponse.status === "ok") {
  //           this.selectedPackagingType.packagingImg = ImagesPostResponse.fileLocationS3
  //         }
  //       },
  //         error => {
  //           //uploadimages unsuccessful,
  //           this.isLoading = true;
  //           this.errorForm("Image upload fail - Invalid Format & Size")
  //         })
  //     //set values for Variety and Description
  //   }
  //   catch (e) {
  //     throw e
  //   }
  // }
  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      })
      this.isLoading = false
    }
    catch (e) {
      throw e
    }
  }
  packagingChange(newpackaging: PackagingTypeModel) {
    try {
      this.selectedPackagingType = newpackaging
      if (this.selectedPackagingType && this.selectedPackagingType.packagingType.includes('Branded')) {
        this.selectedPackagingType.isBranded = true
        // this.isAddBrandingSection = true
      }
      else {
        this.selectedPackagingType.isBranded = false
        // this.isAddBrandingSection = false
      }
    }
    catch (e) {
      throw e
    }
  }

  addBulkItem() {
    try {
      this.inCartService.saveCartItems(
        // 'idisnothere',
        this.bulkOrderdata.currentOrder.orderType,
        this.bulkOrderdata.currentOrder.userCartID,
        this.bulkOrderdata.currentOrder.listingID,
        this.bulkOrderdata.currentOrder.listingSortexType,
        this.bulkOrderdata.currentOrder.listingUnit,
        this.bulkOrderdata.currentOrder.listingUnitShort,
        this.selectedCreditDays,
        this.selectedPackagingType,
        this.bulkOrderdata.currentOrder.bulkQuantity,
        this.bulkOrderdata.currentOrder.date
      ).then(savedCart => {
        this.userCart = savedCart.cart
        localStorage.setItem('userCart', JSON.stringify(this.userCart));
        this.itemAdded("Bulk Item added to cart", "")

        //save cached cart from items that are in cart 
        if (this.userCart && this.userCart.itemListings && this.userCart.itemListings.length !== 0) {
          this.authService.createCacheCart(this.userCart)
        }
        this.closeChat()
      })
    }
    catch (e) {
      this.errorForm(e)
      throw e
    }

  }
  itemAdded(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 1500,
        verticalPosition: 'bottom',
        // horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }

  closeChat() {
    try {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
    catch (e) {
      throw e
    }
  }
}
