import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { QuickViewDialogComponent } from '../components/quick-view-dialog/quick-view-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class QuickViewServiceService {

  constructor(private dialog: MatDialog) { }
  public quickViewImg(data: string): Observable<any> {
    var twodialogConfig = new MatDialogConfig()
    twodialogConfig.autoFocus = true;
    // twodialogConfig.width = "500px";
    // twodialogConfig.height = "500px";
    twodialogConfig.data = data;
    let twodialogRef: MatDialogRef<QuickViewDialogComponent>

    twodialogRef = this.dialog.open(QuickViewDialogComponent, twodialogConfig);
    return twodialogRef.afterClosed();
  }
}
