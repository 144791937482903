import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-with-lot-size',
  templateUrl: './products-with-lot-size.component.html',
  styleUrls: ['./products-with-lot-size.component.css']
})
export class ProductsWithLotSizeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
