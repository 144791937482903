<div class="content">
    <div *ngIf="this.blogLoading" class="content-loading">
        <div id="loadingScreen">
            <div class="loadingText">
                <h5>Domestic & Impex | News and Articles</h5>
            </div>
            <app-myapaar-spinner class="mypspin"></app-myapaar-spinner>
        </div>
    </div>

    <body>
        <!-- Navbar Start -->
        <!-- <div class="rowSearch">
            <div class="col-lg-4">
                <h1>Myapaar<span>&nbsp;Trends</span></h1>
            </div>
            <div class="input-group ml-auto d-none d-lg-flex" style="width: 100%; max-width: 300px;">
                <input type="text" class="form-control border-0" placeholder="Keyword">
                <div class="input-group-append">
                    <button class="input-group-text bg-primary text-dark border-0 px-3"><i
                            class="fa fa-search"></i></button>
                </div>
            </div>
        </div> -->
        <!-- Navbar End -->


        <!-- Breaking News Start -->
        <div class="container-fluid mt-5 mb-3 pt-3">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <div class="section-title border-right-0 mb-0" style="width: 180px;">
                                <h4 class="m-0 text-uppercase font-weight-bold">Related</h4>
                            </div>
                            <div class="owl-carousel Trending-carousel position-relative d-inline-flex align-items-center bg-white border border-left-0"
                                style="width: calc(100% - 180px);">
                                <div style="padding-right: 20px;" *ngFor="let lastThree of this.lastThreeBlogs | slice:0:2; let i=index"
                                    class="text-truncate" (click)="createHrefForBlog(lastThree.articleMainHeading)">
                                    <span class="text-secondary text-uppercase font-weight-semi-bold">
                                        {{this.lastThree.head3}}</span>
                                    <!-- <a [href]="this.useHref"
                                        class="text-secondary text-uppercase font-weight-semi-bold">{{this.lastThree.head3}}</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breaking News End -->


        <!-- News With Sidebar Start -->
        <div class="container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- Blog1 -->
                        <div *ngFor="let twoMainBlog of this.twoRandomMainBLogs" class="position-relative mb-3">
                            <div class="eachBlog">
                                <div (click)="createHrefForBlog(twoMainBlog.articleMainHeading)"
                                    class="bg-white border border-top-0 p-4">
                                    <div class="mb-3">
                                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                                            href="">Business</a>
                                        <a class="text-body" href="">{{twoMainBlog.postedDate | date}}</a>
                                    </div>
                                    <h1 class="mb-3 text-secondary text-uppercase font-weight-bold">
                                        {{twoMainBlog.articleMainHeading}}</h1>
                                    <img class="img-fluid w-100" [src]="twoMainBlog.articleMainImg"
                                        style="object-fit: cover;">
                                    <p>{{twoMainBlog.p1}}</p>
                                    <p>{{twoMainBlog.p2}}</p>
                                </div>
                                <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                                    <div class="d-flex align-items-center">
                                        <img class="rounded-circle mr-2" src="../../../assets/productcategoryimages/allindusroots-nobg.png" width="25" height="25"
                                            alt="">
                                        <span>~All Indus Roots Publishment</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="ml-3"><mat-icon>remove_red_eye</mat-icon>{{twoMainBlog.blogViewCount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Blog2 -->
                        <!-- News Detail End -->

                        <!-- Comment List Start -->
                        <!-- <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">3 Comments</h4>
                            </div>
                            <div class="bg-white border border-top-0 p-4">
                                <div class="media mb-4">
                                    <img src="img/user.jpg" alt="Image" class="img-fluid mr-3 mt-1"
                                        style="width: 45px;">
                                    <div class="media-body">
                                        <h6><a class="text-secondary font-weight-bold" href="">John Doe</a> <small><i>01
                                                    Jan 2045</i></small></h6>
                                        <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor
                                            labore
                                            accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod
                                            ipsum.</p>
                                        <button class="btn btn-sm btn-outline-secondary">Reply</button>
                                    </div>
                                </div>
                                <div class="media">
                                    <img src="img/user.jpg" alt="Image" class="img-fluid mr-3 mt-1"
                                        style="width: 45px;">
                                    <div class="media-body">
                                        <h6><a class="text-secondary font-weight-bold" href="">John Doe</a> <small><i>01
                                                    Jan 2045</i></small></h6>
                                        <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor
                                            labore
                                            accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod
                                            ipsum.</p>
                                        <button class="btn btn-sm btn-outline-secondary">Reply</button>
                                        <div class="media mt-4">
                                            <img src="img/user.jpg" alt="Image" class="img-fluid mr-3 mt-1"
                                                style="width: 45px;">
                                            <div class="media-body">
                                                <h6><a class="text-secondary font-weight-bold" href="">John Doe</a>
                                                    <small><i>01 Jan 2045</i></small>
                                                </h6>
                                                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua,
                                                    tempor
                                                    labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores
                                                    sed sed
                                                    eirmod ipsum.</p>
                                                <button class="btn btn-sm btn-outline-secondary">Reply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Comment List End -->

                        <!-- Comment Form Start -->
                        <!-- <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">What do you think ?</h4>
                            </div>
                            <div class="bg-white border border-top-0 p-4">
                                <form>
                                    <div class="form-row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="name">Name *</label>
                                                <input type="text" class="form-control" id="name">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="email">Email *</label>
                                                <input type="email" class="form-control" id="email">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="website">Website</label>
                                        <input type="url" class="form-control" id="website">
                                    </div>

                                    <div class="form-group">
                                        <label for="message">Message *</label>
                                        <textarea id="message" cols="30" rows="5" class="form-control"></textarea>
                                    </div>
                                    <div class="form-group mb-0">
                                        <input type="submit" value="Leave a comment"
                                            class="btn btn-primary font-weight-semi-bold py-2 px-3">
                                    </div>
                                </form>
                            </div>
                        </div> -->
                        <!-- Comment Form End -->
                    </div>

                    <div class="col-lg-4">
                        <!-- Ads Start -->
                        <!-- <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">Advertisement</h4>
                            </div>
                            <div class="bg-white text-center border border-top-0 p-3">
                                <a href=""><img class="img-fluid" src="img/news-800x500-2.jpg" alt=""></a>
                            </div>
                        </div> -->
                        <!-- Ads End -->

                        <!-- Popular News Start -->
                        <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">Other Trending Articles</h4>
                            </div>
                            <div class="bg-white border border-top-0 p-3">
                                <div *ngFor="let restLinkedBlogs of this.otherRandomTrendBLogs"
                                    class="d-flex align-items-center bg-white mb-3" style="height: 110px;">
                                    <div class="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0"
                                        (click)="createHrefForBlog(restLinkedBlogs.articleMainHeading)">
                                        <div class="mb-2">
                                            <span
                                                class="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2"
                                                href="">Business</span>
                                            <span class="text-body" href=""><small>{{restLinkedBlogs.postedDate | date
                                                    }}</small></span>
                                        </div>
                                        <span
                                            class="h6 m-0 text-secondary text-uppercase font-weight-bold">{{restLinkedBlogs.articleSubHeading}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Popular News End -->


                        <!-- Social Follow Start -->
                        <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">Follow Us</h4>
                            </div>
                            <div class="bg-white border border-top-0 p-3">
                                <a href="https://www.facebook.com/profile.php?id=61553258164931"
                                    class="d-block w-100 text-white text-decoration-none mb-3"
                                    style="background: #39569E;">
                                    <i class="fab fa-facebook-f text-center py-4 mr-3"
                                        style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                                    <span class="font-weight-medium">Facebook for Publications</span>
                                </a>
                                <!-- <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
            style="background: #52AAF4;">
            <i class="fab fa-twitter text-center py-4 mr-3"
                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
            <span class="font-weight-medium">12,345 Followers</span>
        </a> -->
                                <a href="https://www.linkedin.com/company/myapaar/"
                                    class="d-block w-100 text-white text-decoration-none mb-3"
                                    style="background: #0185AE;">
                                    <i class="fab fa-linkedin-in text-center py-4 mr-3"
                                        style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                                    <span class="font-weight-medium">LinkedIn for Networking</span>
                                </a>
                                <a href="https://www.instagram.com/myapaar_/"
                                    class="d-block w-100 text-white text-decoration-none mb-3"
                                    style="background: #C8359D;">
                                    <i class="fab fa-instagram text-center py-4 mr-3"
                                        style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                                    <span class="font-weight-medium">Instagram for Updates</span>
                                </a>
                                <a href="https://www.youtube.com/channel/UCdNya6J7eXsu3gr5HQVFb0g"
                                    class="d-block w-100 text-white text-decoration-none mb-3"
                                    style="background: #DC472E;">
                                    <i class="fab fa-youtube text-center py-4 mr-3"
                                        style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                                    <span class="font-weight-medium">Subscribe on Youtube</span>
                                </a>
                            </div>
                        </div>
                        <!-- Social Follow End -->


                        <!-- Newsletter Start -->
                        <!-- <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">Newsletter</h4>
                            </div>
                            <div class="bg-white text-center border border-top-0 p-3">
                                <p>Aliqu justo et labore at eirmod justo sea erat diam dolor diam vero kasd</p>
                                <div class="input-group mb-2" style="width: 100%;">
                                    <input type="text" class="form-control form-control-lg" placeholder="Your Email">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary font-weight-bold px-3">Sign Up</button>
                                    </div>
                                </div>
                                <small>Lorem ipsum dolor sit amet elit</small>
                            </div>
                        </div> -->
                        <!-- Newsletter End -->

                        <!-- Tags Start -->
                        <!-- <div class="mb-3">
                            <div class="section-title mb-0">
                                <h4 class="m-0 text-uppercase font-weight-bold">Tags</h4>
                            </div>
                            <div class="bg-white border border-top-0 p-3">
                                <div class="d-flex flex-wrap m-n1">
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Politics</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Business</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Corporate</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Business</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Health</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Education</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Science</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Business</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Foods</a>
                                    <a href="" class="btn btn-sm btn-outline-secondary m-1">Travel</a>
                                </div>
                            </div>
                        </div> -->
                        <!-- Tags End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- News With Sidebar End -->


        <!-- Footer Start -->

        <!-- Footer End -->


        <!-- Back to Top -->
        <a href="#" class="btn btn-primary btn-square back-to-top"><i class="fa fa-arrow-up"></i></a>
    </body>

</div>