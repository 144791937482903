import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ListingdialogmanagerServiceService } from 'src/app/services/listingdialogmanager-service.service';
import { SearchServiceService } from 'src/app/services/search-service.service';
import { Location } from '@angular/common';
import { ProductsdialogmanagerService } from 'src/app/services/productsdialogmanager.service';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-show-listing',
  templateUrl: './show-listing.component.html',
  styleUrls: ['./show-listing.component.css']
})
export class ShowListingComponent implements OnInit {
  currentDate = new Date()
  fetchedListings: ListingModel[] = [];
  isLoading = false;
  totalListings = 0;
  listingsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [1, 2, 3, 4, 5];
  loginUserInfo: any
  productName: string
  categoryName: string;
  varietyName: string;
  panelOpenState = false;
  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  searchmessage: string;
  stateName: string;
  allStates: string[];
  allVarieties: string[];
  varietiesToFilter: string[] = [];
  statesToFilter: string[] = [];
  isVarietyPresent: boolean = false;
  toggleVarActive: boolean = false;
  toggleStateActive: boolean = false;
  preserveFetchedListings: ListingModel[];
  useItemName: string = ''
  filterOn: boolean = false;
  noResultsOnFilter: boolean = false;
  sortListingData: ListingModel[];
  type: string;
  constructor(
    public ldms: ListingdialogmanagerServiceService,
    public searchService: SearchServiceService,
    private location: Location,
    public pdms: ProductsdialogmanagerService,
    private _snackBar: MatSnackBar,
    public handleListing: HandleListingsService,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    // keep checking for user auth status
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.isLoading = true;//spinner starts
    //get user info on login and post list
    // this.loginUserInfo = await this.authService.getUserLoginInfo();
    this.route.params.subscribe(params => {
      // for params containing only productName | categoryName | varietyName | searchQueryByUser
      if (params && Object.keys(params).length === 1) {
        this.type = 'singleparamListingLoad'
        // if params has just 1 param = either searched term, or category clicked or producto or varietyclicked
        if (params.listingSearch && params.listingSearch !== '' && params.listingSearch !== null && params.listingSearch !== undefined) {
          this.searchService.sharedBundledSearchListingItems.subscribe(bundledListings => {
            if (bundledListings && bundledListings.length > 0) {
              this.fetchedListings = bundledListings
              this.searchmessage = `${this.fetchedListings.length} Listings Found for ${params.listingSearch}`
              this.preserveFetchedListings = this.fetchedListings
              // keep a set of listings preserved for reset filters
              this.getAllListingStates(this.fetchedListings)
              this.getAllListingVarieties(this.fetchedListings)

              this.isLoading = false;//spinner starts
            }
            else {
              this.location.back()
            }
          })
        }
        if (params.productName && params.productName !== '' && params.productName !== null && params.productName !== undefined) {
          this.productName = params.productName
          this.callAllListings(this.productName, 'product')
        }
        if (params.categoryName && params.categoryName !== '' && params.categoryName !== null && params.categoryName !== undefined) {
          this.categoryName = params.categoryName
          this.callAllListings(this.categoryName, 'category')
        }
        if (params.varietyName && params.varietyName !== '' && params.varietyName !== null && params.varietyName !== undefined) {
          this.varietyName = params.varietyName
          this.callAllListings(this.varietyName, 'variety')
        }
      }

      // for params containing only productName + state
      else {
        this.type = 'multipleparamListingLoad'
        if (params.productName && params.stateName &&
          params.productName !== '' && params.stateName !== '' &&
          params.productName !== null && params.stateName !== null &&
          params.productName !== undefined && params.stateName !== null) {
          this.productName = params.productName
          this.stateName = params.stateName
          this.callAllListingsWithState(this.productName, this.stateName, 'productstate')
        }
        // if (params && params.categoryName && params.categoryName !== '' && params.categoryName !== null && params.categoryName !== undefined) {
        //   this.categoryName = params.categoryName
        //   this.callAllListings(this.categoryName, 'category')
        // }
        // if (params && params.varietyName && params.varietyName !== '' && params.varietyName !== null && params.varietyName !== undefined) {
        //   this.varietyName = params.varietyName
        //   this.callAllListings(this.varietyName, 'variety')
        // }
      }
    })
  }
  callAllListings(findBy: string, type: string) {
    // Call the service to get filtered listings
    this.handleListing.getFilteredListingsOfType(findBy, type).subscribe({
      next: (filteredItems: ListingModel[]) => {
        this.isLoading = false;//spinner starts
        if (filteredItems && filteredItems.length > 0) {
          // Update the fetched listings and preserve a copy
          this.fetchedListings = filteredItems;
          this.preserveFetchedListings = [...this.fetchedListings]; // Create a shallow copy

          // Perform additional operations on the fetched listings
          this.getAllListingStates(this.fetchedListings);
          this.getAllListingVarieties(this.fetchedListings);
          // Update UI messages and state
          this.useItemName = findBy;
          this.searchmessage = `${this.fetchedListings.length} Listings Found for ${findBy}`;
        } else {
          // Handle case when no listings are found
          this.searchmessage = `No Listings Found for ${findBy}`;
          this.fetchedListings = []; // Clear the fetched listings
          this.preserveFetchedListings = []; // Clear the preserved listings
        }
      },
      error: (err: any) => {
        console.error('Error loading fresh listings', err);
        this.searchmessage = 'An error occurred while loading listings for you.';
        this.fetchedListings = []; // Clear the fetched listings
        this.preserveFetchedListings = []; // Clear the preserved listings
      }
    });
  }
  callAllListingsWithState(findBy: string, findByState: string, type: string) {
    this.handleListing.getAllListings().subscribe(waitForAllListings => {
      if (waitForAllListings && waitForAllListings.length > 0) {
        const filteredItems = waitForAllListings.filter(listing => {
          return type === 'productstate' &&
                 listing.productName === findBy &&
                 listing.state === findByState;
        });
  
        // Process the filtered items
        this.fetchedListings = filteredItems;
        this.preserveFetchedListings = [...filteredItems]; // Create a copy
        this.getAllListingStates(filteredItems);
        this.getAllListingVarieties(filteredItems);
        this.useItemName = findBy;
        this.searchmessage = `${filteredItems.length} Listings Found for ${findBy} in ${findByState}`;
      } else {
        console.warn('No listings found.');
      }
      
      this.isLoading = false; // Stop spinner, regardless of outcome
    }, error => {
      console.error('Error fetching listings:', error);
      this.isLoading = false; // Ensure spinner stops even if there's an error
    });
  }
  

  getAllListingStates(fetchedListings: ListingModel[]) {
    const states = fetchedListings.map(listings => listings.state)
    this.allStates = [...new Set(states)]
  }

  getAllListingVarieties(fetchedListings: ListingModel[]) {
    const varieties = fetchedListings.map(listings => listings.varietyName)
    this.allVarieties = [...new Set(varieties.filter(variety => variety && variety !== null && variety !== 'null' && variety !== undefined))]
  }


  selectedStates(clickedState: string) {
    try {
      const isStatePresent = this.statesToFilter.some(states =>
        states === clickedState)
      if (isStatePresent) {
        this.statesToFilter.forEach((item, index) => {
          if (item === clickedState)
            this.statesToFilter.splice(index, 1);
        })
        this.toggleStateActive = false
      }
      if (!isStatePresent) {
        this.statesToFilter.push(clickedState)
        this.toggleStateActive = true

      }
    }
    catch (e) {
      throw e
    }

  }

  getPreservedListings() {
    // to get back origin fetchedListings of this page
    this.fetchedListings = this.preserveFetchedListings
    this.searchmessage = `${this.fetchedListings.length} Listings Found for ${this.useItemName}`

  }

  resetFilter() {
    // to get back origin fetchedListings of this page
    this.filterOn = false
    this.fetchedListings = this.preserveFetchedListings
    this.statesToFilter = []
    this.varietiesToFilter = []
    this.searchmessage = `${this.fetchedListings.length} Listings Found for ${this.useItemName}`

  }

  selectedVarieties(clickedVariety: string) {
    try {
      this.isVarietyPresent = this.varietiesToFilter.some(varieties =>
        varieties === clickedVariety)
      if (this.isVarietyPresent) {
        this.varietiesToFilter.forEach((item, index) => {
          if (item === clickedVariety)
            this.varietiesToFilter.splice(index, 1);
        });
        this.toggleVarActive = false
      }
      if (!this.isVarietyPresent) {
        this.varietiesToFilter.push(clickedVariety)
        this.toggleVarActive = true
      }
    }
    catch (e) {
      throw e
    }

  }


  applySelectedFilter() {
    try {
      this.getPreservedListings()
      // if only states are filtering
      if (this.statesToFilter && this.statesToFilter.length > 0 && this.varietiesToFilter && this.varietiesToFilter.length === 0) {
        const filteredFetchedListingsByState = this.fetchedListings.filter(listing =>
          this.statesToFilter.includes(listing.state))
        this.fetchedListings = filteredFetchedListingsByState
        this.filterOn = true
      }

      // if only varieties are filtering
      if (this.varietiesToFilter && this.varietiesToFilter.length > 0 && this.statesToFilter && this.statesToFilter.length === 0) {
        const filteredFetchedListingsByVariety = this.fetchedListings.filter(listing =>
          this.varietiesToFilter.includes(listing.varietyName))
        this.fetchedListings = filteredFetchedListingsByVariety
        this.filterOn = true

      }

      // if both states and  varieties are filtering
      if (this.statesToFilter && this.statesToFilter.length > 0 && this.varietiesToFilter && this.varietiesToFilter.length > 0) {
        const filteredFetchedListingsByStateAndVariety = this.fetchedListings.filter(listing => { return this.statesToFilter.includes(listing.state) && this.varietiesToFilter.includes(listing.varietyName) })
        this.fetchedListings = filteredFetchedListingsByStateAndVariety
        this.filterOn = true
        if (this.filterOn && this.fetchedListings && this.fetchedListings.length === 0) {
          this.noResultsOnFilter = true
        }
      }

      this.searchmessage = `${this.fetchedListings.length} Listings Found for ${this.useItemName}`
      this.filterApplied()
    }
    catch (e) { }
  }

  filterApplied() {
    const message = `Filter applied`
    const action = ""
    // give snackBar error then route to profule page on action
    // login error notific
    this._snackBar.open(message, action, {
      duration: 1500,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    })
  }

  sortData(sort: Sort) {
    const data = this.fetchedListings
    if (!sort.active || sort.direction === '') {
      this.sortListingData = data;
      return;
    }
    this.sortListingData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'listingCreateDate':
          return compare(a.PublishDate, b.PublishDate, isAsc);
        case 'bulkprice':
          return compare(a.bulkPrice, b.bulkPrice, isAsc);
      }
    })
  }


  //dialog box to ask for discount from seller
  // #Hidden
  // openDiscountDialog(listingId: string, listingCreatorID: string, listingProductName: string, listingTitle: string, bulkPrice: string, bulkQuantity: string, imageUrls: [], bidWriterName: string) {
  //   const isDiscount = true
  //   const isBid = false
  //   //when login First time, Javascript Object is returned , which can be used unparsed, 
  //   //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
  //   this.loginUserInfo = this.authService.getUserLoginInfo();


  //   if (this.loginUserInfo) {
  //     console.log('reached disc - check login details ?', this.loginUserInfo)
  //     this.currentUserId = this.loginUserInfo?.userId
  //     this.currentUserFirstName = this.loginUserInfo?.firstname
  //     this.currentUserLastName = this.loginUserInfo?.lastname
  //     this.currentUserFullName = this.currentUserFirstName + " " + this.currentUserLastName
  //     console.log('unparsedlogDetails', this.loginUserInfo?.userId)
  //     if (this.currentUserId === undefined || this.currentUserId === null) {
  //       const parsedUserDetails = JSON.parse(this.loginUserInfo)
  //       this.currentUserId = parsedUserDetails.userId
  //       this.currentUserFirstName = parsedUserDetails.firstname
  //       this.currentUserLastName = parsedUserDetails.lastname
  //       this.currentUserFullName = this.currentUserFirstName + " " + this.currentUserLastName
  //       console.log('parsedLog', this.currentUserId)
  //     }

  //     // const loggedInUser = JSON.parse(this.loginUserInfo)
  //     const bidCreator = this.currentUserId
  //     console.log('bidCreator', bidCreator)
  //     const bidCreatorName = this.currentUserFullName


  //     this.ldms.askDiscountonListing({ data: { listingId, listingCreatorID, listingProductName, listingTitle, isDiscount, isBid, bidCreator, bulkPrice, bulkQuantity, imageUrls, bidWriterName, bidCreatorName } }).subscribe((discount => {
  //       console.log(discount)
  //     }))
  //   }
  //   else if (this.loginUserInfo === undefined) {
  //     window.alert('You need to log in to create Discount Request')
  //   }
  // }

  //Ask and bid more for seller
  // #Hidden
  // openBidDialog(listingId: string, listingCreatorID: string, listingProductName: string, listingTitle: string, bulkPrice: string, bulkQuantity: string, imageUrls: [], bidWriterName: string) {
  //   const isDiscount = false
  //   const isBid = true
  //   //when login First time, Javascript Object is returned , which can be used unparsed, 
  //   //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
  //   this.loginUserInfo = this.authService.getUserLoginInfo();
  //   if (this.loginUserInfo) {
  //     this.currentUserId = this.loginUserInfo?.userId
  //     this.currentUserFirstName = this.loginUserInfo?.firstname
  //     this.currentUserLastName = this.loginUserInfo?.lastname
  //     this.currentUserFullName = this.currentUserFirstName + '' + this.currentUserLastName
  //     console.log('unparsedlogDetails', this.loginUserInfo?.userId)
  //     if (this.currentUserId === undefined || this.currentUserId === null) {
  //       const parsedUserDetails = JSON.parse(this.loginUserInfo)
  //       this.currentUserId = parsedUserDetails.userId
  //       this.currentUserFirstName = parsedUserDetails.firstname
  //       this.currentUserLastName = parsedUserDetails.lastname
  //       this.currentUserFullName = this.currentUserFirstName + '' + this.currentUserLastName
  //       console.log('parsedLog', this.currentUserId)
  //     }

  //     // const loggedInUser = JSON.parse(this.loginUserInfo)
  //     // console.log('userInf', loggedInUser)
  //     const bidCreator = this.currentUserId
  //     console.log('bidCreator', bidCreator)
  //     console.log('bidCreator', bidCreator)
  //     const bidCreatorName = this.currentUserFullName

  //     if (this.currentUserId) {
  //       //listingCreatorId is bidWriter or one who receives the bid to approve , bidcreator is one who is requesting approval for discount or bidUp
  //       this.ldms.placeBidonListing({ data: { listingId, listingCreatorID, listingProductName, listingTitle, isDiscount, isBid, bidCreator, bulkPrice, bulkQuantity, imageUrls, bidWriterName, bidCreatorName } }).subscribe((bid => {
  //         console.log('sending', bid)
  //       }))
  //     }
  //   }

  //   else if (this.loginUserInfo === undefined) {
  //     window.alert('You need to log in to create Bid Request')
  //   }
  // }

  // onChangedPage(pageData: PageEvent) {
  //   console.log('pageData', pageData),
  //   this.currentPage = pageData.pageIndex + 1;
  //   this.listingsPerPage = pageData.pageSize;
  //   this.handleListing.getListings(this.listingsPerPage, this.currentPage)
  // }

  // openFilterDialog() {
  //   this.pdms.checkCategory(this.fetchedListings)
  //     .subscribe(resParentCategory => {
  //       this.modalFilterValue = resParentCategory
  //       console.log('the value received', this.modalFilterValue)

  //       // if (!this.modalFilterValue) {
  //       //   console.log('close since no data')
  //       // }
  //       // else {
  //       //   this.modalFilterValue.forEach(response => {
  //       //     this.FilterStateIds = response.StateID.filter(res => {
  //       //       return res.selected === true
  //       //     })
  //       //     console.log('see filterstates', this.FilterStateIds)
  //       //     this.FilterCategoryIds = response.CategoryID.filter(res => {
  //       //       return res.selected === true
  //       //     })
  //       //     console.log('see filtercategories', this.FilterCategoryIds)
  //       //     this.IdsOfStates = this.FilterStateIds.map(x => { return x.stateName })
  //       //     console.log('see ids of states mapp', this.IdsOfStates)
  //       //     this.IdsOfCategories = this.FilterCategoryIds.map(x => { return x.categoryName })
  //       //     console.log('see ids of cats mapp', this.IdsOfCategories)

  //       //     if (!this.IdsOfStates.length && !this.IdsOfCategories.length) {
  //       //       console.log('%cstat and cat empty', 'background: #222; color: #bada55')
  //       //       //call no api
  //       //     }
  //       //     else if (this.IdsOfCategories.length && !this.IdsOfStates.length) {
  //       //       console.log('%cCat is full and Stat empty', 'background: #222; color: #bada55')
  //       //       //call only cat api
  //       //       this.CategoryFilterApi();
  //       //     }
  //       //     else if (!this.IdsOfCategories.length && this.IdsOfStates.length) {
  //       //       console.log('%cStat is full and Cat empty', 'background: #222; color: #bada55')
  //       //       //call only state api
  //       //       this.StateFilterApi();
  //       //     }
  //       //     else if (this.IdsOfStates.length && this.IdsOfCategories.length) {
  //       //       console.log('%cCat and Stat full', 'background: #222; color: #bada55')
  //       //       //call both api
  //       //       this.CategoryStateApiFinal();
  //       //     }
  //       //   })
  //       // }
  //     })
  // }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
