<div *ngIf="this.blogLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h5>Bulk Commerce Redefined </h5>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>

<div class="content">
    <div class="wrap" *ngIf="this.readThisBlog">
        <div class="hero">
            <div class="hero-content">
                <img [src]="this.readThisBlog.articleMainImg" [alt]="this.readThisBlog.articleMainImg"
                    style="width:100%;">
                <div class="centered">
                    <h1>{{this.readThisBlog.articleMainHeading}}</h1>
                    <p>{{this.readThisBlog.articleSubHeading}}</p>
                </div>
            </div>
        </div>

        <section class="intro">
            <div class="container">
                <div class="row">
                    <div class="half1">
                        <h2 class="center">{{this.readThisBlog.head1}}</h2>
                        <img [src]="this.readThisBlog.im1" />
                    </div>
                    <div class="half2">
                        <p class="description">
                            {{this.readThisBlog.p1}}
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="half1">
                        <h2 class="center">{{this.readThisBlog.head2}}</h2>
                        <img [src]="this.readThisBlog.im2" />
                    </div>
                    <div class="half2">
                        <p class="description">
                            {{this.readThisBlog.p2}}
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="half1">
                        <h2 class="center">{{this.readThisBlog.head3}}</h2>
                        <img [src]="this.readThisBlog.im3" />
                    </div>
                    <div class="half2">
                        <p class="description">
                            {{this.readThisBlog.p3}}
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="half1">
                        <h2 class="center">{{this.readThisBlog.head4}}</h2>
                        <img [src]="this.readThisBlog.im4" />
                    </div>
                    <div class="half2">
                        <p class="description">
                            {{this.readThisBlog.p4}}
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="half1">
                        <h2 class="center">{{this.readThisBlog.head5}}</h2>
                        <img [src]="this.readThisBlog.im5" />
                    </div>
                    <div class="half2">
                        <p class="description">
                            {{this.readThisBlog.p5}}
                        </p>
                    </div>
                </div>

            </div>
        </section>

        <section class="steps">
            <div class="container">
                <h2>Summary Points</h2>
                <ol>
                    <div *ngFor="let spoints of this.readThisBlog.summaryPoints">
                        <li>{{spoints}}</li>
                    </div>
                </ol>
            </div>
        </section>

        <section *ngIf="this.shuffledBlogsRandom && this.shuffledBlogsRandom.length>0" class="topics">
            <div class="container">
                <h2 routerLink="/Blogs/Published">Myapaar Trends | Read more...</h2>
                <div *ngFor="let moreBlogs of this.shuffledBlogsRandom" class="fade-block">
                    <mat-card class="fade-content">
                        <div (click)="createHrefForBlog(moreBlogs.articleMainHeading)">
                            <div class="rowMain">
                                <mat-icon class="icon">pageview</mat-icon>
                                <h3>{{moreBlogs.articleMainHeading}}</h3>
                            </div>
                            <p>{{moreBlogs.articleSubHeading}} </p>
                        </div>
                    </mat-card>
                </div>
            </div>
        </section>
    </div>
</div>