import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { Review } from 'src/app/datamodels/productreview';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { QuickFeedNetwork } from 'src/app/datamodels/QuickFeed/QuickFeedModel';
import { ProfileLink } from 'src/app/datamodels/userLinking/feedProfileLink';
import { SocketServiceService } from 'src/app/services/socket-service.service';
import { QuickFeedPost } from 'src/app/datamodels/QuickFeed/QuickFeedPost';
import { UtilsService } from 'src/app/services/utils.service';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.css']
})
export class Home2Component implements OnInit {
  userIsAuthenticated: boolean = false;
  private loginUserInfo: any
  private currentUserId: string
  allLists: ListingModel[];
  allComms: Review[]
  allProfs: ProfileLink[];
  transformedListings: QuickFeedNetwork[];
  userName: string;
  myProfDet: ProfileLink;
  sendAllQFPs: QuickFeedNetwork[];
  allPosts: any[] = []; // Simulated data
  visiblePosts: any[] = []; // Posts to display
  page = 1;
  limit = 10; // Number of posts per page
  isLoading: boolean = false;
  isQFPLoading: boolean = false
  hasMorePosts: boolean = true;
  currentPage = 1;
  pageSize = 7;
  paginatedQFPs: QuickFeedNetwork[] = [];
  activityTypes: string[] = ['Requirements', 'Quotations', 'Catalogue', 'Research', 'Announcements', 'Invitations'];
  currentActivity: string = this.activityTypes[0];
  currentIndex: number = 0;
  viewSharedFeed: QuickFeedNetwork
  mode: string;
  seeFeedId: string;
  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isNearBottom() && !this.isLoading) {
      this.loadMore();
    }
  }
  constructor(
    private usrLnkSrv: HandleUserLinkService,
    private commS: CommentpostService,
    private authService: AuthService,
    public router: Router,
    private hlts: HandleListingsService,
    private skts: SocketServiceService,
    private utils: UtilsService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.skts.initializeSocket()
    this.skts.onNewQF().subscribe((QFP) => {
      const newQFP = QFP.qfP
      const byUserProf = QFP.usrProf
      const newFeedListing: QuickFeedNetwork = {
        qfId: newQFP._id,
        qfStat: null,
        qfsCreatorId: newQFP.qfsCreatorId,
        type: newQFP.type,
        description: newQFP.description,
        imageUrls: newQFP.imageUrls,
        docUrl: newQFP.docUrl,
        viewCount: null,
        publishDate: newQFP.publishDate,
        companyName: byUserProf.businessName,
        firstname: byUserProf.name,
        accSubType: byUserProf.accSubType,
        // vidUrl: string;
        isGSTVerified: byUserProf.isGstVerified,
        logoUser: byUserProf.logoImage,
        comments: [],
      }
      this.paginatedQFPs.unshift(newFeedListing); // Add the new trade to the top of the list
      this.utils.playWidgetAudio()
    });
    this.doAuth()
    this.getAllData().subscribe(() => {
      this.isQFPLoading = false
      return
    })
    // this.skts.onEvent<any>('newQFPost').subscribe((data) => {
    //   console.info('h2 QFOre', data)
    //   console.info('QFP here ?')
    //   const newQFP = data.qfP
    //   const byUserProf = data.usrProf
    //   console.info('newQFP')
    //   console.info('byUserProf')
    //   const newFeedListing: QuickFeedNetwork = {
    //     qfId: newQFP._id,
    //     qfStat: null,
    //     qfsCreatorId: newQFP.qfsCreatorId,
    //     type: newQFP.type,
    //     description: newQFP.description,
    //     imageUrls: newQFP.imageUrls,
    //     docUrl: newQFP.docUrl,
    //     viewCount: null,
    //     publishDate: newQFP.publishDate,
    //     companyName: byUserProf.businessName,
    //     firstname: byUserProf.name,
    //     accSubType: byUserProf.accSubType,
    //     // vidUrl: string;
    //     isGSTVerified: byUserProf.isGstVerified,
    //     logoUser: byUserProf.logoImage,
    //     comments: [],
    //   }
    //   console.info('newFeedListing', newFeedListing)
    //   this.paginatedQFPs.unshift(newFeedListing); // Add the new trade to the top of the list
    //   console.info('fresh****', this.paginatedQFPs)
    //   this.utils.playWidgetAudio()


    // });

    this.loadActivityTypes(this.activityTypes)

    // Subscribe to 'new' event and receive new trade data
    // this.skts.onEvent<{ qfP: QuickFeedPost, usrProf: ProfileLink }>('newQFPost').subscribe((QFP) => {
    //   console.info('QFPh2', QFP)
    //   this.newQFP = QFP.
    //   // this.transformedListings.unshift(QFP); // Add the new trade to the top of the list
    // });
  }
  loadActivityTypes(activityTypes: string[]) {
    try {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % activityTypes.length;
        this.currentActivity = activityTypes[this.currentIndex];
      }, 3000); // Change text every 3 seconds

    }
    catch (e) {
      throw e
    }
  }

  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
      this.userName = this.loginUserInfo?.firstname
      if (this.userIsAuthenticated) {
        this.myProfDet =
        {
          usid: this.loginUserInfo.userId,
          name: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
          logoImage: this.loginUserInfo.logoImage,
          bannerImage: this.loginUserInfo.shopImage,
          businessName: this.loginUserInfo.companyName,
          isGstVerified: this.loginUserInfo.isGSTVerified,
          profileAppCode: this.loginUserInfo.profileAppCode,
          accSubType: this.loginUserInfo.accountSubtype
        }
      }
      else {
        this.myProfDet =
        {
          usid: this.loginUserInfo.userId,
          name: null,
          logoImage: null,
          bannerImage: null,
          businessName: null,
          isGstVerified: false,
          profileAppCode: null,
          accSubType: null
        }
      }
    }
    catch (e) { }

  }

  // Fisher-Yates shuffle algorithm
  private shuffleArraySegment(array: QuickFeedNetwork[]): void {
    try {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
    catch (e) {
      throw e
    }
  }

  shuffleEveryThirdDay(data: QuickFeedNetwork[]): QuickFeedNetwork[] {
    try {
      let result: QuickFeedNetwork[] = [];
      for (let i = 0; i < data.length; i += 3) {
        let chunk = data.slice(i, i + 3);
        this.shuffleArraySegment(chunk);
        result.push(...chunk);
      }
      return result;
    }
    catch (e) {
      throw e
    }
  }

  getAllData(): Observable<any> {
    this.isQFPLoading = true
    return this.getQFPs().pipe(
      switchMap((allQFPs) => {
        this.sendAllQFPs = allQFPs;
        return this.getLists();
      }),
      switchMap(() => {
        const allListCrts = this.allLists.map((listing) => listing.listingCreatorID);
        return this.getProfs(allListCrts, this.currentUserId);
      }),
      switchMap(() => {
        return this.getComs();
      }),
      switchMap(() => {
        const allTransformedListingsAsQFPs = this.transformListings(
          this.allLists,
          this.allComms,
          this.allProfs
        ).sort((a, b) => b.publishDate.getTime() - a.publishDate.getTime());
        const allTransformedQFPsWithComms = this.transformQFPs(
          this.sendAllQFPs,
          this.allComms,
        ).filter(itemsWithDate => itemsWithDate.publishDate)
        // const arrangeQFPs = [...allTransformedQFPsWithComms, ...allTransformedListingsAsQFPs];
        // console.info('arrangeQFPs', arrangeQFPs)
        // this.sendAllQFPs = this.shuffleEveryThirdDay(arrangeQFPs)
        const arrangeQFPs = [...allTransformedQFPsWithComms, ...allTransformedListingsAsQFPs];
        this.sendAllQFPs = arrangeQFPs
        this.paginatedQFPs = this.getPaginatedData();
        // console.info('***OLD****', this.paginatedQFPs)
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
          if (paramMap && paramMap.has('FeedId')) {
            //review id is in routes - editreview
            this.mode = "viewFeed"
            this.seeFeedId = paramMap.get('FeedId');
            this.viewSharedFeed = this.sendAllQFPs.find(eachFd => {
              return eachFd.qfId === this.seeFeedId
            })
            this.isLoading = true; //spinner starts
          }
        });
        return of(this.paginatedQFPs); // Only load initial page
      }),
      catchError((error) => {
        console.error('Error in getAllData:', error);
        return of([]);
      })
    );
  }
  getWeek(date: Date): number {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const diff = date.getDate() + startOfMonth.getDay() - 1;
    return Math.floor(diff / 7) + 1;
  }
  sortAndRandomizeByWeek(data: any[]): any[] {
    const weeks = data.reduce((acc, obj) => {
      const week = this.getWeek(new Date(obj.publishDate));
      if (!acc[week]) acc[week] = [];
      acc[week].push(obj);
      return acc;
    }, {});

    // Randomize objects within each week
    Object.keys(weeks).forEach(week => {
      weeks[week].sort(() => Math.random() - 0.5);
    });

    // Flatten and sort weeks from latest to oldest
    return Object.entries(weeks)
      .sort((a, b) => Number(b[0]) - Number(a[0]))
    // .flatMap(([_, objs]) => objs);
  }

  getPaginatedData(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.utils.playWidgetAudio()
    this.isQFPLoading = false
    return this.sendAllQFPs.slice(0, endIndex); // Load up to current page
  }



  // API Methods
  getQFPs(): Observable<any> {
    return this.commS.getAllQFPs();
  }

  getLists(): Observable<any> {
    return this.hlts.getAllListings().pipe(
      switchMap((checkNet) => {
        this.allLists = checkNet;
        return of(checkNet); // Return the data as an observable
      })
    );
  }

  getProfs(allListCrtrs: string[], ownrId: string): Observable<any> {
    return this.usrLnkSrv.usrProfNetw(allListCrtrs, ownrId).pipe(
      switchMap((checkUsrProfs) => {
        this.allProfs = checkUsrProfs;
        return of(checkUsrProfs); // Return the data as an observable
      })
    );
  }

  getComs(): Observable<any> {
    return this.commS.getAllComms().pipe(
      switchMap((checkCOmms) => {
        this.allComms = checkCOmms;
        return of(checkCOmms); // Return the data as an observable
      })
    );
  }

  changeUserInfo() {
    this.router.navigate([`accountInformation/${this.currentUserId}`])
  }

  transformListings(
    listings: ListingModel[],
    comments: Review[],
    userProfiles: ProfileLink[]
  ): QuickFeedNetwork[] {
    return listings.map((listing) => {
      const userProfile = userProfiles.find(
        (profile) => profile.usid === listing.listingCreatorID
      );

      const listingComments = comments.filter(
        (comment) => comment.listingId === listing._id
      );

      return {
        qfId: listing._id,
        qfsCreatorId: listing.listingCreatorID,
        qfStat: 'APP',
        type: 'listing',
        description: listing.description,
        firstname: listing.listingCreator,
        companyName: listing.companyName,
        imageUrls: listing.imageUrls,
        // vidUrl: null,
        docUrl: null,
        isGSTVerified: userProfile ? userProfile.isGstVerified : false,
        accSubType: userProfile ? userProfile.accSubType : [],
        viewCount: listing.listingViewCount,
        logoUser: userProfile ? userProfile.logoImage : "",
        publishDate: new Date(listing.PublishDate),
        comments: listingComments,
      };
    });
  }
  transformQFPs(
    qfps: QuickFeedNetwork[],
    comments: Review[]
  ): QuickFeedNetwork[] {
    return qfps.map((qfp) => {

      const qfpComments = comments.filter(
        (comment) => comment.listingId === qfp.qfId
      );
      return {
        qfId: qfp.qfId,
        qfStat: null,
        qfsCreatorId: qfp.qfsCreatorId,
        type: qfp.type,
        description: qfp.description,
        firstname: qfp.firstname,
        companyName: qfp.companyName,
        imageUrls: qfp.imageUrls,
        // vidUrl: null,
        docUrl: null,
        isGSTVerified: qfp.isGSTVerified,
        accSubType: qfp.accSubType,
        viewCount: qfp.viewCount,
        logoUser: qfp.logoUser,
        publishDate: qfp.publishDate,
        comments: qfpComments,
      };
    });
  }

  isNearBottom(): boolean {
    const threshold = 700; // Adjust for when to trigger loading (px before bottom)
    const position = window.innerHeight + window.scrollY;
    const height = document.body.offsetHeight;
    return position >= height - threshold;
  }

  loadMore(): void {
    if (this.currentPage * this.pageSize >= this.sendAllQFPs.length) {
      return; // No more data to load
    }
    this.isLoading = true;
    this.currentPage++;
    setTimeout(() => {
      this.paginatedQFPs = this.getPaginatedData();
      this.isQFPLoading = false
      this.isLoading = false;
    }, 1000); // Optional: Simulate API delay
  }

}
