import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ProfileLink } from 'src/app/datamodels/userLinking/feedProfileLink';
import { UserLinkPendingRequest } from 'src/app/datamodels/userLinking/userLinkRequestModel';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-accuslnk',
  templateUrl: './accuslnk.component.html',
  styleUrls: ['./accuslnk.component.css']
})
export class AccuslnkComponent implements OnChanges, OnInit {
  @Input() allLnksOwn: string[]
  @Input() currUserId: string
  sndCurrUserIdOwnr: string
  allAccProfs: ProfileLink[] = [];
  constructor(
    private usrLnkSrv: HandleUserLinkService,
    private utils: UtilsService, private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['allLnksOwn'] && changes['allLnksOwn'].currentValue) {
    }
  }
  ngOnInit(): void {
    this.loadAccProfs(this.allLnksOwn)
  }
  loadAccProfs(allMyLnks: string[]) {
    this.usrLnkSrv.loadAllAccLinks(allMyLnks).then(
      allAccProfs => {
        this.allAccProfs = allAccProfs.myAccProfs
      }
    )
  }

  seeMyLinks() {
    try {
      const routeUrl = this.router.url
      const buttonName = "My Network Links"
      this.authService.clikmtrik(routeUrl, buttonName)
      let links: ProfileLink[]
      links = this.allAccProfs
      const linkTypes = {
        linkCards: links, typ: 'acc', usId: this.currUserId
      }
      this.usrLnkSrv.sendLinks(linkTypes)
      this.router.navigate(['allLinks'], { relativeTo: this.route })
    }
    catch (e) {
      throw e
    }
  }
  remCnCorAccPendReq(remId: string) {
    try {
      const requestIndex = this.allAccProfs.findIndex(allReqIds => allReqIds.usid === remId)
      if (requestIndex !== -1) {
        this.allAccProfs.splice(requestIndex, 1)
        this.utils.playLinkAudio()
      }
      return
    }
    catch (e) {
      throw e
    }
  }
}
