<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Loading Your Listings </h1>
            <h3>Complete 10 Orders Successfully to imrpove Ratings</h3>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="banner">
    <div class="overlay">
        <h1 *ngIf="this.searchmessage !== '' " class="fade-in-text">
            {{this.searchmessage}}
        </h1><br>
    </div>
</div>
<div class="contentListings">
    <div class="listingPageActions">
        <div class="filterListings">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="col">
                                <span>
                                    Filter
                                </span>
                                <span class="snv">
                                    <mat-hint>State and Variety</mat-hint>
                                </span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="header">
                        States
                    </div>
                    <div class="stateNames" *ngIf="this.allStates && this.allStates.length > 0">
                        <div class="chips" *ngFor="let state of this.allStates">
                            <button mat-button class="inactiveFilter" (click)="selectedStates(state)">{{state |
                                translate}}</button>
                        </div>
                    </div>
                    <hr>
                    <div class="header">
                        Varieties
                    </div>
                    <div class="varietyNames" *ngIf="this.allVarieties && this.allVarieties.length > 0">
                        <div class="chips" *ngFor="let variety of this.allVarieties">
                            <button mat-button class="inactiveFilter"
                                (click)="selectedVarieties(variety)">{{variety}}</button>
                        </div>
                    </div>
                    <mat-action-row>

                        <div class="actionRow">

                            <div class="selectedItems">
                                <div class="selectedHeader"
                                    *ngIf="this.statesToFilter && this.statesToFilter.length > 0">
                                    States Selected</div>
                                <div class="selectStates" *ngIf="this.statesToFilter && this.statesToFilter.length > 0">
                                    <div class="selectedStateChips" *ngFor="let stf of this.statesToFilter">
                                        <span class="activeFilter">{{stf}} </span>
                                    </div>
                                </div>
                                <div class="selectedHeader"
                                    *ngIf="this.varietiesToFilter && this.varietiesToFilter.length > 0">Varieties
                                    Selected
                                </div>
                                <div class="selectVarieties"
                                    *ngIf="this.varietiesToFilter && this.varietiesToFilter.length > 0">
                                    <div class="selectedVarietyChips" *ngFor="let vtf of this.varietiesToFilter">
                                        <span class="activeFilter">{{vtf}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="action">
                                <button class="reset" mat-raised-button color="warn"
                                    (click)="resetFilter()">Reset</button>
                                <button class="applyFilter" mat-raised-button color="primary"
                                    (click)="applySelectedFilter()">Apply</button>
                            </div>

                        </div>
                    </mat-action-row>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="sortListings">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Sort Listings
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section matSort (matSortChange)="sortData($event)">
                        <h4 id="sortItems" mat-sort-header="listingCreateDate">Created </h4>
                        <h4 id="sortItems" mat-sort-header="bulkprice">Price</h4>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="loadListings" *ngIf="!isLoading">
        <div *ngIf="this.fetchedListings.length>0 && !isLoading" fxLayout="row wrap" fxLayoutAlign="center center"
            fxLayoutGap="16px grid">
            <app-showlistingcard class="featureListcard" *ngFor="let featuredList of this.fetchedListings "
                [sendFeaturedList]="featuredList"></app-showlistingcard>
        </div>

        <div *ngIf="this.filterOn && this.noResultsOnFilter">
            <p class="noresults">No results found ! Use other options to filter. </p>
            <!-- <button mat-raised-button color="warn" (click)="resetFilter()">Reset</button> -->
        </div>
    </div>
</div>