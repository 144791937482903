import { Component, OnInit } from '@angular/core';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProfileLink } from 'src/app/datamodels/userLinking/feedProfileLink';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-myprofile-links',
  templateUrl: './myprofile-links.component.html',
  styleUrls: ['./myprofile-links.component.css']
})
export class MyprofileLinksComponent implements OnInit {
  bannerHeader: string;
  dataType: any;
  links: ProfileLink[] = [];
  currUserId: string
  myLinksHeader: string;
  constructor(
    private _location: Location,
    private husrlnk: HandleUserLinkService,
    private route: ActivatedRoute,
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
    this.husrlnk.shareLinks.subscribe(gotLinks => {
      if (!gotLinks) {
        this._location.back()
      }
      this.dataType = gotLinks.typ
      this.links = gotLinks.linkCards
      this.currUserId = gotLinks.usId
      if (gotLinks.typ == 'acc') {
        this.bannerHeader = "My Network Links"
        this.myLinksHeader = `${this.links.length} Links`
      }
      else if (gotLinks.typ == 'pnd') {
        this.bannerHeader = "My Pending Links"
        this.myLinksHeader = `${this.links.length} Pending Invites`
      }

    })
  }
  remCnCorAccPendReq(remId: string) {
    try {
      const requestIndex = this.links.findIndex(allReqIds => allReqIds.usid === remId)
      if (requestIndex !== -1) {
        this.links.splice(requestIndex, 1)
        this.utils.playLinkAudio()
      }
      return
    }
    catch (e) {
      throw e
    }
  }
}
