<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen"><app-myapaar-spinner class="mypsin"></app-myapaar-spinner></div>
</div>


<div class="content">
    <div class="contactSupport">
        <div class="banner">
            <div class="overlay">
                <h2 class="fade-in-text fsubhead">
                    <h1> Raise Ticket </h1>
                    <h3> Track Issues </h3>
                </h2>
                <div class="contact-row">
                    <button class="shareRef" title="Whatsapp Share" mat-stroked-button (click)="this.shareWhatsapp()">
                        <img class="refim whatsappref" alt="myapaar referral"
                            src="./../../../assets/svgiconsandimages/whatsapp-ref.svg" />
                    </button>
                    <a class="shareRef" [href]="this.useEmailReferral" title="EmailRef" mat-stroked-button>
                        <img class="refim emailref" alt="myapaar referral"
                            src="./../../../assets/svgiconsandimages/email-ref.svg" />
                    </a>
                </div>
            </div>
        </div>

    </div>


    <div *ngIf="!isLoading">
        <div *ngIf="this.viewIssues && this.viewIssues.length >0">

            <p class="head-main"></p>
            <div class="allissues">
                <div *ngFor="let issue of this.viewIssues">
                    <mat-card class="tp-card">
                        <mat-card-header>
                            <mat-card-title>
                                <div class="title">
                                    <div class="issuetitle">
                                        <span class="titleVer">
                                            {{issue.IssueID}}
                                        </span>
                                        <mat-card-subtitle class="subtitle">
                                            <span>{{issue.orderNumber}}</span>
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="issueStatus">
                                        <span class="headingStatus">Status</span>
                                        <p class="currentStatus">
                                            <span *ngIf="issue.issueStatus == 'Pending'"
                                                style="color: rgb(239, 147, 0);"> {{issue.issueStatus}}</span>
                                            <span *ngIf="issue.issueStatus === 'Resolved'"
                                                style="color: rgb(32, 149, 28);">
                                                {{issue.issueStatus}}</span>
                                        </p>
                                    </div>
                                </div>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="eachVersion">
                                <div class="invDetails">
                                    <span class="orderType">
                                        {{issue.orderType}} : </span>
                                    <span class="issueReason">
                                        {{issue.disputeReason.disputeDescription
                                        }}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div *ngIf="!this.viewIssues || this.viewIssues && this.viewIssues.length === 0">
            <p>No Issues Reported By You </p>
        </div>
    </div>
</div>