<div *ngIf="this.getProd1" class="overlap-varieties">
    <div class="cards">
        <div (click)="onSelectVariety(getProd1.varietyName)" class="variety-img">
            <img alt="getProd1.imageUrls[0]" src="{{getProd1.imageUrls[0]}}" width="240">
        </div>
        <div class="varietyContent">
            <div (click)="onSelectVariety(getProd1.varietyName)" class="variety-info">
                <div class="variety-text">
                    <span class="vname">{{getProd1.varietyName | translate}}</span>
                    <span class="pname">{{getProd1.productName | translate}}</span>
                    <span class="description"> {{getProd1.description}}</span>
                </div>
            </div>
            <div class="variety-view">
                <button *ngIf="!this.isShowPrice" (click)="showPrice()" mat-stroked-button class="primary">
                    Show Price
                </button>
                <button *ngIf="this.isShowPrice" mat-stroked-button class="primary">
                    <span>{{(getProd1.bulkPrice).toLocaleString('en-In')}}</span>
                    <span> ₹ / {{getProd1.useUnitShort}}</span>
                </button>
            </div>
        </div>
    </div>
</div>