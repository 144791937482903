<div class="banner">
    <div class="overlay">
        <div>
            <h1 class="fade-in-text">{{ this.loadHeader|translate}}</h1>
            <h6 class="fade-in-text">Complete Account KYC</h6>
        </div>
    </div>
</div>
<div class="noOrders">
    <div *ngIf="this.allTNCs" class="contentNoPo">
        <div *ngFor="let tnc of this.allTNCs" class="checktnCs">
            <h3 class="tncHead"> <u>{{tnc.header}}</u></h3>
            <ul *ngFor="let conditions of tnc.pointers">
                <li> {{conditions}}</li>
            </ul>
        </div>
    </div>
    <div *ngIf="!isUploading" class="actions">
        <div class="back">
            <section class="tncAccept">
                <mat-checkbox #c (click)="toggle(!c.checked)">In Agreement</mat-checkbox>
            </section>
        </div>
        <div class="actionButton next">
            <input hidden #filePicker type='file' name='gstupload' accept=".pdf" (change)="onFileSelected($event)" />
            <button [disabled]="!this.acceptTerms" mat-stroked-button
                [ngClass]="{'inactive':this.acceptTerms===false,'active':this.acceptTerms===true}"
                class="imagePickerbutton" (click)="filePicker.click()">
                <!-- <span *ngIf="!this.images">Upload GST document</span> -->
                <span>{{'Upload GST Document'|translate}}</span>
                <!-- <div>
                    <span *ngIf="this.images">{{this.images.length}} picked</span>
                    <mat-spinner *ngIf="isImageLoading" diameter="20"></mat-spinner>
                </div> -->
            </button> <br>
            <mat-hint>Size limit 3 MB</mat-hint>

        </div>
    </div>
    <div *ngIf="isUploading">
        <h1>Please Wait... <mat-spinner diameter="20"></mat-spinner> </h1>
        <h1>Uploading Document</h1>
    </div>
</div>