<div *ngIf="this.getProd1" class="products">
    <div class="product-card">
        <div class="singleCards">
            <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
            <div (click)="onSelectProduct(getProd1.productName)" class="image_wrapper">
                <img *ngIf="fileType  !== 'video' " [src]="this.fileUrl" alt="this.fileUrl" />
                <video *ngIf="fileType === 'video'" autoplay loop muted>
                    <source [src]="this.fileUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div class="overlay overlay_1">
                    <div class="productname">
                        <p>
                            {{getProd1.productName | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>