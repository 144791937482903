import { OpenTrade } from '../datamodels/contractOrders.ts/openpurchaseorder';
import { Injectable } from '@angular/core';
import { ListingModel } from '../datamodels/ListingModel';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, throwError, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { DetailProductInfo } from '../datamodels/groupedmodel/productDetailInfoModel';

@Injectable({
  providedIn: 'root'
})
export class HandleTradesService {
  private listings: ListingModel[] = [];
  public allListings: ListingModel[] = [];
  private domain: string | undefined
  private listingsUpdate = new Subject<{ listings: ListingModel[], totalListings: number }>();
  private ProductNamelistingsUpdate = new Subject<{ listings: ListingModel[], totalListings: number }>();
  public createListingResponse: { message: string; createlisting: ListingModel; };
  singleListing: ListingModel;
  userlistings: ListingModel[]
  totalListings: number;
  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  async newSupplyTrade(tradeForm: OpenTrade): Promise<{ message: string, addedTrade: OpenTrade, status: number }> {
    console.info('tradeForm', tradeForm)
    const tradeFormData = new FormData();
    //f0
    tradeFormData.append("contractDetails", JSON.stringify(tradeForm.contractDetails));
    tradeFormData.append("itemDetails", JSON.stringify(tradeForm.itemDetails));
    tradeFormData.append("sellerDetails", JSON.stringify(tradeForm.sellerDetails));
    tradeFormData.append("sellerCertifications", JSON.stringify(tradeForm.sellerCertifications));
    tradeFormData.append("creditTerms", JSON.stringify(tradeForm.creditTerms));
    tradeFormData.append("itemShippedFrom", JSON.stringify(tradeForm.itemShippedFrom));
    // tradeFormData.append("listingCreatorID", listingCreatorID);
    //f1
    return this.http.post<{ message: string, addedTrade: OpenTrade, status: number }>(`${this.domain}api/tradeContractRoutes/newTrade`, tradeFormData)
      .toPromise()
  }
}
