import { Injectable } from '@angular/core';
import { PurchaseOrderModel } from '../datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HttpClient } from '@angular/common/http';
import { InvoiceModel } from '../datamodels/invoiceOrderModel/invoiceDetailsModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { BuyerDetails } from '../datamodels/groupedmodel/buyerDetails';
import { SellerDetails } from '../datamodels/groupedmodel/sellerDetails';
import { ItemDetails } from '../datamodels/groupedmodel/itemDetails';
interface IDisplayPayments {
  buyerDetails: BuyerDetails;
  sellerDetails: any;
  itemDetails: ItemDetails;
  dateOfINVDelivered: string;
  dateOfINVGenerated: string;
  dateOfINVPaid: string;
  invoID: string;
  invoiceRaiseValue: number;
  invoiceStatus: string;
  invoiceVersionNumber: number;
  invoNumber: string;
  linkedPO: string;
}
@Injectable({
  providedIn: 'root'
})
export class HandleInvoiceService {
  private invoiceForPO = new BehaviorSubject(null);
  invoiceItemsInPO = this.invoiceForPO.asObservable()
  private domain: string | undefined
  constructor(private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  setPOforInvoicing(purchaseOrder: PurchaseOrderModel) {
    try {
      this.invoiceForPO.next(purchaseOrder)
    }
    catch (e) {
      throw e
    }
  }

  async getAllUserInvoiceOrders(currentUserId: string, logingAccountType: string): Promise<{ message: string; userInvoices: IDisplayPayments[]; status: number; }> {
    const queryParams = `?userId=${currentUserId}&loginAccounttype=${logingAccountType}`
    const fetchedUserInvoices = await this.http.get<{ message: string, userInvoices: IDisplayPayments[], status: number; }>(`${this.domain}api/invoiceOrderRequest/getAllINVOforUser/` + queryParams).toPromise()
    return fetchedUserInvoices
  }

  async getInvosPaidTrend(buyerId: string): Promise<{ totalInvosPaid: number, totalInvosOnTime: number, totalInvosPending: number, totalInvosLate: number, status: number }> {
    const queryParams = `?usr=${buyerId}`
    const buyerInvosTrend = await this.http.get<{ totalInvosPaid: number, totalInvosOnTime: number, totalInvosPending: number, totalInvosLate: number, status: number }>(`${this.domain}api/invoiceOrderRequest/buyerInvosPaidTrend/` + queryParams).toPromise()
    return buyerInvosTrend
  }

  getSingleInvoiceOrder(invoiceOrderID: string): Observable<{ message: string; findINVO: InvoiceModel; status: number; }> {
    try {
      const queryParams = `?INVOid=${invoiceOrderID}`
      // const allInvoicesOfPO = await 
      const invoiceOrderForID = this.http.get<{ message: string; findINVO: InvoiceModel; status: number; }>
        (`${this.domain}api/invoiceOrderRequest/getCurrentINVOrd/` + queryParams)
      return invoiceOrderForID
    }
    catch (e) {
      throw e
    }
  }

  async getInvoiceOrderStatus(invoiceOrderNum: string): Promise<{ invoStatus: string }> {
    try {
      const queryParams = `?INVOnumb=${invoiceOrderNum}`
      // const allInvoicesOfPO = await 
      const invoiceOrderStatus = await this.http.get<{ invoStatus: string, status: number; }>
        (`${this.domain}api/invoiceOrderRequest/getInvoStatus/` + queryParams).toPromise()
      return invoiceOrderStatus
    }
    catch (e) {
      throw e
    }
  }

  async getallInvoicesForPO(purchaseOrderID: string, purchaseOrderNumber: string): Promise<InvoiceModel[]> {
    try {
      const queryParams = `?POid=${purchaseOrderID}&POnumber=${purchaseOrderNumber}`
      // const allInvoicesOfPO = await 
      const allInvoicesOfPO = await this.http.get<{ message: string, allInvoiceOrders: InvoiceModel[] }>(`${this.domain}api/invoiceOrderRequest/allINVOsForPO/` + queryParams).toPromise();
      return allInvoicesOfPO.allInvoiceOrders
    }
    catch (e) {
      throw e
    }
  }

  async createNewInvoice(newInvoice: InvoiceModel): Promise<{ message: string; createdINVO: InvoiceModel; invoS3Link: string; status: number; }> {
    try {
      const newINVData = new FormData()
      for (let newINVDataItem in newInvoice) {
        // each new object should be conditioned in If, or else will be skipped
        //append nested ob from loops

        //maybe blank po id ?
        if (newINVDataItem === '_id') {
          newINVData.append(`_id`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'invoiceNumber') {
          newINVData.append(`invoiceNumber`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'invoiceVersionNumber') {
          newINVData.append(`invoiceVersionNumber`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'purchaseOrderNumber') {
          newINVData.append(`purchaseOrderNumber`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'purchaseOrderID') {
          newINVData.append(`purchaseOrderID`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'buyerDetails') {
          for (let buyerDetailKey in newInvoice[newINVDataItem]) {
            if (buyerDetailKey === 'buyerAddress') {
              //poDataItem is sellerDetails here
              for (let buyerAddressKey in newInvoice[newINVDataItem][buyerDetailKey]) {
                // append two depth nested objects
                newINVData.append(`buyerDetails[${buyerDetailKey}][${buyerAddressKey}]`, newInvoice[newINVDataItem][buyerDetailKey][buyerAddressKey])
              }
            }
            if (buyerDetailKey === 'buyerContactDetails') {
              //poDataItem is sellerDetails here
              // append two depth nested objects
              for (let buyerContactDetailKey in newInvoice[newINVDataItem][buyerDetailKey]) {
                // for numbers, we need to stringify
                buyerContactDetailKey === 'alternatePhoneContact' || buyerContactDetailKey === 'phone' ?
                  newINVData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, JSON.stringify(newInvoice[newINVDataItem][buyerDetailKey][buyerContactDetailKey]))
                  : newINVData.append(`buyerDetails[${buyerDetailKey}][${buyerContactDetailKey}]`, newInvoice[newINVDataItem][buyerDetailKey][buyerContactDetailKey])
              }
            }
            if (buyerDetailKey !== 'buyerAddress' && buyerDetailKey !== 'buyerContactDetails')
              newINVData.append(`buyerDetails[${buyerDetailKey}]`, newInvoice[newINVDataItem][buyerDetailKey])
          }
        }
        // sellerDetails fetched of PO
        if (newINVDataItem === 'sellerDetails') {
          for (let sellerDetailKey in newInvoice[newINVDataItem]) {
            newINVData.append(`sellerDetails[${sellerDetailKey}]`, newInvoice[newINVDataItem][sellerDetailKey])
          }
        }
        if (newINVDataItem === 'itemDetails') {
          const resolvedItemDetails = []
          for (let itemDetailKey in newInvoice[newINVDataItem]) {
            resolvedItemDetails.push(newInvoice[newINVDataItem][itemDetailKey])
          }
          newINVData.append(`itemDetails`, JSON.stringify(resolvedItemDetails))
          // newPOData.append(`itemDetails`, JSON.stringify(purchaseOrderForInv[poDataItem]))
        }
        if (newINVDataItem === 'shipToBuyerLocation') {
          for (let shipToBuyerLocationKey in newInvoice[newINVDataItem]) {
            newINVData.append(`shipToBuyerLocation[${shipToBuyerLocationKey}]`, newInvoice[newINVDataItem][shipToBuyerLocationKey])
          }
        }
        if (newINVDataItem === 'modePickup') {
          for (let modePickupKey in newInvoice[newINVDataItem]) {
            newINVData.append(`modePickup[${modePickupKey}]`, newInvoice[newINVDataItem][modePickupKey])
          }
        }
        if (newINVDataItem === 'bankDetails') {
          for (let bankDetailsKey in newInvoice[newINVDataItem]) {
            newINVData.append(`bankDetails[${bankDetailsKey}]`, newInvoice[newINVDataItem][bankDetailsKey])
          }
        }
        if (newINVDataItem === 'dateOfINVGenerated') {
          newINVData.append(`dateOfINVGenerated`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'dateOfINVExpiry') {
          newINVData.append(`dateOfINVExpiry`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'validityOfINVExpiry') {
          newINVData.append(`validityOfINVExpiry`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        // if (newINVDataItem === 'dateOfPOGenerated') {
        //   newINVData.append(`dateOfPOGenerated`, JSON.stringify(newInvoice[newINVDataItem]))
        // }
        // if (newINVDataItem === 'dateOfPurchaseOrderExpiry') {
        //   newINVData.append(`dateOfPurchaseOrderExpiry`, JSON.stringify(newInvoice[newINVDataItem]))
        // }
        if (newINVDataItem === 'validityOfPurchaseOrderExpiry') {
          newINVData.append(`validityOfPurchaseOrderExpiry`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 's3POpdfLink') {
          newINVData.append(`s3POpdfLink`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 's3InvoicepdfLink') {
          newINVData.append(`s3InvoicepdfLink`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'orderValue') {
          newINVData.append(`orderValue`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'orderValueIncludingTax') {
          newINVData.append(`orderValueIncludingTax`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'orderQuantity') {
          newINVData.append(`orderQuantity`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'orderTax') {
          newINVData.append(`orderTax`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'invoicedItemsAmtPaidAtCheckout') {
          newINVData.append(`invoicedItemsAmtPaidAtCheckout`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'amountRemainingToPayOnCurrentInvoice') {
          newINVData.append(`amountRemainingToPayOnCurrentInvoice`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'invoiceRaiseValue') {
          newINVData.append(`invoiceRaiseValue`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'nextInvoiceBalanceDueValue') {
          newINVData.append(`nextInvoiceBalanceDueValue`, JSON.stringify(newInvoice[newINVDataItem]))
        }

        if (newINVDataItem === 'deadlineDays') {
          newINVData.append(`deadlineDays`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'paymentLink') {
          newINVData.append(`paymentLink`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'paymentStatus') {
          newINVData.append(`paymentStatus`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'paymentTrnsctnId') {
          newINVData.append(`paymentTrnsctnId`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'isPOStatusActive') {
          newINVData.append(`isPOStatusActive`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'poStatus') {
          newINVData.append(`poStatus`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'isInvoiceGenerated') {
          newINVData.append(`isInvoiceGenerated`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        if (newINVDataItem === 'invoiceStatus') {
          newINVData.append(`invoiceStatus`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'invoiceType') {
          newINVData.append(`invoiceType`, newInvoice[newINVDataItem])
        }
        if (newINVDataItem === 'invoiceDeliveryOTP') {
          newINVData.append(`invoiceDeliveryOTP`, JSON.stringify(newInvoice[newINVDataItem]))
        }
        // Display the key/value pairs
        // for (var pair of newINVData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
      }
      // const createInvoiceOrder = await this.http.post<{ message: string, createdINVO: InvoiceModel, invoS3Link: string, status: string }>(`${this.domain}api/invoiceOrderRequest/createINVO/``, newINVData).toPromise()
      const createnewinvoice = await this.http.post<{ message: string, createdINVO: InvoiceModel, invoS3Link: string, status: number }>(`${this.domain}api/invoiceOrderRequest/createINVO/`, newINVData).toPromise()
      return createnewinvoice
    }
    catch (e) {
      throw e
    }
  }

  async remindBuyerToPayInvoice(invoiceId: string, invoiceNumber: string): Promise<{ message: string }> {
    const queryParams = `?invoiceId=${invoiceId}&invoiceNumber=${invoiceNumber}`
    const messageSent = await this.http.get<{ message: string }>(`${this.domain}api/invoiceOrderRequest/sendReminderToBuyer/` + queryParams).toPromise()
    return messageSent
  }
  async cancelInvoiceOrder(invoiceOrderId: string): Promise<{ message: string }> {
    const queryParams = `?invoiceId=${invoiceOrderId}`
    const cancelledINVO = await this.http.get<{ message: string }>(`${this.domain}api/invoiceOrderRequest/cancelInvoiceOrder/` + queryParams).toPromise()
    return cancelledINVO
  }
  async shipInvoiceOrder(invoiceOrderId: string, invoiceOrderNumber: string): Promise<{ message: string }> {
    const queryParams = `?invoiceId=${invoiceOrderId}&invoiceNumber=${invoiceOrderNumber}`
    const updateINVO = await this.http.get<{ message: string }>(`${this.domain}api/invoiceOrderRequest/shipInvoiceOrder/` + queryParams).toPromise()
    return updateINVO
  }
  async completeInvoicePaymentStatus(invoiceOrderNumber: string, invoiceOrderId: string): Promise<{ message: string; status: number }> {
    const queryParams = `?invoiceNumber=${invoiceOrderNumber}&invoiceId=${invoiceOrderId}`
    const updateINVO = await this.http.get<{ message: string; status: number }>(`${this.domain}api/invoiceOrderRequest/updatePaymentStatusSendBill/` + queryParams).toPromise()
    return updateINVO
  }
}
