<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Loading</h1>
            <h2>Purchase Orders </h2>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="contentPO">
    <div *ngIf="!isLoading">
        <div *ngIf="isPOSavailable">
            <div class="banner">
                <div class="overlay">
                    <h1 class="fade-in-text">Purchase Orders</h1>
                </div>
            </div>
            <div class="verifiedID" *ngIf="!this.gstDetailsPending && !this.gstVerificationPending">
                <h5>Verified
                </h5>
            </div>
            <div class="sellerAccountType" *ngIf="this.sellerAccountType">
                <div class="purchaseOrderContainer">
                    <div class="togglecontainer">
                        <div class="buyersPlacedTitle">
                            <h2 matTooltip="Placed by Buyers to me">
                                <span>
                                    Selling
                                </span>
                            </h2>
                        </div>
                        <div class="toggle">
                            <h3>
                                <mat-slide-toggle [(ngModel)]="isSellerCheckingPOtypeAsBuyer">
                                </mat-slide-toggle>
                            </h3>
                        </div>
                        <div class="iPlaceTitle">
                            <h2 matTooltip="Placed by Me to other Sellers">
                                <span>
                                    Buying
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div *ngIf="!isSellerCheckingPOtypeAsBuyer" class="sellerTypePurchaseOrders">
                        <div class="searchField">
                            <mat-form-field appearance="outline">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applySellerAccountSellerTypeFilter($event)"
                                    placeholder="P.O number, Order status" #input>
                            </mat-form-field>
                        </div>
                        <!-- // can issue invoice -->
                        <!-- the 1seller/thisAccountisSeller-actingAsSeller 
                                     Other users created Purchase orders and issued to the 1seller/thisAccountisSeller 
                                    - we will show the buyerDetails for purchase orders that the 1seller/thisAccountisSeller 
                                needs to deliver -->
                        <div>
                            <table mat-table [dataSource]="sellerTypeDataSource">
                                <!-- Position Column -->
                                <!-- Order Column -->
                                <ng-container matColumnDef="pon">
                                    <th mat-header-cell *matHeaderCellDef>Purchase <br> Order No. </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a [routerLink]="['/viewPurchaseOrder',element._id]">
                                            {{element.purchaseOrderNumber}}
                                        </a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef> Status <br> Created <br> Expiry
                                    </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element"> {{element.poStatus}} <br>
                                        <span *ngIf="element.poStatus==='New'">
                                            {{element.dateOfPOGenerated | date}}<br>
                                            {{element.dateOfPurchaseOrderExpiry | date}}</span>
                                    </td>
                                </ng-container>

                                <!--Other Buyers Column -->
                                <ng-container matColumnDef="buyerName">
                                    <th mat-header-cell *matHeaderCellDef> Buyer Name <br> Company Name </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element"> {{element.buyerDetails.buyerName}}
                                        <br><br> {{element.buyerDetails.buyerCompanyName}}
                                    </td>
                                </ng-container>
                                <!-- Generate Invoice Column -->
                                <ng-container matColumnDef="invoice_Status">
                                    <th mat-header-cell *matHeaderCellDef> Invoice </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        {{element.invoiceStatus}}
                                    </td>
                                </ng-container>
                                <!-- Download Column -->

                                <ng-container matColumnDef="po_document">
                                    <th mat-header-cell *matHeaderCellDef> Download </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="downloadLink" href="{{element.s3POpdfLink}}" target="_blank"
                                            rel="noopener noreferrer" mat-raised-button
                                            style="background-color: rgba(35, 130, 246, 0); font-weight: 450;color: rgb(46, 87, 198);">
                                            <mat-icon class="icon-display">cloud_download</mat-icon>
                                        </a>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedSellerTypeTableColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedSellerTypeTableColumns;">
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="isSellerCheckingPOtypeAsBuyer" class="buyerTypePurchaseOrders">
                        <div class="searchField">
                            <mat-form-field appearance="outline">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applySellerAccountBuyerTypeFilter($event)"
                                    placeholder="P.O number, Order status" #input>
                            </mat-form-field>
                        </div>
                        <!-- // canttt issue invoice -->
                        <!-- Other users created Purchase orders and issued to the 1seller/thisAccountisSeller 
                                    - we will show the buyerDetails for purchase orders that the 1seller/thisAccountisSeller 
                                needs to deliver -->
                        <div>
                            <table mat-table [dataSource]="buyerTypeDataSource">
                                <!-- Order Column -->
                                <ng-container matColumnDef="pon">
                                    <th mat-header-cell *matHeaderCellDef>Purchase <br> Order No. </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        <a [routerLink]="['/viewPurchaseOrder',element._id]">
                                            {{element.purchaseOrderNumber}}
                                        </a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef> Status <br> Created <br> Expiry </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element"> {{element.poStatus}} <br>
                                        <span *ngIf="element.poStatus==='New'">
                                            {{element.dateOfPOGenerated | date}}<br>
                                            {{element.dateOfPurchaseOrderExpiry | date}}</span>
                                    </td>
                                </ng-container>

                                <!--Other Seller & Company Column -->
                                <ng-container matColumnDef="sellerName">
                                    <th mat-header-cell *matHeaderCellDef><b>Seller Name</b> <br> Company Name </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        <b>{{element.sellerDetails.firstname}}
                                            {{element.sellerDetails.lastname}} </b><br><br>
                                        {{element.sellerDetails.companyName}}
                                    </td>
                                </ng-container>

                                <!-- Company Column -->
                                <ng-container matColumnDef="invoice_Status">
                                    <th mat-header-cell *matHeaderCellDef> Invoice </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        {{element.invoiceStatus}}
                                    </td>
                                </ng-container>
                                <!-- Download Column -->
                                <ng-container matColumnDef="po_document">
                                    <th mat-header-cell *matHeaderCellDef> Download </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="downloadLink" href="{{element.s3POpdfLink}}" target="_blank"
                                            rel="noopener noreferrer" mat-raised-button
                                            style="background-color: rgba(35, 130, 246, 0); font-weight: 450;color: rgb(46, 87, 198);">
                                            <mat-icon class="icon-display">cloud_download</mat-icon>
                                        </a>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedBuyerTypeTableColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedBuyerTypeTableColumns;"></tr>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
            <!-- buyerNeeds to see Seller Details  -->
            <!-- this 1stBuyer/thisAccountisBuyerType created Purchase orders and issued to the other Sellers 
               - we will show the sellerDetails for purchase orders that the 1stBuyer/thisAccountisBuyerType
               wants as his delivery -->
            <div class="buyerAccountType" *ngIf="this.buyerAccountType">
                <div class="purchaseOrderContainer">
                    <div class="searchField">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyBuyerAccountBuyerTypeFilter($event)"
                                placeholder="P.O number, Order status" #input>
                        </mat-form-field>
                    </div>
                    <div class="secHead">
                        <h3 class="headNoPo">Purchase <br> Orders</h3>
                    </div>
                    <div class="buyerTypePurchaseOrders">
                        <!-- // canttt issue invoice -->
                        <div>
                            <table mat-table [dataSource]="buyerTypeDataSource">
                                <!-- Position Column -->
                                <!-- Order Column -->
                                <ng-container matColumnDef="pon">
                                    <th mat-header-cell *matHeaderCellDef>Purchase <br> Order No. </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a [routerLink]="['/viewPurchaseOrder',element._id]">
                                            {{element.purchaseOrderNumber}}
                                        </a>
                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef> Status <br> Created <br> Expiry</th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">{{element.poStatus}}<br>
                                        <span *ngIf="element.poStatus==='New'">
                                            {{element.dateOfPOGenerated | date}}<br>
                                            {{element.dateOfPurchaseOrderExpiry | date}}</span>
                                    </td>
                                </ng-container>

                                <!--other Seller Column -->
                                <ng-container matColumnDef="sellerName">
                                    <th mat-header-cell *matHeaderCellDef><b>Seller Name</b> <br> Company Name </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        <b>{{element.sellerDetails.firstname}}
                                            {{element.sellerDetails.lastname}} </b><br><br>
                                        {{element.sellerDetails.companyName}}
                                    </td>
                                </ng-container>

                                <!-- Company Column -->
                                <ng-container matColumnDef="invoice_Status">
                                    <th mat-header-cell *matHeaderCellDef> Invoice </th>
                                    <td [routerLink]="['/viewPurchaseOrder',element._id]" mat-cell
                                        *matCellDef="let element">
                                        {{element.invoiceStatus}}
                                    </td>
                                </ng-container>
                                <!-- Download Column -->
                                <ng-container matColumnDef="po_document">
                                    <th mat-header-cell *matHeaderCellDef> Download </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="downloadLink" href="{{element.s3POpdfLink}}" target="_blank"
                                            rel="noopener noreferrer" mat-raised-button
                                            style="background-color: rgba(35, 130, 246, 0); font-weight: 450;color: rgb(46, 87, 198);">
                                            <mat-icon class="icon-display">cloud_download</mat-icon>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedBuyerTypeTableColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedBuyerTypeTableColumns;">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="noOrders" *ngIf="!isPOSavailable">
            <div>
                <div class="contentNoPo" *ngIf="this.buyerAccountType">
                    <div class="secHead">
                        <h3 class="headNoPo">No Purchase Orders. Tips to find the best deal</h3>
                    </div>
                    <div class="noPoSec">
                        <h3>Try sample packs</h3>
                        <h5>
                            You can order sample packs of items you wish to order in bulk later
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>
                            Maximum 10 sample orders allowed
                        </h3>
                        <h5>
                            A limit of 10 sample orders per account is set. You can order 10 sample packs of different
                            or
                            same items from different sellers.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>Pay later</h3>
                        <h5>
                            You only have to pay a small advance before the delivery of products. Pay later and buy in
                            bulk.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>Easy Returns and Refunds</h3>
                        <h5>
                            You can always return your delivered items
                        </h5>
                        <mat-hint>Please read refund policy on <b>Add to cart</b> Page</mat-hint>
                    </div>
                </div>
                <div class="contentNoPo" *ngIf="this.sellerAccountType">
                    <div class="secHead">
                        <h3 class="headNoPo">No Purchase Orders. Tips to attract more Buyers</h3>
                    </div>
                    <div class="noPoSec">
                        <h3>More listings</h3>
                        <h5>
                            Add more listings with detailed description and content to elaborate your product details
                            such as - Variety, Grade, Packaging Type and Other Details.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>
                            Pricing
                        </h3>
                        <h5>
                            Do not overprice your items. It is always a good idea to check and research the market for
                            correct prices and post the same on your listing
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>Listing Images</h3>
                        <h5>
                            Use real life images of your items and product instead of using stock images from internet.
                            This builds authenticity of your brand.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>KYC</h3>
                        <h5>
                            Make sure you are a verified seller by completing the KYC process
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>