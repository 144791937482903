import { Component, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MyvyapaariChatComponent } from '../myvyapaari-chat/myvyapaari-chat.component';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
interface iFooterOPs {
  action: string;
  route: string;
  shortWord: string;
  src: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  loadFooterOps: iFooterOPs[] = [
    // {
    //   action: 'Bulk Order Supply',
    //   shortWord: "Requirement",
    //   route: 'https://www.myapaar.com/requirement/NewRequirement',
    //   src: '../../../assets/svgiconsandimages/help-com.svg'
    // },
    // {
    //   action: 'Latest Mandi Rates',
    //   shortWord: "Mandi",
    //   route: 'https://www.myapaar.com/requirement/NewRequirement',
    //   src: '../../../assets/svgiconsandimages/help-com.svg'
    // },
    {
      action: 'Merchant Network',
      shortWord: "Network",
      route: 'QuickFeed',
      src: '../../../assets/svgiconsandimages/network-group.svg'
    },
    {
      action: 'Myapaar Home',
      shortWord: "Home",
      route: 'home',
      // route: 'viewItems/All',
      src: '../../../assets/svgiconsandimages/home-myp.svg'
    },

    {
      action: 'View All Items on Myapaar',
      shortWord: "Marketplace",
      route: 'viewItems/All',
      // route: 'viewItems/All',
      src: '../../../assets/svgiconsandimages/6-graph-svgrepo-com.svg'
    },
    // {
    //   action: 'Something else...',
    //   shortWord: "Chat",
    //   route: 'https://www.myapaar.com/requirement/NewRequirement',
    //   src: '../../../assets/svgiconsandimages/help-com.svg'
    // },

    // {
    //   action: 'Customer Support',
    //   shortWord: "Help",
    //   route: 'allIssues',
    //   src: '../../../assets/svgiconsandimages/callSupport.svg'
    // },
  ]
  constructor(
    private _bottomSheet: MatBottomSheet,
    private utils: UtilsService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  openBottomSheet(): void {
    this.utils.playWidgetAudio();
    this._bottomSheet.open(MyvyapaariChatComponent, {
      panelClass: 'custom-width'
    });
  }
  routeTo(rt: string) {
    try {
      const routeUrl = rt
      const buttonName = rt
      this.authService.clikmtrik(routeUrl, buttonName)
      this.router.navigate([`${rt}`])
    }
    catch (e) {
      throw e
    }
  }
}
