import { Component, Input, OnInit } from '@angular/core';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-flvarietycard',
  templateUrl: './flvarietycard.component.html',
  styleUrls: ['./flvarietycard.component.css']
})
export class FlvarietycardComponent implements OnInit {
  @Input() sendFeaturedList: ListingModel
  isShowPrice: boolean = false
  userIsAuthenticated = false;
  constructor(
    private auS: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  showPrice() {
    this.userIsAuthenticated = this.auS.getIsAuth();
    this.userIsAuthenticated ? this.isShowPrice = true : this.errorForm("Login to see item price")
    const routeUrl = this.router.url
    const buttonName = "Show Price"
    this.auS.clikmtrik(routeUrl, buttonName)
  }

  errorForm(err: string) {
    const action = "Login"
    try {
      let snackRef = this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackRef.onAction().subscribe(() => {
        this.router.navigate([`/login`])
      })
    }
    catch (e) {
      throw e
    }
  }
}
