import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { TnCModel } from 'src/app/datamodels/groupedmodel/tncsModel';

@Injectable({
  providedIn: 'root'
})
export class HandleUserComplService {
  private domain: string | undefined
  constructor(private http: HttpClient,
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  async gstUploadCompl(selectedFile: File): Promise<any> {
    const formData = new FormData();
    formData.append('gsf', selectedFile);
    const uploadGST = await this.http.post<any>(`${this.domain}api/user/upGSTdoc/`, formData).toPromise()
    return uploadGST
  }
}
