<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Keep your profile up to date</h1>
            <h2> Grow your Business </h2>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="contentUserInfo">
    <mat-card>
        <div class="verifiedID" *ngIf="!this.gstDetailsPending && !this.gstVerificationPending">
            <p>Verified
            </p>
        </div>
        <div class="unverifiedID" *ngIf="!this.gstDetailsPending && this.gstVerificationPending">
            <h5>Verification Pending</h5>
            <h6>KYC status will be updated soon</h6>
        </div>
        <div class="unverifiedID" *ngIf="this.gstDetailsPending && this.gstVerificationPending">
            <h6>KYC Pending! Update it in My Profile from the menu.</h6>
            <h6>Our Team is checking your details.</h6>
            <h6>Email us at solutions@myapaar.com</h6>
        </div>
        <!-- <app-teams-page [passGstId]="this.passIds"></app-teams-page> -->
        <form [formGroup]="userInfoForm" (ngSubmit)="changeProfileInfo()" *ngIf="!isLoading">
            <div class="formDetails">
                <div class="personalDetails">
                    <div class="infoDetails">
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="firstname" formControlName="firstname"
                                placeholder="First Name" required>
                            <mat-error *ngIf="formErrors.firstname">{{formErrors.firstname}}</mat-error>
                            <mat-hint>Jack</mat-hint>
                        </mat-form-field>
                        <div class="phoneRow">
                            <div class="mailBlock" *ngIf="this.userCC">
                                <span>{{this.userCC.code }} ({{this.userCC.dial_code }})</span>
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput type="tel" name="phone" formControlName="phone"
                                    placeholder="Phone Number" pattern="[0-9]*" maxlength="10" required>
                                <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                                <mat-hint>98xxxxxxx9</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="profileImages">
                        <div id="logoBusiness">
                            <div id="logoButton">
                                <button class="logoaddButton" *ngIf="!this.isLogoImageAdded" mat-stroked-button
                                    type="button" (click)="filePickerLogo.click()">
                                    Add Business Logo
                                </button>
                                <mat-icon class="changeIcon" *ngIf="this.isLogoImageAdded" mat-raised-button
                                    (click)="filePickerLogo.click()">replay</mat-icon>
                                <div class="logoImagePresent" *ngIf="this.isLogoImageAdded">
                                    <img [src]="this.logoImagePreview" alt="Avatar">
                                </div>
                                <input type="file" #filePickerLogo (change)="onBrandingImagePicked($event,'logoImage')">
                            </div>
                        </div>

                        <mat-hint>Your Logo is the face of your Business</mat-hint>
                        <div id="shopPhoto">
                            <div id="shopButton">
                                <button class="shopaddButton" *ngIf="!this.isShopImageAdded" mat-stroked-button
                                    type="button" (click)="filePickerShop.click()">
                                    Add Outlet Photo
                                </button>
                                <mat-icon class="changeIcon" *ngIf="this.isShopImageAdded" mat-raised-button
                                    (click)="filePickerShop.click()">replay</mat-icon>
                                <div class="shopImagePresent" *ngIf="this.isShopImageAdded">
                                    <img [src]="this.shopImagePreview" alt="Avatar">
                                </div>
                                <input type="file" #filePickerShop (change)="onBrandingImagePicked($event,'shopImage')">
                            </div>
                        </div>
                        <mat-hint>Showcase your Factory, Farm, Shop and Stores</mat-hint>
                    </div>
                    <mat-hint class="loadingImage" *ngIf="this.isImageUploading">Uploading Image</mat-hint>
                </div>

                <div class="conditionalRequired">
                    <div *ngIf="this.currentMail && this.currentMail != '' " class="mailBlock">
                        <span class="mailCurrent">{{this.currentMail}}</span>
                        <mat-hint class="mailHint">Current Email</mat-hint>
                    </div>

                    <mat-form-field *ngIf="!this.skipPageForGST" appearance="outline">
                        <input matInput type="text" name="GST" formControlName="GST_Id" placeholder="GST Number"
                            maxlength="15">
                        <mat-error *ngIf="formErrors.GST_Id">{{formErrors.GST_Id}}</mat-error>
                        <mat-hint>GST Verification</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="companyName" formControlName="companyName"
                            placeholder="Company Registered Name">
                        <mat-error *ngIf="formErrors.companyName">{{formErrors.companyName}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>PIN Code</mat-label>
                        <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                            formControlName="pinCode" autocomplete="off" />
                        <mat-error *ngIf="formErrors.pinCode">{{formErrors.pinCode}}</mat-error>
                        <mat-hint>226020</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="stateName" name="state" formControlName="state"
                            placeholder="State">
                        <mat-error *ngIf="formErrors.state">{{formErrors.state}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="city" [(ngModel)]="districtName" formControlName="city"
                            placeholder="City">
                        <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Locality</mat-label>
                        <input matInput placeholder="Search" [matAutocomplete]="auto"
                            formControlName="addressLocality" />
                        <mat-autocomplete #auto="matAutocomplete">
                            <ng-container>
                                <mat-option *ngFor="let locality of this.pinLocalities" [value]="locality || null">
                                    <span>{{locality}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                        <mat-error *ngIf="formErrors.addressLocality">{{formErrors.addressLocality}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Building/Shop Name</mat-label>
                        <input matInput formControlName="addressBuildingNameAndFloor" autocomplete="off" />
                        <mat-hint>Name of your business</mat-hint>
                        <mat-error
                            *ngIf="formErrors.addressBuildingNameAndFloor">{{formErrors.addressBuildingNameAndFloor}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Landmark</mat-label>
                        <input matInput formControlName="landmark" autocomplete="off" />
                        <mat-hint>Near by place</mat-hint>
                        <mat-error *ngIf="formErrors.landmark">{{formErrors.landmark}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <button class="submit-button" mat-raised-button type="submit">Update
            </button>
        </form>
        <div *ngIf="!this.skipPageForGST" class="disclaimerNote">
            <p>Changing GST ID will require KYC by our team</p>
        </div>

    </mat-card>
</div>