<div class="profCard">
    <div class="bannerSCentral">
        <div class="shopbanner">
            <div class="backgroundBanner">
                <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
            </div>
            <div class="shopimg sec" *ngIf="this.sendProfCard.isGstVerified">
                <div class="profileActions settingsButton">
                    <div class="ln circle-link">
                    </div>
                </div>
            </div>
            <div class="logoImg">
                <img *ngIf="!this.isUserLogoPresent" src="../../../../../assets/accountTypes/myp-user.svg" />
                <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
            </div>
        </div>
    </div>
    <div class="profileInvite">
        <div class="upInv">
            <p class="link pname">{{this.sendProfCard.name}}</p>
            <p class="link bname">{{this.sendProfCard.businessName}}</p>
        </div>
        <span class="link subtext"> Wants to Link </span>
    </div>
</div>
<div class="actionButton">
    <button class="cancel" (click)="this.cnclPendlinkreq(this.sendProfCard.usid)" mat-stroked-button>
        <mat-icon>close</mat-icon></button>
    <button class="accept" mat-stroked-button (click)="this.accPendlinkreq(this.sendProfCard.usid)">
        <mat-icon>check</mat-icon></button>
</div>