import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-all-user-issues',
  templateUrl: './all-user-issues.component.html',
  styleUrls: ['./all-user-issues.component.css']
})
export class AllUserIssuesComponent implements OnInit {
  private loginUserInfo: any
  isLoading: boolean = false
  currentUserId: string;
  viewIssues: RaiseDisputeModel[] = []
  routeUrl: string;
  useEmailReferral: string;
  constructor(
    private authService: AuthService,
    private utilS: UtilsService,
    private router: Router,
    private rps: ReportProblemService
  ) { }

  ngOnInit(): void {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.loadAllMyIssues(this.currentUserId)
    this.routeUrl = this.router.url
    this.useEmailReferral = `mailto:?subject= Join Myapaar Merchant Network with me&body=Hey, I started using Myapaar Merchant Network to grow my business and reach, and it is awesome! Link with merchants and also business owners from India and worldwide for Food Items trading. Membership is free, just Sign Up! Let’s grow together.`
  }

  loadAllMyIssues(userID: string) {
    try {
      this.isLoading = true
      this.rps.getAllMyIssues(userID).then(allIssues => {
        this.isLoading = false
        if (allIssues && allIssues.status === 200) {
          this.viewIssues = allIssues.issues
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  shareWhatsapp() {
    try {
      const buttonName = "Open My Whatsapp Referral"
      this.authService.clikmtrik(this.routeUrl, buttonName)
      this.utilS.openMyWhapp()
    }
    catch (e) {
      throw e
    }
  }
}
