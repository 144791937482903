<div class="bannerData">
    <div routerLink="/viewItems/All" class="image">
        <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
        <div class="img-slider">
            <div class="slide">
                <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000"
                    color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true"
                    [useKeyboard]="true" [maintainAspectRatio]="false" orientation="ltr">
                    <mat-carousel-slide *ngFor="let slide of this.appBannerslandingproducts; let i = index"
                        [image]="slide" overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                </mat-carousel>
            </div>
        </div>
        <div class="viewProductbutton">
            <div class="viewall" routerLink="/viewItems/All">
                {{this.selectedOBJ.quote | translate}} <br>
                <!-- ~{{this.selectedOBJ.quotedBy | translate}} -->
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div *ngFor="let item of [].constructor(2); let i = index" class="loadSkeleton productsOverlay">
            <div *ngFor="let item of [].constructor(4); let i = index" class="singleCards">
                <div class="shine-splash"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="productsOverlay">
            <app-productcard *ngFor="let product of this.allnonfeaturedproducts | slice:4:8; let i=index"
                [getProd1]="product"></app-productcard>
        </div>
        <div class="productsOverlay">
            <app-productcard *ngFor="let product of this.allnonfeaturedproducts | slice:0:4; let i=index"
                [getProd1]="product"></app-productcard>
        </div>
    </div>
</div>