<div class="sticky-div">
  <div *ngFor="let itms of this.loadFooterOps">
    <div (click)="routeTo(itms.route)" class="footerAct">
      <img [src]="itms.src" class="svg-icon" [alt]="itms.action">
      <mat-hint>{{itms.shortWord}}</mat-hint>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="aboutApp">
    <p>{{ 'sourcing & procurement made easy' | translate }}</p>
    <p>{{ 'manufacturers - wholesalers - retailers' | translate }}</p>
    <div class="icons">
      <div class="singleIcon">
        <mat-icon>favorite</mat-icon>
        <p>{{ 'quality products' | translate }}</p>
      </div>
      <div class="singleIcon">
        <mat-icon>supervisor_account</mat-icon>
        <p>{{ 'verified suppliers' | translate }}</p>
      </div>
      <div class="singleIcon">
        <mat-icon>local_shipping</mat-icon>
        <p>{{ 'vaayu delivery' | translate }}</p>
      </div>
      <div class="singleIcon">
        <mat-icon>store</mat-icon>
        <p>{{ 'bulk orders' | translate }}</p>
      </div>
      <div class="singleIcon">
        <mat-icon>assessment</mat-icon>
        <p>{{ 'merchant membership' | translate }}</p>
      </div>
    </div>
    <div>
      <p>
        {{ 'and much more' | translate }} <mat-icon>done_all</mat-icon>
      </p>
      <p class="highlight">
        {{ 'global merchant network' | translate }}
      </p>
    </div>
  </div>
</footer>

<div class="col5">
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4 class="headingFooter">{{ 'myapaar' | translate }}</h4>
          <div class="quickLinks">
            <a routerLink="/AboutMyapaar" mat-stroked-button class="quickLinksButtons about">
              {{ 'about us' | translate }}</a>
            <a mat-stroked-button target="_blank" rel="noopener" href="https://allindusroots.in/"
              class="quickLinksButtons allindusroots"> {{ 'all indus roots llp' | translate }}</a>
            <a routerLink="/Blogs/Published" mat-stroked-button class="quickLinksButtons">
              {{ 'blogs' | translate }}</a>
            <a routerLink="/FAQs" mat-stroked-button class="quickLinksButtons faqs"> {{ 'faqs' | translate }}</a>
            <a mat-stroked-button class="quickLinksButtons email" target="_blank" rel="noopener"
              href="mailto:solutions@myapaar.com">{{ 'contact' | translate }}</a>
            <a routerLink="/TermsToAgree" mat-stroked-button class="quickLinksButtons">
              {{ 'terms & conditions' | translate }}</a>
          </div>
        </div>
        <div class="footer-col">
          <h4 class="headingFooter disclaimer">{{ 'note' | translate }}</h4>
          <div>
            <p class="footerNote">
              {{ 'myapaar app registration for merchants is free until december 2025 with no hidden fees. we only
              request gst id, pan id, and company address for kyc. beware of unauthorized solicitations demanding
              payments. trust and security are our priorities.' | translate }}
            </p>
          </div>
        </div>
        <div class="footer-col">
          <h4 class="headingFooter">{{ 'follow us' | translate }}</h4>
          <div class="footerNote">Copyright © 2024-2025
            <span>{{ 'myapaar' | translate }}</span>, {{ 'platform of' | translate }}
            <span>{{ 'all indus roots llp' | translate }}</span> <br>
            <span>{{ 'all rights reserved.' | translate }}</span> <br>
          </div>

          <div class="social-links">
            <div class="fb">
              <a class="circle-link" title="facebook" href="https://www.facebook.com/profile.php?id=61553258164931"
                target="_blank" rel="noopener">
                <img id="fbmypr" alt="{{ 'facebook myapaar' | translate }}"
                  src="../../../assets/svgiconsandimages/facebook.svg" style="width:100%;">
              </a>
            </div>
            <div class="ln">
              <a class="circle-link" title="linkedin" href="https://www.linkedin.com/company/myapaar/" target="_blank"
                rel="noopener">
                <img id="linmypr" alt="{{ 'linkedin myapaar' | translate }}"
                  src="../../../assets/svgiconsandimages/linkedin.svg" style="width:100%;">
              </a>
            </div>
            <div class="ig">
              <a class="circle-link" title="instagram" href="https://www.instagram.com/myapaar_/" target="_blank"
                rel="noopener">
                <img id="IGmypr" alt="{{ 'instagram myapaar' | translate }}"
                  src="../../../assets/svgiconsandimages/instagram.svg" style="width:91%;">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>