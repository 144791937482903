<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Invoice Bill </h1>
            <h3>Pay Invoices timely to improve Ratings</h3>
            <h5>Payment Verification... {{timeLeft}} Seconds</h5>
        </div><mat-spinner></mat-spinner>
    </div>
</div>
<div class="contentInvoice">
    <div
        *ngIf="this.currentInvoiceOrderwithId && this.linkedPurchaseOrderwithId && !isLoading && !this.isPaymentWindow">
        <div class="userVerification">
            <div *ngIf="this.currentInvoiceOrderwithId.modePickup.isBuyerPickup" class="unverifiedID">
                <span>Buyer Self Pickup</span>
            </div>
            <div *ngIf="this.currentInvoiceOrderwithId.modePickup.isVaayuPickup" class="verifiedID">
                <span>Myapaar Vaayu Delivery</span>
            </div>
        </div>
        <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Active'" class=" action">

            <div class="buttonset">
                <div *ngIf="this.sellerAccountType &&  this.isUserActingSeller" class="act download">
                    <a href="{{this.currentInvoiceOrderwithId.s3InvoicepdfLink}}" target="_blank"
                        rel="noopener noreferrer" mat-raised-button
                        style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
                <div class="act lpo">
                    <button [routerLink]="['/viewPurchaseOrder',this.currentInvoiceOrderwithId.purchaseOrderID]"
                        mat-raised-button style=" background-color:
                        rgb(153, 106, 5); font-weight: 450;color: white;">
                        <span>Linked Purchase Order</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">
                <div *ngIf="this.sellerAccountType &&  this.isUserActingSeller" class="act cancel">
                    <button *ngIf="this.lastActiveVersionActions" mat-raised-button
                        style="background-color: rgb(193, 21, 21); font-weight: 450;color: white;"
                        (click)="cancelInvoiceOrderStatus(this.currentInvoiceOrderwithId)">
                        <span>Cancel Order</span>
                    </button>
                    <div class="cannotCancel" *ngIf="!this.lastActiveVersionActions">
                        <span>Cannot Cancel Old Version </span>
                    </div>
                </div>
                <div class="act pay">
                    <button [routerLink]=" ['/reportINVproblem',this.currentInvoiceOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Shipped'" class=" action">

            <!-- if invo is active and not yet paid
            seller can - download inv , viewallInvos, cancelInvo, viewLinkedPO
            buyer can - download inv, viewAllInvos,raiseDispute, viewLinkedPO -->
            <div class="buttonset">
                <div *ngIf="this.sellerAccountType &&  this.isUserActingSeller" class="act download">
                    <a href="{{this.currentInvoiceOrderwithId.s3InvoicepdfLink}}" target="_blank"
                        rel="noopener noreferrer" mat-raised-button
                        style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
                <div class="act lpo">
                    <button [routerLink]="['/viewPurchaseOrder',this.currentInvoiceOrderwithId.purchaseOrderID]"
                        mat-raised-button style=" background-color:
                        rgb(153, 106, 5); font-weight: 450;color: white;">
                        <span>Linked Purchase Order</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="/viewAllInvoices" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Invoice Orders</span>
                    </button>
                </div>
                <!-- shipped items cannot be cancelled - either by seller or buyer  -->
                <div class="act dispute">
                    <button [routerLink]=" ['/reportINVproblem',this.currentInvoiceOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Paid'" class=" action">
            <!-- Delivered PO cannot have download Invoice, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <!-- for a seller-asBuyer  , delivered PO will have download Invoice , download PO, viewAllPO, raiseDisputeButtons -->
            <!-- for a seller-asSeller  , delivered PO will have download Invoice , download PO,generate Invoice, viewAllPO, raiseDisputeButtons -->
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentInvoiceOrderwithId.s3InvoicepdfLink}}" target="_blank"
                        rel="noopener noreferrer" mat-raised-button
                        style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>

                <div class="act lpo">
                    <button [routerLink]="['/viewPurchaseOrder',this.currentInvoiceOrderwithId.purchaseOrderID]"
                        mat-raised-button style=" background-color:
                        rgb(153, 106, 5); font-weight: 450;color: white;">
                        <span>Linked Purchase Order</span>
                    </button>
                </div>
            </div>

            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="/viewAllInvoices" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Invoice Orders</span>
                    </button>
                </div>
                <div class="act dispute">
                    <button [routerLink]=" ['/reportINVproblem',this.currentInvoiceOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>

        </div>

        <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Cancelled'" class=" action">
            <!-- Cancelled PO cannot have download Invoice, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <div class="buttonset">
                <button [routerLink]="['/viewPurchaseOrder',this.currentInvoiceOrderwithId.purchaseOrderID]"
                    mat-raised-button style=" background-color:
                rgb(153, 106, 5); font-weight: 450;color: white;">
                    <span>Linked Purchase Order</span>
                </button>
            </div>
            <div class="buttonset">
                <div class="act allShow">
                    <button routerLink="/viewAllInvoices" mat-raised-button
                        style="background-color: rgba(151, 150, 150, 0.733); font-weight: 450;color: white;">
                        <span>All Invoice Orders</span>
                    </button>
                </div>
                <div class="act dispute">
                    <button [routerLink]=" ['/reportINVproblem',this.currentInvoiceOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Delivered'" class=" action">
            <!-- Cancelled PO cannot have download Invoice, generate Inv or cancel buttons, just download PO and allPOs buttons -->
            <div class="buttonset">
                <div class="act download">
                    <a href="{{this.currentInvoiceOrderwithId.s3InvoicepdfLink}}" target="_blank"
                        rel="noopener noreferrer" mat-raised-button
                        style="background-color: rgb(35, 130, 246); font-weight: 450;color: white;">
                        <span>Download</span>
                    </a>
                </div>
                <button [routerLink]="['/viewPurchaseOrder',this.currentInvoiceOrderwithId.purchaseOrderID]"
                    mat-raised-button style=" background-color:
                rgb(153, 106, 5); font-weight: 450;color: white;">
                    <span>Linked Purchase Order</span>
                </button>
            </div>
            <div class="buttonset">
                <div class="act allShow">
                    <button [routerLink]=" ['/reportINVproblem',this.currentInvoiceOrderwithId._id]" mat-raised-button
                        style="background-color: #171717; font-weight: 450;color: white;">
                        <span>Report Problem</span>
                    </button>
                </div>
                <div class="act dispute">
                    <div *ngIf="this.sellerAccountType &&  this.isUserActingBuyer || this.buyerAccountType"
                        class="act pay">
                        <button (click)="this.payInvoCreateReciept()" mat-raised-button
                            style="background-color: rgb(195, 6, 113); font-weight: 450;color: white;">
                            <span>Pay Invoice Amount</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>



        <div class="otherVers">
            <!-- update invoiceVersions -->
            <div class="row numandam" *ngIf="this.linkedPurchaseOrderwithId.invoiceVersions.length">
                <div class="col numandam">Other Invoice Versions</div>
                <div class="col numandam" *ngFor="let invoice of this.linkedPurchaseOrderwithId.invoiceVersions">
                    <div *ngIf="invoice.invoiceNumber !== this.currentInvoiceOrderwithId.invoiceNumber"
                        (click)="changeInvoiceVersion(invoice)" class="valueUP invNo" style="color: rgb(54, 84, 253);">
                        <button mat-stroked-button> V{{invoice.version}}</button>
                    </div>
                    <span *ngIf=" invoice.invoiceNumber===this.currentInvoiceOrderwithId.invoiceNumber"
                        class="valueUP invNo" style="color: rgb(42, 43, 49); text-decoration: underline;"> <button
                            mat-stroked-button>V{{invoice.version}}</button> </span>
                </div>
            </div>
        </div>
        <div class="issuesFound" *ngIf="this.issueForPOExist">
            <button routerLink="/viewIssues" mat-stroked-button color="primary" class="issueCount">
                {{this.issuesFound.length}} Issues Exist
            </button>
        </div>
        <div class="title">
            <div class="center" *ngIf="this.currentInvoiceOrderwithId.modePickup && this.currentInvoiceOrderwithId.invoiceStatus==='Active'
                && this.currentUserId == this.currentInvoiceOrderwithId.sellerDetails._id">
                <mat-form-field appearance="outline">
                    <input [(ngModel)]="this.pickUpOtp" matInput type="string" name="phone" placeholder="Pickup OTP">
                </mat-form-field>
                <button [disabled]="!this.pickUpOtp || this.pickUpOtp.length < 4"
                    *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Active' && this.isUserActingSeller && this.allowShipping "
                    class="shipItems" mat-stroked-button
                    (click)="itemsShipped(this.currentInvoiceOrderwithId._id,this.currentInvoiceOrderwithId.invoiceNumber)">
                    Pickup Complete
                    <mat-icon class="shipIcon">local_shipping</mat-icon>
                </button>
            </div>

            <button *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Delivered' && this.isUserActingSeller "
                class="remindBuyer" mat-stroked-button
                (click)="remindBuyer(this.currentInvoiceOrderwithId._id,this.currentInvoiceOrderwithId.invoiceNumber)">Payment
                Reminder

                <mat-icon class="remindIcon">
                    contact_phone</mat-icon>
            </button>
        </div>
        <div class="currentInvoiceOrder">
            <div class="sec invoDetails">
                <div>
                    <p class="key POI">
                        Invoice Order ID/Number
                    </p>
                    <p class="value-L POI">
                        {{this.currentInvoiceOrderwithId.invoiceNumber}}
                    </p>
                </div>
                <div>
                    <p class="key POS"> Status</p>
                    <p class="value-R POS">
                        <span style="color: rgb(99, 99, 99);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='In Freight Review'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                        </span>
                        <span style="color: rgb(200, 7, 7);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Cancelled'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                        </span>
                        <span style="color: rgb(1, 142, 46);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Active'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                            <span *ngIf="this.currentUserId == this.currentInvoiceOrderwithId.buyerDetails.buyerId">
                                | Pickup OTP
                                {{this.currentInvoiceOrderwithId.modePickup.pickUpOtp}}</span>
                        </span>
                        <span style="color: rgb(0, 34, 229);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Paid'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                            <span *ngIf="this.currentInvoiceOrderwithId.dateOfINVPaid">on
                                {{this.currentInvoiceOrderwithId.dateOfINVPaid | date}}</span>
                        </span>
                        <span style="color: rgb(179, 173, 2);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Shipped'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                            <span *ngIf="this.sellerAccountType &&  this.isUserActingBuyer || this.buyerAccountType">
                                | Delivery OTP {{this.currentInvoiceOrderwithId.invoiceDeliveryOTP}}
                            </span>
                        </span>

                        <span style="color: rgb(167, 3, 136);"
                            *ngIf="this.currentInvoiceOrderwithId.invoiceStatus==='Delivered'">
                            {{this.currentInvoiceOrderwithId.invoiceStatus}}
                        </span>
                    </p>
                </div>
            </div>
            <div class="sec invoValueAmount">
                <div>
                    <p class="key POP">Invoice Amount</p>
                    <p class="value-L POP">
                        {{(this.currentInvoiceOrderwithId.invoiceRaiseValue).toLocaleString('en-IN')}} ₹</p>
                </div>
                <div>
                    <p class="key POQ">Next Invoice Amount</p>
                    <p class="value-R POQ">
                        <button class="value-R POQ" mat-stroked-button>
                            <mat-icon
                                *ngIf="this.sellerAccountType &&  this.isUserActingSeller && this.allowMoreInvoices"
                                class="createMoreInvoices"
                                [routerLink]=" ['/createInvoice',this.currentInvoiceOrderwithId.purchaseOrderID]">add_circle_outline</mat-icon>
                            {{(this.currentInvoiceOrderwithId.nextInvoiceBalanceDueValue).toLocaleString('en-IN')}} ₹
                        </button>
                    </p>
                </div>
            </div>
            <div class="sec invoValueAmount">
                <div>
                    <p class="key POP">Remainder amount to pay on current invoice</p>
                    <p class="value-L POP">
                        {{(this.currentInvoiceOrderwithId.amountRemainingToPayOnCurrentInvoice).toLocaleString('en-IN')}}
                        ₹</p>
                </div>
                <div>
                    <p class="key invS">Amount already paid at checkout</p>
                    <p class="value-R invS">
                        {{(this.currentInvoiceOrderwithId.invoicedItemsAmtPaidAtCheckout).toLocaleString('en-IN')}} ₹
                    </p>
                </div>
            </div>
            <div class=" sec invoDates">
                <div>
                    <p class="key PODG">Invoice Created Date</p>
                    <p class="value-L PODG"> {{this.currentInvoiceOrderwithId.dateOfINVGenerated
                        | date}}</p>
                </div>
                <div *ngIf="this.currentInvoiceOrderwithId.invoiceStatus !== 'Paid'">
                    <p class="key PODE">Payment Due in</p>
                    <p class="value-R PODE"> {{this.currentInvoiceOrderwithId.deadlineDays}} Days</p>
                </div>
            </div>
            <div class="sec invoices">
                <div>
                    <p class="key invS">P.O Status</p>
                    <p class="value-L invS">{{this.linkedPurchaseOrderwithId.poStatus}}</p>
                </div>
                <div>
                    <p class="key invS">P.O Amount</p>
                    <p class="value-R invS">
                        {{(this.linkedPurchaseOrderwithId.orderValueIncludingTax).toLocaleString('en-IN')}} ₹</p>
                </div>

            </div>

            <mat-divider> </mat-divider>
            <br>
            <div class="sec addressDetails">

                <div class="vendor_buy_address_details">
                    <div class="vendorDetail">
                        <p class="headingL sellerAddress">Seller Details &nbsp;&nbsp;
                        </p>
                        <span class="highlightL businessName">{{this.linkedPurchaseOrderwithId.sellerDetails.companyName
                            }} </span>
                        <span class="value-L sellerName">{{this.linkedPurchaseOrderwithId.sellerDetails.firstname}}
                            {{this.linkedPurchaseOrderwithId.sellerDetails.lastname}} </span>
                        <span class="value-L addressBuildingINfo ">
                            {{this.linkedPurchaseOrderwithId.sellerDetails.addressBuildingNameAndFloor
                            }}</span>
                        <!-- <span class="value-L sellerPhone">{{this.linkedPurchaseOrderwithId.sellerDetails.phone
                            }}</span> -->
                        <span class="value-L sellerEmail">{{this.linkedPurchaseOrderwithId.sellerDetails.email
                            }}</span>
                        <div class="value-L sellerStreetPincode">
                            <span> {{this.linkedPurchaseOrderwithId.sellerDetails.addressLocality}}&nbsp;</span>
                            <span> {{this.linkedPurchaseOrderwithId.sellerDetails.pinCode}}&nbsp;</span>
                        </div>
                        <div class="value-L sellersCityState">
                            <span> {{this.linkedPurchaseOrderwithId.sellerDetails.city}}&nbsp;</span>
                            <span> {{this.linkedPurchaseOrderwithId.sellerDetails.state}} </span>
                        </div>
                        <div *ngIf="this.linkedPurchaseOrderwithId.sellerDetails.isGSTVerified" class="sellerGSTVerify">
                            <p class="value-L sellerGST">{{this.linkedPurchaseOrderwithId.sellerDetails.GST_Id}}</p>
                            <p class="value-L sellerGST"> GST Verified <mat-icon>check_small</mat-icon></p>
                        </div>
                        <div *ngIf="!this.linkedPurchaseOrderwithId.sellerDetails.isGSTVerified"
                            class="value-L sellerGSTVerify">
                            <p> GST Verification Pending <mat-icon>error_outline_small</mat-icon></p>
                        </div>
                    </div>
                    <div class="buyerDetail">
                        <div class="buyerAddressDetails">
                            <p class="headingR buyerAddress">Buyer Details</p>
                            <span class="highlightR businessName">
                                {{this.linkedPurchaseOrderwithId.buyerDetails.buyerCompanyName
                                }}
                            </span>
                            <span
                                class="value-R buyerName">{{this.linkedPurchaseOrderwithId.buyerDetails.buyerName}}</span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.linkedPurchaseOrderwithId.buyerDetails.buyerAddress.buildingName
                                }}</span>
                            <!-- <span class="value-R buyerPhone">
                                {{this.linkedPurchaseOrderwithId.buyerDetails.buyerContactDetails.phone
                                }}</span> -->
                            <span class="value-R buyerEmail">
                                {{this.linkedPurchaseOrderwithId.buyerDetails.buyerContactDetails.email
                                }}</span>
                            <div class="value-R buyerStreetPincode">
                                <span>
                                    {{this.linkedPurchaseOrderwithId.buyerDetails.buyerAddress.streetName}},&nbsp;</span>
                                <span>{{this.linkedPurchaseOrderwithId.buyerDetails.buyerAddress.pinCode}}</span>
                            </div>
                            <div class="value-R buyerCityState">
                                <span class="value-R buyerCity">
                                    {{this.linkedPurchaseOrderwithId.buyerDetails.buyerAddress.city}},&nbsp;</span>
                                <span class="value-R buyerState">
                                    {{this.linkedPurchaseOrderwithId.buyerDetails.buyerAddress.state }}</span>
                            </div>
                        </div>
                        <div class="shipToAddress">
                            <span class="headingR shipToAddress">Deliver To Address</span>
                            <span
                                class="highlightR shipToName">{{this.linkedPurchaseOrderwithId.buyerDetails.buyerName}}
                            </span>
                            <span class="value-R addressBuildingINfo ">
                                {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.buildingName}}</span>
                            <div class="value-R shipToStreetPincode">
                                <span>
                                    {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.streetName}},&nbsp;</span>
                                <span> {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.pinCode}}</span>
                            </div>
                            <div class="value-R shipToCityState">
                                <span> {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.city}},&nbsp;</span>
                                <span> {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.state}} </span>
                            </div>
                            <span class="value-R shipToLandmark">
                                {{this.linkedPurchaseOrderwithId.shipToBuyerLocation.landMark}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sec itemDetails">
                <div class="Items">
                    <h1>Item Details</h1>
                    <div class="item" *ngFor="let item of this.itemDetailsofINVO">
                        <div class="itemDescription">
                            <h2 class="itemTitle">{{item.itemTitle}}</h2>
                            <div class="itemDet">
                                <div class="details">
                                    <div class="value itemProdwithVar" *ngIf="item.productVariety!==''">
                                        {{item.productCategory}} |
                                        {{item.productName}} |
                                        {{item.productVariety}}
                                    </div>
                                    <div class="value itemProdnoVar" *ngIf="item.productVariety===''">
                                        {{item.productCategory}}
                                        |
                                        {{item.productName}}
                                    </div>
                                    <div class="value itemPackaging" *ngIf="item.optedPackagingType">
                                        {{item.optedPackagingType.packagingType}}
                                    </div>
                                </div>
                                <div class="packagingImg">
                                    <a class="packImg" [href]="item.optedPackagingType.packagingImg"
                                        target="_blank"><img [src]="item.optedPackagingType.packagingImg" /></a>
                                    <a class="packdesign" [href]="item.optedPackagingType.packagingImg"
                                        target="_blank">Packaging
                                        Design</a>
                                </div>
                            </div>
                            <div class="value itemProdwithVar" *ngIf="item.productVariety!==''">
                                {{item.productCategory}} |
                                {{item.productName}} |
                                {{item.productVariety}}
                            </div>
                            <div class="value itemProdnoVar" *ngIf="item.productVariety===''"> {{item.productCategory}}
                                |
                                {{item.productName}}
                            </div>
                            <div class="itmk orderQuant">Ordered Quantity
                                <span class="value orderQuant">{{item.itemQuantity}} {{item.useUnitShort}}</span>
                                <span class="value itemPriceUnit"> {{(item.itemPricePerUnit).toLocaleString('en-IN')}} ₹
                                    / {{item.useUnitShort}}</span>
                            </div>
                            <div class="itmk totalPriceOfItemOrdered">Total Price incl Taxes
                                <!-- <span class="value totalPriceOfItemOrdered">{{item.itemPricePerUnit*item.itemQuantity}} -->
                                <span
                                    class="value totalPriceOfItemOrdered">{{(item.itemPriceWithTaxes).toLocaleString('en-IN')}}

                                    ₹</span>
                            </div>
                        </div>
                        <mat-divider> </mat-divider>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>