<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="this.openPanel">
    <mat-toolbar>
      <span class="logoState">Mobile Se Vyapaar</span>
    </mat-toolbar>
    <!-- <div class="SellerAccount" *ngIf="userIsAuthenticated &&
this.loginUserInfo.accounttype=='Seller'">
        <button mat-raised-button class="nonfeatured-button" routerLink="/createListing">Post New Item</button> <br>
        <button mat-raised-button routerLink="/createListing">View Old Listings</button> <br>

    </div> -->
    <mat-nav-list>
      <div class="navOptions">
        <div class="currentLocationTab">
          <mat-form-field id="pincodeField" appearance="outline">
            <mat-label class="noPincode" *ngIf="!selectedPincode || selectedPincode === 'undefined'">Add
              Pincode</mat-label>
            <mat-label *ngIf="selectedPincode ">Pincode</mat-label>
            <input [(ngModel)]="selectedPincode" (ngModelChange)="getMyPincodeDetails()" matInput autocomplete="off" />
          </mat-form-field>
          <mat-form-field id="cityField" appearance="outline">
            <mat-label>City</mat-label>
            <input [(ngModel)]="districtName" disabled matInput autocomplete="off" required />
          </mat-form-field>
        </div>
        <!-- <a mat-list-item routerLink="/viewAllcategories">View Categories</a> -->
        <a mat-list-item [matMenuTriggerFor]="menu">Categories</a>
        <mat-menu #menu="matMenu">
          <div class="categories-menu" *ngFor="let category of this.productcategories">
            <button class="categories-menu-button" [routerLink]="['/viewItems',category.categoryName]"
              mat-menu-item>{{category.categoryName}}</button>
          </div>
        </mat-menu>

        <!-- <a mat-list-item routerLink="/viewAllPurchaseOrders">My Orders</a> -->
        <a mat-list-item routerLink="/ManageOrders">My Orders</a>
        <a mat-list-item routerLink="/viewListing"
          *ngIf="userIsAuthenticated && this.loginUserInfo?.accounttype=='Seller'">My Listings</a>
        <!-- #Hidden -->
        <!-- <a mat-list-item [routerLink]="['/viewBids',this.currentUserId]">My Bids</a> -->
        <!-- <a mat-list-item routerLink="/usercontactsecurity">Contact and Security</a> -->
        <!-- <a mat-list-item routerLink="/useraddresses">Addresses</a> -->
        <!-- <a mat-list-item routerLink="/userrequestedproductform">Request Items</a> -->
        <!-- <a mat-list-item routerLink="/usersavedproducts">Wishlist Items</a> -->
        <a *ngIf="userIsAuthenticated" mat-list-item
          [routerLink]="['/Bulk_Supply_On_Myapaar/View_Supplier_Profile/', this.currentUserId,this.businessName]">My
          Profile</a>
        <!-- <a mat-list-item [routerLink]="['/accountInformation', this.currentUserId]">Edit Profile</a> -->
        <!-- <a mat-list-item [routerLink]="['/accountInformation', this.currentUserId]">Edit Profile</a> -->
        <!-- <a mat-list-item routerLink="/farmgallery">Farm Gallery</a> -->
        <!-- <a mat-list-item routerLink="/userfarmvisitform">Plan Farm Visit</a> -->
        <a mat-list-item routerLink="/allIssues">Customer Support</a>
        <a mat-list-item *ngIf="userIsAuthenticated" class="logoutButton" (click)="onLogout()">Logout
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>


  <mat-sidenav-content>
    <mat-toolbar color="primary" class="position-fixed">
      <div class="toolbarIcons">
        <div class="leftIcons">
          <!-- <button id="backButton" mat-button></button> -->
          <mat-icon mat-icon-button *ngIf="!hasRoute('/')" class="backButtonIcon"
            appBackButton>keyboard_arrow_left</mat-icon>
          <mat-icon mat-icon-button class="menuButton" (click)="drawer.toggle()"
            aria-label="Side nav toggle icon">menu</mat-icon>
          <button mat-stroked-button routerLink="/home" class="homeButton">
            <img class="homeIcon" src="../../assets/svgiconsandimages/mainHome.png" />
          </button>
          <!-- #Hidden  -->
          <!-- <button mat-button class="bidsButton" [routerLink]="['/viewBids',this.currentUserId]"> Bids </button> -->
          <mat-icon mat-button routerLink="searchGlobal" *ngIf="!hasRoute('searchGlobal')"
            class="searchIcon">search</mat-icon>
        </div>
        <div class="rightIcons">
          <button [matBadge]="this.listingsCreatedBySeller" matBadgeOverlap="true" class="SellerAccount" mat-button
            routerLink="/createListing" *ngIf="userIsAuthenticated && this.loginUserInfo?.accounttype=='Seller'">
            + Listing
          </button>
          <button mat-button mat-button class="cartButton" routerLink="/cart">
            <div [matBadge]="this.cartItemsCount" matBadgeOverlap="true" [matBadgeHidden]="this.cartItemsCount < 1"
              class="demo-section">Contracts</div>
          </button>
          <!-- <button mat-button *ngIf="!userIsAuthenticated" class="loginButton" routerLink="/login">Login</button> -->
        </div>
      </div>
    </mat-toolbar>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-footer class="footerApp"></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>