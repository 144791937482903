import { Component, NgZone, OnInit } from '@angular/core';
import { UsersCartItem } from 'src/app/datamodels/cartModel';
import { InCartService } from 'src/app/services/in-cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { AddressBook } from 'src/app/datamodels/groupedmodel/addressBook';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { AllstatesService } from 'src/app/services/allstates.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdditionalCharges } from 'src/app/datamodels/groupedmodel/additionalCharges';
import { HandlePaymentsService } from 'src/app/services/handle-payments.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UtilsService } from 'src/app/services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.css']
})
export class CreatePurchaseOrderComponent implements OnInit {
  loginUserInfo: any
  mode: string;
  userId: string;
  isLoading: boolean = false;
  userInfoForm: FormGroup
  selectedPincode: string = '';
  pincodeFullInfo: any = [];
  pinLocalities;
  stateName: string;
  districtName: string;
  localityName: string;
  editAccountdata: any;
  purchaseOrderFromCart: PurchaseOrderModel;
  validationMessages = {
    'fullName': {
      'required': 'Full Name is required',
      'maxlength': 'Name cannot have more than 50 characters!'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
  }
  //js object to display form errors
  formErrors = {
    'fullName': 'Enter Full Name',
    'pinCode': 'Add Company Pincode',
    'state': 'Enter State',
    'city': 'Enter City',
    'addressLocality': 'Enter Company locality/area',
    'addressBuildingNameAndFloor': 'Enter Building name and Floor number',
    // 'addressLocality':'',
    'landmark': 'Enter nearby landmark',
    'phone': 'Add Phone number',
  }
  allItemIDsOfCart: string[];
  cartId: string;
  private currentUserId: string;
  userCart: UsersCartItem;
  generatedPOID: string;
  smallOrderChargesOfBuyer: AdditionalCharges;
  isThisASmallOrder: boolean = false
  interval: NodeJS.Timeout;
  timeLeft: number = 180;
  paymentWindow: Window;
  payNowCharges: number;
  additionalChargesOfBuyer: AdditionalCharges;
  useMerchantId: string;
  usedocId: string;
  usedayakid: string;
  usevyayak: number;
  isBuyerSelfPickup: boolean = false;
  totalAdvanceCandCarryAmount: number = 0;
  pay30AdvanceForCheckAndPay: number = 0;
  buyerShipmentFees: number;
  amtremainingForInvoices: number;
  isPremUser: boolean = false;
  activateDiscount: boolean = false;
  preserveAdditionalCharges: AdditionalCharges[];
  originalCharges: AdditionalCharges;
  newPayCharges: number;
  preservePayNowcharges: number;
  preservePOTotalCharges: number;
  displayPOTotalCharges: number;
  userHandlingCharges: number;
  isPaymentWindow: boolean = false;
  buyerPhone: number;
  displayTransportText: string = "Freight charges, company name, and carrier details will be updated on each invoice. Vaayu team will contact shortly for secure delivery."
  constructor(
    private stateService: AllstatesService,
    private authService: AuthService,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    public route: ActivatedRoute,
    private router: Router,
    private incartService: InCartService,
    private hpmnts: HandlePaymentsService,
    private utils: UtilsService,
    private zone: NgZone,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.userInfoForm = new FormGroup({
      fullName: new FormControl('', { validators: [Validators.required, Validators.maxLength(50)] }),
      pinCode: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(6)]
      }),
      state: new FormControl('', {
        validators: [Validators.required]
      }),
      city: new FormControl('', {
        validators: [Validators.required]
      }),
      addressLocality: new FormControl(''),
      addressBuildingNameAndFloor: new FormControl('', {
        validators: [Validators.required]
      }),
      landmark: new FormControl('', {
        validators: [Validators.required]
      }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      }),
    });
    try {
      this.loginUserInfo = this.authService.getUserLoginInfo();
      if (!this.loginUserInfo.phone) {
        this.errorOccured("Update Phone Number on profile for smooth experience", "Ok")
        this.router.navigate([`/accountInformation/${this.loginUserInfo.userId}`])
        return
      }
      this.currentUserId = this.loginUserInfo.userId
      this.handlePurchaseOrder.sharedBundledPOitems.subscribe((itemforPO: { purchaseOrderDetails: PurchaseOrderModel, allItemIDsOfCart: string[], cartId: string }) => {
        //go back to cart if ITEMPO gets undefined on reload. items need to be passed from cart and wont save on page reload
        !itemforPO || itemforPO === undefined && itemforPO === null ? this.router.navigate(['/cart']) :
          this.purchaseOrderFromCart = itemforPO.purchaseOrderDetails
        const allItemDetails = itemforPO.purchaseOrderDetails.itemDetails
        this.cartId = itemforPO.cartId
        this.allItemIDsOfCart = itemforPO.allItemIDsOfCart
        this.setFormValuesOnEdit()
        this.smallOrderChargesOfBuyer = this.purchaseOrderFromCart.additionalCharges.find(allcharges => {
          return allcharges.chargeType === 'Small Order'
        })
        this.smallOrderChargesOfBuyer && this.smallOrderChargesOfBuyer.amount && this.smallOrderChargesOfBuyer.amount !== 0 ?
          this.isThisASmallOrder = true : this.isThisASmallOrder = false
        const useBuyerHandlingCharges = this.purchaseOrderFromCart.additionalCharges.find(charge => {
          return charge.chargeType === 'Handling Fees'
        })
        this.userHandlingCharges = useBuyerHandlingCharges.amount
        const allBuyerCharges = this.purchaseOrderFromCart.additionalCharges.filter(allCharges => {
          return allCharges.account === 'Buyer'
        })
        const allItemsCashAndCarry = allItemDetails.filter(allItems => {
          return allItems.optedPaymentTerms.creditTermStatement === 'Cash and Carry'
        })
        const allItemsCheckAndPayOnDelivery = allItemDetails.filter(allItems => {
          return allItems.optedPaymentTerms.creditTermStatement === 'Check and Pay on delivery'
        })
        const totalCheckAndPayOnDeliveryAmount = allItemsCheckAndPayOnDelivery.reduce((sum, item) => sum + item.itemPriceWithTaxes, 0)
        this.totalAdvanceCandCarryAmount = allItemsCashAndCarry.reduce((sum, item) => sum + item.itemPriceWithTaxes, 0)
        this.buyerShipmentFees = allBuyerCharges.reduce((sum, item) => sum + item.amount, 0);
        this.pay30AdvanceForCheckAndPay = Number((totalCheckAndPayOnDeliveryAmount * (30 / 100)).toFixed(2))
        const payAmount = this.pay30AdvanceForCheckAndPay + this.totalAdvanceCandCarryAmount
        this.payNowCharges = Number(payAmount.toFixed(2))
        this.displayPOTotalCharges = this.purchaseOrderFromCart.orderValueIncludingTax
        this.preservePayNowcharges = this.payNowCharges
        this.preservePOTotalCharges = this.purchaseOrderFromCart.orderValueIncludingTax
        this.amtremainingForInvoices = this.purchaseOrderFromCart.orderValueIncludingTax - this.payNowCharges
        if (this.loginUserInfo?.isAccountPremium && this.payNowCharges <= 500) {
          this.activateDiscount = true
          const discountSampleOnPremium = this.payNowCharges
          this.payNowCharges = discountSampleOnPremium / 2
          this.amtremainingForInvoices = 0
        }
        // this.originalCharges = Object.assign({}, useBuyerHandlingCharges)
        this.additionalChargesOfBuyer = useBuyerHandlingCharges
      });
    }
    catch (e) {
      throw e
    }
  }
  toggleFees(event: MatSlideToggleChange, handlingAmt: number) {
    const checkIfBuyerPickup = event.checked
    if (checkIfBuyerPickup) {
      this.payNowCharges = this.preservePayNowcharges
      this.displayPOTotalCharges = this.preservePOTotalCharges
      this.displayTransportText = null
    }
    else if (!checkIfBuyerPickup) {
      this.payNowCharges = this.preservePayNowcharges
      this.displayTransportText = "Freight charges, company name, and carrier details will be updated on each invoice. Vaayu team will contact shortly for secure delivery."
    }
  }

  async addShippingAddress(
    // itemsForPurchaseOrder: PurchaseOrderModel
  ) {
    try {
      if (this.isBuyerSelfPickup) {
        this.purchaseOrderFromCart.modePickup.isBuyerPickup = true
        this.purchaseOrderFromCart.modePickup.isVaayuPickup = false
        this.purchaseOrderFromCart.additionalCharges[0].amount = 0
        this.purchaseOrderFromCart.additionalCharges[0].percentage = 0
        this.purchaseOrderFromCart.orderValueIncludingTax = this.purchaseOrderFromCart.orderValueIncludingTax - this.userHandlingCharges
      }
      else {
        this.purchaseOrderFromCart.modePickup.isVaayuPickup = true
        this.purchaseOrderFromCart.modePickup.isBuyerPickup = false
      }
    }
    catch (e) {
      throw e
    }
    this.isLoading = true
    try {
      if (this.userInfoForm.invalid) {
        this.isLoading = false
        return
      }
      let useBuyerPhone: number
      if (this.userInfoForm.valid) {
        typeof (this.userInfoForm.value.phone) == 'string' ? useBuyerPhone = Number(this.userInfoForm.value.phone) : useBuyerPhone = this.userInfoForm.value.phone
        const newShippingAddress: AddressBook = {
          buildingName: this.userInfoForm.value.addressBuildingNameAndFloor,
          city: this.userInfoForm.value.city,
          companyType: this.purchaseOrderFromCart.shipToBuyerLocation.companyType,
          landMark: this.userInfoForm.value.landmark,
          name: this.userInfoForm.value.fullName,
          phone: useBuyerPhone,
          pinCode: this.userInfoForm.value.pinCode,
          state: this.userInfoForm.value.state,
          streetName: this.userInfoForm.value.addressLocality,
        }
        this.purchaseOrderFromCart.shipToBuyerLocation = newShippingAddress
        this.addTaxSummary()
      }
    }
    catch (e) {
      throw e
    }
  }

  addTaxSummary() {
    const allItemTaxes = this.purchaseOrderFromCart.itemDetails.reduce((sum, item) => sum + item.itemTaxes, 0);
    const tcsCharge = this.purchaseOrderFromCart.additionalCharges.find(charges => { return charges.chargeType === 'TCS' }).amount
    try {
      const UTGST_states = [
        "jammu & kashmir",
        "jammu and kashmir",
        "andaman & nicobar islands",
        "andaman and nicobar islands",
        "dadra & nagar haveli",
        "dadra and nagar haveli",
        "daman & diu",
        "daman and diu",
        "chandigarh",
        "lakshadweep"
      ]

      // matchConditions 
      this.purchaseOrderFromCart.itemDetails.forEach(itemInPO => {
        let IGST: boolean = false;
        let CGST: boolean = false;
        let SGST: boolean = false;
        let intraUGST: boolean = false;
        let sellerInUT: boolean = false;
        let sellerInState: boolean = false;
        let buyerInUT: boolean = false;
        let buyerInState: boolean = false;
        //replacements of buyerAddress in itemDetails from shipping address
        itemInPO.taxSummaryTerms.buyerPincode = this.userInfoForm.value.pinCode;
        itemInPO.taxSummaryTerms.buyerState = this.userInfoForm.value.state;
        if (UTGST_states.includes(itemInPO.taxSummaryTerms.buyerState.toLocaleLowerCase())) {
          // buyer in UT
          buyerInUT = true
        }
        if (!UTGST_states.includes(itemInPO.taxSummaryTerms.buyerState.toLocaleLowerCase())) {
          // buyer in state
          buyerInState = true
        }
        if (UTGST_states.includes(itemInPO.taxSummaryTerms.sellerState.toLocaleLowerCase())) {
          // seller in UT
          sellerInUT = true
        }
        if (!UTGST_states.includes(itemInPO.taxSummaryTerms.sellerState.toLocaleLowerCase())) {
          // seller in state
          sellerInState = true
        }
        if (sellerInUT && buyerInUT) {
          // seller and buyer both in UT -> CGST + UGST
          CGST = true
          intraUGST = true
          SGST = false
          IGST = false
        }
        if ((sellerInUT && !buyerInUT) || (!sellerInUT && buyerInUT)) {
          // either seller or buyer in UT, and opposite party in state
          IGST = true
          CGST = false
          SGST = false
          intraUGST = false
        }
        if (!sellerInUT && !buyerInUT) {
          // neither seller nor buyer in UT, both belong to a state
          let sellerStateSameAsBuyerState: boolean = false
          let sellerStateDifferentThanBuyerstate: boolean = false
          if (itemInPO.taxSummaryTerms.buyerState.toLocaleLowerCase() !== itemInPO.taxSummaryTerms.sellerState.toLocaleLowerCase()) {
            // if state is different of buyer and seller
            sellerStateDifferentThanBuyerstate = true
          }
          if (itemInPO.taxSummaryTerms.buyerState.toLocaleLowerCase() === itemInPO.taxSummaryTerms.sellerState.toLocaleLowerCase()) {
            // if state is same of buyer and seller
            sellerStateSameAsBuyerState = true
          }
          if (sellerStateDifferentThanBuyerstate) {
            IGST = true
            CGST = false
            SGST = false
            intraUGST = false
          }
          if (sellerStateSameAsBuyerState) {
            CGST = true
            SGST = true
            IGST = false
            intraUGST = false
          }
        }
        if (intraUGST) {
          itemInPO.taxSummaryTerms.CGSTperc = `${(itemInPO.taxSummaryTerms.taxSlab / 2)} %`;
          itemInPO.taxSummaryTerms.UGSTperc = `${(itemInPO.taxSummaryTerms.taxSlab / 2)} %`;
          itemInPO.taxSummaryTerms.IGSTperc = '0';
          itemInPO.taxSummaryTerms.SGSTperc = '0';
          itemInPO.taxSummaryTerms.TCSperc = '1 %';
          itemInPO.taxSummaryTerms.CGSTamount = itemInPO.itemTaxes / 2;
          itemInPO.taxSummaryTerms.UGSTamount = itemInPO.itemTaxes / 2;
          itemInPO.taxSummaryTerms.IGSTamount = 0;
          itemInPO.taxSummaryTerms.SGSTamount = 0;
          itemInPO.taxSummaryTerms.TCSamount = tcsCharge / this.purchaseOrderFromCart.itemDetails.length;
        }
        if (IGST) {
          itemInPO.taxSummaryTerms.IGSTperc = `${itemInPO.taxSummaryTerms.taxSlab} %`;
          itemInPO.taxSummaryTerms.CGSTperc = '0';
          itemInPO.taxSummaryTerms.SGSTperc = '0';
          itemInPO.taxSummaryTerms.UGSTperc = '0';
          itemInPO.taxSummaryTerms.TCSperc = '1 %';
          itemInPO.taxSummaryTerms.IGSTamount = itemInPO.itemTaxes;
          itemInPO.taxSummaryTerms.CGSTamount = 0;
          itemInPO.taxSummaryTerms.SGSTamount = 0;
          itemInPO.taxSummaryTerms.UGSTamount = 0;
          itemInPO.taxSummaryTerms.TCSamount = tcsCharge / this.purchaseOrderFromCart.itemDetails.length;
        }
        if (CGST &&
          SGST) {
          itemInPO.taxSummaryTerms.CGSTperc = `${(itemInPO.taxSummaryTerms.taxSlab / 2)} %`;
          itemInPO.taxSummaryTerms.SGSTperc = `${(itemInPO.taxSummaryTerms.taxSlab / 2)} %`;
          itemInPO.taxSummaryTerms.IGSTperc = '0';
          itemInPO.taxSummaryTerms.UGSTperc = '0';
          itemInPO.taxSummaryTerms.TCSperc = '1 %';
          itemInPO.taxSummaryTerms.CGSTamount = itemInPO.itemTaxes / 2;
          itemInPO.taxSummaryTerms.SGSTamount = itemInPO.itemTaxes / 2;
          itemInPO.taxSummaryTerms.IGSTamount = 0;
          itemInPO.taxSummaryTerms.UGSTamount = 0;
          itemInPO.taxSummaryTerms.TCSamount = tcsCharge / this.purchaseOrderFromCart.itemDetails.length;
        }
      })
      const updatePOwithCorrectTaxSummary: PurchaseOrderModel = this.purchaseOrderFromCart
      this.createNewPO(updatePOwithCorrectTaxSummary)
    }
    catch (e) {
      throw e
    }
  }

  createNewPO(updatePOwithCorrectTaxSummary: PurchaseOrderModel) {
    const vyayak = this.payNowCharges
    const dayakid = updatePOwithCorrectTaxSummary.buyerDetails.buyerId
    const dayakemail = updatePOwithCorrectTaxSummary.buyerDetails.buyerContactDetails.email
    const dayakphone = updatePOwithCorrectTaxSummary.buyerDetails.buyerContactDetails.phone
    // this.hpmnts.checkPayment(vyayak, dayakid, dayakemail).then((response: any) => {
    const byoraId = ''
    this.isLoading = true
    this.startTimer()
    this.isPaymentWindow = true
    this.utils.scrollTop()
    if (vyayak === 0) {
      const useModifiedPO = this.purchaseOrderFromCart
      useModifiedPO.amountPaidAtCheckout = this.payNowCharges
      useModifiedPO.amountRemainingForInvoices = this.amtremainingForInvoices
      this.handlePurchaseOrder.createInstantPO(useModifiedPO).then(res => {
        this.generatedPOID = res.createdPO._id
        this.isLoading = false
        this.router.navigate([`viewPurchaseOrder/${this.generatedPOID}`])
        return
        this.incartService.deleteMultipleItemfromCart(this.allItemIDsOfCart, this.cartId).subscribe(
          deleted => {
            this.isLoading = false; //spinner starts
            if (deleted && deleted.status === "201") {
              this.userCart = deleted.cart
              if (this.userCart) {
                this.authService.createCacheCart(this.userCart)
                this.router.navigate([
                  `viewPurchaseOrder/${this.generatedPOID}`
                ])
              }
            }
          }
        )
      })

    }
    else if (vyayak > 0) {
      this.hpmnts.startPay('PurchaseOrder', byoraId, vyayak, dayakid, dayakemail, dayakphone).then(async (response: any) => {
        if (response && response.status === 200) {
          const tokenUrl = response.rdrurl.data.instrumentResponse.redirectInfo.url
          this.useMerchantId = response.rdrurl.data.merchantId
          this.usedocId = response.docId
          const useModifiedPO = this.purchaseOrderFromCart
          useModifiedPO.amountPaidAtCheckout = this.payNowCharges
          useModifiedPO.amountRemainingForInvoices = this.amtremainingForInvoices
          const createOrder = () =>
            this.zone.run(() =>
              this.handlePurchaseOrder.createNewPO(useModifiedPO, this.usedocId).then(res => {
                this.generatedPOID = res.createdPO._id
                this.isPaymentWindow = false
                this.isLoading = false
                this.router.navigate([
                  `viewPurchaseOrder/${this.generatedPOID}`
                ])
                return
                this.incartService.deleteMultipleItemfromCart(this.allItemIDsOfCart, this.cartId).subscribe(
                  deleted => {
                    this.isLoading = false; //spinner starts
                    if (deleted && deleted.status === "201") {
                      this.userCart = deleted.cart
                      if (this.userCart) {
                        this.authService.createCacheCart(this.userCart)
                        this.router.navigate([
                          `viewPurchaseOrder/${this.generatedPOID}`
                        ])
                      }
                    }
                  }
                )
              })
            )
          function callback(response) {
            if (response === 'USER_CANCEL') {
              return;
            } else if (response === 'CONCLUDED') {
              createOrder()
              return;
            }
          }
          window.PhonePeCheckout.transact({ tokenUrl, callback, type: "IFRAME" });
          return
          // this.startTimer()
          // this.useMerchantId = response.rdrurl.data.merchantId
          // this.usedocId = response.docId
          // this.usedayakid = dayakid
          // this.usevyayak = vyayak
          // return
        }
        else {
          this.errorOccured("Purchase Order Not Created", "Try Again")
        }
      }
      )
    }
  }

  errorOccured(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
    }
    catch (e) {
      throw e
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 180;
      }
    }, 1000)
  }

  // ifPaidCreatePO(merchid: string, purchid: string, dayakid: string, amt: number) {
  //   const useModifiedPO = this.purchaseOrderFromCart
  //   useModifiedPO.amountPaidAtCheckout = this.payNowCharges
  //   useModifiedPO.amountRemainingForInvoices = this.amtremainingForInvoices

  //   this.handlePurchaseOrder.createNewPO(useModifiedPO, purchid).then(res => {
  //     this.generatedPOID = res.createdPO._id
  //     this.isLoading = false
  //     this.router.navigate([
  //       `viewPurchaseOrder/${this.generatedPOID}`
  //     ])
  //     return
  //     this.incartService.deleteMultipleItemfromCart(this.allItemIDsOfCart, this.cartId).subscribe(
  //       deleted => {
  //         this.isLoading = false; //spinner starts
  //         if (deleted && deleted.status === "201") {
  //           this.userCart = deleted.cart
  //           if (this.userCart) {
  //             this.authService.createCacheCart(this.userCart)
  //             this.router.navigate([
  //               `viewPurchaseOrder/${this.generatedPOID}`
  //             ])
  //           }
  //         }
  //       }
  //     )


  //   })


  // }


  resetAdress() {
    try {
      this.userInfoForm.reset()
    }
    catch (e) { }
  }

  async setFormValuesOnEdit() {
    try {
      this.selectedPincode = this.purchaseOrderFromCart.shipToBuyerLocation.pinCode
      this.stateName = this.purchaseOrderFromCart.shipToBuyerLocation.state
      this.districtName = this.purchaseOrderFromCart.shipToBuyerLocation.city
      // this.isfilterVarietiesLoading = false; //var load stops
      this.userInfoForm.setValue({
        'fullName': this.purchaseOrderFromCart.shipToBuyerLocation.name,
        'phone': this.purchaseOrderFromCart.shipToBuyerLocation.phone,
        'pinCode': this.purchaseOrderFromCart.shipToBuyerLocation.pinCode,
        'state': this.purchaseOrderFromCart.shipToBuyerLocation.state,
        'city': this.purchaseOrderFromCart.shipToBuyerLocation.city,
        'addressLocality': this.purchaseOrderFromCart.shipToBuyerLocation.streetName,
        'addressBuildingNameAndFloor': this.purchaseOrderFromCart.shipToBuyerLocation.buildingName,
        'landmark': this.purchaseOrderFromCart.shipToBuyerLocation.landMark,
        // 'email': accountData.email,
      });
      this.userInfoForm.markAllAsTouched();
    }
    catch (e) {
      throw e
    }
  }

  async getPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        const localities = [...new Set(this.pincodeFullInfo.map((obj: any) => obj.Name))];
        this.pinLocalities = localities
        this.localityName = this.purchaseOrderFromCart.shipToBuyerLocation.streetName
        this.localityName = ""
      }
      return
    }
    catch (e) {
      throw e
    }
  }
}
