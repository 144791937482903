import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserAuthModel } from '../datamodels/userModel';
import { CompanyTeamDetails } from '../datamodels/groupedmodel/companyTeamDetails';

@Injectable({
  providedIn: 'root'
})
export class TeambuilderService {
  private teamSettingsEdit = new BehaviorSubject(null);
  sharedTeamSettings = this.teamSettingsEdit.asObservable()
  private domain: string | undefined
  constructor(
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  async getTeamAccounts(gstId: string): Promise<{ teamUsers: UserAuthModel[] }> {
    const allTeams = await this.http.get<{ teamUsers: UserAuthModel[] }>(`${this.domain}api/companyTeam/getCompanyAccounts/` + gstId).toPromise()
    return allTeams
  }

  async findMyteam(gst: string): Promise<{ teamUsers: CompanyTeamDetails[] }> {
    const companyTeams = await this.http.get<{ teamUsers: CompanyTeamDetails[] }>(`${this.domain}api/companyTeam/findMyCompanyTeam/` + gst).toPromise()
    return companyTeams
  }

  updateTeamSettings(teamSettings: any) {
    try {
      this.teamSettingsEdit.next(teamSettings)
    }
    catch (e) {
      throw e
    }

  }
}
