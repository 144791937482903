import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';
import { HandleTradesService } from 'src/app/services/handle-trades.service';
import { TradeItemDetails } from 'src/app/datamodels/contractOrders.ts/tradeItemsDetails';
import { OpenTrade } from 'src/app/datamodels/contractOrders.ts/openpurchaseorder';
import { UtilsService } from 'src/app/services/utils.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TrDetailsSheetComponent } from '../tr-details-sheet/tr-details-sheet.component';

@Component({
  selector: 'app-new-applied-trades',
  templateUrl: './new-applied-trades.component.html',
  styleUrls: ['./new-applied-trades.component.css']
})
export class NewAppliedTradesComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  userIdTRs: OpenTrade[] = []
  sellerTypeTRs: OpenTrade[] = []
  buyerTypeTRs: OpenTrade[] = []
  sellerTypeDataSource: any
  buyerTypeDataSource: any
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccTRsByAccTyp: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  showCompletedOrdersOnly: boolean = false
  currentUrl: string;
  isTradAvl: boolean = false;
  presBuyerTrs: OpenTrade[];
  presSellerTrs: OpenTrade[];
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private hts: HandleTradesService,
    private utils: UtilsService,
    private _bottomSheet: MatBottomSheet,

  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccTRsByAccTyp = this.loginUserInfo.accounttype
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
    // if (this.router.url.includes('Completed') && this.currentUserId && this.currentUserId !== '') {
    //   this.showCompletedOrdersOnly = true
    //   this.getCompletedUserPurchaseOrders(this.currentUserId, this.fetchAccTRsByAccTyp)
    //   return
    // }
    this.geMyApTr(this.currentUserId, this.fetchAccTRsByAccTyp)
  }


  geMyApTr(currentUserId: string, loginAccounttype: string) {
    try {
      this.hts.geUsApTr(currentUserId, loginAccounttype).subscribe(fetchedTRs => {
        if (fetchedTRs && fetchedTRs.length > 0) {
          this.isLoading = false;
          this.isTradAvl = true
          this.userIdTRs = fetchedTRs
        }
        if (fetchedTRs && fetchedTRs.length === 0) {
          this.isLoading = false;
          this.isTradAvl = false
        }
      })
    }
    catch (e) { }
  }

  searchItem(event: Event) {
    try {
      const filtVal = (event.target as HTMLInputElement).value;
      if (filtVal == '' || !filtVal) {
        this.userIdTRs = this.userIdTRs
        return
      }
      const searchResults = this.userIdTRs.filter(trs => {
        return trs.itemDetails.productName.toLocaleLowerCase().includes(filtVal.toLocaleLowerCase())
      })
      if (filtVal.length >= 3 && searchResults.length > 0) {
        this.userIdTRs = searchResults
      }
      if (filtVal.length >= 3 && searchResults.length == 0 || !searchResults) {
        this.userIdTRs = []
      }
    }
    catch (e) {
      throw e
    }
  }
  openBottomSheet(datasource: string, detailType: string, data: any): void {
    this.utils.playWidgetAudio();
    this._bottomSheet.open(TrDetailsSheetComponent, {
      data: { ds: datasource, dt: detailType, d: data },
      panelClass: 'custom-width'
    });
  }

}
