import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { UserLinkPendingRequest } from '../datamodels/userLinking/userLinkRequestModel';
import { BehaviorSubject, Observable, } from 'rxjs';
import { UserLinkAccept } from '../datamodels/userLinking/userLinkAcceptModel';
import { ProfileLink } from '../datamodels/userLinking/feedProfileLink';
import { map, shareReplay, tap } from 'rxjs/operators';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class HandleUserLinkService {
  private domain: string | undefined
  private seeLinks = new BehaviorSubject(null)
  shareLinks = this.seeLinks.asObservable()

  private postSubject = new BehaviorSubject<ProfileLink[]>(null);
  private posts$: Observable<ProfileLink[]> | null = null;

  constructor(private http: HttpClient, private utils: UtilsService, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  sendLinks(linkTypes: { linkCards: ProfileLink[], typ: string, usId: string }) {
    try {
      this.seeLinks.next(linkTypes)
    }
    catch (e) {
      throw e
    }
  }
  // allNetws(): Observable<ProfileLinkBcard[]> {
  //   return new Observable(
  //     productObserver => {
  //       if (this.bcardProfs && this.bcardProfs.length > 0) {
  //         productObserver.next(this.bcardProfs)
  //         return productObserver.complete()
  //       }
  //       this.http.get<{
  //         status: number; message: string; ntwcrds: ProfileLinkBcard[]
  //       }>(`${this.domain}api/usLnk/getnet/`)
  //         .subscribe((fetchProfBCards) => {
  //           this.bcardProfs = fetchProfBCards.ntwcrds;
  //           productObserver.next(this.bcardProfs);
  //           productObserver.complete();
  //         });
  //     }
  //   )
  // }

  async mypenntw(ownrUsr: string): Promise<{ status: number; message: string; pntw: UserLinkPendingRequest[] }> {
    try {
      const queryParams = `?ownrUsr=${ownrUsr}`
      const chkpennet = await this.http.get<{ status: number; message: string; pntw: UserLinkPendingRequest[] }>(`${this.domain}api/usLnk/mypennet/` + queryParams).toPromise()
      return chkpennet
    }
    catch (e) {
      throw e
    }
  }
  usrProfNetw(listCrtrs: string[], ownerId: string): Observable<ProfileLink[]> {
    if (this.postSubject.value) {
      return this.postSubject.asObservable();
    }
    // Check if posts are already available
    if (!this.posts$) {
      const listCrtsForm = new FormData()
      listCrtsForm.append("listCrtrs", JSON.stringify(listCrtrs))
      listCrtsForm.append("ownerId", JSON.stringify(ownerId))
      this.posts$ = this.http.post<{ status: number; usrProfs: string }>(
        `${this.domain}api/usLnk/usrProfNetwks/`, listCrtsForm
      ).pipe(
        map(response => {
          const decryptedDataProfs = this.utils.decryptData(response.usrProfs);
          this.postSubject.next(decryptedDataProfs);
          return decryptedDataProfs;
        }),
        shareReplay(1), // Cache the response for future subscribers
        tap(() => this.posts$ = null) // Reset observable after first completion
      );
    }
    return this.posts$;
  }

  async mypenntwCnt(ownrUsr: string): Promise<{ status: number; message: string; pntwcount: number }> {
    try {
      const queryParams = `?ownrUsr=${ownrUsr}`
      const chkpennet = await this.http.get<{ status: number; message: string; pntwcount: number }>(`${this.domain}api/usLnk/mypennetcount/` + queryParams).toPromise()
      return chkpennet
    }
    catch (e) {
      throw e
    }
  }
  async allLnks(ownrUsr: string): Promise<{ status: number; message: string; myLnks: string[] }> {
    try {
      const queryParams = `?ownrUsr=${ownrUsr}`
      const chkpennet = await this.http.get<{ status: number; message: string; myLnks: string[] }>(`${this.domain}api/usLnk/mylnks/` + queryParams).toPromise()
      return chkpennet
    }
    catch (e) {
      throw e
    }
  }

  async getOwnrPh(ownrUsr: string): Promise<{ status: number; num: number; }> {
    try {
      const queryParams = `?ownrUsr=${ownrUsr}`
      const ownPh = await this.http.get<{ status: number; num: number; }>(`${this.domain}api/usLnk/getOwnrPhNum/` + queryParams).toPromise()
      return ownPh
    }
    catch (e) {
      throw e
    }
  }

  async getOwnrBizName(ownrUsr: string): Promise<{ status: number; bname: string; }> {
    try {
      const queryParams = `?ownrUsr=${ownrUsr}`
      const ownerBname = await this.http.get<{ status: number; bname: string; }>(`${this.domain}api/usLnk/getOwnrBiz/` + queryParams).toPromise()
      return ownerBname
    }
    catch (e) {
      throw e
    }
  }


  async loadAllPendLinks(pendUsers: string[]): Promise<{ status: number; pendProfs: ProfileLink[] }> {
    try {
      const pendProfFormData = new FormData()
      pendProfFormData.append("pendUsers", JSON.stringify(pendUsers))
      const chkpennet = await this.http.post<{ status: number; pendProfs: ProfileLink[] }>(`${this.domain}api/usLnk/loadPenNetProf/`, pendProfFormData).toPromise()
      return chkpennet
    }
    catch (e) {
      throw e
    }
  }

  async loadAllAccLinks(accUsers: string[]): Promise<{ status: number; myAccProfs: ProfileLink[] }> {
    try {
      const pendProfFormData = new FormData()
      pendProfFormData.append("accUsers", JSON.stringify(accUsers))
      const chkpennet = await this.http.post<{ status: number; myAccProfs: ProfileLink[] }>(`${this.domain}api/usLnk/loadAccLnkProf/`, pendProfFormData).toPromise()
      return chkpennet
    }
    catch (e) {
      throw e
    }
  }

  async chckLnkOno(currUs: string, ownrUsr: string): Promise<{ status: number; linkStatus: string, button: string, linkdPh: number, tlinks: number }> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get<{ status: number; linkStatus: string, button: string, linkdPh: number, tlinks: number, cc: string }>(`${this.domain}api/usLnk/chkLnkAdV/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }

  async sndlnkReq(currUs: string, ownrUsr: string): Promise<{ status: number, message: string }> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get<{ status: number, message: string }>(`${this.domain}api/usLnk/nwLnkRq/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
  async unlnkReq(currUs: string, ownrUsr: string): Promise<{ status: number, message: string }> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get<{ status: number, message: string }>(`${this.domain}api/usLnk/unLinkUser/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
  async cncNewlnkReq(currUs: string, ownrUsr: string): Promise<{ status: number, message: string }> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.delete<{ status: number, message: string }>(`${this.domain}api/usLnk/wthdNewLnkreq/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }

  async cncPndlnkReq(currOwnUs: string, reqByUsr: string): Promise<{ status: number, message: string }> {
    try {
      const queryParams = `?currOwnUs=${currOwnUs}&&reqByUsr=${reqByUsr}`
      const chkLnk = await this.http.delete<{ status: number, message: string }>(`${this.domain}api/usLnk/cnclPndLnkReqForUsr/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
  async accPndlnkReq(currOwnUs: string, reqByUsr: string): Promise<{ status: number, message: string }> {
    try {
      const queryParams = `?currOwnUs=${currOwnUs}&&reqByUsr=${reqByUsr}`
      const chkLnk = await this.http.get<{ status: number, message: string }>(`${this.domain}api/usLnk/accPendLnkReqForUsr/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
}
