import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HandleListingsService } from 'src/app/services/handle-listings.service';

@Component({
  selector: 'app-quick-enquiry-form',
  templateUrl: './quick-enquiry-form.component.html',
  styleUrls: ['./quick-enquiry-form.component.css']
})
export class QuickEnquiryFormComponent implements OnInit, OnChanges {
  @Input() currentListingInfo: { itemName: string, listingId: string, listingCreatorCompany: string, listingCreatorId: string }
  isNewRequirement: boolean = true;
  isBuyerFormDisplay: boolean = false;
  isSellerFormDisplay: boolean = false;
  itemName: string
  itemQuantity: number
  phoneContact: number
  selectedAccountType: string;
  targetPrice: string;
  accountTypes: string[] = [
    "Seller",
    "Buyer",]
  displayPostSuccess: boolean = false;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private usels: HandleListingsService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentListingInfo'] && changes['currentListingInfo'].currentValue) {
    }
  }
  ngOnInit(): void {
    this.currentListingInfo
  }
  doneForm() {
    try {
      if (!this.phoneContact || this.phoneContact === undefined) {
        this.notify('Add Details')
        return
      }
      this.isLoading = true
      const reqForm = {
        accountType: 'Buyer',
        itemName: this.currentListingInfo.itemName,
        listingId: this.currentListingInfo.listingId,
        listingCreatorId: this.currentListingInfo.listingCreatorId,
        listingCreatorCompany: this.currentListingInfo.listingCreatorCompany,
        itemQuantity: this.itemQuantity,
        phone: this.phoneContact,
        targetPr: this.targetPrice
      }
      this.usels.newRequirement(reqForm).then(posted => {
        this.isLoading = false
        let notifyMess: string
        if (posted && posted.status === 201) {
          this.displayPostSuccess = true
          notifyMess = posted.message
        }
        else {
          notifyMess = posted.message
        }
        this.notify(notifyMess)
      })
    }
    catch (e) { throw e }
  }
  notify(mess: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(mess, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
}
