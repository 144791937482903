<div *ngIf="this.sendProfCard">
    <div class="profCard" (click)="this.viewUserProfile(this.sendProfCard.usid)">
        <div class="bannerSCentral">
            <div class="shopbanner">
                <div class="backgroundBanner">
                    <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
                </div>
                <div class="shopimg sec" *ngIf="this.sendProfCard.isGstVerified">
                    <div class="profileActions settingsButton">
                        <div class="ln circle-link">
                        </div>
                    </div>
                </div>
                <div class="logoImg">
                    <img *ngIf="!this.isUserLogoPresent" src="../../../../../assets/accountTypes/myp-user.svg" />
                    <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
                </div>
            </div>
        </div>
        <div class="profileInvite">
            <div class="upInv">
                <p class="link pname">{{this.sendProfCard.name}}</p>
                <p class="link bname">{{this.sendProfCard.businessName}}</p>
            </div>
            <p class="link subtext"> View Profile </p>
        </div>
    </div>
    <div class="actionButton">
        <button class="accept" (click)="this.openWhappForLinked(this.sendProfCard.usid)" class="linkUser"
            mat-raised-button>
            Linked <mat-icon>supervisor_account</mat-icon>
            <br>
            <mat-hint class="linkedSubtext">Message</mat-hint>
        </button>
    </div>
</div>