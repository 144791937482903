<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Loading</h1>
            <h2>Invoice Orders </h2>
        </div>
        <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
    </div>
</div>
<div class="content">
    <div *ngIf="!isLoading">
        <div class="verifiedID" *ngIf="!this.gstDetailsPending && !this.gstVerificationPending">
            <p>Verified
            </p>
        </div>
        <div class="container">
            <div class="sellerAccountType" *ngIf="this.sellerAccountType">
                <div class="togglecontainer">
                    <div class="iIssuedTitle">
                        <h2 matTooltip="Issued by Me to other Buyers">
                            <span>
                                My Issued
                            </span>
                        </h2>
                    </div>
                    <div class="toggle">
                        <h3>
                            <mat-slide-toggle [(ngModel)]="isSellerCheckingINVOtypeAsBuyer">
                            </mat-slide-toggle>
                        </h3>
                    </div>
                    <div class="sellerIssuedTitle">
                        <h2 matTooltip="Issued by Sellers to me">
                            <span>
                                Sellers Issued
                            </span>
                        </h2>
                    </div>
                </div>

                <div class="sellerAsSeller"
                    *ngIf="!isSellerCheckingINVOtypeAsBuyer && this.sellerTypeDataSource && this.sellerTypeDataSource.length>=0">
                    <h3>My Issued - Recieve Payment</h3>

                    <div class="searchField">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput [(ngModel)]="this.sellerTypeSearch" (keyup)="applySellerFilter($event)"
                                placeholder="Purchase Order Number" #input>
                        </mat-form-field>
                    </div>
                    <div *ngIf="this.sellerTypeDataSource.length===0">
                        <h3 class="headNoPo" *ngIf="this.sellerTypeSearch ===''">No Invoice Bills found</h3>
                        <h3 class="headNoPo" *ngIf="this.sellerTypeSearch !==''">No Invoice Bills Found<b>
                                {{this.sellerTypeSearch}}
                            </b></h3>
                    </div>
                    <div class="invoiceOrderContainer">
                        <div class="sellerIsSeller" *ngFor="let po of this.sellerTypeDataSource">
                            <mat-card class="tp-card">
                                <!-- <div mat-card-avatar class="tp-header-image"></div> -->
                                <div [routerLink]="['/viewPurchaseOrder',po._id]" class="title">
                                    <div class="titleversion">
                                        <span class="subtitle">{{po.purchaseOrderNumber}}</span><br>
                                        <span class="titleVer">
                                            {{po.invoiceVersions.length}} Invoice
                                            Versions
                                        </span>
                                    </div>
                                    <div class="spacer">
                                    </div>
                                    <div class="buyerDetails">
                                        <span class="headingBuyer">Buyer Details</span>
                                        <span class="buyerName"> {{po.buyerDetails.buyerName}}</span>
                                        <span class="buyerCompanyName">
                                            {{po.buyerDetails.buyerCompanyName}}</span>
                                    </div>
                                </div>

                                <mat-card-content>
                                    <div class="eachVersion" *ngFor="let eachVersion of po.invoiceVersions">
                                        <div class="versionAndnumber">
                                            <div [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]"
                                                class="version">
                                                <span>V{{eachVersion.version}}</span>
                                            </div>
                                            <div class="invDetails">
                                                <div class="invoiceNumb">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceNumber}}</span>
                                                </div>
                                                <div class="invoiceAmount">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceAmount | number : '1.2-2' }}₹ </span>
                                                </div>
                                                <div class="download">
                                                    <a href="{{eachVersion.s3INVOPDFLink}}" target="_blank"
                                                        rel="noopener noreferrer"
                                                        style="background-color: rgb(255, 255, 255); font-weight: 450;color: rgb(3, 166, 54);">
                                                        <mat-icon class="download_icon">cloud_download</mat-icon>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <!-- sellerAsBuyer - POs that thisUser/Seller created and can view invoices for them -->
                <div class="sellerAsBuyer"
                    *ngIf="isSellerCheckingINVOtypeAsBuyer && this.buyerTypeDataSource && this.buyerTypeDataSource.length>=0">
                    <h3>Sellers Issued - To Pay</h3>

                    <div class="searchField">
                        <mat-form-field appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput [(ngModel)]="this.buyerTypeSearch" (keyup)="applyBuyerFilter($event)"
                                placeholder="Purchase Order Number" #input>
                        </mat-form-field>
                    </div>
                    <div *ngIf="this.buyerTypeDataSource.length===0">
                        <h3 class="headNoPo" *ngIf="this.buyerTypeSearch ===''">No Bills found</h3>
                        <h3 class="headNoPo" *ngIf="this.buyerTypeSearch !==''">No Bills Found <b>
                                {{this.buyerTypeSearch}}
                            </b></h3>
                    </div>
                    <div class="invoiceOrderContainer">
                        <div class="sellerIsBuyer" *ngFor="let po of this.buyerTypeDataSource">
                            <mat-card class="tp-card">
                                <!-- <div mat-card-avatar class="tp-header-image"></div> -->
                                <div [routerLink]="['/viewPurchaseOrder',po._id]" class="title">
                                    <div class="titleversion">
                                        <span class="subtitle">{{po.purchaseOrderNumber}}</span><br>
                                        <span class="titleVer">
                                            {{po.invoiceVersions.length}} Invoice
                                            Versions
                                        </span>
                                    </div>
                                    <div class="spacer">
                                    </div>
                                    <div class="sellerDetails">
                                        <span class="headingSeller">Seller Details</span>
                                        <span class="sellerName"> {{po.sellerDetails.firstname
                                            }} {{po.sellerDetails.lastname
                                            }}</span>
                                        <span class="sellerCompanyName">
                                            {{po.sellerDetails.companyName
                                            }}</span>
                                    </div>
                                </div>
                                <mat-card-content>
                                    <div class="eachVersion" *ngFor="let eachVersion of po.invoiceVersions">
                                        <div class="versionAndnumber">
                                            <div class="version"
                                                [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                <span>V{{eachVersion.version}}</span>
                                            </div>
                                            <div class="invDetails">
                                                <div class="invoiceNumb">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceNumber}}</span>
                                                </div>
                                                <div class="invoiceAmount">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceAmount | number : '1.2-2' }}₹ </span>
                                                </div>
                                                <div class="download">
                                                    <a href="{{eachVersion.s3INVOPDFLink}}" target="_blank"
                                                        rel="noopener noreferrer"
                                                        style="background-color: rgb(255, 255, 255); font-weight: 450;color: rgb(3, 166, 54);">
                                                        <mat-icon class="download_icon">cloud_download</mat-icon>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </mat-card-content>

                            </mat-card>
                        </div>
                    </div>
                </div>

            </div>
            <!-- buyerNeeds to see Seller Details  -->
            <!-- this 1stBuyer/thisAccountisBuyerType created Purchase orders and issued to the other Sellers 
                   - we will show the sellerDetails for purchase orders that the 1stBuyer/thisAccountisBuyerType
                   wants as his delivery -->
            <div class="buyerAccountType" *ngIf="this.buyerAccountType">
                <div class="searchField">
                    <mat-form-field appearance="outline">
                        <mat-label>Filter</mat-label>
                        <input matInput [(ngModel)]="this.buyerTypeSearch" (keyup)="applyBuyerFilter($event)"
                            placeholder="Purchase Order Number" #input>
                    </mat-form-field>
                </div>
                <div *ngIf="!this.buyerTypeDataSource || this.buyerTypeDataSource.length===0">
                    <h3 class="headNoPo" *ngIf="this.buyerTypeSearch ===''">No Bills found</h3>
                    <h3 class="headNoPo" *ngIf="this.buyerTypeSearch !==''">No Bills Found <b>
                            {{this.buyerTypeSearch}}
                        </b></h3>
                </div>

                <div *ngIf="this.buyerTypeDataSource && this.buyerTypeDataSource.length>=0">
                    <h3>Sellers Issued - To Pay</h3>

                    <div class="invoiceOrderContainer">
                        <div class="buyerIsBuyer" *ngFor="let po of this.buyerTypeDataSource">
                            <mat-card class="tp-card">
                                <div class="title" [routerLink]="['/viewPurchaseOrder',po._id]">
                                    <div class="titleversion">
                                        <span class="subtitle">{{po.purchaseOrderNumber}}</span><br>
                                        <span class="titleVer">
                                            {{po.invoiceVersions.length}} Invoice
                                            Versions
                                        </span>
                                    </div>
                                    <div class="spacer">
                                    </div>
                                    <div class="sellerDetails">
                                        <span class="headingSeller">Seller Details</span>
                                        <span class="sellerName"> {{po.sellerDetails.firstname
                                            }} {{po.sellerDetails.lastname
                                            }}</span>
                                        <span class="sellerCompanyName">
                                            {{po.sellerDetails.companyName
                                            }}</span>
                                    </div>
                                </div>
                                <mat-card-content>
                                    <div class="eachVersion" *ngFor="let eachVersion of po.invoiceVersions">
                                        <div class="versionAndnumber">
                                            <div class="version"
                                                [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                <span>V{{eachVersion.version}}</span>
                                            </div>
                                            <div class="invDetails">
                                                <div class="invoiceNumb">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceNumber}}</span>
                                                </div>
                                                <div class="invoiceAmount">
                                                    <span [routerLink]="['/viewInvoiceOrder',eachVersion.invoiceID]">
                                                        {{eachVersion.invoiceAmount | number : '1.2-2' }}₹ </span>
                                                </div>
                                                <div class="download">
                                                    <a href="{{eachVersion.s3INVOPDFLink}}" target="_blank"
                                                        rel="noopener noreferrer"
                                                        style="background-color: rgb(255, 255, 255); font-weight: 450;color: rgb(3, 166, 54);">
                                                        <mat-icon class="download_icon">cloud_download</mat-icon>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </mat-card-content>

                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="noOrders" *ngIf="!isPOSavailable">
        <div>
            <div class="contentNoPo" *ngIf="this.buyerAccountType">
                <div class="secHead">
                    <h3 class="headNoPo">No Purchase Orders. Tips to find the best deal</h3>
                </div>
                <div class="noPoSec">
                    <h3>Try sample packs</h3>
                    <h5>
                        You can order sample packs of items you wish to order in bulk later
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>
                        Maximum 10 sample orders allowed
                    </h3>
                    <h5>
                        A limit of 10 sample orders per account is set. You can order 10 sample packs of different
                        or
                        same items from different sellers.
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>Pay later</h3>
                    <h5>
                        You only have to pay a small advance before the delivery of products. Pay later and buy in
                        bulk.
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>Easy Returns and Refunds</h3>
                    <h5>
                        You can always return your delivered items
                    </h5>
                    <mat-hint>Please read refund policy on <b>Add to cart</b> Page</mat-hint>
                </div>
            </div>
            <div class="contentNoPo" *ngIf="this.sellerAccountType">
                <div class="noPoSec">
                    <h3>More listings</h3>
                    <h5>
                        Add more listings with detailed description and content to elaborate your product details
                        such as - Variety, Grade, Packaging Type and Other Details.
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>
                        Pricing
                    </h3>
                    <h5>
                        Do not overprice your items. It is always a good idea to check and research the market for
                        correct prices and post the same on your listing
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>Listing Images</h3>
                    <h5>
                        Use real life images of your items and product instead of using stock images from internet.
                        This builds authenticity of your brand.
                    </h5>
                </div>
                <div class="noPoSec">
                    <h3>KYC</h3>
                    <h5>
                        Make sure you are a verified seller by completing the KYC process
                    </h5>
                </div>
            </div>
        </div>
    </div>
</div>