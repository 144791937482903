import { Injectable } from '@angular/core';
import { ListingModel } from '../datamodels/ListingModel';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject, throwError, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { DetailProductInfo } from '../datamodels/groupedmodel/productDetailInfoModel';
import { UtilsService } from './utils.service';
import { map, shareReplay, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HandleListingsService {
  private listings: ListingModel[] = [];
  public allListings: ListingModel[] = [];
  private domain: string | undefined
  private listingsSubject = new BehaviorSubject<ListingModel[] | null>(null);
  private listings$: Observable<ListingModel[]> | null = null;
  totalListings: number = 0;
  singleListing: ListingModel;
  userlistings: ListingModel[]
  constructor(
    private utils: UtilsService,
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }
  private handleError(error: any) {
    return throwError(error)
  }
  async priceUpdate(
    listingId: string,
    listingName: string,
    postedById: string,
    postedByName: string,
    postedByNumber: string,
    postedByEmail: string,

    requestedById: string,
    requestedByName: string,
    requestedByNumber: string,
    requestedByEmail: string,
    currentPriceItem: string
  ): Promise<{ status: number }> {
    const priceUpdateData = new FormData()
    priceUpdateData.append('listingId', listingId)
    priceUpdateData.append('listingName', listingName)
    priceUpdateData.append('postedById', postedById)
    priceUpdateData.append('postedByName', postedByName)
    priceUpdateData.append('postedByNumber', postedByNumber)
    priceUpdateData.append('postedByEmail', postedByEmail)
    priceUpdateData.append('requestedById', requestedById)
    priceUpdateData.append('requestedByName', requestedByName)
    priceUpdateData.append('requestedByNumber', requestedByNumber)
    priceUpdateData.append('requestedByEmail', requestedByEmail)
    priceUpdateData.append('currentPriceItem', currentPriceItem)
    const getpriceUpdate = await this.http.post<{ status: number }>(`${this.domain}api/listings/getLatestPriceOn`, priceUpdateData).toPromise()
    return getpriceUpdate
  }
  getFilteredListingsOfType(filterString: string, type: string): Observable<ListingModel[]> {
    return this.getAllListings().pipe(
      map((waitForAllListings) => {
        if (waitForAllListings && waitForAllListings.length > 0) {
          const filterFromListings = waitForAllListings.filter((listings) => {
            if (type === 'category') {
              return listings.categoryName === filterString;
            }
            if (type === 'product') {
              return listings.productName === filterString;
            }
            if (type === 'variety') {
              return listings.varietyName === filterString;
            }
            return false; // Default case
          });
          return filterFromListings;
        }
        return []; // Return an empty array if no listings are available
      })
    );
  }

  async newRequirement(reqForm: any): Promise<{ status: number; message: string }> {
    const reqFormData = new FormData()
    reqFormData.append('accountType', reqForm.accountType)
    reqFormData.append('itemName', reqForm.itemName)
    reqFormData.append('itemQuantity', reqForm.itemQuantity)
    reqFormData.append('phone', reqForm.phone)
    reqFormData.append('targetPr', reqForm.targetPr)
    const newReq = await this.http.post<{ status: number; message: string }>(`${this.domain}api/listings/userRequirementContact`, reqFormData).toPromise()
    return newReq
  }

  async quickRequirement(reqForm: any): Promise<{ status: number; message: string }> {
    const reqFormData = new FormData()
    reqFormData.append('accountType', reqForm.accountType)
    reqFormData.append('itemName', reqForm.itemName)
    reqFormData.append('listingId', reqForm.listingId)
    reqFormData.append('listingCreator', reqForm.listingCreator)
    reqFormData.append('listingCreatorCompany', reqForm.listingCreatorCompany)
    reqFormData.append('itemQuantity', reqForm.itemQuantity)
    reqFormData.append('phone', reqForm.phone)
    reqFormData.append('targetPr', reqForm.targetPr)
    const newReq = await this.http.post<{ status: number; message: string }>(`${this.domain}api/listings/quickRequirementContact`, reqFormData).toPromise()
    return newReq
  }

  async getAllRequirements(): Promise<{ status: number; reqs: any }> {
    const allReqs = await this.http.get<{ status: number; reqs: any }>(`${this.domain}api/listings/allRequirements`).toPromise()
    return allReqs
  }

  saveUserSearch(searchTerm: string, userId: string) {
    const userSearchParams = `?searchTm=${searchTerm}&usrid=${userId}`;
    this.http.get<{ status: number }>(`${this.domain}api/listings/userQuery` + userSearchParams)
      .subscribe(
        result => {
          return result
        }
      )
  }

  getAllListings(): Observable<ListingModel[]> {

    if (this.listingsSubject.value) {
      return this.listingsSubject.asObservable();
    }
    // Check if listings are already available
    if (!this.listings$) {
      this.listings$ = this.http.get<{ status: number; totalListings: number; data: string }>(
        `${this.domain}api/listings/findAllListings`
      ).pipe(
        map(response => {
          this.totalListings = response.totalListings;
          const decryptedData = this.utils.decryptData(response.data);
          this.listingsSubject.next(decryptedData);
          this.listingsSubject.next(decryptedData);
          return decryptedData;
        }),
        shareReplay(1), // Cache the response for future subscribers
        tap(() => this.listings$ = null) // Reset observable after first completion
      );
    }

    return this.listings$;
  }

  // getAllListings(): Observable<ListingModel[]> {
  //   // Check if listings are already available
  //   if (this.listings && this.listings.length > 0 && this.listings.length === this.totalListings) {
  //     console.info('called again')
  //     return new Observable(listingsObserver => {
  //       listingsObserver.next(this.listings);
  //       listingsObserver.complete();
  //     });
  //   }

  //   // If listings are not available, make the API call
  //   return this.http.get<{
  //     status: number;
  //     totalListings: number;
  //     data: string;
  //   }>(`${this.domain}api/listings/findAllListings`).pipe(
  //     // Use shareReplay to cache the result and share it among subscribers
  //     shareReplay(1)
  //   ).pipe(
  //     // Transform the response
  //     map(fetchedListings => {
  //       this.totalListings = fetchedListings.totalListings;
  //       console.info('firsttyime', fetchedListings);
  //       const getDec = this.utils.decryptData(fetchedListings.data);
  //       this.listings = getDec;
  //       return this.listings;
  //     })
  //   );
  // }
  async getSingleListing(id: string): Promise<ListingModel> {
    const foundSingleListing = await this.http.get<{
      status: number,
      fetchListing: string
    }>(`${this.domain}api/listings/singleListing/` + id).toPromise()
    const decryptedData = this.utils.decryptData(foundSingleListing.fetchListing);
    console.info('decryptedData', decryptedData)
    return decryptedData
  }

  getUserListings(id: string): Observable<ListingModel[]> {
    return new Observable(
      userlistingsObserver => {
        if (this.userlistings && this.userlistings.length > 0) {
          userlistingsObserver.next(this.userlistings)
          return userlistingsObserver.complete()
        }
        this.http.get<{
          listings: ListingModel[];
          status: number;
        }>(`${this.domain}api/listings/userListings/` + id)
          .subscribe((fetcheduserListings) => {
            this.listings = fetcheduserListings.listings;
            userlistingsObserver.next(this.listings);
            return userlistingsObserver.complete();
          });
      }
    )

  }

  async getSellerListingsCount(id: string): Promise<{ status: number; count: number }> {
    const totalListingsBySeller = await this.http.get<{
      status: number,
      count: number
    }>(`${this.domain}api/listings/userListingsCount/` + id).toPromise()
    return totalListingsBySeller
  }

  addImagesforListing(ImagesPicked: any): Observable<any> {
    const imagesData = new FormData();
    for (let i = 0; i < ImagesPicked.length; i++) {
      imagesData.append('Photos', ImagesPicked[i])
    }
    return this.http.post<any>(`${this.domain}api/listings/uploadListingImages/`, imagesData)
  }
  addSingleImageForListing(ImagePicked: File): Observable<any> {
    const imageData = new FormData()
    imageData.append('Photos', ImagePicked)
    return this.http.post<any>(`${this.domain}api/listings/uploadSingleListingImage/`, imageData)
  }

  async updtViewCount(liD: string, vC: number): Promise<{ status: number }> {
    const viewParams = `?listingId=${liD}&viewCount=${vC}`;
    const updatedVC = await this.http.get<{ status: number; }>(`${this.domain}api/listings/updateView` + viewParams).toPromise()
    return updatedVC
  }

  async addListing(listingForm: ListingModel): Promise<{ message: string, createdlisting: ListingModel, status: number }> {
    const listingData = new FormData();
    //f0
    listingData.append("PublishDate", listingForm.PublishDate);
    listingData.append("hsnCodeItem", listingForm.hsnCodeItem);
    listingData.append("taxSlab", JSON.stringify(listingForm.taxSlab));
    // listingData.append("listingCreatorID", listingCreatorID);
    //f1
    listingData.append("title", listingForm.title);
    listingData.append("categoryUnique", listingForm.categoryUnique);
    listingData.append("categoryId", listingForm.categoryId);
    listingData.append("categoryName", listingForm.categoryName);
    listingData.append("productId", listingForm.productId);
    listingData.append("productName", listingForm.productName);
    listingData.append("isVarietyAvailable", JSON.stringify(listingForm.isVarietyAvailable));
    listingData.append("varietyId", listingForm.varietyId);
    listingData.append("varietyName", listingForm.varietyName);
    listingData.append("bulkPrice", JSON.stringify(listingForm.bulkPrice));
    listingData.append("oldBulkPrice", JSON.stringify(listingForm.oldBulkPrice));
    listingData.append("bulkPriceUpdateDate", listingForm.bulkPriceUpdateDate);
    listingData.append("isListingSortexType", JSON.stringify(listingForm.isListingSortexType));
    listingData.append("useUnit", listingForm.useUnit);
    listingData.append("useUnitShort", listingForm.useUnitShort);
    listingData.append("bulkQuantity", JSON.stringify(listingForm.bulkQuantity));
    listingData.append("packetSize", JSON.stringify(listingForm.packetSize));
    listingData.append("lotSize", JSON.stringify(listingForm.lotSize));
    if (listingForm.packagingType && listingForm.packagingType.length >= 0) {
      listingData.append("packagingType[]", JSON.stringify(listingForm.packagingType))
    }
    //f2
    //FormData's append() method can only accept objects of string or blob type.
    // If you need to append the array, use JSON.stringify() method to convert your array into a valid JSON string.
    if (listingForm.imageUrls && listingForm.imageUrls.length >= 1) {
      listingForm.imageUrls.forEach(imgUrl => {
        listingData.append("imageUrls[]", imgUrl)
      })
    }
    else {
      listingData.append("imageUrls", JSON.stringify(listingForm.imageUrls))
    }
    listingData.append("description", listingForm.description);
    if (listingForm.productDetailInfo && listingForm.productDetailInfo.length >= 0) {
      listingData.append("productDetailInfo[]", JSON.stringify(listingForm.productDetailInfo))
    }
    //f3
    if (listingForm.creditTerms && listingForm.creditTerms.length >= 0) {
      listingData.append("creditTerms[]", JSON.stringify(listingForm.creditTerms))
    }
    listingData.append("pinCode", JSON.stringify(listingForm.pinCode));
    listingData.append("state", listingForm.state);
    listingData.append("city", listingForm.city);
    listingData.append("companyName", listingForm.companyName);

    listingData.append("addressStreet", listingForm.addressStreet);
    listingData.append("addressBuildingNameAndFloor", listingForm.addressBuildingNameAndFloor);
    listingData.append("landmark", listingForm.landmark);
    //f4
    listingData.append("listingCreator", listingForm.listingCreator);
    listingData.append("GST_Id", listingForm.GST_Id);
    listingData.append("isGSTAvailable", JSON.stringify(listingForm.isGSTAvailable));
    listingData.append("phone", JSON.stringify(listingForm.phone));
    listingData.append("alternatePhoneContact", JSON.stringify(listingForm.alternatePhoneContact));
    listingData.append("email", listingForm.email);
    // Display the values
    // Display the values
    return this.http.post<{ message: string, createdlisting: ListingModel, status: number }>(`${this.domain}api/listings/createListing`, listingData)
      .toPromise()
  }

  async updateListing(
    id: string,
    listingCreatorID: string,
    //misc
    PublishDate: string,
    hsnCodeItem: string,
    taxSlab: number,
    // listingCreatorID: string,
    // listingCreatorID:string,
    //form1
    title: string,
    categoryId: string,
    categoryName: string,
    categoryUnique: string,
    productId: string,
    productName: string,
    varietyId: string,
    varietyName: string,
    isVarietyAvailable: boolean,
    bulkPrice: number,
    bulkQuantity: number,
    oldBulkPrice: number,
    listingViewCount: number,
    bulkPriceUpdateDate: Date,
    isListingSortexType: boolean,
    useUnit: string,
    useUnitShort: string,
    packetSize: number,
    lotSize: number,
    packagingType: any,
    //form2
    imageUrls: any,
    description: string,
    productDetailInfo: DetailProductInfo[],
    //form3
    creditTerms: any,
    pinCode: number,
    state: string,
    city: string,
    companyName: string,
    addressStreet: string,
    addressBuildingNameAndFloor: string,
    landmark: string,
    //form4
    listingCreator: string,
    GST_Id: string,
    isGSTAvailable: boolean,
    phone: number,
    alternatePhoneContact: number,
    email: string,
  ): Promise<ListingModel> {
    const listingData = new FormData();
    listingData.append("id", id);
    //f0
    listingData.append("PublishDate", PublishDate);
    listingData.append("listingCreatorID", listingCreatorID);
    listingData.append("hsnCodeItem", hsnCodeItem);
    listingData.append("taxSlab", JSON.stringify(taxSlab));
    //f1
    listingData.append("title", title);
    listingData.append("categoryId", categoryId);
    listingData.append("categoryName", categoryName);
    listingData.append("categoryUnique", categoryUnique);
    listingData.append("productId", productId);
    listingData.append("productName", productName);
    listingData.append("isVarietyAvailable", JSON.stringify(isVarietyAvailable));
    listingData.append("varietyId", varietyId);
    listingData.append("varietyName", varietyName);
    listingData.append("bulkPrice", bulkPrice.toString());
    listingData.append("bulkQuantity", bulkQuantity.toString());
    listingData.append("oldBulkPrice", JSON.stringify(oldBulkPrice));
    listingData.append("bulkPriceUpdateDate", JSON.stringify(bulkPriceUpdateDate));
    listingData.append("isListingSortexType", JSON.stringify(isListingSortexType));
    listingData.append("useUnit", useUnit);
    listingData.append("useUnitShort", useUnitShort);
    listingData.append("packetSize", packetSize.toString());
    listingData.append("lotSize", lotSize.toString());
    if (packagingType && packagingType.length >= 0) {
      listingData.append("packagingType[]", JSON.stringify(packagingType))
    }
    //f2
    //FormData's append() method can only accept objects of string or blob type.
    // If you need to append the array, use JSON.stringify() method to convert your array into a valid JSON string.
    if (imageUrls && imageUrls.length >= 1) {
      imageUrls.forEach(imgUrl => {
        listingData.append("imageUrls[]", imgUrl)
      })
    }
    else {
      listingData.append("imageUrls", imageUrls)
    }
    listingData.append("description", description);
    if (productDetailInfo && productDetailInfo.length >= 0) {
      listingData.append("productDetailInfo[]", JSON.stringify(productDetailInfo))
    }
    //f3
    if (creditTerms && creditTerms.length >= 0) {
      listingData.append("creditTerms[]", JSON.stringify(creditTerms))
    }
    listingData.append("pinCode", JSON.stringify(pinCode));
    listingData.append("state", state);
    listingData.append("city", city);
    listingData.append("companyName", companyName);

    listingData.append("addressStreet", addressStreet);
    listingData.append("addressBuildingNameAndFloor", addressBuildingNameAndFloor);
    listingData.append("landmark", landmark);
    //f4
    listingData.append("listingCreator", listingCreator);
    listingData.append("GST_Id", GST_Id);
    listingData.append("isGSTAvailable", JSON.stringify(isGSTAvailable));
    listingData.append("phone", phone.toString());
    listingData.append("alternatePhoneContact", JSON.stringify(alternatePhoneContact));
    listingData.append("email", email);
    // Display the values
    const updatedListing = await this.http.put<{ message: string, editedListing: ListingModel, status: string }>(`${this.domain}api/listings/editListing/` + id, listingData)
      .toPromise()
    return updatedListing.editedListing
  }

  async deleteListing(id: string): Promise<any> {
    return this.http.delete(`${this.domain}api/listings/deleteListing/` + id).toPromise();
  }
  // deleteListing(id: string): Observable<any> {
  //   console.log('see id for del', id)

  //   return this.http.delete(`${ this.domain }api / listings / deleteListing /` + id)

  // }




}
