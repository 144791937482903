<mat-card (click)="this.openOverlayDoc()" class="post-card">
    <!-- Header Section -->
    <mat-card-header *ngIf="!this.auth">
        <!-- User Logo -->
        <!-- <img class="avatar" mat-card-avatar
            [src]="post.logoUser || '../../../../../assets/accountTypes/myp-user.svg'" alt="User Logo"> -->
        <img class="avatar" mat-card-avatar src='../../../../../assets/accountTypes/myp-user.svg' alt="User Logo">
        <!-- User Name and Company Profile -->
        <mat-card-title *ngIf="!this.isUserNamePresent ">Unknown</mat-card-title>
        <mat-card-subtitle *ngIf="!this.isUserBizNamePresent ">{{ this.qfsCreator }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-header *ngIf="this.auth">
        <!-- User Logo -->
        <!-- <img class="avatar" mat-card-avatar
            [src]="post.logoUser || '../../../../../assets/accountTypes/myp-user.svg'" alt="User Logo"> -->
        <img *ngIf="this.isUserLogoPresent" class="avatar" mat-card-avatar [src]='this.profDet.logoImage'
            alt="User Logo">
        <img *ngIf="!this.isUserLogoPresent" class="avatar" mat-card-avatar
            src='../../../../../assets/accountTypes/myp-user.svg' alt="User Logo">
        <!-- User Name and Company Profile -->
        <mat-card-title *ngIf="!this.isUserNamePresent">Post Name</mat-card-title>
        <mat-card-subtitle *ngIf="!this.isUserBizNamePresent">Company Name</mat-card-subtitle>
        <mat-card-title *ngIf="this.isUserNamePresent">{{this.profDet.name}}</mat-card-title>
        <mat-card-subtitle *ngIf="this.isUserBizNamePresent">{{this.profDet.businessName}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="this.qfForm">
            <textarea formControlName="description" [rows]="textareaRows" cols="50"
                placeholder="Post on Merchant Network" [(ngModel)]="userInput" (input)="onInputChange($event)"
                maxlength="1300"></textarea>
        </form>

        <div>
            <mat-hint class="descHint" *ngIf="remainingChars >= 0">
                {{ remainingChars }} Characters </mat-hint>
            <mat-hint class="descHint" *ngIf="remainingChars < 0" style="color: red;">
                Description limit exceeded!
            </mat-hint>
        </div>
    </mat-card-content>
    <mat-card-content>
        <div class="postInteract">
            <!-- Image Reviews Section -->
            <div class="imageReviews">

                <div id="imagesReview" *ngIf="this.imageUrls  && this.imageUrls.length >= 0">
                    <div *ngFor="let eimg of this.imageUrls" class="eachImg">
                        <img class="eachImgPreview" [src]="eimg" />
                    </div>
                </div>
                <div *ngIf="this.qfd && this.qfd!==''">
                    <a [href]="this.qfd" target="_blank">
                        <div class="eachImg docReady" mat-stroked-button>
                            <!-- Content inside the div -->
                        </div>
                    </a>
                </div>
                <!-- <div *ngIf="this.videoUrl">
                    <video controls class="videoPreview">
                        <source [src]="this.videoUrl" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div> -->

                <div *ngIf="this.disableDoc || this.disableImg">
                    <button class="close-btn" (click)="this.rmQfFiles()" mat-stroked-button>
                        <span #closeOv>&times;</span>
                    </button>
                </div>
            </div>
            <span *ngIf="this.images">{{this.images.length}} picked</span>

            <!-- Buttons Section -->
            <div class="buttonsRow">
                <div class="buttonset">
                    <button [disabled]="this.disableImg" class="linkUser" mat-stroked-button
                        (click)="imgPicker.click()">
                        <span>Photos </span><mat-icon class="useMerchant">add_a_photo</mat-icon>
                        <div>
                            <mat-spinner *ngIf="isImageLoading" diameter="20"></mat-spinner>
                        </div>
                    </button>
                    <mat-hint>Add 4 Images</mat-hint>
                </div>

                <div class="buttonset">
                    <button mat-stroked-button [disabled]="this.disableDoc" class="linkUser"
                        (click)="filePicker.click()">
                        <!-- <span *ngIf="!this.images">Upload GST document</span> -->
                        <span>Catalogue </span><mat-icon class="useMerchant">assistant_photo</mat-icon>
                        <!-- <div>
                    <span *ngIf="this.images">{{this.images.length}} picked</span>
                    <mat-spinner *ngIf="isImageLoading" diameter="20"></mat-spinner>
                </div> -->
                    </button>
                    <mat-hint>PDF Document</mat-hint>
                </div>
                <!-- <div class="buttonset">
                    <button (click)="vidPicker.click()" [disabled]="this.disableVideo" class="linkUser"
                        mat-stroked-button>
                        MyShot
                    </button>
                    <mat-hint>Short Video</mat-hint>
                </div> -->


            </div>
            <div class="buttonsRowAct">

                <div class=" done">
                    <button (click)="this.postQf()" mat-stroked-button class="linkUser">
                        <span>Post</span>
                    </button>
                    <mat-error class="easeShow" *ngIf="this.notifyPostError">{{this.notifyPostError}}</mat-error>
                </div>
            </div>
            <!-- Hidden File Input -->
            <input hidden #imgPicker type='file' name='images' multiple (change)="onImagesPicked($event)" />
            <input hidden #filePicker type='file' name='docUpload' accept=".pdf" (change)="onFileSelected($event)" />
            <!-- <input hidden #vidPicker type='file' name='mypShot' accept="video/*" (change)="onVidPick($event)" /> -->
            <!-- Hidden Video Input -->
            <!-- <input type="file" #vidPicker (change)="processVideo($event)" accept="video/*"> -->
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="isDocOverlayOpen" class="overlay">
    <div class="overlay-content-doc">
        <span class="close-btn" #closeOvDoc (click)="closeDocOverlay()">&times;</span>
        <app-login-box></app-login-box>
    </div>
</div>