import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { PaidInvoice } from 'src/app/datamodels/sellerpayModel/invoicePaidDetailsModel';
import { SellerPayout } from 'src/app/datamodels/sellerpayModel/sellerPayoutModel';
import { WithdrawalDetails } from 'src/app/datamodels/sellerpayModel/withdrawalDetailsModel';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { HandlesellerCentralService } from 'src/app/services/handleseller-central.service';
import { UtilsService } from 'src/app/services/utils.service';
interface invoiceInfoIn {
  useInvoiceNumber: string;
  useInvoiceAmount: number;
  useInvoicePaidDate: Date;
  useFundWithdrawabledate: Date;
  useIsFundWithdrawable: boolean;
  useIsWithdrawalReqAlreadyPlaced: boolean;
  usefundWithdrawnDate: Date;
  usetotalDeducDone: number;
  useWithdrawableAmount: number
}
interface ordersAccountingIn {
  invoiceInfo: invoiceInfoIn[]
  orderCreditInterest: number;
  orderCreditInterestShareOfSeller: number;
  orderFinalSellerShareOfOrder: number;
  orderFinalValue: number;
  orderHandlingFeesFromBuyer: number;
  orderNumber: string;
  orderStatus: string;
  orderPlatformCreditInterestShare: number;
  orderTCSHandlingFeesAndFromSeller: number;
  invoicedTimes: number;
  orderFinalPayout: number
}
interface itemSalesGroupIn {
  activeOrders: number;
  itemId: string;
  catUniq: string,
  prodName: string,
  itemTitle: string;
  netSales: number;
  numberOfPurchaseOrders: number;
  settledOrders: number;
}
interface pendingOrdersIn {
  orderNumber: string;
  pendingAmount: number;
  orderAmount: number;
}
interface lateItemIn {
  lateAmountOfAllitems: number;
  numberOfItemsLate: number;
  orderNumber: string
}
@Component({
  selector: 'app-seller-central',
  templateUrl: './seller-central.component.html',
  styleUrls: ['./seller-central.component.css']
})
export class SellerCentralComponent implements OnInit {
  isLoading: boolean = false
  businessName: string
  private loginUserInfo: any
  currentUserId: string
  fetchAccountPOsByAccountType: string;
  sellerTypePurchaseOrders: PurchaseOrderModel[] = []
  allPaidPurchaseOrders: PurchaseOrderModel[] = []
  companyNameSeller: string;
  sellerRating: number;
  sellerTakeAway: SellerPayout[];
  allOrdersOfCurrentMonth: SellerPayout[];
  allOrdersOfPreviousMonth: SellerPayout[];
  allOrdersOfCurrentYear: SellerPayout[];
  paidInvsOfCurrentMonth: PaidInvoice[];
  salesDoneOfCurrentMonth: string;
  salesDoneOfCurrentMonthNm: number;
  paidInvsOfPreviousMonth: PaidInvoice[];
  salesDoneOfPreviousMonth: string;
  salesDoneOfPreviousMonthNm: number;
  paidInvsOfCurrentYear: PaidInvoice[];
  salesDoneOfCurrentYear: string;
  salesDoneOfCurrentYearNm: number;
  percentageChangeFromLastMonth: number;
  ordersWithInvoices: SellerPayout[];
  allPurchaseOrdersSettled: SellerPayout[];
  allPurchaseOrdersActive: SellerPayout[];
  allPurchaseOrdersLate: lateItemIn[]
  totalInvoicesPaid: PaidInvoice[];
  groupedOrdersWithListingID: {};
  listingIdGroupedObject: {};
  allInvoicedItemsPaid: string[]
  useItemSalesGroup: itemSalesGroupIn[];
  allPurchaseOrdersPending: SellerPayout[];
  pendingPurchaseOrders: pendingOrdersIn[];
  myWithdrawals: WithdrawalDetails[];
  accountingInfo: ordersAccountingIn[]
  invoiceNmOfAllWithdrawals: string[] = [];
  viewWithdrawableOrders: boolean = false
  preserveAccountInfo: ordersAccountingIn[] = [];
  showLnkCTA: boolean;
  constructor(
    private usrLnkSrv: HandleUserLinkService,
    private authService: AuthService,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private hsc: HandlesellerCentralService,
    public router: Router,
    private util: UtilsService
  ) {

  }
  ngOnInit(): void {
    this.isLoading = true;//spinner starts
    this.loginUserInfo = this.authService.getUserLoginInfo();
    // this.checkLink(this.currentUserId, this.currentUserId)
    const accountType = this.loginUserInfo.accounttype
    if (accountType === 'Buyer') {
      this.router.navigate([`/`])
      return
    }
    this.companyNameSeller = this.loginUserInfo.companyName
    this.businessName = this.util.getBusinessNameForURL(this.companyNameSeller)
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccountPOsByAccountType = this.loginUserInfo.accounttype
    this.sellerRating = this.loginUserInfo?.accountRating || 2
    // this.getAllPurchaseOrders(this.currentUserId, this.fetchAccountPOsByAccountType)
    this.getmyprPayout(this.currentUserId)
    this.getWithdrawals(this.currentUserId)
  }

  // checkLink(currLogUsr: string, linkToUser: string) {
  //   try {
  //     this.showLnkCTA = this.usrLnkSrv.startLink(currLogUsr, linkToUser)
  //     return this.showLnkCTA
  //   }
  //   catch (e) {
  //     throw e
  //   }
  // }


  getWithdrawals(currentUserId: string) {
    try {
      this.hsc.getAllWithdrawalsSeller(currentUserId).subscribe(allWithdrawals => {
        if (allWithdrawals && allWithdrawals.length >= 0) {
          this.myWithdrawals = allWithdrawals
          this.invoiceNmOfAllWithdrawals = allWithdrawals.map(eachWithdrawal => {
            return eachWithdrawal.invoiceNumb
          })
        }
        else {
          this.invoiceNmOfAllWithdrawals = []
        }
      })
    }
    catch (e) { }
  }

  getAllPurchaseOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.handlePurchaseOrder.getAllUserPurchaseOrders(currentUserId, loginAccounttype).subscribe(fetchedPOs => {
        if (fetchedPOs && fetchedPOs.length >= 0) {
          this.sellerTypePurchaseOrders = fetchedPOs.filter(eachPO => { return eachPO.sellerDetails._id === this.currentUserId })
          this.allPaidPurchaseOrders = this.sellerTypePurchaseOrders.filter(eachPO => {
            return eachPO.poStatus === 'Paid'
          })
          // const currentMonthSalesAmount = this.allPaidPurchaseOrders.filter(allPOs => {
          // }).reduce((sum, item) => sum + item.orderValue, 0);
          // const lastMonthSalesAmount = this.allPaidPurchaseOrders.filter(allPaidPO => {
          //   allPaidPO.da
          // })
        }
      })
    }
    catch (e) { }
  }

  getmyprPayout(currentUserId: string) {
    try {
      this.hsc.getMYPRpayments(currentUserId).subscribe(fetchedpayments => {
        if (fetchedpayments && fetchedpayments.length >= 0) {
          this.sellerTakeAway = fetchedpayments
          const currentdate = new Date();
          const cur_month = currentdate.getMonth() + 1;
          const cur_year = currentdate.getFullYear();
          this.ordersOfpreviousMonth(this.sellerTakeAway, cur_month, cur_year)
          this.ordersOfCurrentMonth(this.sellerTakeAway, cur_month, cur_year)
          this.ordersOfCurrentYear(this.sellerTakeAway, cur_year)
          this.allSettledOrders(this.sellerTakeAway)
          this.allActiveOrders(this.sellerTakeAway)
          this.allInvoicesPaid(this.sellerTakeAway)
          this.allLateOrders(this.sellerTakeAway)
          this.getAccounting(this.sellerTakeAway)
          this.isLoading = false;
          // this.allPendingOrders(this.sellerTakeAway)
        }
      })
    }
    catch (e) { }
  }

  ordersOfCurrentMonth(useSellerTakeAway: SellerPayout[], cur_month: number, cur_year: number) {
    try {
      this.allOrdersOfCurrentMonth = useSellerTakeAway.filter(allpayouts => {
        const monthsOfPaidOrders = new Date(allpayouts.orderDetails.ordercheckoutPaymentDate).getMonth() + 1
        const yearOfPaidorders = new Date(allpayouts.orderDetails.ordercheckoutPaymentDate).getFullYear()
        if (monthsOfPaidOrders === cur_month && yearOfPaidorders === cur_year) {
          return allpayouts
        }
      })
      const paidOrdersOfCurrentMonth = this.allOrdersOfCurrentMonth.filter(allordersCm => allordersCm.invoicePaidDetails && allordersCm.invoicePaidDetails.length > 0).map(allPaid => allPaid.invoicePaidDetails)
      this.paidInvsOfCurrentMonth = [].concat.apply([], paidOrdersOfCurrentMonth);
      this.salesDoneOfCurrentMonthNm = this.paidInvsOfCurrentMonth.reduce((sum, item) => sum + item.invoiceAmount, 0)
      this.salesDoneOfCurrentMonth = this.salesDoneOfCurrentMonthNm.toLocaleString('en-IN')
      this.salesDoneOfCurrentMonthNm && this.salesDoneOfCurrentMonthNm > 0 && this.salesDoneOfPreviousMonthNm && this.salesDoneOfPreviousMonthNm > 0 ?
        this.percentageChangeFromLastMonth = parseInt(Number((this.salesDoneOfCurrentMonthNm - this.salesDoneOfPreviousMonthNm) / this.salesDoneOfPreviousMonthNm * 100).toFixed(2)) : this.percentageChangeFromLastMonth = 0
    }
    catch (e) {
      throw e
    }
  }

  ordersOfpreviousMonth(useSellerTakeAway: SellerPayout[], cur_month: number, cur_year: number) {
    try {
      this.allOrdersOfPreviousMonth = useSellerTakeAway.filter(allpayouts => {
        const monthsOfPaidOrders = new Date(allpayouts.orderDetails.ordercheckoutPaymentDate).getMonth() + 1
        const yearOfPaidorders = new Date(allpayouts.orderDetails.ordercheckoutPaymentDate).getFullYear()
        if (monthsOfPaidOrders === cur_month - 1 && yearOfPaidorders === cur_year || yearOfPaidorders === (cur_year - 1)) {
          return allpayouts
        }
      })
      const paidOrdersOfPreviousMonth = this.allOrdersOfPreviousMonth.filter(allordersPm => allordersPm.invoicePaidDetails && allordersPm.invoicePaidDetails.length > 0).map(allPaid => allPaid.invoicePaidDetails)
      this.paidInvsOfPreviousMonth = [].concat.apply([], paidOrdersOfPreviousMonth);
      this.salesDoneOfPreviousMonthNm = this.paidInvsOfPreviousMonth.reduce((sum, item) => sum + item.invoiceAmount, 0)
      this.salesDoneOfPreviousMonth = this.salesDoneOfPreviousMonthNm.toLocaleString('en-IN')
    }
    catch (e) {
      throw e
    }
  }

  ordersOfCurrentYear(useSellerTakeAway: SellerPayout[], cur_year: number) {
    try {
      this.allOrdersOfCurrentYear = useSellerTakeAway.filter(allpayouts => {
        const yearOfPaidorders = new Date(allpayouts.orderDetails.ordercheckoutPaymentDate).getFullYear()
        if (yearOfPaidorders === cur_year) {
          return allpayouts
        }
      })
      const paidOrdersOfCurrentYear = this.allOrdersOfCurrentYear.filter(allordersCy => allordersCy.invoicePaidDetails && allordersCy.invoicePaidDetails.length > 0).map(allPaid => allPaid.invoicePaidDetails)
      this.paidInvsOfCurrentYear = [].concat.apply([], paidOrdersOfCurrentYear);
      this.salesDoneOfCurrentYearNm = this.paidInvsOfCurrentYear.reduce((sum, item) => sum + item.invoiceAmount, 0)
      this.salesDoneOfCurrentYear = this.salesDoneOfCurrentYearNm.toLocaleString('en-IN')
    }
    catch (e) {
      throw e
    }
  }

  allSettledOrders(useSellerTakeAway: SellerPayout[]) {
    try {
      this.allPurchaseOrdersSettled = useSellerTakeAway.filter(allPaid => {
        return allPaid.orderDetails.orderStatus === 'Paid' && allPaid.orderDetails.orderVyayAction === 'InvoiceSettlement'
      })
    }
    catch (e) {
      throw e
    }
  }

  allActiveOrders(useSellerTakeAway: SellerPayout[]) {
    try {
      this.allPurchaseOrdersActive = useSellerTakeAway.filter(allActive => {
        return allActive.orderDetails.orderStatus === 'Active' && allActive.orderDetails.orderVyayAction === 'InvoicePayment'
      })
      this.pendingPurchaseOrders = this.allPurchaseOrdersActive.map(
        pendingOrdersIn => {
          const totalInvoicePaidAmount = pendingOrdersIn.invoicePaidDetails.reduce((sum, item) => sum + item.invoiceAmount, 0)
          const orderData: pendingOrdersIn = {
            orderNumber: pendingOrdersIn.orderDetails.orderNumber,
            pendingAmount: pendingOrdersIn.orderDetails.orderTotalValue - totalInvoicePaidAmount,
            orderAmount: pendingOrdersIn.orderDetails.orderItemsFinalValue
          }
          return orderData
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  allLateOrders(useSellerTakeAway: SellerPayout[]) {
    try {
      let today = new Date()
      // const proxyDateLateinFuture = this.util.addDays(today, +53)
      let isItemOfPOLate: boolean
      let lateorders = []
      let lateItemInfo: lateItemIn
      useSellerTakeAway.forEach(allorders => {
        let itemPrices: number[] = []
        let lateItemsNM: number = 0
        const useOrder = allorders
        const useOrderNumber = allorders.orderDetails.orderNumber
        useOrder.itemDetails.forEach(
          itemsOfOrders => {
            isItemOfPOLate = new Date(itemsOfOrders.optedPaymentTerms.lastDateOfPayment) < new Date(today)
            if (isItemOfPOLate) {
              lateItemsNM++
              const itemPrice = itemsOfOrders.itemPriceWithTaxes
              itemPrices.push(itemPrice)
              lateItemInfo = {
                orderNumber: useOrderNumber,
                numberOfItemsLate: lateItemsNM,
                lateAmountOfAllitems: itemPrices.reduce((sum, item) => sum + item, 0)
              }
              return lateItemInfo
            }
          }
        )
        if (lateItemInfo == undefined) {
          return
        }
        if (Object.keys(lateItemInfo).length !== 0) {
          lateorders.push(lateItemInfo)
        }
      })
      this.allPurchaseOrdersLate = lateorders
    }
    catch (e) {
      throw e
    }
  }

  allInvoicesPaid(useSellerTakeAway: SellerPayout[]) {
    try {
      this.ordersWithInvoices = useSellerTakeAway.filter(allPaid => allPaid.invoicePaidDetails && allPaid.invoicePaidDetails.length > 0)
      const allInvoicesOfOrders = this.ordersWithInvoices.map(allInvoices => allInvoices.invoicePaidDetails)
      this.totalInvoicesPaid = [].concat.apply([], allInvoicesOfOrders);
      const itemsWithInvoice = this.totalInvoicesPaid.map(invoices => {
        // const invoicedItemIds = [].concat.apply([], invoices.invoicedItems);
        const invoicedItemIds = [].concat.apply([], invoices.invoicedItems);
        return invoicedItemIds
      })
      this.allInvoicedItemsPaid = [].concat.apply([], itemsWithInvoice);
      this.groupByListingIDs(this.ordersWithInvoices)
    }
    catch (e) {
      throw e
    }
  }

  getAccounting(totalOrders: SellerPayout[]) {
    try {
      this.accountingInfo = totalOrders.map(orders => {
        const useOrderNumber = orders.orderDetails.orderNumber
        const useOrderStatus = orders.orderDetails.orderStatus
        const useOrderValueForseller = orders.orderDetails.orderItemsFinalValue
        const usePlatformHandlingFeesBuyer = orders.orderDetails.orderAmountPaidAtCheckout
        const useSellerAccounts = {
          sellerFinalShare: orders.sellerCharges.sellerFinalShareOfOrderAMT,
          sellerTaxAndSurcharge: orders.sellerCharges.sellerPaysTCSandTotalSurchargeAMT,
          totalCreditInterest: orders.financierCharges.financierGetsCreditInterestCutAMT,
          sellerShareCreditInterest: orders.financierCharges.financierkeepsFinalShareOfOrderAMT,
          platformShareCreditInterest: orders.financierCharges.financierPaysMyapaarCreditInterestCutAMT,
        }
        let useInvoiceInfo: invoiceInfoIn
        let allInvoiceInfos: invoiceInfoIn[] = []
        orders.invoicePaidDetails.forEach(invoice => {
          let isInvoiceWithdrawable = false
          const today = new Date()
          // const proxyDateLateinFuture = this.util.addDays(today, +8)
          if (new Date(invoice.fundWithdrawableDate) < today &&
            !this.invoiceNmOfAllWithdrawals.includes(invoice.invoiceNumber)) {
            isInvoiceWithdrawable = true
          }
          useInvoiceInfo = {
            useInvoiceNumber: invoice.invoiceNumber,
            useInvoiceAmount: invoice.invoiceAmount,
            useInvoicePaidDate: invoice.invoicePaidDate,
            useFundWithdrawabledate: invoice.fundWithdrawableDate,
            useIsFundWithdrawable: isInvoiceWithdrawable,
            useIsWithdrawalReqAlreadyPlaced: this.invoiceNmOfAllWithdrawals.includes(invoice.invoiceNumber),
            // useIsFundWithdrawable: true,
            usefundWithdrawnDate: invoice.fundWithdrawnDate,
            usetotalDeducDone: invoice.totalDeducDone,
            useWithdrawableAmount: invoice.invoiceAmount - invoice.totalDeducDone
          }
          allInvoiceInfos.push(useInvoiceInfo)
          return allInvoiceInfos
        })
        const orderInfo: ordersAccountingIn = {
          orderNumber: useOrderNumber,
          orderStatus: useOrderStatus,
          orderFinalValue: useOrderValueForseller,
          orderHandlingFeesFromBuyer: usePlatformHandlingFeesBuyer,
          orderTCSHandlingFeesAndFromSeller: useSellerAccounts.sellerTaxAndSurcharge,
          orderCreditInterest: useSellerAccounts.totalCreditInterest,
          orderCreditInterestShareOfSeller: useSellerAccounts.sellerShareCreditInterest,
          orderPlatformCreditInterestShare: useSellerAccounts.platformShareCreditInterest,
          orderFinalSellerShareOfOrder: useSellerAccounts.sellerFinalShare,
          invoiceInfo: allInvoiceInfos,
          invoicedTimes: allInvoiceInfos.length,
          orderFinalPayout: useSellerAccounts.sellerShareCreditInterest + useSellerAccounts.sellerFinalShare
        }
        return orderInfo
      })
      this.preserveAccountInfo = this.accountingInfo.reverse()
      this.hsc.updateBundledAccounting(this.accountingInfo)
    }
    catch (e) {
      throw e
    }
  }

  filterOrdersWithInvoices(accountingInfo: ordersAccountingIn[]) {
    try {
      if (!this.viewWithdrawableOrders) {
        const onlyWithdrawableAccounting = accountingInfo.filter(eachAcc => {
          return eachAcc.invoiceInfo.length > 0
        })
        this.accountingInfo = onlyWithdrawableAccounting
      }
      else if (this.viewWithdrawableOrders) {
        this.accountingInfo = this.preserveAccountInfo
      }
      this.hsc.updateBundledAccounting(this.accountingInfo)
    }
    catch (e) {
      throw e
    }
  }

  newWithdrawInvoice(withdrawalInvo: invoiceInfoIn) {
    try {
      this.hsc.updateInvoiceWithdrawal(withdrawalInvo)
      this.router.navigate(['/SellerCentral/newWithdrawal', this.currentUserId])

    }
    catch (e) {
      throw e
    }
  }

  groupByListingIDs(useInvoicedOrders: SellerPayout[]) {
    try {
      const groupedIds = []
      useInvoicedOrders.forEach(paidorder => {
        paidorder.itemDetails.forEach(item => {
          if (this.allInvoicedItemsPaid.includes(item.listingId)) {
            const itemVals = {
              listingId: item.listingId,
              listingCategoryUniq: item.productCategory.split(' ').join('_'),
              listingProductName: item.productName.split(' ').join('_'),
              listingTitle: item.itemTitle,
              itemSalesDoneOnCurrentPO: item.itemPriceWithTaxes,
              orderId: paidorder.orderDetails.orderNumber,
              orderstatus: paidorder.orderDetails.orderStatus,
            }
            groupedIds.push(itemVals)
          }
        })
      })
      this.listingIdGroupedObject = groupedIds.reduce((r, a) => {
        r[a.listingId] = r[a.listingId] || [];
        r[a.listingId].push(a);
        return r;
      }, Object.create(null))
      this.useItemSalesGroup = this.getGroupedOrdersByListingIds(this.listingIdGroupedObject)
    }
    catch (e) {
      throw e
    }
  }

  getGroupedOrdersByListingIds(groupedbyListingId: {}) {
    let groupedOrdersByItems = []
    for (var key in groupedbyListingId) {
      var obj = groupedbyListingId[key]
      let activeOrders: number = 0
      let paidOrders: number = 0
      let useNumberofPurchaseOrdersForItem = obj.length
      const useTitle = obj[0].listingTitle
      const catunique = obj[0].listingCategoryUniq
      const prodName = obj[0].listingProductName
      const added: itemSalesGroupIn = {
        itemId: key,
        catUniq: catunique,
        prodName: prodName,
        itemTitle: useTitle,
        numberOfPurchaseOrders: useNumberofPurchaseOrdersForItem,
        activeOrders: activeOrders,
        settledOrders: paidOrders,
        netSales: obj.reduce((sum, item) => sum + item.itemSalesDoneOnCurrentPO, 0)
      }
      groupedOrdersByItems.push(added)
    }
    return groupedOrdersByItems
  }

  ratingsAsStars(stars: any) {
    return Array(stars)
  }

}
