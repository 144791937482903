import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TeambuilderService } from 'src/app/services/teambuilder.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CompanyTeamDetails } from 'src/app/datamodels/groupedmodel/companyTeamDetails';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.css']
})
export class EditTeamComponent implements OnInit {
  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  myTeam: CompanyTeamDetails[] = []
  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];
  useGSTId: any;
  allTeams: any;
  membersNotIncluded: any;
  private loginUserInfo: any;
  private currentUserId: string;
  constructor(
    private _location: Location,
    private authService: AuthService,
    private route: ActivatedRoute,
    private tbs: TeambuilderService) { }

  ngOnInit(): void {
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.tbs.sharedTeamSettings.subscribe(teamSetts => {
      if (!teamSetts) {
        this._location.back()
      }
      this.route.params.subscribe(params => {
        this.useGSTId = params.gstId
      })
      this.allTeams = teamSetts
    })
    this.getMyTeamMems(this.useGSTId)

  }
  getMyTeamMems(gstId: string) {
    try {
      this.tbs.findMyteam(gstId).then(
        companyTeam => {
          if (companyTeam) {
            this.myTeam = companyTeam.teamUsers
            this.allTeams.forEach(myapMem => {
              const isPresent = myapMem

            })
          }

          else {
            this.myTeam = []
            this.membersNotIncluded = this.allTeams
          }
        }
      ).catch(
        error => {
          this.myTeam = []
          this.membersNotIncluded = this.allTeams
        }
      )
    }
    catch (e) {
      throw e
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    try {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
    catch (e) { throw e }

  }
}
