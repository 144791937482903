<div *ngIf="isLoading" class="content-loading">
  <div id="loadingScreen">
    <div class="loadingText">
      <h1> Loading Your Listings </h1>
      <h3>Complete 10 Orders Successfully to improve Ratings</h3>
    </div>
    <app-myapaar-spinner class="mypsin"></app-myapaar-spinner>
  </div>
</div>
<div class="banner">
  <div class="overlay">
    <h1 class="fade-in-text head">Update And Delete</h1>
    <h2 class="fade-in-text subhead">Inventory Listings</h2>
  </div>
</div>
<div class="contentMyListings">
  <div *ngIf="this.myListings.length>0 && !isLoading" fxLayout="row wrap" fxLayoutAlign="center center"
    fxLayoutGap="16px grid">
    <div class="featureListcard" *ngFor="let featuredList of this.myListings ">
      <div class="product-card small-card">

        <img
          [routerLink]="['/Listing/Place_Bulk_Order',featuredList._id,featuredList.categoryUnique,featuredList.productName]"
          [src]="featuredList.imageUrls[0]" alt="{{featuredList.title}}">

        <div class="info">
          <div class="categoryProduct">
            <mat-hint id="categoryName"> {{featuredList.categoryName | translate }} </mat-hint> |
            <mat-hint id="productName"> {{featuredList.productName | translate }} </mat-hint>
          </div>
          <h3
            [routerLink]="['/Listing/Place_Bulk_Order',featuredList._id,featuredList.categoryUnique,featuredList.productName]">
            {{featuredList.title}}</h3>

          <div class="actRow">
            <div class="soldBy">
              <div>
                <span class="listState"> <mat-icon class="subtitle-spacer">
                    location_on</mat-icon> &nbsp;{{featuredList.state |
                  translate}}</span>
                <div class="sellerName">
                  {{featuredList.companyName}}</div>
              </div>
            </div>
            <div class="shP">
              <button
                [routerLink]="['/Listing/Place_Bulk_Order',featuredList._id,featuredList.categoryUnique,featuredList.productName]"
                mat-stroked-button class="open">
                <span>{{(featuredList.bulkPrice).toLocaleString('en-In')}}</span>
                <span> ₹ / {{featuredList.useUnitShort}}</span>
              </button>
            </div>
          </div>
          <div class="actRow">
            <button [routerLink]="['/editListing',featuredList._id]" class="primary" mat-stroked-button>
              Update
            </button>
            <button (click)="deletelistingDialog(featuredList._id)" class="warn" mat-stroked-button>Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf=" this.noListingFound">
    <h2 [routerLink]="['/createListing']">{{noListings}}</h2>
  </div>
</div>