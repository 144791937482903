import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 100): string {
    if (!value) return ''; // Return empty string if value is null or undefined
    if (value.length > limit) {
      return value.slice(0, limit); // Truncate and add ellipsis
    }
    return value; // Return the full description if it has 100 or fewer characters
  }
}
