import { Routes } from '@angular/router'
import { HomeComponent } from '../../components/home/home.component';
import { AllproductsComponent } from '../../components/allproducts/allproducts.component';
import { SinglecategoryproductsComponent } from '../../components/singlecategoryproducts/singlecategoryproducts.component';
import { VarietyofproductsComponent } from 'src/app/components/varietyofproducts/varietyofproducts.component';
import { PostCreateComponent } from 'src/app/components/post-create/post-create.component';
import { ProductsofstateComponent } from 'src/app/components/productsofstate/productsofstate.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CreateListingComponent } from 'src/app/components/create-listing/create-listing.component';
import { ViewListingComponent } from 'src/app/components/view-listing/view-listing.component';
import { ShowListingComponent } from 'src/app/components/show-listing/show-listing.component';
import { ViewbidsComponent } from 'src/app/components/viewbids/viewbids.component';
import { SingleListingComponent } from 'src/app/components/single-listing/single-listing.component';
import { ForgotpasswordComponent } from 'src/app/components/forgotpassword/forgotpassword.component';
import { CreatePurchaseOrderComponent } from 'src/app/components/create-purchase-order/create-purchase-order.component';
import { UserProfileComponent } from 'src/app/components/user-profile/user-profile.component';
import { ViewPurchaseOrderComponent } from 'src/app/components/view-purchase-order/view-purchase-order.component';
import { ViewAllPurchaseOrdersComponent } from 'src/app/components/view-all-purchase-orders/view-all-purchase-orders.component';
import { ManageOrdersComponent } from 'src/app/components/manage-orders/manage-orders.component';
import { ViewAllInvoicesComponent } from 'src/app/components/view-all-invoices/view-all-invoices.component';
import { CreateInvoiceComponent } from 'src/app/components/create-invoice/create-invoice.component';
import { ViewInvoiceOrderComponent } from 'src/app/components/view-invoice-order/view-invoice-order.component';
import { RaiseDisputePURComponent } from 'src/app/components/raise-dispute-pur/raise-dispute-pur.component';
import { RaiseDisputeINVComponent } from 'src/app/components/raise-dispute-inv/raise-dispute-inv.component';
import { ViewIssuesPageComponent } from 'src/app/components/view-issues-page/view-issues-page.component';
import { SearchComponent } from 'src/app/components/search/search.component';
import { CartComponent } from 'src/app/components/cart/cart.component';
import { ReturnordersComponent } from 'src/app/components/returnorders/returnorders.component';
import { RefundPolicyComponent } from 'src/app/components/refund-policy/refund-policy.component';
import { ViewReturnOrderComponent } from 'src/app/components/view-return-order/view-return-order.component';
import { ViewAllReturnOrdersComponent } from 'src/app/components/view-all-return-orders/view-all-return-orders.component';
import { RaiseDisputeReturnComponent } from 'src/app/components/raise-dispute-return/raise-dispute-return.component';
import { DeliveryInvorderaVaayuputraComponent } from 'src/app/components/delivery-invordera-vaayuputra/delivery-invordera-vaayuputra.component';
import { SellerCentralComponent } from 'src/app/components/seller-central/seller-central.component';
import { CreateWithdrawalComponent } from 'src/app/components/create-withdrawal/create-withdrawal.component';
import { ViewallaccountingComponent } from 'src/app/components/viewallaccounting/viewallaccounting.component';
import { FaqPostsComponent } from 'src/app/components/faq-posts/faq-posts.component';
import { AboutusComponent } from 'src/app/components/aboutus/aboutus.component';
import { ViewPostComponent } from 'src/app/components/view-post/view-post.component';
import { MyprofileComponent } from 'src/app/components/myprofile/myprofile.component';
import { AllUserIssuesComponent } from 'src/app/components/view-issues-page/all-user-issues/all-user-issues.component';
import { ViewpaymentsComponent } from 'src/app/components/viewpayments/viewpayments.component';
import { BecomePartnerComponent } from 'src/app/components/become-partner/become-partner.component';
import { RequirementsComponent } from 'src/app/components/requirements/requirements.component';
import { MandipricespageComponent } from 'src/app/components/mandipricespage/mandipricespage.component';
import { TermsConditionsComponent } from 'src/app/components/terms-conditions/terms-conditions.component';
import { DeleteAccountComponent } from 'src/app/components/delete-account/delete-account.component';
import { BlogPostsComponent } from 'src/app/components/blog-posts/blog-posts.component';
import { ReadBlogsComponent } from 'src/app/components/read-blogs/read-blogs.component';
import { BlogPostModel } from 'src/app/datamodels/blogPostModel';
import { ViewSubscriptionComponent } from 'src/app/components/view-subscription/view-subscription.component';
import { TwoAccountInfoComponent } from 'src/app/components/signup/two-account-info/two-account-info.component';
import { OneAccountypeComponent } from 'src/app/components/signup/one-accountype/one-accountype.component';
import { ThreeCompanyInfoComponent } from 'src/app/components/signup/three-company-info/three-company-info.component';
import { EditTeamComponent } from 'src/app/components/edit-team/edit-team.component';
import { TradeProcurementComponent } from 'src/app/components/trade-procurement/trade-procurement.component';
import { NewSupplyTradeComponent } from 'src/app/components/new-supply-trade/new-supply-trade.component';
import { FourCommDetailsComponent } from 'src/app/components/signup/four-comm-details/four-comm-details.component';
import { MyTradesComponent } from 'src/app/components/my-trades/my-trades.component';
import { FiveGstkycComponent } from 'src/app/components/signup/five-gstkyc/five-gstkyc.component';
import { NewBuyTradeComponent } from 'src/app/components/new-buy-trade/new-buy-trade.component';
import { NewAppliedTradesComponent } from 'src/app/components/new-applied-trades/new-applied-trades.component';
import { LiveTradesComponent } from 'src/app/components/live-trades/live-trades.component';
import { MyprofileLinksComponent } from 'src/app/components/myprofile/myprofile-links/myprofile-links.component';
import { Home2Component } from 'src/app/components/home2/home2.component';
import { CommentBoxComponent } from 'src/app/components/home2/listing-posts/comment-box/comment-box.component';
export const newroutes: Routes = [
    { path: 'createListing', component: CreateListingComponent, canActivate: [AuthGuard] },
    { path: 'editListing/:listingId', component: CreateListingComponent, canActivate: [AuthGuard] },
    { path: 'viewListing', component: ViewListingComponent, canActivate: [AuthGuard] },
    { path: 'createReview', component: PostCreateComponent, canActivate: [AuthGuard] },
    { path: 'editReview/:reviewId', component: PostCreateComponent, canActivate: [AuthGuard] },
    { path: 'viewBids/:listingCreatorId', component: ViewbidsComponent, canActivate: [AuthGuard] },
    { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
    { path: 'TermsAndConditionPolicy', component: RefundPolicyComponent, canActivate: [AuthGuard] },
    { path: 'createPurchaseOrder', component: CreatePurchaseOrderComponent, canActivate: [AuthGuard] },
    { path: 'createInvoice/:purchaseOrderId', component: CreateInvoiceComponent, canActivate: [AuthGuard] },
    { path: 'createReturn/Completed/:purchaseOrderId', component: ReturnordersComponent, canActivate: [AuthGuard] },
    { path: 'accountInformation/:userId', component: UserProfileComponent, canActivate: [AuthGuard] },
    { path: 'viewPurchaseOrder/:poId', component: ViewPurchaseOrderComponent, canActivate: [AuthGuard] },
    { path: 'viewAllPurchaseOrders', component: ViewAllPurchaseOrdersComponent, canActivate: [AuthGuard] },
    { path: 'viewAllPurchaseOrders/Completed', component: ViewAllPurchaseOrdersComponent, canActivate: [AuthGuard] },
    { path: 'viewAllInvoices', component: ViewAllInvoicesComponent, canActivate: [AuthGuard] },
    { path: 'viewInvoiceOrder/:invoId', component: ViewInvoiceOrderComponent, canActivate: [AuthGuard] },
    { path: 'ManageOrders', component: ManageOrdersComponent, canActivate: [AuthGuard] },
    { path: 'viewReturnOrder/:retnId', component: ViewReturnOrderComponent, canActivate: [AuthGuard] },
    { path: 'viewAllReturnOrders', component: ViewAllReturnOrdersComponent, canActivate: [AuthGuard] },
    { path: 'viewAllPayments', component: ViewpaymentsComponent, canActivate: [AuthGuard] },
    { path: 'reportPOproblem/:poId', component: RaiseDisputePURComponent, canActivate: [AuthGuard] },
    { path: 'reportINVproblem/:invoId', component: RaiseDisputeINVComponent, canActivate: [AuthGuard] },
    { path: 'reportRETproblem/:retnId', component: RaiseDisputeReturnComponent, canActivate: [AuthGuard] },
    { path: 'viewIssues', component: ViewIssuesPageComponent, canActivate: [AuthGuard] },
    { path: 'allIssues', component: AllUserIssuesComponent, canActivate: [AuthGuard] },
    { path: 'viewIssue/:issId', component: ViewIssuesPageComponent, canActivate: [AuthGuard] },
    { path: 'SellerCentral/:sellerId', component: SellerCentralComponent, canActivate: [AuthGuard] },
    { path: 'SellerCentral/AllOrdersAccounting/:sellerId', component: ViewallaccountingComponent, canActivate: [AuthGuard] },
    { path: 'SellerCentral/newWithdrawal/:sellerId', component: CreateWithdrawalComponent, canActivate: [AuthGuard] },
    { path: 'BecomePartner', component: BecomePartnerComponent, canActivate: [AuthGuard] },
    { path: 'EasyVyapaar/Pricing', component: ViewSubscriptionComponent, canActivate: [AuthGuard] },
    { path: 'searchGlobal', component: SearchComponent, canActivate: [AuthGuard] },
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    // { path: 'Blogs/Published/:articleTitle', component: BlogPostsComponent },
    { path: 'Blogs/Published', component: BlogPostsComponent },
    { path: 'EditTeam/:gstId', component: EditTeamComponent },
    { path: 'Blogs/Published/Article/:blogTitle', component: ReadBlogsComponent },
    { path: 'ByeMyapaar', component: DeleteAccountComponent },
    { path: 'Listing/Place_Bulk_Order/:listingId/:categoryUniq/:product', component: SingleListingComponent },
    { path: 'mypr/delivery/invordera/vaayuputra/:invoId', component: DeliveryInvorderaVaayuputraComponent },
    { path: 'TermsToAgree', component: TermsConditionsComponent },
    { path: 'MandiRate', component: MandipricespageComponent },
    { path: 'FAQs', component: FaqPostsComponent },
    { path: 'Posts/:id/:metaInfo', component: ViewPostComponent },
    { path: 'AboutMyapaar', component: AboutusComponent },
    // { path: 'signup/BusinessSector', component: OneAccountypeComponent },
    { path: 'signup/DailyBulkSupply', component: OneAccountypeComponent },
    // { path: 'signup/SellerPartnership', component: OneAccountypeComponent },
    // { path: 'signup/DailyBulkSupply', component: OneAccountypeComponent },
    { path: 'signup/PersonalInfo', component: TwoAccountInfoComponent },
    { path: 'signup/Commodities', component: FourCommDetailsComponent },
    { path: 'signup/BusinessInfo', component: ThreeCompanyInfoComponent },
    { path: 'signup/bizKYC', component: FiveGstkycComponent },
    // { path: 'home', component: Home2Component },
    { path: 'home', component: HomeComponent },
    { path: 'forgotPassword', component: ForgotpasswordComponent },
    { path: 'login', component: LoginComponent },
    { path: 'requirement/NewRequirement', component: RequirementsComponent },
    {
        path: 'MTR/MyTradeRoom', component: TradeProcurementComponent, canActivate: [AuthGuard],
        children: [
            { path: 'liveTrades', component: LiveTradesComponent },
            { path: 'newSupplyTrade', component: NewSupplyTradeComponent },
            { path: 'newBuyTrade', component: NewBuyTradeComponent },
            { path: 'myTrades', component: MyTradesComponent },
            { path: 'appliedTrades', component: NewAppliedTradesComponent },
            { path: 'myTrades/:tradeId/:contractId', component: ViewAllPurchaseOrdersComponent },
        ]
    },
    { path: 'viewItems/All', component: AllproductsComponent, canActivate: [AuthGuard] },
    { path: 'QuickFeed', component: Home2Component },
    { path: 'QuickFeed/ViewSharedFeed/:FeedId', component: Home2Component },
    { path: 'viewItems/:categoryName', component: AllproductsComponent, canActivate: [AuthGuard] },
    { path: 'viewCategoryitems/:categoryUnique', component: SinglecategoryproductsComponent, canActivate: [AuthGuard] },
    { path: 'viewVarietyItems/:productUnique', component: VarietyofproductsComponent, canActivate: [AuthGuard] },
    { path: 'viewStateProducts/:stateUnique', component: ProductsofstateComponent, canActivate: [AuthGuard] },
    { path: 'showListingProduct/:productName', component: ShowListingComponent, canActivate: [AuthGuard] },
    { path: 'showListingCategory/:categoryName', component: ShowListingComponent, canActivate: [AuthGuard] },
    { path: 'showListingVariety/:varietyName', component: ShowListingComponent, canActivate: [AuthGuard] },
    { path: 'showListingSearch/:listingSearch', component: ShowListingComponent, canActivate: [AuthGuard] },
    { path: 'showListingProductwithState/:productName/:stateName', component: ShowListingComponent, canActivate: [AuthGuard] },
    { path: 'Merchant_Network/Check_Business_Profile/:userId/:businessName', component: MyprofileComponent, canActivate: [AuthGuard] },
    { path: 'Merchant_Network/Check_Business_Profile/:userId/:businessName/allLinks', component: MyprofileLinksComponent, canActivate: [AuthGuard] },
    { path: 'Merchant_Network/Check_Business_Profile/:userId/:businessName/pendingLinks', component: MyprofileLinksComponent, canActivate: [AuthGuard] },
    { path: 'QuickFeed/editComment/:postId', component: CommentBoxComponent, canActivate: [AuthGuard] },
    // {path:'viewmainproduct', component : MainproductComponent ,pathMatch:'full'}
] 