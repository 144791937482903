<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <h2 *ngIf="this.LoadingText !== ''">
            {{this.LoadingText}}
        </h2>
        <app-myapaar-spinner class="mypspin"></app-myapaar-spinner>
    </div>
</div>

<div class="content">
    <div class="bannerSCentral">
        <img src="../../../assets/productcategoryimages/allprods.png" alt="view all myapaar" />
    </div>
    <div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <app-prod-card [getProd]="product" *ngFor="let product of selectedProduct" fxFlex="33%" fxFlex.xs="100%"
                fxFlex.sm="50%" class="product-card"></app-prod-card>
        </div>
    </div>
</div>