<div class="main">
    <!-- <div class="leads" *ngIf="this.allLeads && this.allLeads.length > 0">
        <div class="contentLeads" *ngFor="let leads of this.allLeads">
            <div class="leadCard secondary">
                <div class="basicRow">
                    <div class="accountTypeLead">{{leads.accountType}}</div>
                    <div class="callLead"> <mat-icon class="callIcon"> call</mat-icon> <span>{{leads.phone}}</span>
                    </div>
                </div>
                <div class="basicRow">
                    <div class="itemName">{{leads.itemName}}</div>
                    <div class="itemQuantity">{{leads.itemQuantity}} Ql.</div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="userVerification">
        <div class="unverifiedID" *ngIf="!this.gstDetailsPending && this.gstVerificationPending">
            <h5>Verification Pending</h5>
            <h6>KYC status will be updated soon</h6>
        </div>
        <div (click)="this.changeUserInfo()" class="unverifiedID"
            *ngIf="this.gstDetailsPending">
            <h6>KYC Pending. Click here to update your KYC</h6>
            <h6>Or email us at solutions@myapaar.com</h6>
        </div>
    </div>
    <div class="landingproducts">
        <app-landingproducts [appBannerslandingproducts]="appBannerslandingproducts"></app-landingproducts>
    </div>
    <div class="landingvarieties">
        <app-landingvarieties [appBannerslandingvarieties]="appBannerslandingvarieties"></app-landingvarieties>
    </div>
    <!-- <div class="itemsWithLots">
        <app-products-with-lot-size></app-products-with-lot-size>
    </div> -->
    <div class="featuredlistingcat">
        <img class="featuredListings" alt="myapaar bulk wholesale trade Listings"
            src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/6myapaar_featured_bulk_orders_food.png" />
        <app-featuredlistingcategory></app-featuredlistingcategory>
    </div>
    <div class="categoriesproduct">
        <img class="featuredListings" alt="myapaar bulk wholesale trade Listings categories suppliers grains spices"
            src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/10-myapaar-categories.png" />
        <app-categoriesproduct></app-categoriesproduct>
    </div>
    <div class="featuredproductvarieties">
        <app-featuredproductvarieties
            [appBannersallproducthomegrid]="appBannersallproducthomegrid"></app-featuredproductvarieties>
    </div>
    <!-- <app-allproducthomegrid [appBannersallproducthomegrid]="appBannersallproducthomegrid"></app-allproducthomegrid> -->
</div>

<!-- <app-landingstates></app-landingstates> -->