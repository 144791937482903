<div class="banner">
    <div class="overlay">
        <h1 class="fade-in-text">{{ this.bannerHeader}}</h1>
    </div>
</div>
<p class="ucol cardHeader">
    {{this.myLinksHeader}}
</p>
<div class="contentSC">
    <div [ngSwitch]="this.dataType">
        <ng-template ngSwitchCase="acc">
            <div class="profCards">
                <app-acc-us-card class="allProfs" [sendProfCard]="eachProfCard" [sendCurrUsIdOfOwn]="this.currUserId"
                    *ngFor="let eachProfCard of this.links">
                </app-acc-us-card>
            </div>
        </ng-template>
        <ng-template ngSwitchCase="pnd">
            <div class="profCards">
                <app-penduscard class="allProfs" [sendProfCard]="eachProfCard" [sendCurrUsIdOfOwn]="this.currUserId"
                    (remUsrFromPending)="remCnCorAccPendReq($event)" *ngFor="let eachProfCard of this.links">
                </app-penduscard>
            </div>
        </ng-template>
    </div>
</div>