<div class="example-container" cdkDropListGroup>
    <button class="inTeamSave" mat-raised-button>Save</button>
    <div class="inTeam">
        <div cdkDropList [cdkDropListData]="this.myTeam" class="teamMember inTeamBox"
            (cdkDropListDropped)="drop($event)">
            <h2 class="head">In Team</h2>
            <div class="teamBox">
                <div cdkDrag class="teamsHere" *ngFor="let people of this.myTeam">
                    <div class="teamCard">
                        <div class="name">
                            {{people?.firstname}} {{people?.lastname}}
                        </div>
                        <div *ngIf="people?.phone != null" class=" contact">
                            <mat-icon class="contactIcon"> call</mat-icon>
                            {{people?.phone}}
                        </div>
                        <div *ngIf="people?.email != ''" class="contact">
                            <mat-icon class="contactIcon"> email</mat-icon>
                            {{people?.email}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="others">
        <div cdkDropList [cdkDropListData]="this.membersNotIncluded" class="teamMember othersBox"
            (cdkDropListDropped)="drop($event)">
            <div>
                <h2 class="head">Others ( Reject KYC in 30 days )</h2>
                <div class="teamBox">
                    <div cdkDrag class="teamsHere" *ngFor="let people of this.membersNotIncluded">
                        <div class="teamCard">
                            <div class="name">
                                {{people?.firstname}} {{people?.lastname}}
                            </div>
                            <div *ngIf="people?.phone != null" class=" contact">
                                <mat-icon class="contactIcon"> call</mat-icon>
                                {{people?.phone}}
                            </div>
                            <div *ngIf="people?.email != ''" class="contact">
                                <mat-icon class="contactIcon"> email</mat-icon>
                                {{people?.email}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>