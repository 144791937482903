<div class="content-loading">
    <div *ngIf="isLoading" class="wrapper">
        <div *ngFor="let item of [].constructor(4); let i = index" class="loadSkeleton singleCards">
            <div class="shine-splash"></div>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="wrapper">
            <div class="product-grid">
                <app-flproductcard class="featureListcard"
                    *ngFor="let featuredList of this.featuredListings |  slice:5:10; let i=index"
                    [sendFeaturedList]="featuredList"></app-flproductcard>
                <app-flproductcard class="featureListcard"
                    *ngFor="let featuredList of this.featuredListings | slice:0:5; let i=index"
                    [sendFeaturedList]="featuredList"></app-flproductcard>
                <!-- <app-flproductcard *ngFor="let featuredList of this.featuredListings | slice:6:12; let i =index"
                    [sendFeaturedList]="featuredList"></app-flproductcard> -->
            </div>
        </div>
    </div>
</div>