<div class="bannerData">
  <div class="image">
    <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
    <div class="img-slider">
      <div class="slide">
        <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000" color="accent"
          [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true" [useKeyboard]="true"
          [maintainAspectRatio]="false" orientation="ltr">
          <mat-carousel-slide *ngFor="let slide of this.appBannerslandingvarieties; let i = index" [image]="slide"
            overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
        </mat-carousel>
      </div>
    </div>
    <div *ngIf="isLoading">
      <div class="itemRowLoading">
        <div *ngFor="let item of [].constructor(4); let i = index" class="loadSkeleton card-wrapper singleCards">
          <div class="shine-splash"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="overlap-varieties">
      <div *ngIf="this.featuredVarietyListings" class="card-wrapper">
        <div class="itemRow">
          <app-landing-var-cards *ngFor="let product of this.featuredVarietyListings| slice:0:6 ; let i = index"
            [getProd1]="product"></app-landing-var-cards>
        </div>
      </div>
    </div>
  </div>
</div>