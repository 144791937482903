<div *ngIf="this.sendFeaturedList" class="product-card small-card">
    <img [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]"
        [src]="this.sendFeaturedList.imageUrls[0]" alt="{{this.sendFeaturedList.title}}">
    <div class="info">
        <h3
            [routerLink]="['/Listing/Place_Bulk_Order',this.sendFeaturedList._id,this.sendFeaturedList.categoryUnique,this.sendFeaturedList.productName]">
            {{this.sendFeaturedList.title}}</h3>
        <p>
            <button *ngIf="!this.isShowPrice" (click)="showPrice()" mat-stroked-button class="primary">
                Show Price
            </button>
            <button *ngIf="this.isShowPrice" mat-stroked-button class="primary">
                <span>{{(this.sendFeaturedList.bulkPrice).toLocaleString('en-In')}}</span>
                <span> ₹ / {{this.sendFeaturedList.useUnitShort}}</span>
            </button>
        </p>
    </div>
</div>