<div class="contentFeaturedVarietiesFeaturedVarieties">
    <div class="bannerData">

        <div class="image">
            <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
            <div class="img-slider">
                <div class="slide">
                    <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000"
                        color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true"
                        [useKeyboard]="true" [maintainAspectRatio]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let slide of this.appBannersallproducthomegrid; let i = index"
                            [image]="slide" overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" class="wrapper">
            <div *ngFor="let item of [].constructor(4); let i = index" class="loadSkeleton singleCards">
                <div class="shine-splash"></div>
            </div>
        </div>
        <div class="categoriesOverlay" *ngIf="!isLoading && this.featuredVarietyurrentStateListings">
            <div class="categories">
                <app-flvarietycard class="listing-card"
                    *ngFor="let featuredList of this.featuredVarietyurrentStateListings | slice:0:3; let i=index"
                    [sendFeaturedList]="featuredList"></app-flvarietycard>
                <app-flvarietycard class="listing-card"
                    *ngFor="let featuredList of this.featuredVarietyurrentStateListings | slice:4:7; let i=index"
                    [sendFeaturedList]="featuredList"></app-flvarietycard>
            </div>
        </div>
    </div>