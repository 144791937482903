<div class="wrapper">
    <div class="post-card-header">
        <h1>Quick Feed</h1>
        <h3>Share Day to Day</h3>
        <h4 class="text-container">
            <span class="fade">{{ currentActivity }}</span>
        </h4>
        <h5>Across The Globe</h5>
    </div>

    <app-quick-feed [profDet]="this.myProfDet" [auth]="this.userIsAuthenticated" class="eachPostList"></app-quick-feed>
    <div *ngIf="this.viewSharedFeed">
        <app-listing-posts class="eachPostList" [shrfd]="true" [post]="this.viewSharedFeed"></app-listing-posts>
    </div>
    <div *ngIf="this.paginatedQFPs && this.paginatedQFPs.length > 0">
        <app-listing-posts class="eachPostList" [shrfd]="false" *ngFor="let post of paginatedQFPs"
            [post]="post"></app-listing-posts>
    </div>
    <div *ngIf="this.isQFPLoading || this.isLoading">
        <app-myapaar-spinner class="mypspin"></app-myapaar-spinner>
    </div>
</div>