import { Component, OnInit } from '@angular/core';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AllstatesService } from 'src/app/services/allstates.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CC } from 'src/app/datamodels/groupedmodel/countryCodeModel';

@Component({
  selector: 'app-two-account-info',
  templateUrl: './two-account-info.component.html',
  styleUrls: ['./two-account-info.component.css']
})
export class TwoAccountInfoComponent implements OnInit {
  isLoading: boolean = false;
  signupform: FormGroup
  hide = true;
  stateName: string;
  viralityReferral: string
  validationMessages = {
    'fullname': {
      'required': 'First name is required',
      'maxlength': 'First name cannot have more than 50 characters!'
    },
    //
    'password': {
      'required': 'Password is required'
    },
    'isRegistered': {
      'required': 'Toggle on if you have a License for trading'
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    'countryCodeControl': {
      'required': 'Country Code is required',
    },
    'email': {
      'required': 'Please Add Email',
      'pattern': 'Email not in valid format'
    },
  }
  //js object to display form errors
  formErrors = {
    'fullname': '',
    'password': '',
    'isRegistered': '',
    'pinCode': '',
    'phone': '',
    'countryCodeControl': '',
    'email': '',
    'GST_Id': '',
    'PAN_Id': '',
    // 'Aadhaar_Id':'',

  }
  oneAccountData: any
  skipPageForLogistics: boolean = false;
  useCCs: CC[] = [];
  filteredCC: Observable<CC[]>;
  selectedCC: CC = {
    name: "India",
    dial_code: "+91",
    code: "IN"
  }
  constructor(private pageShift: SignUpPageShiftService,
    private _location: Location,
    private _snackBar: MatSnackBar,
    private router: Router,
    private st: AllstatesService
  ) { }

  ngOnInit(): void {
    this.pageShift.currentData.subscribe(data => {
      if (!data) {
        this.router.navigate(['/signup/DailyBulkSupply'])
      }
      this.oneAccountData = data
    });
    this.signupform = new FormGroup({
      //generic
      fullname: new FormControl('', {
        validators: [Validators.maxLength(100)]
      }),
      countryCodeControl: new FormControl(this.selectedCC, {
        validators: [Validators.maxLength(5)]
      }),
      phone: new FormControl('', {
        validators: [Validators.minLength(10), Validators.maxLength(10)]
      }),
      email: new FormControl('', {
        validators: [Validators.maxLength(250), Validators.minLength(5), Validators.pattern(/.+@.+\..+/)]
      }),
      password: new FormControl('', { validators: [Validators.required] }),
      referral: new FormControl(''),
    });

    this.st.getCC().subscribe(ch => {
      this.useCCs = ch
    })
    this.signupform.valueChanges.subscribe(data => {
      this.onValueChanged(data)
    });
    this.onValueChanged();
    this.filteredCC = this.signupform.get('countryCodeControl').valueChanges.pipe(
      startWith(''), // Start with an empty string
      map(value => this._filter(value)) // Filter the list
    );
  }
  // Filter function to match user input with country codes
  private _filter(value: string): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.useCCs.filter(option =>
        option.name.toLowerCase().includes(filterValue) ||
        option.dial_code.toLowerCase().includes(filterValue)
      );
    }
    return this.useCCs;
  }
  // Display function to show the selected country code in the input field
  displayFn(country: any): string {
    this.selectedCC = country
    return country ? `${country.name} (${country.dial_code})` : '';
  }
  onValueChanged(data?: any) {
    if (!this.signupform) {
      return;
    }
    const sform = this.signupform;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = sform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              if (messages[key] === undefined) {
                messages[key] = ''
              }
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }
  goBack() {
    try {
      this._location.back()
    }
    catch (e) {
      throw e
    }
  }
  nextOne() {
    try {
      if (!this.signupform.get('phone').value && !this.signupform.get('email').value) {
        const errMEssage = "Add Phone number or Email"
        this.signupError(errMEssage, "Ok")
        return
      }
      if (!this.signupform.get('password').value) {
        const errMEssage = "Create password"
        this.signupError(errMEssage, "Ok")
        return
      }
      const accountInfo = {
        accountTypeName: this.oneAccountData.accountTypeName,
        subAccountTypesChosen: this.oneAccountData.subAccountTypesChosen,
        personName: this.signupform.value.fullname,
        accountEmail: this.signupform.value.email,
        phone: this.signupform.value.phone,
        cc: this.signupform.value.countryCodeControl,
        password: this.signupform.value.password,
      }
      this.pageShift.setAccTypeData(accountInfo)
      const regex = /logistics/i;
      this.skipPageForLogistics = this.oneAccountData.subAccountTypesChosen.map(sbAt => {
        return sbAt
      }).some((eachAt: string) => {
        return regex.test(eachAt)
      })
      if (this.skipPageForLogistics) {
        return this.router.navigate(['/signup/BusinessInfo'])
      }
      this.skipPageForLogistics = false
      this.router.navigate(['/signup/Commodities'])
      // this.router.navigate(['/signup/BusinessInfo'])
    }
    catch (e) {
      throw e
    }
  }

  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
