import { Component, Input, OnInit, Output, EventEmitter, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { QuickFeedNetwork } from 'src/app/datamodels/QuickFeed/QuickFeedModel';
import { Review } from 'src/app/datamodels/productreview';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { QuickViewServiceService } from 'src/app/services/quick-view-service.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-listing-posts',
  templateUrl: './listing-posts.component.html',
  styleUrls: ['./listing-posts.component.css']
})
export class ListingPostsComponent implements OnInit {
  @Input() post: QuickFeedNetwork;
  @Input() shrfd: boolean
  @Input() listingId: string
  @Input() reviewSubmit: string
  @Input() reviewDelete: string
  @Output() totalReviewsEvent = new EventEmitter<string>();
  @Output() reviewDeletedEvent = new EventEmitter<string>();
  showFullDescription = false;
  reviewDeleted: string;
  loginUserInfo: any
  reviewSubmitted: {
    review: Review;
    status: string;
  };
  userIsAuthenticated: boolean = false;
  currentUserId: any;
  totalReviewsonCurrentListing: number;
  styledDescription: string = '';
  postId: string;
  commsOnPost: number;
  isCommVisible = false;
  isOverlayOpen: boolean = false;
  backgroundImage: string;
  isUserLogoPresent: boolean = false;
  isUserNamePresent: boolean = false;
  isUserBizNamePresent: boolean = false;
  qfsCreator: string;
  isDocOverlayOpen: boolean = false;
  seeDoc: string;
  docOpen: string;
  postComments: Review[] = [];
  routeUrl: string;
  constructor(
    private authService: AuthService,
    public qvds: QuickViewServiceService,
    private router: Router,
    private commentservice: CommentpostService,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.doAuth()
    this.postId = this.post.qfId
    this.postComments = this.post.comments
    this.commsOnPost = this.postComments.length
    if (this.post?.logoUser && this.post?.logoUser !== '') {
      this.isUserLogoPresent = true
    }
    if (this.post?.firstname && this.post?.firstname !== '') {
      this.isUserNamePresent = true
    }
    if (this.post?.companyName && this.post?.companyName !== '') {
      this.isUserBizNamePresent = true
    }
    if (!this.isUserBizNamePresent) {
      this.qfsCreator = this.post?.qfsCreatorId.substring(0, 8)
    }
    this.routeUrl = this.router.url
    // this.setDescription(this.post.description)
  }


  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo()
      if (this.userIsAuthenticated) {
        this.currentUserId = this.loginUserInfo.userId
      }
    }
    catch (e) { }

  }
  openProfile(userId: string, bname: string) {
    const useBname = this.utils.getBusinessNameForURL(bname)
    this.router.navigate(['Merchant_Network/Check_Business_Profile', userId, useBname])
  }
  toggleVisibility() {
    try {
      this.isCommVisible = !this.isCommVisible;
    }
    catch (e) {
      throw e
    }
  }

  // highlightImportantWords(description: string): string {

  //   if (!description) return '';

  //   const words = description.split(' ');
  //   const importantWords = words
  //     .filter(word => word.length > 3) // Prioritize longer words
  //     .sort((a, b) => b.length - a.length) // Sort by length
  //     .slice(0, 5); // Pick top 5 important words

  //   const styledDescription = words
  //     .map(word => (importantWords.includes(word)
  //       ? `<span style="color:#ff4141; font-weight:500; font-style:italic;">${word}</span>`
  //       : word))
  //     .join(' ');

  //   return `<div style="font-family: Arial, sans-serif; line-height: 1.6; font-size: 16px; color: #333;">
  //             ${styledDescription}
  //           </div>`;
  // }
  ratingsAsStars(stars: any) {
    return Array(stars)
  }

  shareWhatsapp(feedId: string) {
    try {
      const buttonName = "Share Feed*-" + feedId
      this.authService.clikmtrik(this.routeUrl, buttonName)
      this.utils.shareFeed(feedId)
    }
    catch (e) {
      throw e
    }
  }
  // setDescription(description: string) {
  //   this.styledDescription = this.highlightImportantWords(description);
  // }
  toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
  }
  // Check if the description has more than 100 characters
  hasMoreThan100Chars(description: string): boolean {
    if (!description) return false; // Return false if description is null or undefined
    return description.length > 100; // Return true if there are more than 100 characters
  }
  // Determine the number of columns for the photo grid
  getGridColumns(photoCount: number): number {
    if (photoCount === 1) return 1;
    if (photoCount === 2) return 2;
    if (photoCount === 3) return 2; // 2 columns for 3 images
    if (photoCount === 4) return 2;
    return 3; // Default for 5+ photos
  }

  // Determine colspan for the third image
  getColspan(photoCount: number, index: number): number {
    if (photoCount === 3 && index === 2) {
      return 2; // Third image spans 2 columns
    }
    return 1; // Default colspan
  }

  reloadPostList(submittedStatus: any) {
    try {
      if (submittedStatus.status == '201') {
        if (submittedStatus.review) {
          const pushNewComm = {
            author: submittedStatus.review.author,
            authorLogo: submittedStatus.review.authorLogo,
            comment: submittedStatus.review.comment,
            creatorID: submittedStatus.review.creatorID,
            date: submittedStatus.review.date,
            listingId: submittedStatus.review.listingId,
            rating: submittedStatus.review.rating,
            reviewImage: submittedStatus.review.reviewImage, // Fixed typo: 'reviewImage' instead of 'reViewImage'
            _id: submittedStatus.review._id,
          };

          // Add the new comment to the array
          this.postComments = this.postComments.concat(pushNewComm);
          // Log the updated array
        } else {
          console.error('submittedStatus.review is undefined');
        }
      }
      // this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }
  reloadPostListOnDelete(submittedStatus: string) {
    try {
      this.reviewDeleted = submittedStatus
      // this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }
  getTotalReviewsonListing(reviewsNumber: any) {
    try {
      this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }

  onDeleteComment(commentId: string) {
    try {
      // Optionally, you can call a backend API to delete the comment from the database
      this.commentservice.deleteReview(commentId).then(
        (del) => {
          // Find the index of the comment in the post.comments array
          const commentIndex = this.postComments.findIndex(comment => {
            return comment._id === commentId
          });
          // If the comment is found, remove it from the array
          if (commentIndex !== -1) {
            this.postComments.splice(commentIndex, 1);
          }
        },
        (error) => {
          console.error('Error deleting comment:', error);
        }
      );
    }
    catch (e) {
      throw e
    }
  }
  checkImg(photo: string) {
    try {
      this.qvds.quickViewImg(photo)
        .subscribe(resParentCategory => {
        })
    }
    catch (e) {
      throw e
    }
  }
  openOverlayDoc(docUrl: string) {
    try {
      this.fetchAndOpenPdf(docUrl).then(docObBlob => {
        this.docOpen = docObBlob
        this.isDocOverlayOpen = true;
      })
    }
    catch (e) {
      throw e
    }
  }
  async fetchAndOpenPdf(docUrl: string) {
    try {
      const pdfBlob = await this.commentservice.gets3Url(docUrl);
      const docObUrlBlob = URL.createObjectURL(pdfBlob); // Convert Blob to URL
      return docObUrlBlob
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }

  // Close the overlay
  closeDocOverlay() {
    this.isDocOverlayOpen = false;

    if (this.docOpen) {
      URL.revokeObjectURL(this.docOpen); // Clean up the Blob URL
    }
    this.docOpen = null;
  }
  // Open the overlay
  openOverlay(photo: string) {
    this.backgroundImage = photo
    this.isOverlayOpen = true;
  }

  // Close the overlay
  closeOverlay() {
    this.isOverlayOpen = false;
  }
}
