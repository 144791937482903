<div class="content">
    <div class="loginBox">
        <div class="loginText">
            <h1>
                {{'member login'|translate}}
            </h1>
        </div>
        <app-login-box></app-login-box>
    </div>

</div>