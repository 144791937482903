import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';
interface selectAccount {
  id: number,
  accountTypeName: string,
  accountTypeIMG: string,
  accountSubType: string,
  checked: boolean
}
@Component({
  selector: 'app-one-accountype',
  templateUrl: './one-accountype.component.html',
  styleUrls: ['./one-accountype.component.css']
})
export class OneAccountypeComponent implements OnInit {
  allComplete: boolean = false;
  atleastOneItemSelected: boolean = false;
  selectedAccountsCards: selectAccount[] = []
  accountTypes: selectAccount[] = [
    { id: 1, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/1-myp-fpo.png', accountSubType: "Producer" },
    { id: 2, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/2-myp-manuf.png', accountSubType: "Manufacturer" },
    { id: 3, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/3-myp-agroprocess.png', accountSubType: "Agro-Processor" },
    { id: 5, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/4-myp-wholesale.png', accountSubType: "Wholesaler" },
    { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/5-foodsandbeverages.png', accountSubType: "F&B Company" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/9-ayurvedawellnessmyapaar.png', accountSubType: "Ayurveda Wellness" },
    { id: 7, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/6-myp-rest.png', accountSubType: "Restaurant" },
    { id: 8, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/7-myp-cafe.png', accountSubType: "Cafe" },
    { id: 9, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/8-cloudKitchen.png', accountSubType: "Cloud Kitchen" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/10-kirana.png', accountSubType: "Retail Grocery Kirana" },

  ]
  sendAccTypeData: any;
  constructor(private pageShift: SignUpPageShiftService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
  }
  updateAllComplete() {
    try {
      this.allComplete = this.accountTypes != null && this.accountTypes.every(t => t.checked);
    }
    catch (e) { throw e }
  }

  someComplete(): boolean {
    try {
      if (this.accountTypes == null) {
        return false;
      }
      const check = this.atleastOneItemSelected && !this.allComplete;
      this.atleastOneItemSelected = this.accountTypes.filter(t => t.checked).length > 0
      if (this.atleastOneItemSelected) {
        this.selectedAccountsCards = this.accountTypes.filter(t => t.checked)
      }
      return check
    }
    catch (e) {
      throw e
    }
  }

  setAll(completed: boolean) {
    try {
      this.allComplete = completed;
      if (this.accountTypes == null) {
        return;
      }
      this.accountTypes.forEach(t => (t.checked = completed));
    }
    catch (e) { throw e }
  }

  nextOne() {
    try {
      let useAccountType: string = ''
      const checkIfSeller = this.selectedAccountsCards.some(eachCard => {
        return eachCard && eachCard.accountTypeName && eachCard.accountTypeName === 'Seller'
      })
      checkIfSeller ? useAccountType = 'Seller' : useAccountType = 'Buyer'
      const allSubAccountTypes = this.selectedAccountsCards.map(eachCard => {
        return eachCard.accountSubType
      })
      if (!allSubAccountTypes || allSubAccountTypes.length === 0) {
        this.signupError('Pick Business Types', "Ok")
        return
      }
      const addAccountType = {
        accountTypeName: useAccountType,
        subAccountTypesChosen: allSubAccountTypes
      }
      this.pageShift.setAccTypeData(addAccountType)
      this.router.navigate(['/signup/PersonalInfo'])
    }
    catch (e) {
      throw e
    }
  }
  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
