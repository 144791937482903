<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen"><app-myapaar-spinner class="mypsin"></app-myapaar-spinner></div>
</div>


<div class="content">
    <div class="bannerIssues">
        <div class="img1 imgSec">
        </div>
        <div class="img2 imgSec">
        </div>
        <div class="img3 imgSec">
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="this.viewIssues && this.viewIssues.length >0">
            <p class="head-main">Order Issues </p>
            <div class="allissues">
                <div *ngFor="let issue of this.viewIssues">
                    <mat-card class="tp-card">
                        <mat-card-header>
                            <mat-card-title>
                                <div class="title">
                                    <div class="issuetitle">
                                        <span class="titleVer">
                                            {{issue.IssueID}}
                                        </span>
                                        <mat-card-subtitle class="subtitle">
                                            <span>{{issue.orderNumber}}</span>
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="issueStatus">
                                        <span class="headingStatus">Status</span>
                                        <p class="currentStatus">
                                            <span *ngIf="issue.issueStatus == 'Pending'"
                                                style="color: rgb(239, 147, 0);"> {{issue.issueStatus}}</span>
                                            <span *ngIf="issue.issueStatus === 'Resolved'"
                                                style="color: rgb(32, 149, 28);">
                                                {{issue.issueStatus}}</span>
                                        </p>
                                    </div>
                                </div>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="eachVersion">
                                <div class="invDetails">
                                    <span class="orderType">
                                        {{issue.orderType}} : </span>
                                    <span class="issueReason">
                                        {{issue.disputeReason.disputeDescription
                                        }}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div *ngIf="!this.viewIssues || this.viewIssues && this.viewIssues.length === 0">
            <p>No Issues Reported By You </p>
        </div>
    </div>
</div>