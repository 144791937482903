<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1>Contracts in Draft</h1>
            <h3>Create Instant Purchase Orders Here</h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="offerCheck">
    <h5>Flat 50% off on Sample Orders for <br>
        <span> <mat-icon class="ratings" *ngFor="let ratings of ratingsAsStars(3)"
                style="color: rgb(219, 223, 0)">star</mat-icon> </span>
        Merchants
        and order value below 500 ₹
    </h5>
</div>
<div *ngIf="this.cartisEmpty">
    <div class="emptyCart">
        <div [routerLink]="['/viewItems/All']" class="emptyCartImg">
            <button mat-stroked-button> Cart is empty.
                Add Items to create Purchase Orders</button>
        </div>
    </div>
</div>

<div class="contentCart" *ngIf="!this.cartisEmpty">
    <div class="unverifiedID" *ngIf="this.isShowLimit">
        <h5>eKYC Pending. Order Limit</h5>
    </div>
    <div>
        <div *ngIf="!this.groupedCartItemsWithSellerID">
            Cart is empty
        </div>
        <div class="product-card" *ngFor="let item of groupedCartItemsWithSellerID | keyvalue">
            <div class="soldBy">
                <div *ngIf="item.value.length > 0" class="bulkBundle">
                    <h1 class="header">Supplied By {{item.value[0].sellerCompanyName }} </h1>
                    <div class="bulkOrderItems" *ngFor="let value of item.value">
                        <mat-card [ngClass]="value.type">
                            <mat-card-content>
                                <div class="listingcontainer">
                                    <div class="listingImageSec">
                                        <div class="listingImage">
                                            <div class="listingItemImage">
                                                <img class="itemImage" mat-card-image
                                                    [routerLink]="['/Listing/Place_Bulk_Order',value.currentListing._id,value.currentListing.categoryUnique,value.currentListing.productName]"
                                                    [src]="value.currentListing.imageUrls[0]" />
                                            </div>
                                            <div class="packagingImg">
                                                <img class="packagingImage" mat-card-image
                                                    [routerLink]="['/Listing/Place_Bulk_Order',value.currentListing._id,value.currentListing.categoryUnique,value.currentListing.productName]"
                                                    [src]="value.packagingType.packagingImg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listingDetails">
                                        <div class="titleHeader">
                                            <div class="title"
                                                [routerLink]="['/Listing/Place_Bulk_Order',value.currentListing._id,value.currentListing.categoryUnique,value.currentListing.productName]">
                                                {{value.currentListing.title
                                                }}
                                                <div class="packInfo">
                                                    <mat-hint class="packType">
                                                        {{ value.packagingType.packagingType}}
                                                    </mat-hint>
                                                </div>
                                            </div>

                                            <div class="deleteitem">
                                                <button mat-icon-button color="warn"
                                                    (click)="deleteItem(value._id,value.listingId)"><mat-icon>clear</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="priceQuant">
                                            <div class="detailsItem">
                                                <div class="productDetails">
                                                    <span class="itemProd">
                                                        {{value.currentListing.productName}}
                                                    </span>
                                                    <span class="itemCat">
                                                        |&nbsp; {{value.currentListing.categoryName}}
                                                    </span>
                                                    <span
                                                        *ngIf="value.currentListing.varietyName !== '' && value.currentListing.varietyName !== null && value.currentListing.varietyName !== 'null' && value.currentListing.varietyName !== undefined"
                                                        class="itemVar">
                                                        |&nbsp; {{value.currentListing.varietyName}}
                                                    </span>
                                                </div>
                                                <div class="itemQuantityCredit">
                                                    <span
                                                        class="priceValue">{{(value.totalAmount).toLocaleString('en-IN')}}
                                                        ₹</span>
                                                    <span *ngIf="value.quantityAdded > 0.05">{{value.quantityAdded}}
                                                        {{value.currentListing.useUnit}}</span>
                                                    <span>
                                                        {{value.type | uppercase}} ORDER</span>
                                                    <span *ngIf="value.quantityAdded <= 0.05">{{value.quantityAdded
                                                        *
                                                        100}}
                                                        KG</span>
                                                </div>

                                            </div>
                                            <div class="spacerDiv">
                                            </div>
                                            <div class="taxPayments">
                                                <div [routerLink]="['/Listing/Place_Bulk_Order',value.currentListing._id,value.currentListing.categoryUnique,value.currentListing.productName]"
                                                    class="listingBuy">
                                                    <div class="itemDetail">
                                                        <span> Tax {{value.currentListing.taxSlab}} % </span> <span
                                                            class="spacer"></span>&nbsp;
                                                        <span class="itemHSN">
                                                            HSN {{value.currentListing.hsnCodeItem}}
                                                        </span>
                                                    </div>


                                                    <div class="price">
                                                        <div style="color: rgb(255, 255, 255);" class="disclaimerPO"
                                                            *ngIf="value.creditTerms.creditTermDays > 0">
                                                            Pay anytime in
                                                            {{value.creditTerms.creditTermStatement}}
                                                        </div>
                                                        <div style="font-weight: 500; color: rgb(184, 255, 188);"
                                                            class="disclaimerInstantBuy"
                                                            *ngIf="value.creditTerms.creditTermDays===0">
                                                            {{value.creditTerms.creditTermStatement}}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="action">
                        <button mat-raised-button (click)="createBundledPurchaseOrder(item.value)"
                            style="background-color: #ff4141; font-weight: 450;color: white;">
                            <span>Add Delivery Address</span></button>
                    </div>
                </div>
            </div>
            <!-- <div>
                        <button mat-raised-button
                            style="background-color: rgb(35, 109, 246); font-weight: 450;color: white;">Add more Item by
                            Seller
                            Order</button>
                    </div> -->
        </div>
    </div>
</div>