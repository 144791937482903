import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ImageHandlingService {


  constructor() { }
  async compressMultiple(files: FileList): Promise<File[]> {
    try {
      const compressedFiles: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const compressedFile = await this.resizeSingleImage(file);
        compressedFiles.push(compressedFile);
      }
      return compressedFiles;
    }
    catch (e) {
      throw e
    }
  }
  resizeSingleImage(file: File): Promise<File> {
    try {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calculate new dimensions while preserving aspect ratio
            const maxWidth = 800; // Maximum width for the resized image
            const maxHeight = 800; // Maximum height for the resized image
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas
            if (ctx) {
              ctx.drawImage(img, 0, 0, width, height);
            }

            // Convert canvas to Blob and then to File
            canvas.toBlob((blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, { type: file.type });
                resolve(compressedFile);
              }
            }, file.type);
          };
          img.src = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      });
    }

    catch (e) {
      throw e
    }
  }

  async generateImagePreviews(files: File[]): Promise<string[]> {
    try {
      const previews: string[] = [];
      for (const file of files) {
        const preview = await this.readFileAsDataURL(file);
        previews.push(preview);
      }
      return previews;
    }
    catch (e) {
      throw e
    }
  }

  readFileAsDataURL(file: File): Promise<string> {
    try {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          resolve(event.target?.result as string);
        };
        reader.readAsDataURL(file);
      });
    }
    catch (e) {
      throw e
    }

  }
}

