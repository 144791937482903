<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="this.openPanel">
    <mat-toolbar>
      <span class="logoState">Mobile Se Vyapaar</span>
      <button class="sNavBT" (click)="drawer.toggle()" mat-stroked-button>
        <mat-icon aria-label="Myapaar Orders" class="drawClose">first_page</mat-icon>
      </button>
    </mat-toolbar>
    <!-- <div class="SellerAccount" *ngIf="userIsAuthenticated &&
this.loginUserInfo.accounttype=='Seller'">
        <button mat-raised-button class="nonfeatured-button" routerLink="/createListing">Post New Item</button> <br>
        <button mat-raised-button routerLink="/createListing">View Old Listings</button> <br>

    </div> -->
    <mat-nav-list>
      <div class="navOptions">
        <div class="currentLocationTab">
          <mat-form-field id="pincodeField" appearance="outline">
            <mat-label class="noPincode" *ngIf="!selectedPincode || selectedPincode === 'undefined'">
              {{'add pincode'|translate}}</mat-label>
            <mat-label *ngIf="selectedPincode ">{{'pincode' | translate}}</mat-label>
            <input [(ngModel)]="selectedPincode" (ngModelChange)="getMyPincodeDetails()" matInput autocomplete="off" />
          </mat-form-field>
          <mat-form-field id="cityField" appearance="outline">
            <mat-label>{{'city'|translate}}</mat-label>
            <input [(ngModel)]="districtName" disabled matInput autocomplete="off" required />
          </mat-form-field>
        </div>
        <a mat-list-item class="blinking">
          <mat-label class="langLabel">
            <span class="cR" *ngIf="!this.selectedLang">Language / भाषा</span>
            <span class="cB" *ngIf="this.selectedLang">
              {{this.selectedLang}}
            </span>
          </mat-label>
          <mat-select [(ngModel)]="selectedLang" (ngModelChange)="letsChangeLang($event)" name="language">
            <mat-option class="cB" *ngFor="let lng of languages" [value]="lng.value">{{lng.viewValue}}</mat-option>
          </mat-select>
        </a>
        <!-- <a mat-list-item routerLink="/viewAllcategories">View Categories</a> -->
        <a mat-list-item [matMenuTriggerFor]="menu">{{'categories' | translate}}</a>
        <mat-menu #menu="matMenu">
          <div class="categories-menu" *ngFor="let category of this.productcategories">
            <button class="categories-menu-button" [routerLink]="['/viewItems',category.categoryName]"
              mat-menu-item>{{category.categoryName}}</button>
          </div>
        </mat-menu>

        <!-- <a mat-list-item routerLink="/viewAllPurchaseOrders">My Orders</a> -->
        <a mat-list-item routerLink="/ManageOrders" routerLinkActive="active">
          {{'my orders' | translate}}
        </a>
        <a mat-list-item routerLink="/viewListing" routerLinkActive="active"
          *ngIf="userIsAuthenticated && this.loginUserInfo?.accounttype=='Seller'">
          {{'my listings' | translate}}
        </a>
        <!-- <a mat-list-item routerLink="/MTR/MyTradeRoom/liveTrades" routerLinkActive="active" *ngIf="userIsAuthenticated">
          {{'trade window' | translate}}
        </a> -->
        <!-- #Hidden -->
        <!-- <a mat-list-item [routerLink]="['/viewBids',this.currentUserId]">My Bids</a> -->
        <!-- <a mat-list-item routerLink="/usercontactsecurity">Contact and Security</a> -->
        <!-- <a mat-list-item routerLink="/useraddresses">Addresses</a> -->
        <!-- <a mat-list-item routerLink="/userrequestedproductform">Request Items</a> -->
        <!-- <a mat-list-item routerLink="/usersavedproducts">Wishlist Items</a> -->
        <a *ngIf="userIsAuthenticated" mat-list-item
          [routerLink]="['/Merchant_Network/Check_Business_Profile/', this.currentUserId,this.businessName]"
          routerLinkActive="active">
          {{'my profile' | translate}}
        </a>
        <!-- <a mat-list-item [routerLink]="['/accountInformation', this.currentUserId]">Edit Profile</a> -->
        <!-- <a mat-list-item [routerLink]="['/accountInformation', this.currentUserId]">Edit Profile</a> -->
        <!-- <a mat-list-item routerLink="/farmgallery">Farm Gallery</a> -->
        <!-- <a mat-list-item routerLink="/userfarmvisitform">Plan Farm Visit</a> -->
        <a mat-list-item *ngIf="userIsAuthenticated" class="logoutButton" (click)="onLogout()">
          {{'logout' | translate}}
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>



  <mat-sidenav-content>
    <mat-toolbar class="position-fixed">
      <div class="toolbarIcons">
        <div class="leftIcons">
          <!-- <button (click)="toggleTheme()">Toggle Theme</button> -->
          <!-- <button id="backButton" mat-button></button> -->
          <mat-icon mat-icon-button *ngIf="!hasRoute('/') && !hasRoute('/home')" class="backButtonIcon"
            appBackButton>keyboard_arrow_left</mat-icon>
          <mat-icon mat-icon-button class="menuButton" (click)="drawer.toggle()"
            aria-label="Side nav toggle icon">menu</mat-icon>
          <button mat-stroked-button routerLink="/home" routerLinkActive="homeButton" class="homeButton sNavBT">
            <img class="homeIcon" src="../../assets/svgiconsandimages/mainHome.png" />
          </button>
          <!-- #Hidden  -->
          <!-- <button mat-button class="bidsButton" [routerLink]="['/viewBids',this.currentUserId]"> Bids </button> -->
          <button mat-button class="sNavBT">
            <mat-icon routerLink="searchGlobal" routerLinkActive="searchIcon" *ngIf="!hasRouteSearch('searchGlobal')"
              class="searchIcon">search</mat-icon>
          </button>
        </div>
        <div class="rightIcons">
          <button [matBadge]="this.listingsCreatedBySeller" matBadgeOverlap="true" class="addListing sNavBT" mat-button
            routerLink="/createListing" routerLinkActive="addListing"
            *ngIf="userIsAuthenticated && this.loginUserInfo?.accounttype=='Seller'">
            <span matTooltip="Add Items on platform to Sell">+ {{'list'|translate}}</span>
          </button>
          <button mat-button class="cartButton" routerLinkActive="cartButton sNavBT" routerLink="/cart">
            <div [matBadge]="this.cartItemsCount" [matBadgeHidden]="this.cartItemsCount < 1" matBadgeOverlap="true"
              class="demo-section">
              <span matTooltip="Purchase Orders in process">{{'cart'|translate}}</span>
            </div>
          </button>
          <button [routerLink]="['/Merchant_Network/Check_Business_Profile/', this.currentUserId,this.businessName]"
            *ngIf="userIsAuthenticated" mat-button class="sNavBT">
            <mat-icon [matBadge]="this.pendLnksCnt" matBadgeOverlap="true" matTooltip="Profile setup" class="profileIc"
              routerLinkActive="profileIc" mat-button>account_circle</mat-icon>
          </button>
          <!-- <button mat-button *ngIf="!userIsAuthenticated" class="loginButton" routerLink="/login">Login</button> -->
        </div>
      </div>
    </mat-toolbar>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-footer class="footerApp"></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>