import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';

@Component({
  selector: 'app-view-all-invoices',
  templateUrl: './view-all-invoices.component.html',
  styleUrls: ['./view-all-invoices.component.css']
})
export class ViewAllInvoicesComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  userIdPurchaseOrders: PurchaseOrderModel[] = []
  sellerTypePurchaseOrders: PurchaseOrderModel[] = []
  buyerTypePurchaseOrders: PurchaseOrderModel[] = []
  sellerTypeDataSource: PurchaseOrderModel[] = []
  buyerTypeDataSource: PurchaseOrderModel[] = []
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  invoiceVersionsAvailableForPO: boolean = false
  isSellerCheckingINVOtypeAsBuyer: boolean = false
  preseveSellerTypeData: PurchaseOrderModel[] = []
  preserveBuyerTypeData: PurchaseOrderModel[] = []
  sellerTypeSearch: string
  buyerTypeSearch: string
  isPOSavailable: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private handlePurchaseOrder: HandlePurchaseOrderService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId
    this.fetchAccountPOsByAccountType = this.loginUserInfo.accounttype
    this.currentUserId && this.currentUserId !== '' ? this.getAllUserPurchaseOrders(this.currentUserId, this.fetchAccountPOsByAccountType) : this.router.navigate(['/'])
    // console.log('un-parsedlogDetails', this.loginUserInfo)
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
  }


  getAllUserPurchaseOrders(currentUserId: string, loginAccounttype: string) {
    try {
      this.handlePurchaseOrder.getAllUserPurchaseOrders(currentUserId, loginAccounttype).subscribe(
        userFetchedPOs => {
          this.isLoading = false;
          if (userFetchedPOs && userFetchedPOs.length > 0) {
            this.isPOSavailable = true
            this.userIdPurchaseOrders = userFetchedPOs.filter(eachPO => { return eachPO.invoiceVersions.length > 0 })
            if (this.userIdPurchaseOrders) {
              this.isLoading = false;
              this.invoiceVersionsAvailableForPO = true
              this.sellerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.sellerDetails._id === this.currentUserId })
              this.buyerTypePurchaseOrders = this.userIdPurchaseOrders.filter(eachPO => { return eachPO.buyerDetails.buyerId === this.currentUserId })
              this.displayedBuyerTypeTableColumns = ["Purchase Order No.", "Invoice Versions", "Seller Name"]
              this.buyerTypeDataSource = this.buyerTypePurchaseOrders
              this.preserveBuyerTypeData = this.buyerTypePurchaseOrders
              this.displayedSellerTypeTableColumns = ["Purchase Order No.", "Invoice Versions", "Buyer Name"]
              this.sellerTypeDataSource = this.sellerTypePurchaseOrders
              this.preseveSellerTypeData = this.sellerTypePurchaseOrders
            }
          }
        }
      )
    }
    catch (e) { }
  }

  applySellerFilter(event: Event) {
    try {
      const filterValueSeller = (event.target as HTMLInputElement).value;
      if (this.sellerTypeSearch === '') {
        this.sellerTypeDataSource = this.sellerTypePurchaseOrders
        return
      }
      this.sellerTypeDataSource = this.sellerTypeDataSource
        .filter(allPOs => {
          return allPOs.purchaseOrderNumber.toLocaleLowerCase().includes(this.sellerTypeSearch.toLocaleLowerCase())
        })
    }
    catch (e) {
    }
  }

  applyBuyerFilter(event: Event) {
    try {
      const filterValueBuyer = (event.target as HTMLInputElement).value;
      if (this.buyerTypeSearch === '') {
        this.buyerTypeDataSource = this.buyerTypePurchaseOrders
        return
      }
      this.buyerTypeDataSource = this.buyerTypeDataSource.filter(allPos => {
        return allPos.purchaseOrderNumber.includes(this.buyerTypeSearch)
      })
    }
    catch (e) {
    }
  }
}
