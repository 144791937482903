import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PurchaseOrderModel } from 'src/app/datamodels/purchaseOrderModel/purchaseOrderDetailsModel';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { AddressBook } from '../../datamodels/groupedmodel/addressBook';
import { ItemDetails } from 'src/app/datamodels/groupedmodel/itemDetails';
import { SellerDetails } from 'src/app/datamodels/groupedmodel/sellerDetails';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { InvoiceModel } from 'src/app/datamodels/invoiceOrderModel/invoiceDetailsModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PurchaseOrderDialogService } from 'src/app/services/purchase-order-dialog.service';
import { VersionInvoicing } from 'src/app/datamodels/invoiceOrderModel/invoiceVersionDataModel';
import { ReportProblemService } from 'src/app/services/report-problem.service';
import { RaiseDisputeModel } from 'src/app/datamodels/groupedmodel/raiseDisputeModel';
import { UtilsService } from 'src/app/services/utils.service';
import { AdditionalCharges } from 'src/app/datamodels/groupedmodel/additionalCharges';
interface invoiceVersionsOnDisplay {
  invoiceNumber: string;
  invoiceVersion: number;
  invoiceID: string;
  invoiceStatus: string;
}
@Component({
  selector: 'app-view-purchase-order',
  templateUrl: './view-purchase-order.component.html',
  styleUrls: ['./view-purchase-order.component.css']
})
export class ViewPurchaseOrderComponent implements OnInit {
  private loginUserInfo: any
  private currentUserId: string
  purchaseOrderID: string;
  isLoading: boolean = false
  currentPurchaseOrderwithId: PurchaseOrderModel;
  itemDetailsinPO: ItemDetails[] = [];
  sellerDetails: SellerDetails[] = [];
  sellerAccountType: boolean = false
  // if this PO is created by 1seller for some order he wants from another 2seller
  isUserActingBuyer: boolean = false
  // if this PO is created by other buyers for some order they want from this 1seller
  isUserActingSeller: boolean = false
  buyerAccountType: boolean = false
  gstDetailsPending: boolean = false;
  gstVerificationPending: boolean = false;
  fetchAccountPOsByAccountType: string;
  displayedSellerTypeTableColumns: string[] = []
  displayedBuyerTypeTableColumns: string[] = []
  allInvoicesForPurchaseOrder: InvoiceModel[] = []
  invoiceVersionsOfPO: VersionInvoicing[] = []
  allInvoiceNumbers: invoiceVersionsOnDisplay[] = []
  allowMoreInvoices: boolean = true;
  poIssues: RaiseDisputeModel[] = [];
  issueForPOExist: boolean = false;
  issuesFound: RaiseDisputeModel[] = [];
  additionalBuyerCharges: AdditionalCharges[] = []
  totalDeliverycharges: number;
  isAnyInvoiceDelivered: boolean = false;
  checkIfReturnableIn7: number;
  atleast1InvoDeliveredMode: boolean = false;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private handlePurchaseOrder: HandlePurchaseOrderService,
    private _snackBar: MatSnackBar,
    private pods: PurchaseOrderDialogService,
    private rps: ReportProblemService,
    private utils: UtilsService
  ) {

  }


  ngOnInit(): void {
    this.isLoading = true;
    this.purchaseOrderID = this.route.snapshot.params.poId;
    this.loginUserInfo = this.authService.getUserLoginInfo();
    this.currentUserId = this.loginUserInfo.userId


    //    a seller can have generate inoice towards orders that other buyers created
    //   but also have a download invoice button depending upon if 
    //   the order 1sellerAccount created towards 2sellerAccount 

    // we need to bifurcate
    //  1   - if 1seller / sellertyp - thisaccount - need to show 
    //   generate Inovoice button if the userId matches with sellerId on view - p - o pageBanner
    //   2  - if 1seller / buyer - type - thisAccount - show status of invoice generated towards his order
    //   on this page and give button to download invoice / viewInvoice

    // -if this is a buyer account , then also 2nd condition satisfies, and the currentUsers Id will be in PO's buyerDetails
    this.getCurrentPurchaseOrder(this.purchaseOrderID);
    this.loginUserInfo.accounttype === 'Seller' ? this.sellerAccountType = true : this.buyerAccountType = true
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
    }
  }

  getCurrentPurchaseOrder(purchaseOrderID: string) {
    try {
      this.handlePurchaseOrder.getSinglePurchaseOrder(purchaseOrderID).subscribe(
        (purchaseOrder) => {
          this.currentPurchaseOrderwithId = purchaseOrder
          if (this.currentPurchaseOrderwithId) {
            this.isLoading = false;
            if (this.currentUserId === this.currentPurchaseOrderwithId.buyerDetails.buyerId) {
              this.isUserActingBuyer = true
              this.isUserActingSeller = false
            }
            if (this.currentUserId === this.currentPurchaseOrderwithId.sellerDetails._id) {
              this.isUserActingBuyer = false
              this.isUserActingSeller = true
            }
            this.itemDetailsinPO = this.currentPurchaseOrderwithId.itemDetails

            if (this.currentPurchaseOrderwithId.additionalCharges && this.currentPurchaseOrderwithId.additionalCharges.length > 0) {
              this.additionalBuyerCharges = this.currentPurchaseOrderwithId.additionalCharges.filter(allCharges => {
                return allCharges.account === 'Buyer'
              })
            }
            this.totalDeliverycharges = this.additionalBuyerCharges.reduce((sum, item) =>
              sum + item.amount, 0
            )
            const today = new Date()
            this.atleast1InvoDeliveredMode = this.currentPurchaseOrderwithId.invoiceVersions.some(versions => {
              return versions.invoiceStatus === 'Delivered'
            })
            this.checkIfReturnableIn7 = this.utils.getDatesDifference(today, purchaseOrder.dateOfPurchaseOrderDelivery)
            this.getAllInvoicesForPurchaseOrder(this.currentPurchaseOrderwithId)
            this.getPurchaseOrderIssues(purchaseOrderID)
          }
        }
      )
    }
    catch (e) { }
  }

  async getAllInvoicesForPurchaseOrder(purchaseOrder: PurchaseOrderModel) {
    try {
      this.invoiceVersionsOfPO = purchaseOrder.invoiceVersions
      if (this.invoiceVersionsOfPO && this.invoiceVersionsOfPO.length > 0) {
        const totalAmountRaisedInAllVersions = this.invoiceVersionsOfPO.reduce((sum, item) => sum + (item.invoiceAmount), 0)
        this.isAnyInvoiceDelivered = this.invoiceVersionsOfPO.some(eachVer => {
          return eachVer.invoiceStatus === "Delivered"
        })
        totalAmountRaisedInAllVersions === purchaseOrder.orderValueIncludingTax ? this.allowMoreInvoices = false : this.allowMoreInvoices = true
        this.allInvoiceNumbers = this.invoiceVersionsOfPO.map((eachVersion) => {
          return {
            invoiceNumber: eachVersion.invoiceNumber,
            invoiceVersion: eachVersion.version,
            invoiceID: eachVersion.invoiceID,
            invoiceStatus: eachVersion.invoiceStatus,
          }
        })
      }

      return this.invoiceVersionsOfPO
    }
    catch (e) { }
  }

  // after this - the Seller can add shipping details - like truck number, docket number , docket ID etc - feature pending
  acceptPurchaseOrder(currentPurchaseOrderwithId: PurchaseOrderModel) {
    try {
      const message = "Confirm all Items in Purchase Order are Available in Good Quality"
      const action = "Confirm"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 10000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarRef.onAction().subscribe(() => {
        this.updatePurchaseOrderStatus(currentPurchaseOrderwithId)
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }

  updatePurchaseOrderStatus(currentPurchaseOrderwithId: PurchaseOrderModel) {
    try {
      this.isLoading = true
      this.handlePurchaseOrder.updatePurchaseOrderStatusOf(currentPurchaseOrderwithId._id, currentPurchaseOrderwithId.purchaseOrderNumber).then(itemIs => {
        // this.currentPurchaseOrderwithId = itemIs;
        if (itemIs.status === 201) {
          this.getCurrentPurchaseOrder(currentPurchaseOrderwithId._id)
          this.purchaseOrderStatusChanged()
        }
      }).catch(
        error => {
          this.errorpurchaseOrderStatusChanged
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  errorpurchaseOrderStatusChanged() {
    const message = `Oops ! An Error Occured - Failure in status change`
    const action = ""
    // give snackBar error then route to profule page on action
    // login error notific
    let snackbarRef = this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    })
    snackbarRef.onAction().subscribe(() => {
    })
  }

  purchaseOrderStatusChanged() {
    try {
      const message = `Purchase Order Status Changed from New to Active`
      const action = ""
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarRef.onAction().subscribe(() => {
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
  newPOnotif() {
    try {
      const message = "Purchase Order is New. You can download once it is Active"
      const action = "Confirm"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(message, action, {
        duration: 10000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
  // after this - the Seller can add shipping details - like truck number, docket number , docket ID etc - feature pending
  cancelPurchaseOrder(currentPurchaseOrderwithId: PurchaseOrderModel) {
    try {
      const message = "Purchase Order will be Cancelled "
      const action = "Confirm"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 10000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarRef.onAction().subscribe(() => {
        this.updatePurchaseOrderStatus(currentPurchaseOrderwithId)
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }

  cancelPurchaseOrderStatus(currentPurchaseOrderwithId: PurchaseOrderModel) {
    try {
      let useRefundAmount: number
      if (currentPurchaseOrderwithId && currentPurchaseOrderwithId.poStatus === "New") {
        useRefundAmount = currentPurchaseOrderwithId.amountPaidAtCheckout
      }
      else {
        useRefundAmount = 0
      }
      const cancelPurchaseOrderParams = {
        purchaseOrderID: currentPurchaseOrderwithId._id,
        refundAmount: useRefundAmount,
        purchaseOrderNumber: currentPurchaseOrderwithId.purchaseOrderNumber,
        purchaseOrderGenerateDate: currentPurchaseOrderwithId.dateOfPOGenerated,
        poStatusBeforeCancel: currentPurchaseOrderwithId.poStatus,
        userIDcancelling: this.currentUserId,
        accountTypeOfUser: this.loginUserInfo.accounttype,
        // if accounttype of User is Seller - he can act as a Seller for PO or as a Buyer too for PO if he placed a PO
        userActingAsSeller: this.isUserActingSeller,
        userActingAsBuyer: this.isUserActingBuyer
      }
      this.pods.cancelPurchaseOrder(cancelPurchaseOrderParams).subscribe(purchaseOrderStatusBack => {
        if (purchaseOrderStatusBack === undefined || purchaseOrderStatusBack === true) {
          this.getCurrentPurchaseOrder(currentPurchaseOrderwithId._id)
        }
      })
    }
    catch (e) {
      throw e
    }
  }

  getPurchaseOrderIssues(purchaseOrderID: string) {
    try {
      this.rps.getCurrentOrderissue(purchaseOrderID).then(
        (allPOIssues) => {
          if (allPOIssues && allPOIssues.status === 200) {
            this.issuesFound = allPOIssues.issues
            this.issuesFound.length && this.issuesFound.length > 0 ? this.issueForPOExist = true : this.issueForPOExist = false
            this.rps.shareFoundIssues(this.issuesFound)
          }
        }
      )
    }
    catch (e) {
      throw e
    }
  }

  demandInvoice(poId: string) {
    try {
      this.isLoading = true
      if (this.currentPurchaseOrderwithId.invoiceStatus !== 'Active' && (this.isUserActingBuyer || this.buyerAccountType)) {
        this.handlePurchaseOrder.demandInvoice(poId).then(
          messageResponse => {
            if (messageResponse) {
              this.isLoading = false
              const message = "Reminder sent to Seller to raise Invoice"
              const Action = "Ok"
              this.reminderSentSuccessfull(message, Action)
            }
          }
        )
      }
    }
    catch (e) {
      throw e
    }

  }

  reminderSentSuccessfull(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 6000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
    }
    catch (e) {
      throw e
    }

  }

}
