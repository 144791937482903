import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';
interface selectAccount {
  id: number,
  accountTypeName: string,
  accountTypeIMG: {
    sector: string,
    img: string
  }[],
  accountSubType: string,
  checked: boolean
}
@Component({
  selector: 'app-one-accountype',
  templateUrl: './one-accountype.component.html',
  styleUrls: ['./one-accountype.component.css']
})
export class OneAccountypeComponent implements OnInit {
  allComplete: boolean = false;
  atleastOneItemSelected: boolean = false;
  selectedAccountsCards: selectAccount[] = []
  // accountTypes: selectAccount[] = [
  //   { id: 1, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-producer-fpo.png', accountSubType: "Farmers & Producers Company" },
  //   { id: 2, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-mill-fatory.png', accountSubType: "Mills & Food Processing Factory" },
  //   // { id: 3, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/3-myp-agroprocess.png', accountSubType: "Agro-Processor" },
  //   // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/agent-signup.svg', accountSubType: "Agent | Broker" },
  //   // { id: 5, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-trader-whoelsale.png', accountSubType: "Trader & Merchant" },
  //   // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "FMCG Company" },
  //   { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "Food & Beverage Company" },
  //   { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-ayurvedawellnessmyapaar.png', accountSubType: "Herbaceuticals & Ayurveda Company" },
  //   { id: 7, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/6-myp-rest.png', accountSubType: "Restaurant & Cafe" },
  //   // { id: 8, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/7-myp-cafe.png', accountSubType: "Cafe" },
  //   // { id: 9, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/8-cloudKitchen.png', accountSubType: "Cloud Kitchen" },
  //   { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/10-kirana.png', accountSubType: "Supermart & Grocer" },

  // ]
  accountTypes: selectAccount[] = [
    {
      id: 1, checked: false, accountTypeName: "Seller",
      accountTypeIMG:
        [
          {
            sector: 'Farmer',
            img: '../../../../assets/accountTypes/farmer/myp-farmer.svg'
          },
          {
            sector: 'FPO',
            img: '../../../../assets/accountTypes/farmer/myp-fpo.png'
          }
        ]
      , accountSubType: "Farm Gate"
    },
    // { id: 3, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/3-myp-agroprocess.png', accountSubType: "Agro-Processor" },
    // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/agent-signup.svg', accountSubType: "Agent | Broker" },
    {
      id: 2, checked: false, accountTypeName: "Seller",
      accountTypeIMG:
        [
          {
            sector: 'Wholesaler',
            img: '../../../../assets/accountTypes/merchant/myp-whoelsale.png'
          },
          {
            sector: 'Trader',
            img: '../../../../assets/accountTypes/merchant/myp-trader.svg'
          }
        ],
      accountSubType: "Merchant"
    },
    {
      id: 3, checked: false, accountTypeName: "Seller",
      accountTypeIMG:
        [
          {
            sector: 'Mill',
            img: '../../../../assets/accountTypes/factory/myp-mill.png'
          },
          {
            sector: 'Factory',
            img: '../../../../assets/accountTypes/factory/myp-fatory.png'
          }
        ],
      accountSubType: "Food Processing"
    },
    {
      id: 4, checked: false, accountTypeName: "Buyer",
      accountTypeIMG:
        [
          {
            sector: 'Hotel',
            img: '../../../../assets/accountTypes/retail/myp-hotel.png'
          },
          {
            sector: 'Restaurant',
            img: '../../../../assets/accountTypes/retail/myp-rest.png'
          },
          {
            sector: 'Supermart',
            img: '../../../../assets/accountTypes/retail/myp-supermart.png'
          },
          {
            sector: 'Kirana',
            img: '../../../../assets/accountTypes/retail/myp-kirana.png'
          }
        ]
      , accountSubType: "Retail"
    },
    {
      id: 5, checked: false, accountTypeName: "Service",
      accountTypeIMG:
        [
          {
            sector: 'Trucking',
            img: '../../../../assets/accountTypes/logistics/myp-trucking.svg'
          },
          {
            sector: 'Freight Forwarder',
            img: '../../../../assets/accountTypes/logistics/myp-freightcargo.svg'
          }
        ],
      accountSubType: "Logistics"
    },
    // {
    //   id: 6, checked: false, accountTypeName: "Service",
    //   accountTypeIMG:
    //     [
    //       {
    //         sector: 'Non-Banking Finance Company',
    //         img: '../../../../assets/accountTypes/trade-finance/myp-nbfc.svg'
    //       },
    //       {
    //         sector: 'Regional Rural Finance',
    //         img: '../../../../assets/accountTypes/trade-finance/rural-rrf.svg'
    //       }
    //     ], accountSubType: "Trade Finance"
    // },
    // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "FMCG Company" },
    // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "Food & Beverage Company" },
    // { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-ayurvedawellnessmyapaar.png', accountSubType: "Herbaceuticals & Ayurveda Company" },
    // { id: 7, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/6-myp-rest.png', accountSubType: "Restaurant & Cafe" },
    // { id: 8, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/7-myp-cafe.png', accountSubType: "Cafe" },
    // { id: 9, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/8-cloudKitchen.png', accountSubType: "Cloud Kitchen" },

  ]
  loadAccountTypes: selectAccount[] = []
  sendAccTypeData: any;
  headerLoad: string
  constructor(private pageShift: SignUpPageShiftService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    this.headerLoad = 'pick your business'
  }
  onAccountChange(accountId: number): void {
    this.accountTypes.forEach(account => {
      account.checked = account.id === accountId;
    });
    this.selectedAccountsCards = this.accountTypes.filter(check => {
      return check.checked
    })
  }
  nextOne() {
    try {
      let useAccountType: string = ''
      // const checkIfSeller = this.selectedAccountsCards.some(eachCard => {
      //   return eachCard && eachCard.accountTypeName && eachCard.accountTypeName === 'Seller'
      // })
      // console.info('checkIfSeller', checkIfSeller)
      // checkIfSeller ? useAccountType = 'Seller' : useAccountType = 'Buyer'
      const allSubAccountTypes = this.selectedAccountsCards.map(eachCard => {
        const eachCardSubt = eachCard.accountSubType
        const eachCardSec = eachCard.accountTypeIMG.map(imgSec => {
          return imgSec.sector
        })
        const getSubtype = [eachCardSubt, ...eachCardSec]
        return getSubtype
      })
      const useAccST = allSubAccountTypes.pop()
      if (!useAccST || useAccST.length === 0) {
        this.signupError('Pick Business Types', "Ok")
        return
      }
      const addAccountType = {
        accountTypeName: this.selectedAccountsCards[0].accountTypeName,
        subAccountTypesChosen: useAccST
      }
      this.pageShift.setAccTypeData(addAccountType)
      this.router.navigate(['/signup/PersonalInfo'])
    }
    catch (e) {
      throw e
    }
  }
  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
