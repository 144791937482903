import { Injectable } from '@angular/core';
import { BlogPostModel } from '../datamodels/blogPostModel';
import * as CryptoJS from "crypto-js";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private darkThemeClass = 'dark-theme';
  shuffledBlogsRandom: BlogPostModel[];
  constructor(

  ) { }

  toggleTheme(): void {
    const body = document.body;
    if (body.classList.contains(this.darkThemeClass)) {
      body.classList.remove(this.darkThemeClass);
      localStorage.setItem('theme', 'light');
    } else {
      body.classList.add(this.darkThemeClass);
      localStorage.setItem('theme', 'dark');
    }
  }

  applySavedTheme(): void {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.body.classList.add(this.darkThemeClass);
    }
  }

  getDatesDifference(date1: Date, date2: Date) {
    const differenceTimeBetweenDates = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    const differenceDays = Math.ceil(differenceTimeBetweenDates / (1000 * 3600 * 24));
    return differenceDays
  }

  convertDataUrlToBlob(dataUrl: string): Blob {
    try {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
    catch (e) {
      throw e
    }
  }

  genObjId(): string {
    const prefix = 'FEED'; // First four characters
    const hexChars = '0123456789abcdef';
    let randomHex = '';

    // Generate 20 hexadecimal characters (to ensure a total of 24 characters)
    for (let i = 0; i < 20; i++) {
      randomHex += hexChars[Math.floor(Math.random() * 16)];
    }

    return prefix + randomHex;
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  createId() {
    const generateDateNow = new Date()
    const docOrderCode = generateDateNow.toString().replace(/\s|:/g, "").split("G")[0].slice(6)
    const alp1 = String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
    const alp2 = String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
    // Generate a random number (0-9)
    const number = Math.floor(Math.random() * 10); // 0-9
    // Combine the generated values into the code
    const code = alp1 + alp2 + number;
    const finalCode = 'oP' + code + docOrderCode
    return finalCode
  }
  getBusinessNameForURL(companyName: string) {
    const bnameToUse = companyName.replace(/[^a-zA-Z0-9]+/g, "_");
    return bnameToUse
  }

  getRandomBlogs(allBlogs: BlogPostModel[]) {
    this.shuffledBlogsRandom = this.shuffle(allBlogs)
    return this.shuffledBlogsRandom
  }

  playWidgetAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audios/myapaari.mp3";
    audio.load();
    audio.play();
  }

  playMessageAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audios/messageExchange.wav";
    audio.load();
    audio.play();
  }

  playLinkAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audios/link.wav";
    audio.load();
    audio.play();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  replaceLast4Digits(str: string) {
    // Ensure the string has at least 4 characters
    if (str.length >= 4) {
      return str.slice(0, -4) + '****';  // Replace last 4 characters with '*'
    } else {
      return str;  // If the string is shorter than 4 characters, return as is
    }
  }

  shuffle(array) {
    try {
      let currentIndex = array.length;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array
    }
    catch (e) {
      throw e
    }

  }

  // Function to check if platform is mobile
  isMobile(): boolean {
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // openWhatsApp(phone: number) {
  openMyWhapp() {
    let url: string
    const message = encodeURIComponent(
      "Hello ! Started using Myapaar Merchant Network to grow business and link with merchants, logistics & consultants. " +
      "You're in the food business, this platform is for you. " +
      "Download from google playstore. " +
      "https://play.google.com/store/apps/details?id=com.myapaar.ang&pcampaignid=web_share"
    );
    url = this.isMobile()
      ? `https://api.whatsapp.com/send?text=${message}`
      : `https://web.whatsapp.com/send?text=${message}`;
    this.playMessageAudio()
    window.open(url, "_blank");
  }
  openOwnrWhapp(ownPh: number) {
    let url: string
    const message = encodeURIComponent("Hey, check this item");
    // later we will use to invite on whatsapp to other ownerprofs
    // const phoneNumber = `${phone}`; // Replace with your WhatsApp number
    // if (phoneNumber.toString().length > 9) {
    //   url = this.isMobile()
    //     ? `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`
    //     : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    // }
    // else if (phoneNumber.toString().length < 5) {
    //   url = `https://api.whatsapp.com/send?text=${message}`
    // } 
    url = this.isMobile()
      ? `https://api.whatsapp.com/send?phone=91${ownPh}&text=${message}`
      : `https://web.whatsapp.com/send?phone=91${ownPh}&text=${message}`;
    this.playMessageAudio()
    window.open(url, "_blank");
  }

  shareFeed(seeFeed: string) {
    let checkFeedUrl = `www.myapaar.com/QuickFeed/ViewSharedFeed/${seeFeed}`
    const message = encodeURIComponent(
      "Sharing this Post with you. " +
      "You're in the food business, this platform is for you. " +
      "Download from google playstore. " +
      checkFeedUrl
    );
    checkFeedUrl = this.isMobile()
      ? `https://api.whatsapp.com/send?text=${message}`
      : `https://web.whatsapp.com/send?text=${message}`;
    this.playMessageAudio()
    window.open(checkFeedUrl, "_blank");
  }

  decryptData(encryptedData: string): any {
    try {
      const key = CryptoJS.enc.Utf8.parse(environment.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(environment.iv);
      const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      // Convert decrypted data to a UTF-8 string
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) throw new Error("Decryption failed");

      return JSON.parse(decryptedText); // Parse the JSON string back to an object
    } catch (error) {
      console.error("Decryption error:", error);
      return null;
    }
  }


}
