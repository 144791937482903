import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { Review } from 'src/app/datamodels/productreview';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginResponse } from 'src/app/datamodels/loginResponse';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listingId: string
  @Input() reviewSubmit: string
  @Input() reviewDelete: string
  @Output() totalReviewsEvent = new EventEmitter<string>();
  @Output() reviewDeletedEvent = new EventEmitter<string>();

  currentDate = new Date()

  alluserReviews: Review[] = [];
  reviews: Review[] = [];
  isLoading = false;
  totalPosts = 0;
  totalReviewsOnListing: number
  // currentPage = 1;
  // pageSizeOptions = [5, 10, 15, 20];
  loginUserInfo: any
  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  constructor(
    private route: ActivatedRoute,
    public commentservice: CommentpostService, private authService: AuthService) { }

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    if (this.userIsAuthenticated) {
      this.loginUserInfo = this.authService.getUserLoginInfo();
    }
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
      this.loginUserInfo = this.authService.getUserLoginInfo();
    });
    this.getReviews()
  }
  getReviews() {
    this.isLoading = true; //spinner starts
    if (this.listingId) {
      this.commentservice.getReviews(this.listingId).then
        (response => {
          this.isLoading = false; //spinner stops
          if (response && response.length > 0) {
            this.reviews = response
            // this.reviews = this.alluserReviews.filter(e => {
            //   return e.listingId === this.listingId
            // })
            this.totalReviewsOnListing = this.reviews.length
            this.totalReviewsEvent.emit(this.totalReviewsOnListing.toString())
            this.totalPosts = this.reviews.length
          }
        });
    }

  }
  ratingsAsStars(stars: any) {
    return Array(stars)
  }
  ngOnChanges() {
    // new post submitted from post create - status sent to parent singleListing - input added to sibling postlist and this triggers
    this.getReviews()
  }
  ngOnDestroy() {
    // prvent mem leak
    this.authListenerSubs.unsubscribe();
  }
  onDeleteComment(reviewId: string) {
    this.isLoading = true; //spinner starts
    this.commentservice.deleteReview(reviewId).then((resDel) => {
      if (resDel) {
        const deleted = 201
        this.reviewDeletedEvent.emit(deleted.toString())
        this.commentservice.getReviews(this.listingId);
      }
      else {
        this.isLoading = false; //spinner starts
      }
    });
  }

}
